import { setItem, parseObjectsToStore } from 'src/store/_helpers'

export function TRIGGER_SAVE (state, status) {
  return true
}

export function SAVING (state, status) {
  state.saving = status
}

export function SET_WIZARD_NEW (state, businessId) {
  state.wizardNew = businessId
}

export function BUSINESS_OVERVIEW_LOADING (state, { businessId, status }) {
  state.overviewLoading[businessId] = status
}

export function SET_BUSINESS_OVERVIEW (state, { businessId, overview }) {
  state.overviews[businessId] = {
    orders: parseObjectsToStore(overview.orders),
    payments: parseObjectsToStore(overview.payments)
  }
}

export function BROWSING (state, status) {
  state.browsing = status
}

export function LOADING (state, { businessId, status }) {
  state.loading[businessId] = status
}

export function BUSINESS_SUPERVISORS_LOADING (state, { businessId, status }) {
  state.supervisorsLoading[businessId] = status
}
export function SET_BUSINESS_SUPERVISORS (state, { businessId, supervisors }) {
  state.loggedSupervisors[businessId] = parseObjectsToStore(supervisors)
}

export function BUSINESS_CHEFS_LOADING (state, { businessId, status }) {
  state.chefsLoading[businessId] = status
}
export function SET_BUSINESS_CHEFS (state, { businessId, chefs }) {
  state.loggedChefs[businessId] = parseObjectsToStore(chefs)
}

export function BUSINESS_WAITERS_LOADING (state, { businessId, status }) {
  state.waitersLoading[businessId] = status
}
export function SET_BUSINESS_WAITERS (state, { businessId, waiters }) {
  state.loggedWaiters[businessId] = parseObjectsToStore(waiters)
}

const BUSINESS_DATA = [
  'title',
  'updatedAt',
  'image',
  'currency'
]

// Save only that business data you need to show on dashboard
export function SAVE_FOR_SHOW (state, businesses) {
  state.all = []

  businesses.forEach(business => {
    // console.log(business)
    let businessData = {}
    businessData.id = business.id

    for (let key in business.attributes) {
      if(key === 'image') {
        const image = business.get(key)
        if(image) {
          businessData[key] = {}
          businessData[key].url = image.url()

        } else {
          businessData[key] = undefined
        }

      } else {
        businessData[key] = business.get(key)
      }
    }

    state.all.push(businessData)
  });
}

export function SET_LIVE_CREDITS(state, payload) {
  state.liveCredits = payload
}

export function SET_PURCHASES(state, payload) {
  state.purchases = payload
}

export function SET_SUBSCRIPTION(state, payload) {
  state.subscription = payload
}
export function SET_LOADING_SUBSCRIPTION(state, payload) {
  state.loadingSubscription = payload
}

export function SET_CURRENT(state, payload) {
  state.current = payload
}
