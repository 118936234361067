import { Parse } from 'src/boot/parse'
import { getBusinessPointer } from 'src/store/_helpers'
import { LocalStorage } from 'quasar'

export function getData ({ commit, state }) {
  console.log(commit, state);
  return state.series
  // return new Parse.Object('User', user).save()
}

export async function getOrders ({ commit }, payload) {
  const { businessId, startDate, endDate } = payload

  const query = new Parse.Query("RestaurantOrder")
    .include(["restaurant_item", "waiter"])

  query.limit(100000)

  if(businessId)  query.equalTo('business', getBusinessPointer(businessId))
  if(startDate)   query.greaterThan('createdAt', startDate)
  if(endDate)     query.lessThan('createdAt', endDate)
  const res = await query.find({sessionToken: LocalStorage.getItem('auth.token')})
    .catch(error => console.log('#### getOrders error:', error) )

  commit('SET_RESTAURANT_ORDERS', res)
  return res
}

export async function getPayments ({ commit }, payload) {
  const { businessId, startDate, endDate } = payload

  const query = new Parse.Query("Payment")


  query.limit(100000)

  if(businessId)  query.equalTo('business', getBusinessPointer(businessId))
  if(startDate)   query.greaterThan('createdAt', startDate)
  if(endDate)     query.lessThan('createdAt', endDate)

  const res = await query.find({sessionToken: LocalStorage.getItem('auth.token')})
    .catch(error => console.log('#### getPayments error:', error) )

  commit('SET_PAYMENTS', res)

  return res

}

export async function getRatings ({ commit }, payload) {
  const { businessId, startDate, endDate } = payload

  const query = new Parse.Query("RestaurantItemRating")
    .include(["restaurant_item", "waiter"])

  query.limit(100000)

  if(businessId)  query.equalTo('business', getBusinessPointer(businessId))
  if(startDate)   query.greaterThan('createdAt', startDate)
  if(endDate)     query.lessThan('createdAt', endDate)

  const res = await query.find({sessionToken: LocalStorage.getItem('auth.token')})
    .catch(error => console.log('#### getRatings error:', error) )

  commit('SET_RATINGS', res)
  return res

}
export async function getSummaries ({ commit }, payload) {
  const { businessId, startDate, endDate } = payload

  const query = new Parse.Query("RestaurantOrderSummary")
    .include(["waiter"])

  query.limit(100000)

  if(businessId)  query.equalTo('business', getBusinessPointer(businessId))
  if(startDate)   query.greaterThan('createdAt', startDate)
  if(endDate)     query.lessThan('createdAt', endDate)
  const res = await query.find({sessionToken: LocalStorage.getItem('auth.token')})
    .catch(error => console.log('#### getSummaries error:', error) )

  commit('SET_SUMMARIES', res)
  return res
}
