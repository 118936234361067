import { boot } from 'quasar/wrappers'
import Parse from 'parse'

export default boot(({ app }) => {
  Parse.initialize(process.env.Parse.APP_ID)
  Parse.serverURL = process.env.Parse.PARSE_SERVER_URL
  // Parse.User.enableRevocableSession()

  app.config.globalProperties.$parse = Parse
})


export { Parse }
