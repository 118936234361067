import { getItem } from 'src/store/_helpers'

export const one = (state) => (id) => {
  return getItem(state.all, id)
}

export const isUnique = (state) => ({ column, value, id }) => {
  for (const i in state.all) {
    if (state.all[i][column] === value && state.all[i].id !== id) {
      return false
    }
  }
  return true
}
