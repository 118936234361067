import { Parse } from 'src/boot/parse'
import { Platform } from 'quasar'
import { LocalStorage } from 'quasar'

export async function attempt({ commit }, { email, password }) {
  return new Promise(async (resolve, reject) => {
    email = email.toLowerCase()
    const user = await new Parse.Query('_User').equalTo('email', email)
    .find(LocalStorage.getItem('auth.token') ? 
    {sessionToken: LocalStorage.getItem('auth.token')} : {})
    if (!user.length) {
      resolve({
        success: false,
        error: 'noUser'
      })
      return 'noUser'
    }

    return Parse.User.logIn(email, password, { usePost: true }).then(async user => {
      commit('AUTH_SET_USER', user)

      if (!user.get('is_admin')) {
        user.set('is_admin', true).save()
      }

      const subscription = await new Parse.Query('_User')
        .equalTo('objectId', user.id)
        .subscribe()

      subscription.on('error', e => {
        console.log('#### Socket Error (auth user)', e)
      })
      subscription.on('update', (user) => {
        commit('AUTH_SET_USER', user)
      })
      subscription.on('delete', () => {
        dispatch('logout')
      })

      commit('AUTH_LOADING', false)
      resolve({ success: true })
    }).catch(() => {
      commit('AUTH_LOADING', false)
      resolve({
        success: false,
        error: 'fail'
      })
    })
  })
}

export function register({ dispatch, commit }, userData) {
  return new Promise(async (resolve, reject) => {
    userData.email = userData.email.toLowerCase()
    const existingUser = await new Parse.Query('_User').equalTo('email', userData.email)
    .find({sessionToken: LocalStorage.getItem('auth.token')})
    if (existingUser.length) {
      reject('existingUser')
    }

    let platform = 'admin_web'
    if (Platform.is.capacitor) {
      if (Platform.is.ios) {
        platform += '_ios'
      } else if (Platform.is.android) {
        platform += '_and'
      }
    }

    try {
      await new Parse.User({
        language: 1,
        blocked: false,
        is_admin: true,
        registered_using: platform,
        email: userData.email,
        username: userData.email,
        password: userData.password,
        name: userData.name,
        phone: userData.phone.replace(/\D/g, ''),
        country: userData.country
      }).signUp()

      await dispatch('attempt', {
        email: userData.email,
        password: userData.password
      })

      commit('SET_NEW_USER', {
        name: userData.name,
        email: userData.email,
        emailVerified2: false
      })

      const subscription = await new Parse.Query('_User')
        .equalTo('objectId', Parse.User.current().id)
        .subscribe()

      subscription.on('error', e => {
        console.log('#### Socket Error (auth newUser)', e)
      })
      subscription.on('update', (user) => {
        commit('SET_NEW_USER', {
          name: user.get('name'),
          email: user.get('email'),
          emailVerified2: !!user.get('emailVerified2')
        })
        if (!!user.get('emailVerified2')) {
          subscription.unsubscribe()
        }
      })
      subscription.on('delete', () => {
        commit('SET_NEW_USER', {})
        subscription.unsubscribe()
      })

      resolve(true)
    } catch (e) {
      reject(e.message)
    }
  })
}

export function sendPasswordResetLink({ commit }, { email }) {
  // scaffolding for custom reset emails
  return new Promise((resolve, reject) => {
    Parse.User.requestPasswordReset(email.toLowerCase())
      .then(() => resolve(true))
      .catch((e) => reject(e.code))
  })
}

export function sendVerificationCode({ commit, rootState }, { countryCode, phoneNumber }) {
  commit('AUTH_LOADING', true)
  return Parse.Cloud
    .run('sendVerificationCode', {
      from: 'DreamDiner Admin',
      phoneNumber: countryCode + phoneNumber.replace(/^0+/, '')
    }).then((code) => {
      commit('AUTH_VERIFICATION_CODE_SET', code)
      commit('AUTH_LOADING', false)
    })
}

export function uncheck({ commit }) {
  commit('AUTH_UNCHECK')
}

export async function refresh({ state, commit, dispatch }) {
  return new Promise(resolve => {
    if (LocalStorage.getItem('auth.token')) {
      Parse.User.become(LocalStorage.getItem('auth.token'))
        .then((user) => {
          commit('AUTH_SET_USER', Parse.User.current())
          commit('AUTH_LOADING', false)
          resolve({
            success: true,
            user: Parse.User.current()
          })
        }, (error) => {
          console.log('refresh error: ' + error);
          Parse.User.logOut().then(() => {
            commit('AUTH_FLUSH_DATA')
            commit('AUTH_LOADING', false)
            resolve({
              success: false,
              error
            })
            console.log('#### auth refresh error', error)
          })
        })
    } else {
      commit('AUTH_FLUSH_DATA')
    }
  })
}

export function logout({ commit }, redirectToLogin = false) {
  commit('AUTH_LOADING', true)
  Parse.User.logOut({sessionToken: LocalStorage.getItem('auth.token')}).then(() => {
    commit('AUTH_FLUSH_DATA')
    window.location.reload()
  })
}

export function verifyOtp({ commit }, otp) {
  return new Promise(resolve => {
    new Parse.Query('User')
      .equalTo('objectId', Parse.User.current().id)
      .equalTo('verification_code', parseInt(otp))
      .count({sessionToken: LocalStorage.getItem('auth.token')})
      .then(count => {
        if (count > 0) {
          Parse.User.current()
            .set({ emailVerified2: true })
            .save()
            .then(() => {
              resolve({ success: true })
            })
        } else {
          resolve({
            success: false,
            error: 'Incorrect code'
          })
        }
      }).catch(error => {
        console.log('#### auth verifyOtp error', error)
        resolve({
          success: false,
          error
        })
      })
  })
}

export function resendVerificationEmail() {
  return new Promise(async resolve => {
    Parse.Cloud.run('resendEmailVerification').then(() => {
      resolve({
        success: true
      })
    }).catch(error => {
      console.log('#### auth resendVerificationEmail error', error)
      resolve({
        success: false,
        error
      })
    })
  })
}

export async function updateUser({ commit, dispatch }, userData) {
  const user = await new Parse.Query('_User').get(userData.id)

  for(const key in userData) {

    if(key === 'image') {
      const image = await new Parse.File('user_' + new Date().getTime() + '.jpg', { base64: userData.image }).save()
      user.set('image', image)
      continue
    }
    user.set(key, userData[key])
  }

  await user.save()
  commit('AUTH_UPDATE_USER', user)
}
