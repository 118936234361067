import { Parse } from 'src/boot/parse'
import { LocalStorage } from 'quasar'
import { parseObjectToStore, getParseObjectAttrs } from 'src/store/_helpers'

import { Connect } from 'src/boot/connect'

function businessIdToPointer (businessId) {
  return {
    __type: 'Pointer',
    className: 'Business',
    objectId: businessId
  }
}

function getStartOfDay () {
  const startOfDay = new Date()
  startOfDay.setHours(0, 0, 0, 0)
  return startOfDay
}

export function browse ({ commit, rootState }) {
  commit('BROWSING', true)
  const businessQuery = new Parse.Query('Business')
    .equalTo('admin', Parse.User.current())

  // load demo business
  const demoQuery = new Parse.Query('Business')
    .equalTo('objectId', process.env.BUSINESS_DEMO_ID)

  return new Promise(async (resolve, reject) => {
    Parse.Query.or(businessQuery,demoQuery)
      .find({sessionToken: LocalStorage.getItem('auth.token')})
      .then(businesses => {
        commit('SAVE_FOR_SHOW', businesses)
        commit('BROWSING', false)
        resolve(businesses)
      })
      .catch(error => {
        console.log('#### businesses get error', error)
        commit('BROWSING', false)
        reject(error)
      })
    })
}

export function get ({ commit, state }, businessId) {
  return new Promise((resolve, reject) => {
    commit('LOADING', { businessId, status: true })
    new Parse.Query('Business')
      .get(businessId, {sessionToken: LocalStorage.getItem('auth.token')})
      .then(result => {
        commit('SAVE_MANY', [result])
        resolve(result)
      })
      .catch(error => {
        console.log('#### businesses get error', error)
        reject(error)
      })
      commit('LOADING', { businessId, status: false })
    })
}

export async function save ({ commit, dispatch, state }, business) {
  commit('SAVING', true)
  const currentUser = Parse.User.current()
  if (business.image && business.image.length && business.image.indexOf('data:image') === 0) {
    business.image = await dispatch('dreamdiner/saveImage', { base64: business.image }, { root: true })
  } else {
    delete business.image
  }

  if (!business.admin) {
    business.admin = currentUser
  }

  if (Object.prototype.hasOwnProperty.call(business, 'local')) {
    delete business.local
  }

  business.admin = {
    __type: 'Pointer',
    className: '_User',
    objectId: business.admin.id
  }

  if(business.currency) {
    business.currency = {
      __type: 'Pointer',
      className: 'Currency',
      objectId: (business.currency.id) ? business.currency.id : business.currency.objectId
    }
  }

  if (business.email === currentUser.email) {
    business.businessEmailVerified = true
  }

  return new Promise(async (resolve, reject) => {
    await new Parse.Object('Business', business).save().then(savedBusiness => {
      const ID = savedBusiness?.id
      const Business = Parse.Object.extend("Business")
      const businessPointer = Business.createWithoutData(ID)

      const user = Parse.User.current()
      if (!user.get('business')) {
        user.set('business', businessPointer)
      }
      
      const businesses = (user.get('businesses')) ? user.get('businesses') : []
      businesses.push(businessPointer)

      user.set('businesses', businesses)
        .save()
        .then(() => {
          dispatch('get', ID)

          resolve(ID)
        })
    }).catch(error => {
      console.log('#### businesses save error', error)
      resolve(error)
    })
    commit('SAVING', false)
  })
}

export async function update({ commit, dispatch, state }, business) {
  commit('SAVING', true)
  if (business.image && business.image.length && business.image.indexOf('data:image') === 0) {
    business.image = await dispatch('dreamdiner/saveImage', { base64: business.image }, { root: true })
  } else {
    delete business.image
  }
  if(business.currency) {
    business.currency = {
      __type: 'Pointer',
      className: 'Currency',
      objectId: (business.currency.id) ? business.currency.id : business.currency.objectId
    }
  }

  if(business.location) {
    business.location = {
      "__type": "GeoPoint",
      latitude: business.location.latitude,
      longitude: business.location.longitude
    }
  }

  return new Promise(async (resolve, reject) => {
    await new Parse.Object('Business', business).save().then(savedBusiness => {
      const ID = savedBusiness?.id
      commit('SAVE_MANY_FOR_PREVIEW', [savedBusiness])

      // if it is current business - update in store
      if(state.current && state.current.id === savedBusiness.id) {
        commit('SET_CURRENT', getParseObjectAttrs(savedBusiness, state.emptyInstance))
      }

      resolve(ID)

    }).catch(error => {
      console.log('[dd store:] businesses update error', error)
      resolve(error)
    })
    commit('SAVING', false)
  })
}

export function saveAndSetCurrent ({ commit, dispatch }, business) {
  return new Promise(async (resolve, reject) => {
    const businessId = await dispatch('save', business)
    commit('SET_WIZARD_NEW', businessId)
    resolve(businessId)
  })
}
const parseObjectNames = [
  'categories',
  'chefs',
  'hours',
  'items',
  'kitchens',
  'menus',
  'supervisors',
  'tables',
  'waiters',
  'extras',
  'groups'
]

export function clearCurrentBusiness({ commit }) {
  parseObjectNames.forEach(objects =>{
    commit(`${objects}/DELETE_ALL`, [], { root: true })
  })
}

export function verifyBusinessEmail ({ commit, rootState }, businessId) {
  return Parse.Cloud.run('sendBusinessEmailVerification', { businessId })
}

export function getOverviewData ({ commit }, businessId) {
  commit('BUSINESS_OVERVIEW_LOADING', { businessId, status: true })
  return new Promise((resolve, reject) => {
    return new Parse.Query('RestaurantOrderSummary')
      .equalTo('business', businessIdToPointer(businessId))
      .greaterThan('createdAt', getStartOfDay())
      .find({sessionToken: LocalStorage.getItem('auth.token')})
      .then(orders => {
        const orderIds = []
        for (const i in orders) {
          orderIds.push(orders[i].id)
        }
        new Parse.Query('Payment')
          .containedIn('restaurant_order_summary', orderIds)
          .find({sessionToken: LocalStorage.getItem('auth.token')})
          .then(payments => {
            commit('SET_BUSINESS_OVERVIEW', { businessId, overview: { orders, payments } })
            commit('BUSINESS_OVERVIEW_LOADING', { businessId, status: false })
            resolve({ orders, payments})
          })
      })
      .catch(error => {
        console.log('[dd store:] businesses getOverviewData error', error)
        commit('BUSINESS_OVERVIEW_LOADING', { businessId, status: false })
        reject(error)
      })
    })
}

export function getLoggedStaff ({ commit }, businessId) {
  // commit('BUSINESS_SUPERVISORS_LOADING', { businessId, status: true })
  commit('BUSINESS_CHEFS_LOADING', { businessId, status: true })
  commit('BUSINESS_WAITERS_LOADING', { businessId, status: true })

  const loggesStaffQuery = (query) => {
    return query
      .equalTo('business', businessIdToPointer(businessId))
      .greaterThan('from', getStartOfDay())
      .doesNotExist('to')
  }

  return new Promise((resolve, reject) => {
    Promise.all([
      loggesStaffQuery(new Parse.Query('WaiterLog'))
        .include('waiter')
        .find({sessionToken: LocalStorage.getItem('auth.token')})
        .then((waiterLogs) => {
          const waiters = []
          for (const i in waiterLogs) {
            waiters.push(waiterLogs[i].get('waiter'))
          }
          commit('SET_BUSINESS_WAITERS', { businessId, waiters })
          commit('BUSINESS_WAITERS_LOADING', { businessId, status: false })
        })
        .catch(error => {
          console.log('[dd store:] businesses getLoggedStaff waiters error', error)
          commit('BUSINESS_WAITERS_LOADING', { businessId, status: false })
        }),
      loggesStaffQuery(new Parse.Query('KitchenLog'))
        .include('kitchen')
        .find({sessionToken: LocalStorage.getItem('auth.token')})
        .then((kitchenLogs) => {
          const chefs = []
          for (const i in kitchenLogs) {
            chefs.push(kitchenLogs[i].get('kitchen'))
          }
          commit('SET_BUSINESS_CHEFS', { businessId, chefs })
          commit('BUSINESS_CHEFS_LOADING', { businessId, status: false })
        })
        .catch(error => {
          console.log('[dd store:] businesses getLoggedStaff chefs error', error)
          commit('BUSINESS_CHEFS_LOADING', { businessId, status: false })
        })
    ])
  })
}
// SET_BUSINESS_SUPERVISORS

export async function invite ({ commit }, { user_phone, business_id, staff_type }) {
  return new Promise(resolve => {
    Parse.Cloud.run('sendStaffSMS', {
      user_phone,
      business_id,
      staff_type
    }).then(() => {
      resolve({ success: true })
    }).catch(error => {
      resolve({ success: false, error })
      console.log('[dd store:] businesses invite error', error)
    })
    resolve({ success: true })
  })
}

// TODO: Save data into related stores
const getStoreNameByObjectName = {
  'Category': 'categories',
  'Kitchen': 'chefs',
  'OpeningHours': 'hours',
  'RestaurantItem': 'items',
  'KitchenTerminal': 'kitchens',
  'kitchen': 'chefs',
  'RestaurantMenu': 'menus',
  'SubAdmin': 'supervisors',
  'Table': 'tables',
  'Waiter': 'waiters',
  "SubAdmin": 'supervisors',
  'RestaurantItemExtra': 'extras',
  'RestaurantItemExtraGroup': 'groups'
}

export async function getDashboardData({commit, state}, { businessId }) {

  return new Promise(resolve => {
    const params = {
      businessId : businessId,
      sessionToken: LocalStorage.getItem('auth.token')
    }
    
    Parse.Cloud.run('getBusinessDasboardData', params)
      .then((result) => {
        result.order.forEach((objectName, index) => {
          commit(
            `${getStoreNameByObjectName[objectName]}/SAVE_MANY_FOR_PREVIEW`,
            result.data[index],
            { root: true }
          )
        })
        resolve({ success: true })
      }).catch(error => {
        resolve({ success: false, error })
        console.log('[dd store:] businesses dashboard data error', error)
        window.location.reload()
      })

  })

}

export async function togglePublicAccess( { commit, dispatch, getters }, businessId ) {
  commit('LOADING', { businessId: businessId, status: true})
  const business = getters.one(businessId)

  await dispatch('save', {
    id: businessId,
    hide_from_client_search: !business.hide_from_client_search
  })
  commit('LOADING', { businessId: businessId, status: false})
}

export async function getLiveBusiness({ dispatch, commit }, businessId) {
  let query = new Parse.Query('Business')
  query.equalTo('objectId', businessId)
  query.select('credits_restaurant_items')
  query.select('stripe_account_id')
  query.select('stripe_charges_enabled')
  query.select('business_subscription')

  let subscription = await query.subscribe()

  subscription.on('update', (object) => {
    const credits = object.get('credits_restaurant_items')
    commit('SET_LIVE_CREDITS', credits)
    dispatch('getSubscription', object.id)
    commit('SAVE_MANY_FOR_PREVIEW', [object])
    commit('SAVING', false)
  });

  return subscription.unsubscribe
}
export async function getLiveSubscription({ commit }, subId) {
  const query = new Parse.Query('BusinessSubscription')
  query.equalTo('objectId', subId)
  query.select('active')
  query.select('active_till')

  const subscription = await query.subscribe()

  subscription.on('update', (object) => {
    commit('SET_SUBSCRIPTION', parseObjectToStore(object))
  });

  return subscription.unsubscribe
}

export async function getPurchases({ state, commit }, businessId) {
  try {
    const query = new Parse.Query('BusinessPurchase');
    query.equalTo('businessObjectId', businessId);

    const purchases = await query.find({ sessionToken: LocalStorage.getItem('auth.token') });
    
    // Convert Parse objects to plain JavaScript objects
    const purchasesData = purchases.map(purchase => purchase.toJSON());
    
    commit('SET_PURCHASES', purchasesData);
    return purchasesData;
  } catch (error) {
    console.log('####Error fetching purchases:', error);
    throw error;
  }
}

export function createStripeSession ({ getters }, businessId) {
  const business = getters.one(businessId)
  const keys = process.env.STRIPE_SUBSCRIPTION_KEYS.split(' ')
  const data = {
    product: keys[0],
    priceId: keys[1], 
    businessId, 
    customer_email: business.email
  }
  return Parse.Cloud.run('createStripeSession', data)
}

export async function createStripePortal ({ state, getters }, businessId) {
  let customer = ''
  if(state.subscription) {
    customer = state.subscription.customer
  } else {
    const business = getters.one(businessId)
    const subId = business.business_subscription.id
    const sub = await new Parse.Query('BusinessSubscription')
      .get(subId, {sessionToken: LocalStorage.getItem('auth.token')})

    customer = sub.get('customer')
  }
  return Parse.Cloud.run('createCustomerPortal', { customerId: customer, businessId })
}

export async function getSubscription({ getters, commit }, businessId) {
  commit('SET_LOADING_SUBSCRIPTION', true)
  let subId = null
  try {
    const business = getters.one(businessId)
    subId = business.business_subscription?.id

    if(subId) {
      const sub = await new Parse.Query('BusinessSubscription')
        .get(subId, {sessionToken: LocalStorage.getItem('auth.token')})
  
      if(sub) commit('SET_SUBSCRIPTION', parseObjectToStore(sub))
    }
  } catch(e) {
    console.warn('dd error get business subscription', e)
    commit('SET_LOADING_SUBSCRIPTION', false)
  }
  commit('SET_LOADING_SUBSCRIPTION', false)
  return subId
}

export async function createStripeAccount({ state, getters, dispatch }, businessId) {
  // TODO: catch errors
  const result = await Parse.Cloud.run('createStripeAccount')
  // console.log('stripe account result', result)

  const id = result.account

  dispatch('update', {
    id: businessId,
    stripe_account_id: id,
  })

  // console.log('stripe account id', id)

  return id
}

export async function createStripeAccountLink({ state, getters, dispatch }, businessId) {
  const business = getters.one(businessId)

  if(business) {
    const id = business.stripe_account_id
    const link = await Parse.Cloud.run('createStripeAccountLink', { account: id, businessId })
    // console.log('link', link)

    return link
  }

  return false
}

// Supported components: 
// payments, 
// account_management, 
// notification_banner, 
// payouts, 
// documents
export function createStripeAccountSession({ }, { businessId, component }) {

  // console.log('payload', businessId, component)
  const fetchClientSecret = async () => {
    const link = await Parse.Cloud.run('createStripeAccountSession', { businessId, component })
    // console.log('link', link)
    return link.client_secret
  }

  const stripeConnectInstance = Connect.loadConnectAndInitialize({
    publishableKey: process.env.STRIPE_PUBLISHABLE_KEY,
    fetchClientSecret: fetchClientSecret,
  });
  const paymentComponent = stripeConnectInstance.create(component.split('_').join('-'));
  // console.log('stripe comp', paymentComponent)
  return paymentComponent
}