export function BROWSING (state, payload) {
  state.browsing = payload
}
export function SET_LIVE_ONLY (state, payload) {
  state.liveOnly = payload
}
export function SET_UNPAID_ONLY (state, payload) {
  state.unpaidOnly = payload
}
export function SET_UNRATED_ONLY (state, payload) {
  state.unratedOnly = payload
}
export function SET_SORT_BY (state, payload) {
  state.sortBy = payload
}
export function SET_DATE (state, payload) {
  if(!payload) {
    state.date = ''
    return
  }
  const [year, month, day] = payload.split('-').map(Number);
  state.date = new Date(year, month - 1, day, 0, 1);
}
export function SET_UNPAID_COUNT(state, payload) {
  state.unpaidCount = payload
}

export function SET_CURRENT_PAGE (state, payload) {
  state.currentPage = payload
}
export function SET_ORDERS_PER_PAGE (state, payload) {
  state.ordersPerPage = payload
}
export function SET_SKIP (state, payload) {
  state.skip = payload
}
export function SET_SUBSCRIPTION (state, payload) {
  state.subscription = payload
}
export function SET_UPDATES(state, payload) {
  state.updatesCount = payload
}
export function SET_NEW_ORDERS_COUNT(state, payload) {
  state.newOrdersCount = payload
}
export function DELETE_ALL (state) {
  state.skip = 0
  state.all = []
}
