import { useStore } from 'vuex'
import { computed } from 'vue'

const getCurrentCountryAbb = (businessId) => {
  const store = useStore()
  const getBusiness = store.getters['businesses/one']
  const getAbb = store.getters['dreamdiner/getAbbByCountry']

  const countryAbb = computed(() => {
    const business = getBusiness(businessId)

    return getAbb(business.country)
  })

  return countryAbb
}

export default getCurrentCountryAbb