import { LocalStorage } from 'quasar'
import { parseObjectsToStore } from 'src/store/_helpers'

export function DD_WARNINGS_LOADING (state, status) {
  state.warningsLoading = status
}
export function DD_SET_WARNINGS (state, warnings) {
  warnings = parseObjectsToStore(warnings)
  state.warnings = warnings
  LocalStorage.set('dd_admin.dreamdiner.warnings', warnings)
}


export function DD_KITCHEN_ROLES_LOADING (state, status) {
  state.kitchenRolesLoading = status
}
export function DD_SET_KITCHEN_ROLES (state, kitchenRoles) {
  kitchenRoles = parseObjectsToStore(kitchenRoles)
  state.kitchenRoles = kitchenRoles
  LocalStorage.set('dd_admin.dreamdiner.kitchenRoles', kitchenRoles)
}


export function DD_CURRENCIES_LOADING (state, status) {
  state.currenciesLoading = status
}
export function DD_SET_CURRENCIES (state, currencies) {
  currencies = parseObjectsToStore(currencies)
  state.currencies = currencies
  LocalStorage.set('dd_admin.dreamdiner.currencies', currencies)
}
