import { LocalStorage } from 'quasar'
import timezones from 'src/store/dreamdiner/data/timezones.js'
import countriesJson from 'src/store/dreamdiner/data/countries.json'
import colors from 'src/store/dreamdiner/data/colors.js'
import categoryIcons from 'src/store/dreamdiner/data/category-icons.js'
import videoGuides from 'src/store/dreamdiner/data/videos.js'

export default function () {
  return {
    timezones,
    videoGuides,
    countriesJson,
    colors,
    categoryIcons,
    titleMaxLength: 30,
    descriptionMaxLength: 200,
    warningsLoading: false,
    warnings: LocalStorage.has('dd_admin.dreamdiner.warnings') ? LocalStorage.getItem('dd_admin.dreamdiner.warnings') : [],
    kitchenRolesLoading: false,
    kitchenRoles: LocalStorage.has('dd_admin.dreamdiner.kitchenRoles') ? LocalStorage.getItem('dd_admin.dreamdiner.kitchenRoles') : [],
    currenciesLoading: false,
    currencies: LocalStorage.has('dd_admin.dreamdiner.currencies') ? LocalStorage.getItem('dd_admin.dreamdiner.currencies') : []
  }
}
