import { getItem } from 'src/store/_helpers'
import { LocalStorage } from 'quasar'

import * as templateActions from 'src/store/_parseObjectTemplate/actions'
import * as templateGetters from 'src/store/_parseObjectTemplate/getters'
import * as templateMutations from 'src/store/_parseObjectTemplate/mutations'

import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

export default {
  namespaced: true,
  state,
  actions: { ...templateActions, ...actions },
  mutations: { ...templateMutations, ...mutations },
  getters: { ...templateGetters, ...getters }
}
