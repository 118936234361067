import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

export const useResponsivity = () => {
  const { width } = useWindowSize();

  const isSmallWidth = computed(() => width.value < 700);

  return {
    isSmallWidth
  }
}