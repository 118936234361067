export default function makeState (objectName, emptyInstance = {}, extraData = {}) {
  const state = {
    objectName,
    loading: false,
    saving: false,
    deleting: false,
    skip: 0,
    limit: 8,
    count: 0,
    all: [],
  }
  if (Object.keys(emptyInstance).length) {
    state.emptyInstance = emptyInstance
  }
  if (Object.keys(extraData).length) {
    for (const i in extraData) {
      state[i] = extraData[i]
    }
  }
  return state
}
