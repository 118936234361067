export default async function (to, from, next, store) {
  let result

  if (from.matched.length && from.name === to.name) {
    let block = (Object.keys(from.params).length === 0)
    for (const i in from.params) {
      if (from.params[i] !== to.params[i]) {
        block = false
        break
      }
    }
    if (block) {
      result = false
    }
  }

  if (to.meta.middleware && to.meta.middleware.length) {
    if (!store.state.auth.checked) {
      await store.dispatch('auth/refresh')

      if (store.state.auth.checked) {
        const logged = store.state.auth.logged
        for (const i in to.meta.middleware) {
          switch (to.meta.middleware[i]) {
            case 'guest':
              if (logged) {
                result = { path: '/' }
              }
              break
            case 'auth':
              if (!logged) {
                store.commit('layout/SET_AUTH_POPUP', true)
                result = { path: '/' }
                
              }
              break
          }
        }
      }
    }

  }

  return (result) ? next(result) : next()
}
