<template>
  <q-field
    dir="ltr"
    borderless
    class="row items-start justify-start no-wrap full-width bg-white q-pb-none dd-admin-border q-px-sm"
    :error="!!(v$.phone && v$.phone.$errors && v$.phone.$errors.length)"
    :error-message="!!(v$.phone && v$.phone.$errors && v$.phone.$errors.length) ? $t('validation.' + v$.phone.$errors[0].$validator, v$.phone.$errors[0].$params) : ''"
  >
    <country-code
      class="q-mr-sm"
      :enableSearchField="true"
      :enabledCountryCode="true"
      :defaultCountry="defaultCountry"
      @onSelect="updateCountryData"
    />
    <q-input
      class="full-width text-primary text-weight-medium text-normal"
      borderless
      :placeholder="$t('auth.register.phone')"
      :model-value="phone"
      @update:model-value="updatePhoneData"
    />
    <dd-tooltip class="">
      <div class="text-tooltip"> {{ $t('tooltips.registrationPhone') }}</div>
    </dd-tooltip>
  </q-field>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueTelInput } from 'vue3-tel-input'
import { useQuasar } from 'quasar'
import CountryCode from 'src/components/_utility/CountryCode.vue'
import DdTooltip from 'src/components/_utility/Tooltip.vue'

const props = defineProps({
  v$: {
    type: Object,
    required: true
  },
  value: {
    type: String,
    default: ''
  },
  dense: {
    type: Boolean,
    default: false
  },
  country: {
    type: String,
    default: ''
  },
  defaultCountry: {
    type: String,
    default: ''
  }
})

// console.log('phone value:', props.value)
// console.log('defaultCountry value:', props.defaultCountry)

const emit = defineEmits([
  'update:phone',
  'update:country',
  'update:dialCode'
])

const phone = ref(props.value)
const dialCode = ref('')


function updatePhoneData(newPhone) {
  if (typeof newPhone === 'string' || newPhone instanceof String) {
    if (newPhone.startsWith('0')) {
      newPhone = newPhone.substring(1)
    }
    phone.value = newPhone
    emit('update:phone', newPhone)
  }
}
function updateCountryData(newCountry) {
  // console.log('updateCountryData',newCountry)
  if (typeof newCountry === 'string' || newCountry instanceof String) {
    emit('update:country', newCountry.toUpperCase())
  } else {
    dialCode.value = newCountry.dialCode
    const len = dialCode.value.length
    if(dialCode.value === phone.value.slice(0, len)) {
      //  console.log('is equal')
       phone.value.replace(dialCode.value, '')
    }
    emit('update:country', newCountry.iso2.toUpperCase())
  }
}
</script>

<style lang="sass">
.q-field--dense
  .vue-tel-input
    .vti__dropdown
      display: none !important

.vue-tel-input
  border: none
  .vti__dropdown
    background: transparent
    &:hover
      background: transparent
  input
    background: transparent
  &:focus-within
    box-shadow: none
  &.wizard
    border: none
    .vti__dropdown
      background: transparent
      &:hover
        background: transparent
    input
      background: $white
    &:focus-within
      box-shadow: none
</style>