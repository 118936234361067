import { removeLocalIds } from 'src/store/_helpers'
import { hourToTimestamp } from 'src/modules/display'

export function step (state) {
  for (const i in state.steps) {
    if (parseInt(i) === state.currentStep) {
      return state.steps[i]
    }
  }
  return false
}

export function business (state) {
  const result = structuredClone(state.business)
  result.email = result.email.toLowerCase()
  const formProperties = [
    'adminPassword',
    'adminPasswordRepeat',
    'hours'
  ]

  for (const i in formProperties) {
    if (Object.prototype.hasOwnProperty.call(result, formProperties[i])) {
      delete result[formProperties[i]]
    }
  }

  return result
}

export function hours (state) {
  return state.business.hours
}

export function supervisors (state) {
  const result = removeLocalIds(state.supervisors)
  return result
}

export function tables (state) {
  const result = removeLocalIds(state.tables)
  for (const i in result) {
    result[i].seats = parseInt(result[i].seats)
  }
  return result
}

export function waiters (state) {
  const result = removeLocalIds(state.waiters)
  let tables = []
  for (const i in result) {
    tables = []
    for (const j in result[i].tables) {
      tables.push(result[i].tables[j])
    }
    result[i].tables = tables
  }
  return result
}

export function kitchens (state) {
  const result = removeLocalIds(state.kitchens)
  return result
}

export function chefs (state) {
  const result = removeLocalIds(state.chefs)
  return result
}

export function categories (state) {
  const result = removeLocalIds(state.categories)
  return result
}

export function menus (state) {
  const result = removeLocalIds(state.menus)
  for (const i in result) {
    result[i].from = hourToTimestamp(result[i].from)
    result[i].to = hourToTimestamp(result[i].to)
  }
  return result
}

export function items (state) {
  const removeColumns = [
    'regularOptions',
    'groupOptions',
    'allergens',
    'labels',
    'kitchens',
    // 'symbol', // Dont delete symbol
    'local'
  ]

  const result = removeLocalIds(state.items)
  let categories = []
  let menus = []
  let extras = []
  for (const i in result) {
    categories = []
    for (const j in result[i].categories) {
      categories.push(result[i].categories[j].id ? result[i].categories[j].id : result[i].categories[j].objectId)
    }
    result[i].categories = categories

    menus = []
    for (const j in result[i].menus) {
      menus.push(result[i].menus[j])
    }
    result[i].menus = menus

    extras = []
    for (const j in result[i].extras) {
      extras.push(result[i].extras[j])
    }
    result[i].extras = extras

    result[i].price = parseFloat(result[i].price)

    for (const k in removeColumns) {
      if (Object.prototype.hasOwnProperty.call(result[i], removeColumns[k])) {
        delete result[i][removeColumns[k]]
      }
    }
  }
  return result
}

export function extras (state) {
  const extras = removeLocalIds(state.extras)
  const result = []
  for (const i in extras) {
    if (extras[i].groupOptions && extras[i].groupOptions.length) {
      for (const j in extras[i].groupOptions) {
        result.push({
          id: extras[i].id + j,
          local: true,
          group: extras[i].title,
          ...extras[i].groupOptions[j]
        })
      }
    } else {
      result.push(extras[i])
    }
  }

  for (const i in result) {
    result[i].price = parseFloat(result[i].price)
  }

  return result
}

export const isUnique = (state) => ({ collection, column, value, id }) => {
  collection = state[collection]
  for (const i in collection) {
    if (collection[i][column] === value) {
      return collection[i].id === id
    }
  }
  return true
}

export const stepWasSkipped = (state) => (step) => {
  for (const i in state.steps) {
    if (state.steps[i].name === step) {
      return !!state.steps[i].skipped && state[step].length === 1
    }
  }
  return false
}

export const businessType = (state) => state.businessType
