const colors = {
  black: {
    name: 'black',
    color: '#000000'
  },
  brown: {
    name: 'brown',
    color: '#915e1e'
  },
  coolGreen: {
    name: 'coolGreen',
    color: '#00a05b'
  },
  coolRed: {
    name: 'coolRed',
    color: '#ff2644'
  },
  cyan: {
    name: 'cyan',
    color: '#00b5ac'
  },
  coffee: {
    name: 'coffee',
    color: '#d19959'
  },
  darkBlue: {
    name: 'darkBlue',
    color: '#003250'
  },
  darkGreen: {
    name: 'darkGreen',
    color: '#73be00'
  },
  flameRed: {
    name: 'flameRed',
    color: '#ff4100'
  },
  grey7: {
    name: 'grey7',
    color: '#f4f5f6'
  },
  grey15: {
    name: 'grey15',
    color: '#e8eaed'
  },
  grey30: {
    name: 'grey30',
    color: '#d1d6db'
  },
  grey40: {
    name: 'grey40',
    color: '#c1c8d0'
  },
  grey50: {
    name: 'grey50',
    color: '#b2bbc4'
  },
  grey75: {
    name: 'grey75',
    color: '#8c99a7'
  },
  grey100: {
    name: 'grey100',
    color: '#66788a'
  },
  lightBlue: {
    name: 'lightBlue',
    color: '#00afd8'
  },
  lightGreen: {
    name: 'lightGreen',
    color: '#beea3c'
  },
  lightYellow: {
    name: 'lightYellow',
    color: '#ffeecc'
  },
  orange: {
    name: 'orange',
    color: '#ff7700'
  },
  pink: {
    name: 'pink',
    color: '#eb3a75'
  },
  ppBlue: {
    name: 'ppBlue',
    color: '#0c67e9'
  },
  violet: {
    name: 'violet',
    color: '#6f46a8'
  },
  warmGreen: {
    name: 'warmGreen',
    color: '#68882f'
  },
  white: {
    name: 'white',
    color: '#ffffff'
  },
  yellow: {
    name: 'yellow',
    color: '#ffaf00'
  }
}

colors.primary = Object.assign({}, colors.darkBlue, { name: 'primary' })
colors.secondary = Object.assign({}, colors.darkGreen, { name: 'secondary' })
colors.accent = Object.assign({}, colors.coolRed, { name: 'accent' })

colors.dark = Object.assign({}, colors.black, { name: 'dark' })

colors.positive = Object.assign({}, colors.lightGreen, { name: 'positive' })
colors.negative = Object.assign({}, colors.flameRed, { name: 'negative' })
colors.info = Object.assign({}, colors.lightYellow, { name: 'info' })
colors.warning = Object.assign({}, colors.yellow, { name: 'warning' })

colors.ordered = Object.assign({}, colors.flameRed, { name: 'ordered' })
colors.progress = Object.assign({}, colors.orange, { name: 'progress' })
colors.ready = Object.assign({}, colors.darkGreen, { name: 'ready' })
colors.delivered = Object.assign({}, colors.grey75, { name: 'delivered' })

export default colors
