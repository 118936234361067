import { Parse } from 'src/boot/parse'
import { LocalStorage } from 'quasar'

export function getDefaultCountry () {
  return new Promise(resolve => {
    fetch('https://api.ipregistry.co/?fields=location.country.code,location.country.calling_code&key=' + process.env.IP_REGISTRY_API_KEY).then(response => {
      response.json().then(({ location }) => {
        resolve(location)
      })
    })
  })
}

export function checkUserExists ({ commit }, username) {
  return new Parse.Query('User')
    .equalTo('username', username)
    .find({sessionToken: LocalStorage.getItem('auth.token')})
}

export function createUser ({ commit }, user) {
  return new Parse.Object('User', user).save()
}

export async function getSpecifications ({ commit }) {
  commit('DD_WARNINGS_LOADING', true)
  return await new Parse.Query('Warning')
    .ascending('sort_order')
    .find({sessionToken: LocalStorage.getItem('auth.token')})
    .then(warnings => {
      commit('DD_SET_WARNINGS', warnings)
      commit('DD_WARNINGS_LOADING', false)
    })
    .catch(error => {
      console.log('#### getSpecifications error:', error)
      commit('DD_WARNINGS_LOADING', false)
    })
}

export async function getKitchenRoles ({ commit }) {
  return await new Parse.Query('KitchenRole')
    .ascending('sort_order')
    .find({sessionToken: LocalStorage.getItem('auth.token')})
    .then(kitchenRoles => {
      commit('DD_SET_KITCHEN_ROLES', kitchenRoles)
    })
    .catch(error => {
      console.log('#### getKitchenRoles error:', error)
    })
}

export async function getCurrencies ({ commit }) {
  return await new Parse.Query('Currency')
    .find({sessionToken: LocalStorage.getItem('auth.token')})
    .then(currencies => {
      commit('DD_SET_CURRENCIES', currencies)
    })
    .catch(error => {
      console.log('#### getCurrencies error:', error)
    })
}

export async function saveImage (ctx, image) {
  return await new Parse.File(new Date().getTime() + '.jpg', image).save()
}
