// import { Parse } from 'src/boot/parse'
import { i18n } from 'src/boot/i18n'

export function completeMissingObjects ({ commit, state }) {
  const mutations = {
    kitchens: 'ADD_KITCHEN',
    categories: 'ADD_CATEGORY',
    menus: 'ADD_MENU',
    tables: 'ADD_TABLE'
  }

  for (const objectSlug in mutations) {
    if (!state[objectSlug].length) {
      commit('SET_STEP_SKIPPED', objectSlug)

      const suggestions = i18n.global.tm(`suggestions.${objectSlug}`)
      for (const j in suggestions) {
        if (suggestions[j].default) {
          commit(mutations[objectSlug], suggestions[j])
        }
      }
    }
  }
}

// TODO: for subadmin set email as username
// export function createUsers ({ dispatch }, users) {
//   const promises = []
//   console.log('users', users)
//   dispatch('dreamdiner/getDefaultCountry', null, { root: true }).then(location => {
//     // console.log('dialCode', location.country.calling_code)
//     // console.log('country', location.country.code.toUpperCase())
//     for (const i in users) {
//       promises.push(new Parse.User({
//         language: 1,
//         blocked: false,
//         is_admin: false,
//         registered_using: 'wizard',
//         username: location.country.calling_code + users[i].phone,
//         password: users[i].phone,
//         name: users[i].name,
//         phone: users[i].phone,
//         country: location.country.code.toUpperCase()
//       }).signUp().then(() => {
//         dispatch('auth/refresh', null, { root: true })
//       }))
//     }
//   })
// }

