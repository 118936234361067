import ImgSvg from 'src/components/global/ImgSvg'
import Lottie from 'src/components/global/Lottie'
import ObjectForm from 'src/components/global/ObjectForm'
import ShowVideo from 'src/components/global/ShowVideo'
import Display from 'src/modules/display'

import { AddressbarColor } from 'quasar'
AddressbarColor.set('#003250')

export default ({ app }) => {
  app.component('lottie', Lottie)
  app.component('img-svg', ImgSvg)
  app.component('object-form', ObjectForm)
  app.component('show-video', ShowVideo)

  app.provide('Display', Display)
}
