import { computed } from 'vue'

const useDetectDemoBusiness = (businessId = null) => {
  const demoId = process.env.BUSINESS_DEMO_ID 

  const checkIfDemo = (id) => demoId === id

  return {
    checkIfDemo,
    isDemo: computed(() => checkIfDemo(businessId)),
  }
}

export default useDetectDemoBusiness