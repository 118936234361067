<template>
<div class="full-width">
  <div v-if="loading">
    <q-spinner-dots color="grey75" />
  </div>
  <div v-else>

    <!-- Options btn -->
    <q-btn
      class="full-width bg-white dd-admin-border"
      no-caps
      flat
      outline
      :disable="disable"
      :label="label"
    >
    <!-- Popup-->
      <q-popup-proxy
        maximized
        :breakpoint="1600"
        v-model="show"
      >
        <div class="fit flex flex-center">

          <q-card
            class=" text-center q-pa-sm rounded-borders"
            flat
            bordered
            style="min-width: 300px;"
          >
            <div class="text-center text-primary text-h6 text-weight-medium">
            {{ title }}
            </div>

            <q-card-section horizontal class="fit">

              <!-- Available Options Column -->
              <q-card-section>

                <div class="text-primary text-h6">{{ availableLabel }}</div>
                <div class="row justify-center items-center">
                  <q-chip square outline color="stepColor" :label="$t('general.step01')" />
                  <span
                    class="text-stepColor text-weight-medium q-px-sm"
                  >{{ $t('business.items.options_dnd.columnAvailableExplan') }}</span>
                </div>
                <div class="row">
                  <q-input
                    v-model="search"
                    :placeholder="$t('app.autocomplete')"
                    class="dd-admin-border bg-rowLiveBg full-width"
                    filled
                    outline
                    clearable
                    dense
                  >
                    <template #prepend>
                      <q-icon name="mdi-magnify" />
                    </template>
                  </q-input>
                </div>

                <div
                  class="fit"
                  style="min-width: 150px;"
                >
                  <drag
                    v-for="item in filtredItems"
                    :data="item"
                    class="flex full-width q-my-sm row items-center dd-admin-border shadow-light-3 rounded-borders-sharper"
                    style="min-width: 150px;"
                    :key="item.id"
                    @dragstart="isDragging = true"
                    @dragend="isDragging = false"
                    go-back
                    :delta="50"
                  >
                  <div class="col-2">
                        <q-icon size="md" name="mdi-select"></q-icon>
                      </div>
                      <div class="col-5">{{ item.title || item.label }}</div>
                      <div class="col-3">
                        <span v-if="item.price">{{ formatCurrency(currency, item.price) }}</span>
                      </div>
                      <div class="col-2">
                      <q-btn
                        flat
                        round
                        icon="mdi-plus"
                        color="coolGreen"
                        @click="onInsert({ index: 0, data: item })"
                      ></q-btn>
                    </div>
                  </drag>
                </div>
              </q-card-section>

              <q-separator vertical />
              <!-- Selected Options Column -->
              <q-card-section>

                <div
                  class="text-primary text-h6"
                >{{ selectedLabel }}</div>
                <div class="row justify-center items-center">
                  <q-chip
                    square
                    outline
                    color="stepColor"
                    :label="$t('general.step02')"
                  />
                  <span
                    class="text-stepColor text-weight-medium q-px-sm"
                  >{{ $t('business.items.options_dnd.columnSelectExplan') }}</span>
                </div>

                <drop-list
                  :items="selectedItems"
                  :class="{ 'dd-dnd-drop-field': isDragging }"
                  style="min-width: 150px; min-height: 200px;"
                  @insert="onInsert"
                  @reorder="$event.apply(selectedItems)"
                >
                  <template v-slot:item="{item, reorder}">
                    <drag
                      :key="item.id"
                      class="flex full-width q-my-sm row items-center dd-admin-border shadow-light-3 rounded-borders-sharper"
                      :class="reorder ? 'dd-dnd-graped-item bg-grey50': ''"
                      @dragstart="isDragging = true"
                      @dragend="isDragging = false"
                      go-back
                      :delta="0"
                    >
                      <div class="col-2">
                        <q-icon size="md" name="mdi-drag"></q-icon>
                      </div>
                      <div class="col-5">{{ item.title || item.label }}</div>
                      <div class="col-3">
                        <span v-if="item.price">{{ formatCurrency(currency, item.price) }}</span>
                      </div>
                      <div class="col-2">
                        <q-btn
                          outline
                          flat
                          round
                          icon="mdi-delete"
                          color="red"
                          @click="onDelete(item.id)"
                        ></q-btn>
                      </div>
                    </drag>
                  </template>
                  <template v-slot:feedback="{data}">
                    <div
                      class="flex full-width q-my-sm row items-center rounded-borders shadow-light-3 rounded-borders-sharper feedback"
                      :key="data.id"
                    >
                      <div class="col-2 row">
                        <q-icon size="md" name="mdi-drag"></q-icon>
                      </div>
                      <div class="col-5 row">{{ data.title || data.label }}</div>
                      <div class="col-3">
                        <span v-if="data.price">{{ formatCurrency(currency, data.price) }}</span>
                      </div>
                      <div class="col-2 row">

                        <q-btn
                          outline
                          flat
                          round
                          icon="mdi-delete"
                          color="red"
                          disable
                        ></q-btn>
                      </div>
                    </div>
                  </template>
                </drop-list>
              </q-card-section>

            </q-card-section>
            <q-separator />
            <q-card-actions align="center">
              <q-btn
                flat
                padding="xs lg"
                icon="mdi-cancel"
                class="bg-grey75 text-white"
                @click="onReset();show = false;"
              >{{ $t('general.cancel') }}</q-btn>
              <q-btn
                flat
                padding="xs lg"
                icon="mdi-backup-restore"
                class="bg-orange text-white"
                @click="onReset"
              >{{ $t('general.resetAll') }}</q-btn>
              <q-btn
                flat
                padding="xs lg"
                icon="mdi-content-save-outline"
                class="bg-darkGreen text-white"
                @click="onSave"
                >{{ $t('general.save') }}</q-btn>
            </q-card-actions>
          </q-card>

        </div>
      </q-popup-proxy>
    </q-btn>
    <div>
      <q-scroll-area style="height: 200px;">
        <transition
          appear
          enter-active-class="animated fadeInUp"
          leave-active-class="animated fadeOutDown transparent"
        >
          <q-list class="full-width q-pa-sm select-list">
            <div class="full-width"
              v-for="option in selected"
              :key="option.id"
            >
              <q-item class="full-width q-my-sm bg-yellowExtra dd-admin-border"
              >
                <q-item-section>
                  <q-item-label> {{ option.slug ? $t(`warnings.${option.slug}`) : (option.title||option.label) }}</q-item-label>
                </q-item-section>
                <q-item-section v-if="option.price" side>
                  <q-item-label> {{ formatCurrency(currency, option.price) }}</q-item-label>
                </q-item-section>
              </q-item>
            </div>
          </q-list>
        </transition>
      </q-scroll-area>
    </div>
  </div>
</div>
</template>

<script setup>
import { toRefs, ref, computed, watch, inject } from 'vue'
import { Drag, DropList } from "vue-easy-dnd";

const { formatCurrency2: formatCurrency } = inject('Display')

const show = ref(false)
const emit = defineEmits( [
  'update'
])

const props = defineProps({
  loading: {
    type: Boolean,
    required: true
  },
  disable: {
    type: Boolean,
    default: false
  },
  warning: {
    type: Boolean,
    default: false
  },
  currency: {
    type: Object
  },
  options: {
    type: Array,
    default: () => []
  },
  label: {
    type: String,
    default: () => 'Edit Options'
  },
  title: {
    type: String,
    default: () => 'Edit Options'
  },
  selectedLabel: {
    type: String,
    default: () =>'Selected Options'
  },
  availableLabel: {
    type: String,
    default: () =>'Available Options'
  },
  selected: {
    type: Object,
    default: () => { return {} }
  },
})

const selectedItems = ref([])

const search = ref('')
const items = computed(() => {
  const seletedIds = selectedItems.value.map(extra => extra.id)
  return props.options.filter(extra => extra && !seletedIds.includes(extra.id))
})

const filtredItems = computed(() => items.value.filter(
  item => search.value ?
    item.title.toLowerCase().includes(search.value.toLowerCase()) :
    !!item)
)


const onInsert = (event) => {
  selectedItems.value.splice(event.index, 0, structuredClone(event.data));
}

const onDelete = (id) => {
  selectedItems.value = selectedItems.value.filter(item => item.id !== id)
}

const isDragging = ref(false)

const onSave = () => {
  const result = {}

  selectedItems.value.forEach(extra => {
    result[extra.id] = structuredClone(extra)
  })

  emit('update', result)
  show.value = false
}

const onReset = () => {
  buildSelectedItems()
}

function buildSelectedItems() {
  selectedItems.value = Object.values(props.selected)
}
buildSelectedItems()
</script>

<style lang="scss" scoped>
.dd-dnd-graped-item {
  border: 1px solid #1976D2;
  border-left: 5px solid #1976D2;
  margin-left: -2px;
}
.feedback {
  background-color: rgb(255, 220, 220);
  border: 2px dashed black;
}

.dd-dnd-drop-field {
  outline: 2px dashed pink;
}
</style>
