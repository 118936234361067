export default {
  path: '/wizard',
  name: 'wizard',
  redirect: { name: 'wizard.intro' },
  meta: {
    middleware: ['auth']
  },
  components: {
    default: () => import('layouts/Wizard.vue'),
    toolbar: () => import('components/wizard/Toolbar.vue'),
  },
  children: [
    {
      path: '/wizard/intro',
      component: () => import('pages/wizard/Intro.vue'),
      name: 'wizard.intro',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/types',
      component: () => import('pages/wizard/Types.vue'),
      name: 'wizard.types',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/customize',
      component: () => import('pages/wizard/Customize.vue'),
      name: 'wizard.customize',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/business',
      component: () => import('pages/wizard/Info.vue'),
      name: 'wizard.info',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/supervisors',
      component: () => import('pages/wizard/objects/Supervisors.vue'),
      name: 'wizard.supervisors',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/tables',
      component: () => import('pages/wizard/objects/Tables.vue'),
      name: 'wizard.tables',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/waiters',
      component: () => import('pages/wizard/objects/Waiters.vue'),
      name: 'wizard.waiters',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/kitchens',
      component: () => import('pages/wizard/objects/Kitchens.vue'),
      name: 'wizard.kitchens',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/chefs',
      component: () => import('pages/wizard/objects/Chefs.vue'),
      name: 'wizard.chefs',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/categories',
      component: () => import('pages/wizard/objects/Categories.vue'),
      name: 'wizard.categories',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/menus',
      component: () => import('pages/wizard/objects/Menus.vue'),
      name: 'wizard.menus',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/extras',
      component: () => import('pages/wizard/objects/Extras.vue'),
      name: 'wizard.extras',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/items',
      component: () => import('pages/wizard/objects/Items.vue'),
      name: 'wizard.items',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/terms',
      component: () => import('pages/wizard/Terms.vue'),
      name: 'wizard.terms',
      meta: {
        middleware: ['auth']
      },
    },
    {
      path: '/wizard/summary',
      component: () => import('pages/wizard/Summary.vue'),
      name: 'wizard.summary',
      meta: {
        middleware: ['auth']
      },
    }
  ]
}
