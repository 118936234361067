import { LocalStorage } from 'quasar'

export default function () {
  return {
    restaurantOrders: [],
    payments: [],
    ratings: [],
    summaries: []
  }
}
