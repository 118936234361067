export default function () {
  return {
    objectName: "Onboarding",
    objectId: null,
    saving: false,
    loading: false,
    step: 0,
    finished: false,
    showBack: false
  }
}
