const isDelivered = (order) => (order.item_orders && order.item_orders_delivered && order.item_orders.length === order.item_orders_delivered.length)
const isCompleted = (order) => (order.paid && isDelivered(order)) || order.rated || order.closed_by_admin
// import { date } from 'quasar'
// function paginate(array, page_size, page_number) {
//   // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
//   return array.slice((page_number - 1) * page_size, page_number * page_size);
// }

export function filtered (state) {
  const result = []

  for (const i in state.all) {
    if(state.liveOnly) {
      if (isDelivered(state.all[i])) {
        continue
      }
    }

    if (state.unpaidOnly) {
      if (state.all[i].paid) {
        continue
      }
    }
    
    result.push(state.all[i])
  }

  if(state.sortBy === 'date') {
    return result.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  } else if (state.sortBy === 'num') {
    return result.sort((a,b) => a.internal_id - b.internal_id)
  }

  return result
}

export function unpaidCount(_, getters) {
  let count = 0

  for (const order of getters.filtered) {
    if(!order.paid) {
      count+=1
    }
  }
  return count
}
export function inProgressCount(_, getters) {
  let count = 0

  for (const order of getters.filtered) {
    if(!isDelivered(order)) {
      count+=1
    }
  }
  return count
}


const isInRange = (range, currentTime) =>
  (currentTime.hours > range.from.hours ||
    (currentTime.hours === range.from.hours &&
      currentTime.minutes >= range.from.minutes)) &&
  (currentTime.hours < range.to.hours ||
    (currentTime.hours === range.to.hours &&
      currentTime.minutes <= range.to.minutes));

export const activeMenu = (state, getters, rootState) => (currentDate) => {
  if (!rootState.menus.all) {
    return [];
  }

  // TODO: get time by timezone of the business
  const currentTime = {
    hours: currentDate.getHours(),
    minutes: currentDate.getMinutes()
  }

  const result = [];
  let mainMenu;

  if (rootState.menus.all.length < 2) {
    result.push(rootState.menus.all[0]);
  } else {
    let menuHours = {};

    for (const i in rootState.menus.all) {
      menuHours = {
        from: {
          hours: new Date(rootState.menus.all[i].from).getUTCHours(),
          minutes: new Date(rootState.menus.all[i].from).getUTCMinutes(),
        },
        to: {
          hours: new Date(rootState.menus.all[i].to).getUTCHours(),
          minutes: new Date(rootState.menus.all[i].to).getUTCMinutes(),
        },
      };

      if (isInRange(menuHours, currentTime)) {
        // skip main menu
        if (rootState.menus.all[i].ref !== "main") {
          result.push(rootState.menus.all[i]);
        } else {
          mainMenu = rootState.menus.all[i];
        }
      }
    }
  }

  return result[0] || {}
}


export function pagesQuantity(state) {
  return Math.ceil(state.count / state.ordersPerPage)
}