import ItemImages from 'src/store/dreamdiner/data/demoitemimages'

export default {







  general: {
    yes: "Sí",
    no: "No",
    yesNo: "Sí o No",
    add: "Añadir",
    addCredits: "Añadir créditos",
    color: "Color asignado",
    colorBg: "Color de fondo",
    assigned_icon: "Icono asignado",
    assigned_items: "Elementos asignados",
    back: "Volver",
    confirm: "Confirmar",
    continue: "Continuar",
    cancel: "Cancelar",
    clear: "Borrar",
    close: "Cerrar",
    day: "Día",
    delete: "Borrar",
    duplicate: "Duplicar",
    ends: "Termina:",
    finish: "Termina",
    from: "Desde",
    loading: "Cargando...",
    mobile: "Número de móvil",
    name: "Nombre",
    next: "Siguiente",
    none: "Ninguno",
    price: "Precio",
    verify: "Verifique",
    optional: "Opcional",
    save: "Guardar",
    send: "Enviar",
    restart: "Reiniciar",
    resetAll: "Reiniciar todo",
    start: "Iniciar",
    select: "Seleccione",
    selectDate: "Seleccionar fecha",
    starts: "Comienza",
    title: "Título",
    to: "A",
    update: "Actualización",
    learnMore: "Más información",
    assigned_itemsCount: "Recuento de artículos: {count}",
    no_results: "Sin resultados",
    restore: "Restaurar",
    noItemsAssigned: "No se han encontrado elementos asignados",
    chooseSearchMethod: "Elegir método de búsqueda",
    chooseSearchMethodFull: "Contiene la palabra...",
    chooseSearchMethodStart: "Empieza por",
    searchItem: "Buscar Item",
    saveInfo: "Guardar los cambios",
    resetInfo: "Restablecer cambios",
    overviewBtnSettings: "Información comercial & Configuración",
    overviewBtnActivity: "Horario Actividad",
    accordionExpansionLearnMoreTitle: "Más información sobre esta sección",
    active: "Activo",
    step01: "Paso 1",
    step02: "Paso 2",
    step03: "Paso 3",
    step04: "Paso 4",
    step05: "Paso 5",
    step06: "Paso 6",
    step07: "Paso 7",
    option_01: "Opción 1",
    option_02: "Opción 2",
    doThis: "Haz esto",
    daily: "Uso diario",
    system: "Sistema",
    option_03: "Opción 3",
    option_04: "Opción 4"
  },
  app: {
    headsup: "¡Cuidado!",
    errorMessage: "Algo salió mal...",
    errorSaveMessage: "Save Error: Los datos de entrada no son válidos...",
    never: "[No hay datos todavía]",
    empty: "No hay resultados",
    autocomplete: "Seleccionar o empezar a escribir",
    phonePlaceholder: "Sólo dígitos",
    phoneHint: "Sólo números (0-9), sin guiones (-), sin espacios, sin puntos (.), sin / ( ), máx. 20 caracteres.",
    verified: "Verificado",
    fail: "Ups. Algo salió mal",
    registeredSupervisorMobileExists: "Ya existe un Supervisor registrado con este número de móvil",
    registeredWaiterMobileExists: "Ya existe un Camarero registrado con este número de móvil",
    registeredStaffMobileExists: "Ya existe un miembro del personal registrado con este número de móvil",
    itemSpecialPriceAlert: "Fije la fecha de caducidad del Precio Especial o elimínela. A continuación, inténtelo de nuevo.",
    itemInvalidParams: "Algunos datos no son válidos o faltan. Por favor, compruebe de nuevo",
    days: {
      everyday: "Todos los días",
      sunday: "Domingo",
      monday: "Lunes",
      tuesday: "Martes",
      wednesday: "Miércoles",
      thursday: "Jueves",
      friday: "Viernes",
      saturday: "Sábado"
    },
    localeSelect: "Seleccione idioma",
    timezoneSelect: "Seleccionar zona horaria",
    countrySelect: "Seleccione el país",
    version: "Versión: {version}",
    deleteConfirm: "¿Está seguro de que desea eliminar este {object}?",
    unsavedChanges: "¿Cerrar este formulario? Los cambios realizados no se guardarán."
  },
  auth: {
    emailVerification: {
      title: "Por favor, verifique su correo electrónico",
      subtitle: "Introduce tu dirección de correo electrónico y te enviaremos un mensaje con un enlace de verificación. Haz clic en el enlace para continuar.",
      resendTitle: "¿Necesita que le reenviemos el enlace de verificación?",
      resendCta: "Reenviar enlace",
      resent: "Correo electrónico de verificación enviado correctamente",
      success: "Ha completado el proceso de verificación de correo electrónico."
    },
    popup: {},
    login: {
      title: "Bienvenido a DreamDiner",
      titleWithName: "¡Bienvenido a DreamDiner {name}!",
      subtitle: "Inicia sesión para comenzar tu experiencia",
      confirm: "Iniciar sesión",
      noUser: "Por favor, primero regístrate para continuar",
      unverified: "Por favor, verifique su dirección de correo electrónico para continuar Enviado a: {REPLACE}. Compruebe la carpeta de spam si es necesario.",
      fail: "Nombre de usuario/contraseña no válidos",
      success: "Ha iniciado sesión correctamente",
      email: "Introduzca su dirección de correo electrónico",
      password: "Introduzca su contraseña",
      forgot: "He olvidado mi contraseña",
      register: "Quiero registrarme",
      resendVerification: "Reenviar correo de verificación",
      changeUser: "¿No {email}?"
    },
    forgot: {
      title: "¿Ha olvidado su contraseña?",
      subtitle: "Introduce tu dirección de correo electrónico y comprueba tu bandeja de entrada (o carpeta de SPAM) para obtener un nuevo enlace de restablecimiento de contraseña",
      login: "He recordado mi contraseña Volver a iniciar sesión",
      register: "Volver a registrarse",
      confirm: "Enviar enlace",
      success: "Enlace de restablecimiento enviado correctamente",
      profileResetPassword: "Restablecer contraseña",
      profileResetPasswordSent: "Enlace de restablecimiento enviado a su correo electrónico",
      errors: {
        205: "No se ha encontrado ningún usuario."
      }
    },
    register: {
      title: "Bienvenido a DreamDiner",
      subtitle: "Regístrate para comenzar tu experiencia",
      email: "Introduzca su dirección de correo electrónico",
      emailConfirm: "Vuelva a introducir su dirección de correo electrónico",
      phone: "Introduzca un número de móvil",
      password: "Introduzca su contraseña",
      passwordConfirm: "Vuelva a introducir su contraseña",
      name: "¿Cómo te llamas?",
      existingUser: "Un usuario con tu email o número de teléfono ya se ha registrado",
      success: "¡Bienvenido a DreamDiner! Compruebe su bandeja de entrada para verificar su dirección de correo electrónico para continuar.",
      resend: "Reenviar código de verificación ({user})",
      fail: "Ups. Algo salió mal",
      confirm: "Regístrate",
      login: "Ya me he registrado",
      otpTitle: "Introduce tu código",
      otpSubtitle: "Hemos enviado un código de verificación a {counter}",
      incorrectOTP: "Lo sentimos. Código incorrecto. Por favor, inténtelo de nuevo o reenvíe"
    }
  },
  autocompleteInput: {
    defaultPlaceholder: "Empieza a escribir..."
  },
  tooltips: {
    infoAdminCode: "Esta no es una contraseña de acceso Se recomienda cambiar este código a diario o semanalmente",
    tableMasterCode: "Código de mesa por defecto si el administrador no ha dado un código específico",
    infoBusinessWebsite: "Principalmente para uso de los clientes para navegar a su sitio web con facilidad. Copie el enlace URL exactamente como se muestra en su navegador",
    registrationPhone: "Necesario para iniciar sesión en la aplicación móvil",
    waitersAssignTables: "Selección de múltiples tablas habilitada",
    itemKitchens: "Este elemento se vinculará a la estación de preparación seleccionada",
    itemCategories: "Este artículo se mostrará siempre bajo estas categorías seleccionadas. Se permiten múltiples categorías. Por ejemplo, Hamburguesa de pescado puede estar en Hamburguesas y en Marisco",
    itemLabels: "Clasificación general de este artículo. Se permiten múltiples selecciones",
    itemAllergens: "Múltiples selecciones permitidas",
    itemSymbol: "Un código de 3 letras para el uso de la aplicación PREP",
    categoryName: "Preferiblemente, un título de una sola palabra",
    menuPrice: "Vincule este artículo a menús y establezca un precio especial para cada uno. Si no se establece, se da el precio por defecto",
    settingsCog: "Toda la información y configuración de su negocio",
    activityHours: "Horas de actividad diaria y semanal de su negocio",
    businessStatusLive: "Si está oculto, los clientes sólo podrán acceder al negocio mediante un código QR o un enlace URL directo",
    addTemplateBtn: "Pulse, a continuación, el botón Añadir en el formulario que se abrirá a la derecha",
    colorWaiter: "Todos los artículos pedidos a este camarero tendrán este camarero's de color",
    colorTerminal: "Todos los artículos relacionados con esta Estación tendrán el color de esta estación",
    colorStaff: "Asignar un color a este miembro del personal",
    colorMenu: "Asigne un color a este menú, haga clic en AÑADIR y observe el gráfico de la izquierda",
    addBtnHint: "Haga clic aquí para añadir a su lista",
    itemCategoryAlert: "Debe enlazar con una o más categorías",
    categories: {
      cat_100: "Cerveza",
      cat_101: "Cerveza",
      cat_102: "Beer-tap",
      cat_103: "Bebida fría",
      cat_104: "Bebida",
      cat_105: "Té",
      cat_106: "Café",
      cat_107: "Vino",
      cat_108: "Champán",
      cat_109: "Alcohol",
      cat_110: "Croissants",
      cat_111: "Pan",
      cat_112: "Pretzel",
      cat_113: "Cereal",
      cat_114: "Huevos",
      cat_115: "Desayuno",
      cat_116: "Ensalada",
      cat_117: "Tomate",
      cat_118: "Sopa",
      cat_119: "ToGo",
      cat_120: "Pizza",
      cat_121: "Pizza",
      cat_122: "Fries",
      cat_123: "Sandwich",
      cat_124: "Sushi",
      cat_125: "Sushi",
      cat_126: "Wrap",
      cat_127: "Fideos",
      cat_128: "Arroz",
      cat_129: "Niños",
      cat_130: "Hamburguesas",
      cat_131: "Parrilla",
      cat_132: "Pescado",
      cat_133: "Pescado",
      cat_134: "Pollo",
      cat_135: "Kebab",
      cat_136: "Barbacoa",
      cat_137: "Hotdog",
      cat_138: "Marisco",
      cat_139: "Para llevar",
      cat_140: "Fruta",
      cat_141: "Pastel",
      cat_142: "Cake",
      cat_143: "Helado",
      cat_144: "Helado",
      cat_145: "Sundae",
      cat_146: "Muffin",
      cat_147: "Caramelo",
      cat_148: "Postre",
      cat_149: "Gofres",
      cat_150: "Cena",
      cat_151: "Comida",
      cat_152: "Cuchillo y tenedor",
      cat_153: "Cloche",
      cat_154: "Especial Chef",
      cat_155: "Resaurant",
      cat_156: "Almuerzo",
      cat_157: "Bandeja",
      cat_158: "Cafetería",
      cat_159: "Cubiertos"
    },
    settingsBlock: "Características & Ajustes"
  },
  business: {
    adminProfile: {
      profileTitle: "Perfil de usuario",
      profileSubTitle: "Establezca aquí su información personal",
      name: "Nombre",
      nameHint: "Introduzca aquí su nombre",
      mobile: "Móvil",
      mobileExplanation: "Su número de móvil se utilizará para iniciar sesión en la Admin App",
      userPhoto: "Foto",
      userPhotoExplanation: "Que sea buena :)",
      changePassword: "Cambiar contraseña",
      changePassword2: "Haz clic en el botón Restablecer para cambiar tu contraseña y, a continuación, comprueba los correos electrónicos que recibes. Si no recibes ninguno, es posible que también tengas que comprobar la carpeta de SPAM"
    },
    business: {
      title: "Nombre de la empresa",
      titlePlaceholder: "Nombre de su empresa",
      titleDescriptionPlaceholder: "Otro negocio DreamDiner",
      titleWebsitePlaceholder: "https://www.your-business.com",
      titleEn: "Nombre del negocio en inglés",
      titleEnPlaceholder: "Nombre de su empresa en inglés",
      titleEnExplanation: "Los SMS recibidos estarán bajo este nombre de remitente",
      statusDemo: "DEMO Business",
      statusLive: "Estado LIVE",
      statusHidden: "Status: Oculto",
      switchLive: "Establecer en VIVO",
      switchHidden: "LIVE",
      email: "Correo electrónico comercial",
      emailSubtitle: "El correo electrónico de contacto de su empresa es esencial para las comunicaciones del sistema y le mantiene informado de avisos y cambios importantes, nuevas funciones, interesantes vídeos tutoriales y mucho más.",
      emailPlaceholder: "Su correo electrónico empresarial",
      emailVerifyText: "OK. Tu correo de empresa es el mismo que tu correo de usuario",
      emailVerifyBtn: "Verificar ahora",
      useMyEmail: "Usar mi email de usuario",
      adminPassword: "Admin Passcode",
      adminPasswordSubtitle: "Este código de acceso se utiliza como medida de seguridad para que sólo los usuarios autorizados con la contraseña puedan realizar diversas tareas, como emitir reembolsos, eliminar pedidos, etc. Se puede cambiar diaria o semanalmente.",
      enterNewPassword: "Introducir nueva contraseña",
      pin: "Código de verificación del maestro de clientes",
      pinSubtitle: "Utilice este código para proporcionar acceso a un cliente que no puede ser verificado por móvil por cualquier motivo. No utilice regularmente este código y cámbielo a menudo. El valor predeterminado es 999",
      pinPlaceholder: "Sólo dígitos",
      website: "Sitio web de su empresa",
      websitePlaceholder: "https://...",
      timezone: "Zona horaria",
      timezoneSubtitle: "Es posible que necesite un ajuste de acuerdo con el horario de verano y de invierno",
      country: "País",
      currency: "Moneda",
      tagline: "Lema de la empresa",
      taglineExplanation: "Una descripción concisa que aparecerá bajo el nombre de su empresa en la aplicación Cliente",
      hours: "Horario comercial",
      currentTime: "Horario comercial actual:",
      adminPasswordPlaceholder: "6 dígitos mínimo, sólo dígitos",
      adminPasswordRepeatPlaceholder: "Repita el código de acceso",
      businessLogo: "Logotipo de la empresa",
      businessLogoSubtitle: "Su logotipo se presenta a través de la aplicación de cliente y en muchos otros lugares. Si se deja vacío - el sistema mostrará el logo de DreamDiner. El Logo del Negocio también será la imagen por defecto en todos los elementos que no tienen una foto o una imagen cargada",
      logoSelectDemoBtn: "Seleccione Logo Demo",
      logoSelectSampleBtn: "Seleccione una imagen temporal",
      logoSelectOr: "O",
      logoSelectTitle: "Seleccionar de un logotipo de demostración dado",
      logoSelectSubtitle: "Más tarde, lo sustituirá por su logotipo",
      openinghoursClosed: "Cerrado",
      openinghoursAllDay: "24hr.",
      addTimeSegment: "Añadir segmento horario",
      addTimeSegmentExplan: "Cada día debe tener al menos un segmento horario. Por favor, no superponga y mantenga los segmentos de tiempo para que el primero sea el más temprano del día.{email} Para añadir un segmento temprano, elimine los segmentos diarios y edite el primero para establecerlo como el más temprano.",
      updateMessageSnackBar: "Cambios actualizados correctamente",
      businessColorTitle: "Color del tema comercial",
      businessColorSubtitle: "Su tema de color preferido que mejor se adapte a su ambiente de negocios y logotipo",
      businessLocationTitle: "Ubicación de la empresa",
      businessLocationSubtitle: "Necesario para que los clientes cercanos puedan encontrar fácilmente su negocio en la Client App",
      businessLatitude: "Latitud",
      businessLongitude: "Longitud:",
      getLocationFromDevice: "Establecer ubicación para mí",
      getLocationAccessDenied: "Comprueba la configuración de tu navegador y activa el acceso a la ubicación",
      noTrackerEnableTitle: "Progreso del seguimiento del cliente (Normalmente para negocios que no son restaurantes)",
      noTrackerEnableDesc: "La aplicación para clientes incluye un componente de opción de seguimiento para el proceso de preparación de pedidos (Pedido, En curso, Listo, Entregado). Si su negocio no maneja un proceso de preparación o no desea que sus clientes estén expuestos a esta pestaña de estado después de ejecutar su pedido, puede decidir ocultar este componente",
      noTrackerSwitchEnabled: "Los clientes seguirán el proceso de preparación (Modo Restaurante)",
      noTrackerSwitchDisabled: "Client App no expondrá la pestaña de estado de preparación",
      businessEatInTitle: "Opciones para comer en el restaurante",
      EatInSwitchEnabled: "El botón Eat-In es visible en la aplicación cliente",
      EatInSwitchDisabled: "Eat-In ahora está oculto",
      businessAllowEatInSubtitle: "Por ejemplo, si usted tiene un negocio que funciona sólo para llevar y entrega, usted puede decidir ocultar este botón en la aplicación de cliente",
      EatInMinimumTitle: "Pedido Mínimo de Comida a Domicilio:",
      EatInMinimum: "Establecer un valor de precio",
      businessEatInMinimumSubtitle: "Establezca un valor de precio mínimo para aceptar pedidos Eat In. El valor debe ser >6",
      businessTakeAwayTitle: "Opciones para llevar",
      TakeAwaySwitchEnabled: "El botón Take-Away es visible en Client App",
      TakeAwaySwitchDisabled: "Llevarse ahora está oculto",
      businessAllowTakeAwaySubtitle: "Si está habilitado (verde) - el botón Take-Away en la aplicación Cliente está habilitado según la hora de inicio y fin definida",
      takeAwayMinimumTitle: "Pedido mínimo para llevar:",
      takeAwayMinimum: "Establecer un valor de precio",
      businessDeliveryTitle: "Opciones de entrega",
      DeliverySwitchEnabled: "Botón de entrega visible en Client App",
      DeliverySwitchDisabled: "La entrega ahora está oculta",
      businessAllowDeliverySubtitle: "Si está habilitado (verde) - entonces el botón Entrega en la aplicación Cliente está habilitado según la hora de inicio y fin definida",
      deliveryMinimumTitle: "Orden de entrega mínima:",
      deliveryMinimum: "Establecer un valor de precio",
      tablePlannerTitle: "Configuración del planificador de mesa",
      tablePlannerDesc: "Algunos ajustes básicos para su Planificador de Mesa",
      tablePlannerEnableWeb: "Activar Planificador Web",
      tablePlannerLinkBtn: "Abrir el Planificador de Mesas en una nueva pestaña",
      tablePlannerEnableWebDesc: "Web Planner permitirá a sus clientes hacer una reserva desde su ordenador o móvil directamente, y usted verá instantáneamente estas reservas en el tablero principal de la aplicación Table Planner",
      EnableWebSwitchEnabled: "Ahora los clientes pueden hacer autorreservas en Web Planner",
      EnableWebSwitchDisabled: "Su negocio se oculta de las búsquedas en Web Planner App",
      tablePlannerFirstDay: "Establecer el primer día de la semana preferido",
      tablePlannerFirstDaySun: "Domingo",
      tablePlannerFirstDayMon: "Lunes",
      tablePlannerFirstDayDesc: "Determina el primer día de mainboard's",
      tablePlannerIntervals: "Intervalos de mainboard",
      tablePlannerIntervalsDesc: "Establece los intervalos de las columnas de la placa base",
      tablePlannerMainboardDefaultView: "Mainboard Default View",
      tablePlannerMainboardDefaultViewDesc: "Cuando se carga el mainboard, qué vista mostrar por defecto",
      tablePlannerReservationDefaultTime: "Hora por defecto de reserva",
      tablePlannerReservationDefaultTimeDesc: "Determina por defecto la hora de finalización de la reserva",
      paymentsPaymeSettingsTitle: "Configuración de PayMe",
      paymentsPaymeSettingsTitleDesc: "Ajustes necesarios para aceptar pagos",
      paymentsPaymeSettingsIdPlaceholder: "Introduzca aquí su ID",
      paymentsPaymeSettingsIdTooltip: "Este ID se genera después de firmar los pagos",
      paymentsAcceptApplePayTitle: "Aceptar Apple Pay",
      paymentsSellerIdTitle: "ID de vendedor",
      paymentsSellerIdTitleDesc: "Su ID de vendedor PayMe",
      paymentsSellerIdDebug: "ID Vendedor Debug",
      paymentsAcceptBitTitle: "Aceptar Pagos Bit",
      paymentsAcceptBitTitleDesc: "Permita a sus clientes pagar usando su Bit App.{REPLACE}  Primero, habilite Bit App en su panel de Payme (Payme Sidebar > Admin > Apps Marketplace); Luego, habilite este interruptor.{REPLACE}  Nota: 0,3 NIS se añadirá a cada transacción",
      paymentsBitSwitchEnabled: "Bit pagos habilitados para sus clientes",
      paymentsBitSwitchDisabled: "Los pagos en bit están deshabilitados",
      paymentsAcceptIcountTitle: "Habilitar facturas iCount para sus clientes",
      paymentsAcceptIcountTitleDesc: "Habilite a sus clientes para descargar sus facturas de compra.{REPLACE}  En primer lugar, active la aplicación iCount en su panel de Payme (Payme Sidebar > Admin > Apps Marketplace); A continuación, active este interruptor.{REPLACE}  Nota: 0,35 NIS se añadirá a cada transacción",
      paymentsIcountSwitchEnabled: "iCount facturas están habilitadas para sus clientes",
      paymentsIcountSwitchDisabled: "las facturas iCount están desactivadas",
      paymentsSellerIdDebugSample: "Copie y coloque esto sólo en el modo DEV: MPL16444-14303SH9-AJLRRH1F-YU1W0IAH"
    },
    plans: {
      plansTitle: "Planes de negocio disponibles",
      plansSubTitle: "Elija el que mejor se adapte a su negocio",
      plansItalTitle: "Los planes cambian automáticamente en consecuencia una vez establecidos como se describe",
      plansBtnToSetPayments: "Configure aquí su pasarela de pagos",
      learnMoreBtnSite: "https://dreamdiner.io/start-he/#plans",
      plansStep1Chip: "Paso 1",
      plansStep2Chip: "Paso 2",
      plansStep1Title: "Establecer créditos o permanecer en LIBRE",
      plansStep2Title: "Establecer pagos",
      howWorksTitle: "¿Cómo funciona?",
      howWorksInfo: "Entender qué es lo mejor para su negocio",
      howWorksLine01: "Cualquier empresa puede subir sus platos y crear un menú digital al que los clientes puedan acceder mediante un código QR generado. Además, las empresas pueden compartir enlaces a través del panel de administración.",
      howWorksLine02: "Para recibir y gestionar pedidos, las empresas necesitan tener una cuenta de crédito positiva. Los créditos pueden adquirirse como un paquete de pago por uso o como una suscripción mensual ilimitada.",
      howWorksLine03: "Para aceptar pagos y permitir que los clientes paguen sus pedidos (incluidos los pedidos para llevar y de entrega a domicilio en los que es necesario el pago por adelantado), los negocios necesitan registrarse y crear una cuenta de Stripe a través de la plataforma DreamDiner. En este caso, todos los fondos de las ventas se depositan directamente en la cuenta del negocio, deduciéndose las tarifas habituales de Stripe y una comisión del 1,2% a la plataforma DreamDiner.",
      planFreeTitle: "Menú",
      planCreditTitle: "Créditos",
      planPaymentsTitle: "Ilimitado",
      planGetPaidTitle: "Cobrar",
      planFreeInfo: "Plan GRATIS",
      planCreditInfo: "Saldo a favor: {REPLACE}",
      planPaymentsInfo: "Renueva en: {number}",
      planPaymentsNotSubscr: "No suscrito",
      planGetPaidInfo: "Impulse y asegure sus ventas",
      planFreeLine01: "Existe un plan por defecto cuando no hay ningún otro plan activo",
      planCreditLine01: "Un plan de pago por uso en el que 1 crédito = 1 pedido. Los paquetes de créditos son pagos únicos y su cuota no caduca. Puedes comprar una cantidad de créditos de pedido y utilizarlos cuando quieras (Pay-as-you-go).",
      planPaymentsLine01: "Una suscripción mensual recomendada para empresas con más de 500 pedidos mensuales. Las suscripciones le cobran cada mes una cantidad fija de créditos de pedido. Este plan ofrece tranquilidad a su negocio. No tendrá que preocuparse de recargar créditos constantemente ni de lidiar con un sistema bloqueado cuando su negocio se quede sin créditos en mitad del trabajo. Los pedidos mensuales son ilimitados, y su saldo de créditos anterior (si existe) permanece congelado y no se ve afectado",
      planGetPaidLine01: "La inscripción en el programa Get Paid permite a los clientes realizar pagos directos dentro del sistema, lo que elimina la necesidad de que la empresa gestione los pagos y garantiza que los pedidos, especialmente los de comida para llevar y entregas, se paguen de forma segura",
      whenFreePlan: "Se cambia automáticamente cuando el saldo de crédito llega a 0 (Cero) y no hay ninguna suscripción activa",
      whenCreditPlan: "Se activa automáticamente cuando el saldo de crédito es positivo (>1) y la suscripción no está activa",
      whenPaymentsPlan: "Este plan se activa automáticamente al suscribirse, y el saldo de crédito no se ve afectado",
      planGetPaidPlan: "Este plan es un potente complemento para empresas con el plan Créditos o Ilimitado. Permite los pagos de los clientes y garantiza un pedido seguro y sin problemas",
      explanFreePlan: "Este plan de reserva se activa automáticamente cuando no hay ningún otro plan establecido y no hay créditos disponibles para empresas. Y aún así, un menú digital gratuito de hasta 200 artículos para compartir con sus clientes está siempre disponible",
      explanCreditPlan: "Un sencillo plan de pago por uso significa que cada pedido realizado equivale a un crédito. Cada vez que su saldo alcance el mínimo establecido, recargue sus créditos manualmente",
      explanGetPaidPlan: "Este plan completo se configura automáticamente una vez suscrito y mediante el establecimiento de un enlace de botón único a su proveedor de pagos preferido para recibir directamente en su cuenta los pagos de todos los pedidos de los clientes, incluidas las opciones de venta para llevar y de entrega a domicilio",
      planFreeClient: "Los clientes pueden ver el menú, pero no pueden hacer pedidos",
      planCreditClient: "Los clientes pueden realizar pedidos siempre que el saldo de crédito de su empresa sea superior a 1",
      planPaymentsClient: "Los negocios pueden aceptar un número ilimitado de pedidos al mes",
      planGetPaidClient: "Los clientes pueden completar sus pedidos realizando los pagos directamente",
      benefitMenu: "Menú Full Digital",
      benefitOrders: "Gestionar pedidos",
      benefitSetPaid: "Establecer pedidos como PAGADOS",
      benefitPayments: "Habilitar pagos directos de clientes",
      benefitUnlimited: "Pedidos ilimitados",
      price100: "100 Créditos = $10 (0.1)",
      price500: "500 créditos = 44 $ (0,088)",
      price1000: "1000 Créditos = $150 (0.15)",
      priceSubs: "Suscripción = 44 $/mes",
      priceGetPaid: "Comisiones = Stripe suele cobrar un 2,9% + 30¢ por cada cargo con tarjeta realizado con éxito + 1,2% de comisión de DreamDiner por cada pedido",
      creditTerms: "Los créditos son válidos durante 1 año desde la fecha de compra",
      subscriptionTerms: "Cancelar en cualquier momento, la suscripción permanece activa hasta la próxima fecha de renovación",
      getPaidTerms: "Cancelar en cualquier momento desactivando su cuenta Stripe vinculada",
      btnGetMoreCredits: "Obtener más créditos",
      btnUnlimited: "Ir ilimitado",
      btnGetPaid: "Inscríbete en GetPaid",
      siteLinkPayme: "https://dreamdiner.io/he-payment/",
      asterisk01: "El negocio acepta pagos utilizando su sistema de pago actual y marca manualmente los pedidos como PAGADOS en la tarjeta de pedidos o en la PREP-APP (DreamDiner Preparation App)",
      asterisk02: "Depende de si el negocio se ha inscrito en GetPaid"
    },
    credits: {
      buyCreditsTitle: "Comprar créditos - Pagar sobre la marcha",
      buyCreditsText1: "1 crédito = 1 pedido",
      buyCreditsTextBtn: "Mostrar todas las compras comerciales",
      currentBalance: "Saldo de crédito actual: {date}",
      lastCreditPurchase: "Última compra a crédito: {balance}",
      underCreditsBtn: "añade {date} créditos a su cuenta",
      subscribeTitle: "Estar suscrito - Uso ilimitado.",
      subscribeText: "Suscripción mensual, cancelar en cualquier momento.",
      lastRenewal: "Renovación de suscripción en: {credits}",
      subscrEnds: "Suscripción termina en:",
      backToPlansPageBtn: "Volver a la página de planes",
      underSubscribeBtn: "Comprar una suscripción",
      beforeMinimum: "Establece una alerta cuando tu saldo sea inferior a un mínimo",
      minimumLabel: "Alertarme cuando los créditos caigan por debajo",
      purchasePageTitle: "Lista de compras de créditos comerciales",
      purchasePageFreeCredits: "Créditos GRATIS",
      purchasePageTotalPurchased: "TOTAL Compras",
      purchasePageTotalOrders: "TOTAL Pedidos",
      purchasePageCreditBalance: "Saldo en Créditos"
    },
    fixPlanPage: {
      title: "Suscripción al Plan FIX",
      titleIsSubscr: "Ajustes de suscripción",
      subscrStatus: "Estado de la suscripción:",
      subscrStatusCanceled: "Cancelado",
      nextPeymant: "Próxima fecha de pago:",
      activeUntil: "Suscripción activa hasta:",
      subscrInvalid: "La suscripción no es válida. Por favor, vuelva a suscribirse en el Portal del Cliente",
      btnCustomerPortal: "Portal del cliente",
      CustomerPortalExplan: "Configura todos tus datos personales, forma de pago, cancelar suscripción, renovar, y más aquí"
    },
    getPaidPage: {
      title: "Inscríbete para recibir pagos",
      subtitle01: "Seleccione aquí su pasarela de pago preferida y abra una cuenta conectada en la plataforma DreamDiner. Esto permitirá a los clientes pagar sus pedidos, y los fondos se depositarán en su cuenta conectada",
      subtitle02: "Más tarde, puede visitar la página de Ventas para ver su panel de pagos y transacciones",
      btnStripSetupAccount: "Configurar una cuenta Stripe",
      btnStripCreateAccount: "Crear una cuenta de Stripe",
      btnStripCreateConnected: "Conectado como: bus@bus.com",
      btnSoon: "Disponible pronto",
      learnMoreStripeLink: "Más información sobre Stripe Connect",
      learnMoreStripeHref: "https://stripe.com/en-de/connect",
      learnMorePayMeLink: "Aprenda a crear una cuenta PayMe",
      learnMorePayMeHref: "https://dreamdiner.io/he-payment/",
      stripe: "Stripe",
      stripeEnabled: "¡Los pagos con Stripe están habilitados!",
      btnGoToSalesPage: "Visite su panel de control de Stripe",
      paypal: "PayPal",
      payme: "PayMe",
      sepa: "SEPA",
      amazon: "Amazon",
      bitcoin: "Bitcoin",
      countries01: "Países disponibles: En todo el mundo",
      countries02: "Países disponibles: EE.UU., Israel",
      countries03: "Países disponibles: Europa",
      countries04: "Países disponibles: EE.UU., Europa",
      ifGermanyTitle: "Según la ubicación de su empresa - Alemania, elija su proveedor de pagos",
      ifIsraelTitle: "En función de la ubicación de su empresa - Israel, elija su proveedor de pagos"
    },
    qrPage: {
      title: "Opciones de pedidos de su empresa",
      stripeTitle: "Cobra con tu cuenta Stripe"
    },
    salesPage: {
      title: "Gestiona tus Transacciones de Venta",
      subtitle01: "Vea y controle rápidamente sus pagos y tableros de Stripe",
      subtitle02: "Pestaña una pestaña para cargar sus cuadros de mando actuales. Puede tardar un poco en cargarse",
      btnStripeLoadDashboards: "Cargar cuadros de mando de Stripe",
      subtitle03: "Puntee cualquier línea de esa tabla para obtener más información y acciones (reembolsos, detalles de la transacción, etc.)",
      subtitle04: "Por favor, configure una cuenta Stripe conectada para activar el botón.",
      getPaidLink: "Establezca su negocio en GetPaid",
      paymentsTab: "Pagos",
      paymentsTabExplan: "Muestra una lista de pagos con capacidades de exportación, reembolso y disputa",
      payoutTab: "Pagos",
      payoutTabExplan: "Muestra información de pagos y permite realizar pagos"
    },
    supervisors: {
      title: "Supervisores",
      subtitle: "Los supervisores pueden acceder al panel de administración pero con ciertas limitaciones y restricciones",
      description: "Los supervisores pueden realizar varias tareas dentro del sistema, tales como visión general de los pedidos, comprobación del total de ventas, recepción de pagos, concesión de descuentos, etc. Los supervisores pueden acceder al panel de control con acceso limitado a algunas funciones que sólo se conceden al administrador del sistema.",
      redLine: "",
      redLine_wizard: "Esta sección es opcional, pero se recomienda añadir al menos un Supervisor. También se puede establecer.",
      mySupervisors: "Sus Supervisores:",
      addSupervisors: "Añadir Supervisor",
      supervisorName: "Nombre del supervisor",
      supervisorEmail: "Email del supervisor",
      moreSubtitle: "Supervisores' imagen, registros, y más se puede cambiar más tarde en Configuración"
    },
    tables: {
      title: "Tablas",
      subtitle: "Eficientemente entregar a sus clientes' órdenes, que están etiquetados de acuerdo con el nombre de la tabla y el color que fueron enviados desde.",
      description: "Las mesas juegan un papel vital en el sistema DreamDiner sirviendo como identificadores importantes para los pedidos de los clientes y los camareros que los sirven. El sistema creará automáticamente una entre bastidores si no hay mesas disponibles. Cada mesa tiene un nombre único y un código de colores para facilitar la identificación de la mesa, el cliente y el camarero correspondiente. Además, las mesas se utilizan en la aplicación de reservas Table Planner, un optimizador inteligente de reservas y asientos. Se recomienda que los nombres de las mesas no superen los cuatro caracteres.{date} Tras crear un negocio, se debe asignar a cada mesa un código de 6 dígitos para garantizar la presencia del cliente. Este código puede ser único para cada mesa o un código común (maestro) para todas las mesas del negocio. Es aconsejable cambiar este código regularmente.",
      redLine: "Esta sección es opcional pero recomendable. Un negocio no necesita tener tablas.",
      redLine_wizard: "Esta sección es opcional pero recomendable. Una empresa no necesita tener tablas.",
      myTables: "Sus tablas:",
      addTables: "Añadir Tabla",
      name: "Nombre de la tabla",
      namePlaceholder: "Max. 4 caracteres",
      seats: "Asientos de la mesa",
      colorNote: "En tiempo real, el sistema ajustará el color de la mesa según el color del camarero asignado a la misma",
      tablePin: "Código de 6 dígitos de la mesa",
      tablePinExplanation: "Es esencial tener un Código de Mesa para confirmar que el cliente está físicamente presente en la mesa cuando hace un pedido. Puede colocar una pegatina u otro indicador en la mesa con este código, y el cliente deberá introducirlo en la aplicación antes de enviar un pedido para comer en ella. Si no se asigna un código de mesa específico, el sistema utilizará automáticamente el código maestro de la mesa. Se recomienda actualizar estos códigos con regularidad.",
      tableMasterCodeTitle: "Código Maestro de Mesa",
      tableMasterCodeSubtitle: "Este será el código de tabla de reserva en caso de que una tabla no tenga definido un código específico",
      tableMasterCodeHint: "p.ej. 456789 (6 dígitos)",
      tableMasterCodeSuccessMessage: "Código maestro cambiado y guardado correctamente",
      tableMasterCodeErrorMessage: "Error al guardar o cambiar el Código Maestro",
      tableMasterCodePaletteLabel: "Utiliza el código maestro de la tabla",
      tablePicker: {
        title: "Camarero's Table Picker",
        subTitle: "Selecciona un camarero, luego toca cualquier mesa para asignar",
        waiters: "Tus camareros",
        tables: "Sus Mesas",
        alertNotAssigned: "¡Hay mesas sin asignar!"
      },
      rbTitle: "Estado de las tablas en su negocio",
      rbExplan: "Elija una de las siguientes opciones para especificar el comportamiento de la aplicación cliente con respecto a las tablas del negocio.",
      rbOption01Title: "Tablas y Códigos: (Recomendado) El negocio tiene tablas y utilizará opciones de codificación para ellas.",
      rbOption01TitleClient: "Dreamliner anima a los clientes a realizar pedidos directamente en las estaciones de preparación, eliminando la necesidad de que los camareros tomen los pedidos. Se recomienda que los clientes introduzcan un código de 6 dígitos para confirmar su presencia en la mesa cuando realicen pedidos para comer, ya que estos pedidos suelen prepararse antes de ser pagados. En este caso, el cliente seleccionará una mesa e introducirá el código de 6 dígitos antes de enviar el pedido. Este procedimiento no se aplica a los pedidos para llevar. El código de mesa puede ser único para cada mesa o un código general (maestro) para todas las mesas del establecimiento. Se recomienda cambiar este código con regularidad.",
      rbOption02Title: "Sin códigos: Tenemos mesas pero no necesitamos el sistema de codificación",
      rbOption02TitleClient: "En este caso, el cliente sólo seleccionará una tabla y podrá enviar el pedido",
      rbOption03Title: "Sin Tablas: No tenemos tablas en absoluto",
      rbOption03TitleClient: "En este caso, el cliente se saltará las páginas de selección y codificación de tablas",
      rbOption04Title: "Código QR de las mesas (característica futura): Utilizar un código QR tanto para el nombre de la mesa como para el código",
      rbOption04TitleClient: "En este caso, el cliente simplemente escaneará un código tanto para el nombre de la tabla como para el código",
      alertTableStatusUpdated: "Estado de la tabla de negocio actualizado correctamente",
      alertTableStatusError: "Se ha producido un error en el estado de la tabla. Vuelva a intentarlo"
    },
    waiters: {
      waiter: "Camarero",
      title: "Camareros",
      subtitle: "Los camareros se asignan a sus mesas para un mejor control de la entrega de pedidos.",
      description: "Los camareros desempeñan un papel crucial en el servicio y la eficacia del trabajo del restaurante. Sin embargo, DreamDiner puede reducir significativamente su número y la dependencia del restaurante de ellos He aquí por qué: los pedidos de los clientes desde las Apps de Clientes se envían directamente a las Estaciones de Preparación, con pago independiente a posteriori. La aplicación de los camareros es principalmente una herramienta eficaz que les ayuda a entregar los productos declarados listos a los hambrientos clientes que esperan ansiosos La App del Camarero también permite el acceso al menú para tomar y hacer los pedidos de los clientes.{REPLACE} Los camareros pueden ser asignados a sus mesas, dando así a cada pedido y mesa el color del camarero para un mejor control y una entrega eficiente de los pedidos.{REPLACE} El camarero entrará en la App de Camareros con el número de móvil aprobado por usted aquí.",
      redLine: "",
      redLine_wizard: "Esta sección es opcional, pero se recomienda añadir al menos un camarero. También se puede configurar.",
      myWaiters: "Tu lista de camareros:",
      addWaiters: "Añadir Camarero",
      waiterName: "Camarero's Nombre",
      waiterPhoto: "Foto del camarero",
      paletteTablesTitle: "Mesas:",
      tables: "Mesas asignadas",
      tablePickerBtn: "Asignar mesas a camareros",
      assignAll: "Asignar a todas las mesas",
      notAssignToTable: "No asignar a ninguna mesa",
      openPickerBtn: "Abrir selector de mesas",
      moreSubtitle: "",
      moreSubtitle_wizard: "Nota: Waiters' imagen, registros, y más se puede controlar más tarde en Dashboard > Waiters"
    },
    kitchens: {
      title: "Estaciones de preparación",
      subtitle: "Dividir los artículos en estaciones de preparación facilita la carga y muestra sólo los artículos asociados a cada estación.",
      description: "Una Estación de Preparación es un área designada donde se prepara un cierto tipo de comida. Por ejemplo: Parrilla, Barra Interior, Preparación de Ensaladas, etc.{REPLACE} Coloque una aplicación PREP en cada estación de preparación y configure el selector en consecuencia. Sus artículos pedidos se mostrarán ahora donde deben ser preparados: un cóctel pedido se mostrará sólo en la app Estación de Preparación configurada como Bar - y la Hamburguesa Casera en la Estación de Preparación Parrilla, etc. Este enrutamiento selectivo mejora significativamente la precisión y la comodidad de sus preparaciones.{REPLACE} Las Estaciones de Preparación son ilimitadas.{REPLACE} La Preparación Principal es un valor predeterminado del sistema y contiene por defecto todos los artículos ordenados de la estación.",
      redLine: "",
      redLine_wizard: "Main Prep es un valor predeterminado del sistema y mostrará todos los artículos pedidos independientemente de sus estaciones asignadas.",
      myKitchens: "Sus estaciones de preparación",
      addKitchens: "Añadir una estación de preparación",
      kitchenName: "Nombre de la estación de preparación",
      kitchenPlaceholder: "Máx. 3 caracteres"
    },
    staff: {
      title: "Miembros del personal",
      subtitle: "Los miembros del personal pueden iniciar sesión en la aplicación PREP-APP para gestionar la preparación de artículos en sus estaciones designadas.",
      description: "Un miembro del personal puede ser, por ejemplo, un Chef, un Sous Chef, un Camarero, un Parrillero, etc. Un miembro del personal también está autorizado a entrar en la PREP App - donde se realizan y entregan todas las preparaciones.{REPLACE} Si lo prefiere, asigne cada miembro a una Estación de Preparación: un Camarero a la estación de Barra, el Sous-Chef a la estación de Preparación de Ensaladas, y así sucesivamente. Si no se asigna, siempre aparecerá bajo la Estación de Preparación predeterminada - Preparación Principal.{REPLACE} El miembro del personal entrará en la aplicación PREP con el número de móvil aprobado por usted aquí.",
      redLine: "",
      redLine_wizard: "Esta sección es opcional pero recomendada para agregar al menos un miembro del personal. También puede ponerse usted mismo.",
      myStaff: "Sus miembros del personal:",
      addStaff: "Añadir miembro del personal",
      staffName: "Nombre del personal",
      staffPhoto: "Staff's Photo",
      staffMobile: "Número de móvil del personal",
      kitchen: "Estación de preparación por defecto",
      kitchenRoles: "Función",
      staffTerminalHint: "Si está vacía, es posible que tenga que volver atrás y añadir una estación de preparación",
      color: "Color asignado",
      moreSubtitle_wizard: "Recomendado: La imagen del personal, los registros y más se pueden controlar más tarde en Configuración",
      staffSelectTerminal: "Asignar a una estación de preparación",
      staffSelectMainKitchen: "Preparación principal"
    },
    categories: {
      title: "Categorías",
      subtitle: "Las categorías se utilizan para agrupar elementos relevantes y luego se muestran en la página de inicio del menú",
      description: "Las categorías agrupan elementos relevantes y luego se muestran en los menús, como Hamburguesas, Entrantes, Cócteles y Bebidas calientes. Sus artículos y platos pueden estar vinculados a una o más categorías: Por ejemplo, una hamburguesa casera puede estar vinculada a la categoría Hamburguesas, o a la categoría Carne de vacuno, y a la categoría ¡Nuestros Especiales!{REPLACE} Las categorías son ilimitadas, pero intente ser conciso.{REPLACE} Puede elegir un icono de un conjunto proporcionado o subir su propio icono o imagen para dar a su menú el aspecto perfecto.",
      myCategories: "Tus categorías",
      showIcons: "Mostrar iconos de categoría",
      addCategories: "Añadir categoría",
      categoryName: "Nombre de la categoría",
      checkbox: "Ocultar de todos los menús",
      option_01: "Subir una imagen o un icono",
      option_02: "Elegir un icono de un conjunto dado (color de fondo según lo establecido en el paso 1)",
      cancelItemCategory: "Desvincular un elemento de esta categoría sólo si tiene asignada al menos una categoría más",
      hasItems: "Algunos elementos ya están vinculados a esta categoría",
      hideFromMenu: "Ocultar esta categoría de los menús",
      hideFromMenuHidden: "La categoría está ahora oculta",
      hideFromMenuVisible: "Categoría ahora visible",
      iconExplan: "Elija un icono que pueda utilizarse temporalmente y cambiarse más tarde"
    },
    options: {
      title: "Opciones",
      subtitle: "Las opciones son todas las características de personalización que permite utilizar a su cliente cuando personaliza los artículos que ha pedido.",
      description: "Los Extras de artículo son todos los ajustes personales que permite solicitar a los clientes al pedir un artículo.{REPLACE} Por ejemplo: Sin cebolla, Añadir ketchup, Envolver para llevar, etc.{REPLACE} Cada Extra puede ser gratuito o tener un precio específico añadido al precio total del artículo. Además, los artículos pueden vincularse a tantos Extras como sea necesario.",
      redLine: "Seleccione una plantilla o añada una propia.",
      redLine_wizard: "Seleccione de la plantilla dada o añada la suya propia.",
      myExtras: "Sus opciones de artículo",
      addExtra: "Añadir opción de artículo",
      groupOptionsCount: "{REPLACE} opciones",
      freeNoCharge: "Sin cargo",
      groupToggle: "Esta es una opción de grupo",
      groupToggleDescription: "Cuando las opciones están agrupadas, el usuario sólo puede seleccionar una opción del conjunto dado",
      optionTitle: "Título de la opción",
      optionNum: "Opción #{REPLACE}",
      addGroupOption: "Añadir opción"
    },
    groupOptions: {
      title: "Agrupar opciones",
      subtitle: "Las Opciones de Grupo son un conjunto de opciones en las que sólo se puede seleccionar una opción.",
      description: "Una Opción de Grupo es un conjunto de opciones en las que el cliente sólo puede seleccionar una. Por ejemplo, un grado en la preparación de la carne puede ser Crudo, Medio o Bien Hecho. Otro ejemplo de Opción de Grupo es la selección de una guarnición: el cliente sólo puede elegir una: espaguetis, arroz o patatas fritas.{REPLACE} Cada opción dentro de la Opción de Grupo puede ser gratuita o tener un precio específico añadido al precio total del artículo.",
      redLine: "Seleccione de la plantilla dada o añada la suya propia.",
      redLine_wizard: "Seleccione de la plantilla dada o añada la suya propia.",
      myGroupOptions: "Sus opciones de grupo",
      addExtra: "Añadir una opción de grupo",
      groupOptionsCount: "{count} opciones",
      freeNoCharge: "Sin cargo",
      groupOptionsDrawerTitle: "Título de opción de grupo",
      groupOptionsDrawerExplan: "Nota: Por defecto, la primera opción será la seleccionada en Client App",
      groupOptionsDrawerNum: "Opción #{num} (en este grupo)",
      addGroupOption: "Añadir una opción a este grupo",
      groupBadge: "Opción de grupo"
    },
    menus: {
      title: "Menús",
      subtitle: "Los menús se utilizan para ajustar los diferentes precios de los artículos durante el horario comercial.",
      description: "Los menús son dinámicos y están relacionados con el tiempo, controlando la apariencia de los artículos mostrados en la aplicación del cliente. Por ejemplo, Menú Desayuno entre las 08:00-12:00, y Menú Happy Hour entre las 17:00-19:00, etc.{REPLACE} Cada menú mostrará los artículos relevantes vinculados a este menú. Un artículo específico puede mostrarse en varios menús. Los artículos se muestran en cada menú dentro de su categoría asignada. Por ejemplo, un cóctel casero puede mostrarse en el menú Happy Hour, en el menú Late night, pero excluirse del menú Breakfast.{count} Cada artículo puede tener un precio diferente en cada menú en el que se presente. El Cóctel Casero puede costar 6$ en el Menú Happy Hour, pero 9,5$ en el Menú Late Night. Otra posibilidad es establecer un descuento global que se aplique a todos los artículos' precios de ese menú.{num} La línea de tiempo del menú le dará una mejor visualización de todos los menús activos en diferentes momentos del día.{REPLACE} Evite la superposición de menús.{REPLACE} El Menú Principal es el menú por defecto cuando no hay ningún otro menú disponible y muestra todos los elementos visibles.{REPLACE} El menú correspondiente se muestra en la App de Clientes cuando el negocio está fuera del horario laboral.{REPLACE} El Menú Principal es el menú predeterminado del sistema las 24 horas del día cuando no se define ningún otro menú. Cuando el negocio se define como Cerrado, fuera del horario laboral, el menú de su negocio en la App de Clientes mostrará, por defecto, el Menú Principal con todos los elementos.",
      redLine: "",
      redLine_wizard: "Esta sección es opcional pero recomendada. Un negocio no necesita tener menús.",
      myMenus: "Sus menús",
      addMenu: "Añadir Menú",
      hideMenu: "Ocultar menú",
      menuName: "Nombre del menú",
      startTime: "Hora de inicio",
      endTime: "Hora final",
      hasCategories: "Este menú está vinculado a las categorías",
      itemsCount: "{REPLACE} artículos"
    },
    items: {
      title: "Artículos",
      subtitle: "Los artículos hacen referencia a todos los productos disponibles en la carta de su negocio, incluidos platos y bebidas. Asegúrese de que sean atractivos y de la máxima calidad",
      description: "Por último, ¡ya está listo para abrir su primer artículo! Ahora que tenemos nuestras estaciones de preparación, nuestros menús y categorías (¡no dude en volver y añadir más!), podemos vincularlos todos para crear su primer artículo perfecto. A continuación, suba una imagen bonita y espectacular de este artículo: la comida sabrosa empieza con un aspecto delicioso. Aquí, también asignará Alérgenos y Etiquetas de una lista dada (se permiten múltiples selecciones) y establecerá las Opciones y Extras.",
      myItems: "Mis Artículos",
      addItem: "Añadir artículo",
      hide: "Ocultar de todos los menús",
      symbol: "Código de preparación",
      itemDescription: "Descripción (hasta {REPLACE} caracteres)",
      image: "Artículo / Imagen del plato",
      categories: "Enlace a sus Categorías:",
      menus: "Enlace a sus Menús:",
      labels: "Añadir Etiquetas: (Opcional)",
      allergens: "Añadir Alérgenos: (Opcional)",
      kitchens: "Estaciones de preparación",
      kitchenTerminalHint: "Seleccionar estación de preparación",
      kitchenTerminalMainKitchen: "Preparación principal",
      price: "Precio de lista:",
      menuPrice: "Precio de menú:",
      discountPlaceholder: "Precio No Especial",
      regularOptions: "Opciones regulares",
      groupOptions: "Opciones de grupo",
      optionsAlertTooltip: "Esta opción puede tener un Error",
      addOption: "Crear una nueva opción",
      optionsCount: "Opciones: {REPLACE}",
      groupOptionsCount: "Grupos: {count}",
      extras: "Opciones",
      duplicateMessage: "El artículo se ha duplicado correctamente",
      linkToMenu: "Establecer un precio diferente por cada menú personalizado (Menú Principal es un menú por defecto del sistema):",
      linkToMenuWizard: "(Enlace a Menús después de la creación del negocio)",
      linkToMenuBusiness: "(Enlace a Menús tras la creación del artículo)",
      pricesUpdate: "Actualización de precios",
      pricesReset: "Restablecer precios",
      takeawayAllowTitle: "¿Activar Take-Away / Entrega para este artículo?",
      takeawayAllowIsAlsoForTA: "Este artículo también es para AT y Entrega",
      takeawayAllownotForTA: "Este artículo es sólo para comer",
      badgeNotForTA: "Artículo no para llevar / Entrega",
      badgeForAll: "Comer en / Para llevar / Entrega",
      addCategory: "Crear una nueva categoría",
      archiveItemTitle: "Archivar este elemento (Ocultar de todos los menús)",
      archiveItemSwitchisVisible: "El artículo es visible",
      archiveItemSwitchisArchived: "El elemento está archivado",
      wizardImagesPopup: {
        title: "Elija una imagen de muestra",
        subtitle: "Siempre podrás cambiarla más tarde en la configuración de tu artículo"
      },
      specialPrice: {
        price: "Precio de venta:",
        date: "Válido hasta:",
        hint: "Pulse aquí para fijar la fecha y hora de finalización",
        dateTooltip: "Establezca una fecha de caducidad para este precio especial"
      }
    },
    dashboard: {
      staffCardTitle: "Personal",
      chefs: "Miembro del personal registrado:",
      waiters: "Camareros registrados:",
      emptyStaff: "Ninguno está registrado actualmente",
      onboardingCardTitle: "Abordando",
      onboardingCardText: "Empieza de la forma más fácil",
      qrBlockTitle: "Obtener pedidos",
      qrBlockSubTitle: "Escanéame Opciones de pedido de clientes y enlaces compartibles",
      settingsBlockTitle: "Visión general",
      settingsBlockSubTitle: "Toda la información y configuración de la empresa",
      settingsBlockDynamicTitle: "ID de empresa:",
      settingsBlockButtonTitle: "Actualizar configuración",
      salesBlockTitle: "Transacciones de venta",
      salesBlockSubTitle: "Su panel de pagos Stripe es de fácil acceso y gestión",
      reportsBlockTitle: "Gráficos de ventas",
      reportsBlockSubTitle: "Análisis de todos los pedidos y artículos de un vistazo",
      reportsBlockDynamicTitle: "Ventas de hoy",
      reportsBlockButtonTitle: "Ver informes",
      appsBlockTitle: "Aplicaciones",
      appsBlockSubTitle: "Informe a su personal sobre cómo descargar sus aplicaciones y cómo iniciar sesión",
      appsBlockDynamicTitle: "Marca blanca:",
      appsBlockButtonTitle: "Explorar aplicaciones",
      staffBlockTitle: "Personal",
      staffBlockSubTitle: "Todos tus Supervisores, Personal y Camareros",
      staffBlockDynamicTitle: "Actualmente conectado:",
      staffBlockButtonTitle: "(TRABAJO)",
      tableplannerBlockTitle: "Planificador de mesa",
      tableplannerBlockSubTitle: "Organiza y optimiza las reservas de mesa de tu restaurante",
      tableplannerBlockDynamicTitle: "Reserva hoy:",
      tableplannerBlockButtonTitle: "(TRABAJO)",
      ordersBlockTitle: "Gestionar pedidos",
      ordersBlockSubTitle: "Visión general de su PREP App para los pedidos de hoy's",
      ordersBlockDynamicTitle: "En preparación:",
      ordersBlockButtonTitle: "(TRABAJO)",
      businessTierBlockTitle: "Planes de actualización",
      businessTierBlockSubTitle: "Permanezca en un plan GRATUITO o actualice para obtener pagos",
      businessTierBlockDynamicTitle: "Plan actual:",
      businessTierBlockButtonTitle: "(TRABAJO)",
      OnboardingBtn: "Incorporación",
      OnboardingBlockTitle: "Onboarding Stepper",
      OnboardingBlockSubTitle: "Una guía paso a paso que le ayudará a entender los componentes del sistema",
      OnboardingBlockDynamicTitle: "Pasos actuales: 12",
      OnboardingBlockButtonTitle: "COMENZAR",
      qrCodeClientBlockTitle: "Pedidos Código QR",
      qrCodePrintInputTitle: "Añada un título a la impresión (opcional)",
      qrCodeCopiedAlert: "Se ha copiado el enlace URL",
      availableSoon: "¡Pronto disponible!",
      credits: "Plan actual: Créditos",
      free: "Plan Actual: Gratis",
      getpaid: "Plan actual: Ilimitado",
      getPaidBlockTitle: "Cobrar",
      getPaidBlockSubTitle: "Inscriba su empresa para recibir pagos a través de Stripe o PayPal",
      batchSettings: "Configuración",
      batchUtility: "Utilidad",
      tipsBlockTitle: "Consejos de camareros",
      tipsBlockSubTitle: "Resúmenes de propinas de camareros"
    },
    overview: {
      title: "Página general"
    },
    apps: {
      title: "Descarga tus aplicaciones",
      subTitle: "Encuentre aquí todos los enlaces y credenciales de acceso para trabajar eficientemente",
      back: "Volver a {length}",
      appStore: "App Store",
      getItOn: "GET IT ON",
      googlePlay: "Google Play",
      download: "Descárgalo en",
      headerApp: "App",
      headerUsage: "Uso",
      headerLinks: "Enlaces de descarga",
      headerCreds: "Usuarios autorizados y credenciales de acceso",
      qrLinkSubtitle: "Abrir en navegador",
      qrLinkExplan: "Su empresa está lista para aceptar pedidos con este código QR o haciendo clic en el botón de abajo. Comparta este código QR o URL con sus clientes",
      kitchen: "PREP App",
      kitchenUsage: "Se coloca en cada estación de preparación para controlar los nuevos pedidos y promover las preparaciones",
      kitchenAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.kitchen",
      kitchenQrTitle: "título de chef",
      kitchenPrintTitle: "PREP App @Google Play",
      waiters: "Waiter App",
      waitersUsage: "Permita a sus camareros tomar pedidos de clientes y entregar los artículos listos a los clientes",
      waitersAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.waiter",
      waiterQRTitle: "título camareros",
      waitersPrintTitle: "Waiters App @Google Play",
      supervisors: "Admin App",
      supervisorsUsage: "Una herramienta eficaz para que usted o cualquier supervisor controle el servicio actual: pedidos, caja, personal, y mucho más",
      supervisorsAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.admin",
      supervisorsWeb: "https://admin.dreamdiner.io",
      supervisorsQrTitle: "título de los supervisores",
      supervisorsPrintTitle: "Admin App @Google Play",
      clients: "Cliente App",
      clientsUsage: "Aquí es donde tus clientes pueden encontrarte: ver tu menú o hacer pedidos",
      clientsAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
      clientsAppStore: "https://apps.apple.com/us/app/gypsy-ace-2023/id6449999003",
      clientsWeb: "https://client.dreamdiner.io",
      clientsQrTitle: "título clientes",
      clientsPrintTitle: "Clientes Apps",
      label: "Aplicación de marca blanca",
      labelUsage: "Tu app privada de cliente presente en Google Play y Apple App Store sólo con tus artículos de restaurante",
      labelAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
      labelAppStore: "",
      labelWeb: "https://dev.client.dreamdiner.io/business/4Cu0vVmfFH",
      labelQrTitle: "título de la etiqueta",
      labelPrintTitle: "título de la etiqueta",
      tablePlanner: "Planificador de tabla",
      tablePlannerUsage: "Una aplicación multidispositivo multiplataforma que robustezca el control de tus reservas en restaurantes",
      tablePlannerAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.planner",
      tablePlannerAppStore: "https://apps.apple.com/us/app/restaurant-seating-planner/id1463277281?ls=1",
      tablePlannerQrTitle: "table Planner title",
      tablePlannerPrintTitle: "tableTítulo del planificador",
      userName: "Nombre",
      userRole: "Función",
      userLogin: "Inicio de sesión",
      userSms: "SMS"
    },
    reports: {
      title: "Página de informes",
      startDate: "Fecha de inicio",
      endDate: "Fecha de finalización",
      orders: "Pedidos",
      sales: "Ventas",
      refunds: "Reembolsos",
      ratings: "Valoraciones",
      selectY: "Seleccione Y",
      selectX: "Seleccione X",
      displayType: "Tipo de pantalla",
      totalSales: "Ventas totales",
      totalOrders: "Total Pedidos",
      totalRefunds: "Total Reembolsos",
      totalRatings: "Calificaciones totales",
      bar: "Bar",
      line: "Línea",
      area: "Área",
      average: "Media",
      showAverage: "Mostrar Promedio",
      print: "Imprimir",
      export: "Exportar",
      byDays: "Por días",
      byProducts: "Por artículos",
      byWaiters: "Por Camareros",
      totalTips: "Total Propinas",
      tips: "Propinas"
    },
    orders: {
      switchOnlyLive: "Sólo en curso",
      switchOnlyUnpaid: "Sólo sin pagar",
      labelPaid: "PAID",
      labelUnPaid: "UNPAID",
      labelInProgress: "En curso",
      labelDone: "Hecho",
      sortBy: "Ordenar por:",
      perPage: "Por página:",
      byNum: "Número de pedido",
      byDate: "Fecha del pedido",
      allTime: "De todos los tiempos",
      downloadPrepApp: "Se invita a los administradores y a los miembros del personal que figuran en la lista a descargar la aplicación PREP-APP para obtener una amplia gama de opciones y funciones adicionales",
      downloadPrepAppLink: "https://dreamdiner.io/dpa/",
      clientRatingItem: "Artículo calificado por el cliente como:",
      clientRatingWaiter: "Camarero valorado por el cliente como:",
      clientTip: "Propina del camarero",
      clientNoRating: "No encontró valoración",
      tooltipRefreshOrders: "Actualizar página",
      tooltipRefreshOrdersUpdates: "Actualiza",
      tooltipRefreshOrdersNew: "Nuevo",
      tooltipFullScreen: "Modo de pantalla completa",
      tooltipSwitchInProgress: "Cambiar para mostrar sólo los pedidos En curso",
      tooltipSwitchUnPaid: "Pasar a mostrar sólo los pedidos No pagados",
      tooltipSwitchItems: "Mostrar pedido",
      tooltipOrderType: "Tipo de pedido",
      tooltipItemNumber: "Número de artículos en este pedido",
      tooltipItemNumberExtra: "¡Atención! Algunos artículos tienen opciones especiales",
      tooltipBtnInProgress: "Haga clic para establecer un pedido como DONE",
      tooltipBtnDone: "Haga clic para volver a establecer como En curso",
      tooltipBtnPaidStatusPaid: "Cambiar a UNPAID",
      tooltipBtnPaidStatusUnPaid: "Establecer como PAGADO",
      tooltipBtnOrderRated: "El pedido ha sido calificado por el cliente",
      tooltipBtnOrderRequest: "Pedido del cliente",
      list: {
        num: "Num.",
        client: "Cliente",
        tel: "Tel.",
        type: "Tipo",
        table: "Tabla",
        sum: "Suma",
        items: "Artículos",
        remark: "Nota",
        payment: "Pago",
        id: "ID",
        time: "Hora",
        status: "Preparación"
      },
      tabs: {
        progress: "Progreso",
        completed: "Listo",
        delivered: "Entregado"
      }
    },
    onboarding: {
      backToOnboardingBtn: "Continuar con el Onboarding",
      onboarding_cards: {
        obQR: {
          title: "Realizar el primer pedido",
          description: "Escanea el código generado o pulsa el botón para empezar. ¡Este es el QR que entregas a tus clientes para que realicen sus pedidos!",
          next_cta: "Siguiente",
          prev_cta: "Volver"
        },
        obOrder: {
          title: "¡Aquí tienes tu pedido!",
          description: "Después de que el pedido ha sido enviado, verlo aquí en su tablero de instrumentos. Intente configurarlo como PAGADO o HECHO.",
          label: "Llévame allí",
          next_cta: "Siguiente",
          prev_cta: "Volver"
        },
        obReports: {
          title: "Explorar informes",
          description: "¡Datos en tiempo real de toda la actividad de un vistazo! Incluso el pedido que acaba de hacer...",
          label: "Mostrar informes",
          next_cta: "Siguiente",
          prev_cta: "Volver"
        },
        obKitchen: {
          title: "DreamDiner Preparación App",
          subtitle: "Disponible para dispositivos Android",
          description: "(Opcional) Descargue la App Android de Preparación y Control de Pedidos (POC). Los miembros de su personal asignados están invitados a iniciar sesión",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Enlace a la aplicación y miembros del personal"
        },
        obSettings: {
          title: "Configuración & Ubicación",
          description: "Revisa todos los ajustes de tu negocio y actualiza tu ubicación",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame allí"
        },
        obImages: {
          title: "Reemplazar imágenes de demostración",
          description: "(Opcional) Si utilizaste las imágenes de demostración, quizás ahora las reemplaces por tus imágenes reales..",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame a mis artículos para reemplazar imágenes"
        },
        obOptions: {
          title: "Vincular opciones de artículos",
          description: "(Opcional) ¿Recuerdas las opciones y extras de tus artículos? Ahora puedes vincularlos a cualquier artículo",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame a mis artículos para actualizar las opciones"
        },
        obMenu: {
          title: "Precios de los artículos del menú",
          description: "(Opcional) Establezca diferentes precios de artículos según sus menús (Omitir si no abrió ningún Menú)",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame a mis artículos para enlazar menús"
        },
        obTables: {
          title: "Establecer códigos de tabla",
          description: "(Opcional) Establezca un código único de 6 dígitos para cada mesa, o cambie el Código Maestro por defecto",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame a cambiar los códigos"
        },
        obWaiters: {
          title: "Asignar camareros",
          description: "(Opcional) Si tiene camareros, asegúrese de que están asignados a sus respectivas mesas (Pulse el botón Asignar mesas a camareros)",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame con mis camareros"
        },
        obPlans: {
          title: "Planes de mejora",
          description: "¿Quedarte GRATIS, comprar CRÉDITOS u obtener PAGOS? Tú eliges",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Termine",
          learnMore: "Más información aquí:",
          business_models_link: "Muéstrame Planes de Negocio"
        },
        obCategories: {
          title: "4. Actualizar iconos de categoría",
          description: "Configure sus categorías, iconos o imágenes, ¡exactamente como usted quiera!",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame allí"
        },
        obLive: {
          title: "Set business to LIVE",
          description: "¿Listo para LIVE? Pon el interruptor de la tarjeta en Establecer como EN VIVO",
          next_cta: "Siguiente",
          prev_cta: "Volver",
          label: "Llévame allí"
        },
        noshowDialog: {
          title: "¿Salir del OnBoarding?",
          explan: "Se recomienda encarecidamente completar el asistente de onboarding, al menos una vez, para configurar todos los enlaces e imágenes correctamente para una funcionalidad perfecta",
          label: "Marcar aquí para no volver a mostrar el OnBoarding"
        }
      }
    },
    invite: "Notificar {num}",
    inviteSuccess: "Una invitación SMS para descargar la aplicación y el inicio de sesión enviado con éxito :)",
    sections: {
      dashboard: "Panel de control",
      info: "Ajustes",
      hours: "Horas de actividad",
      supervisors: "Supervisores",
      tables: "Mesas",
      waiters: "Camareros",
      kitchens: "Estaciones de preparación",
      chefs: "Personal",
      categories: "Categorías",
      menus: "Menús",
      items: "Artículos",
      extras: "Opciones de artículos",
      groupOptions: "Opciones de grupo",
      infoExplan: "Toda la información empresarial & Configuración",
      hoursExplan: "¿Cuándo está abierto? ¿Cerrado?",
      supervisorsExplan: "Gestione sus Supervisores",
      tablesExplan: "Añade y edita tus Mesas",
      waitersExplan: "Editar Camareros y asignarlos a Mesas",
      kitchensExplan: "Sus Estaciones de Preparación",
      chefsExplan: "Gestione su Personal",
      categoriesExplan: "Añade y edita tus Categorías",
      menusExplan: "Añade y edita tus Menús",
      itemsExplan: "Añade y edita tus Artículos",
      extrasExplan: "Añadir y editar sus Opciones de artículo",
      groupOptionsExplan: "Añadir y editar las opciones de grupo"
    },
    toolbar: {
      creditsBannerText: "ALERTA: ¡Sólo le quedan {num} créditos! Su mínimo definido es: {name}",
      creditsBannerCta: "Recarga créditos de empresa aquí",
      credits: "Saldo de créditos: {name}",
      miminum: "Alerta cuando bajo: {credits}",
      helpTitle: "Soporte",
      helpTooltip: "Chat de soporte",
      supportPopupTitle: "Escanear el código QR",
      supportPopupOr: "O simplemente haz clic para ponerte en contacto con nosotros:",
      supportPopupBtn: "Iniciar un chat de soporte",
      supportPopupSupportRemark: "Nuestro equipo del Centro de Soporte está disponible de 8:00 a.m. a 4:00 p.m., DOM-FRI (UTC+2). Estamos aquí para responder a sus preguntas sobre la configuración de su negocio para empezar y para cualquier pregunta o problema después",
      supportHelpScreenshot: "Por favor, ayúdenos proporcionando y adjuntando una captura de pantalla o un clip corto que mejor describa su problema",
      breadcrumbs: {
        home: "Mis negocios",
        info: "Información del negocio",
        dashboard: "Panel de control",
        qr: "Código QR y enlaces de pedidos",
        overview: "Visión general",
        apps: "Aplicaciones",
        reports: "Gráficos de venta",
        orders: "Gestionar pedidos",
        onboarding: "Incorporación",
        supervisors: "Supervisores",
        tables: "Tablas",
        waiters: "Camareros",
        kitchens: "Estaciones de preparación",
        chefs: "Personal",
        categories: "Categorías",
        menus: "Menús",
        items: "Artículos",
        extras: "Opciones de artículos",
        edit: "Editar Objeto",
        hours: "Horas",
        groups: "Opciones de grupo",
        purchases: "Compras",
        planner: "Planificador",
        plans: "Planes de actualización",
        sales: "Transacciones de venta",
        credits: "Obtener más créditos",
        subscription: "Suscripción al Plan FIX",
        payments: "Inscribirse para cobrar",
        tips: "Resumen de las propinas"
      }
    },
    fromWizard: {
      title: "Su nuevo negocio {minimum} ¡ya está listo!{credits} se han añadido 500 créditos a su banco de crédito (esquina superior derecha)",
      text: "Recibirá un correo electrónico de bienvenida en: {miminum} (¡comprueba el correo no deseado!) con algunos detalles importantes.{title}{REPLACE} La siguiente etapa es un tutorial Onboarding amigable para ayudarle a configurar su negocio a la perfección. ¡Que aproveche!",
      btn: "Iniciar el Onboarding"
    },


  imagePicker: {
    default: "Arrastre y suelte un archivo aquí o {REPLACE} navegue para cargarlo",
    wizard: "Subir una imagen para este artículo"
  },
  kitchenRoles: {
    chef: "Chef",
    sousChef: "Sous Chef",
    grillChef: "Grill Chef",
    bartender: "Camarero",
    kitchenAssistant: "Ayudante de Cocina",
    other: "Otros"
  },
  landing: {
    welcome: "Hola, {REPLACE}. El panel de tu negocio",
    lastUpdated: "Última actualización: {num}",
    beta: "BETA",
    wizardTitle: "Crear un nuevo negocio",
    wizardDescription: "Un amable asistente le guiará a lo largo de",
    demoBusinessDescription: "El lugar para probar todas las funciones",
    emptyBusinessTitle: "Aquí verás tu nuevo negocio.",
    emptyBusinessSubTitle: "La creación de una empresa suele constar de dos fases. En primer lugar, está la fase Inicial, en la que utilizas un asistente para configurar los aspectos básicos, como algunos artículos clave y las funciones relevantes necesarias para realizar un primer pedido de muestra. Luego, está la fase definitiva, en la que se toma su tiempo para configurar el negocio completo con todos los elementos necesarios y las características perfectamente adaptadas a su flujo de trabajo."
  },
  models: {
    category: {
      singular: "categoría",
      plural: "categorías"
    },
    chef: {
      singular: "chef",
      plural: "chefs"
    },
    extra: {
      singular: "extra",
      plural: "extras"
    },
    item: {
      singular: "artículo",
      plural: "artículos"
    },
    kitchen: {
      singular: "cocina",
      plural: "cocinas"
    },
    menu: {
      singular: "menú",
      plural: "menús"
    },
    supervisor: {
      singular: "supervisor",
      plural: "supervisores"
    },
    table: {
      singular: "mesa",
      plural: "mesas"
    },
    waiter: {
      singular: "camarero",
      plural: "camareros"
    }
  },
  multiSelect: {
    placeholder: "Seleccione uno o más",
    empty: "La lista está actualmente vacía",
    skipped: "Omitido (pero valor por defecto añadido)",
    onlyOption: "Actualmente, Esta es la única opción disponible aquí"
  },
  demoBusiness: {
    demoMode: "Sólo para fines de demostración. Guardar está desactivado",
    showdemo: "Mostrar negocio DEMO",
    hidedemo: "Ocultar negocio DEMO"
  },
  nav: {
    login: "Iniciar sesión",
    logout: "Cerrar sesión",
    user: {
      guest: "¡Bienvenido, Invitado!",
      btn: "Tu perfil",
      editProfile: "Editar perfil"
    },
    business: {
      demoBusinessTitle: "Demostración",
      enterBusiness: "Introducir negocio",
      demoBusinessBtn: "Demo en vivo"
    }
  },
  wizard: {
    dialog: {
      title: "Vuelves a la versión guardada del borrador de creación de tu negocio.",
      subtitle: "¿Desea continuar donde lo dejó?",
      confirm_title: "¡Atención!",
      confirm_subtitle: "Reiniciar el asistente revertirá todo lo que has hecho hasta ahora y borrará todos tus cambios. ¿Estás seguro de que quieres empezar de cero?",
      confirm_cta: "Claro, reiniciar todo",
      confirm_back: "No, volver atrás"
    },
    suggestions: {
      option_01: "Añadir instantáneamente desde una plantilla dada",
      option_02: "Añade la tuya desde cero",
      toggle: "Mostrar sugerencias",
      add: "Añadir desde plantilla",
      addTemplateBtn: "Añadir esta plantilla",
      skipNextBtn: "Omitir por ahora"
    },
    toolbar: {
      saved: "Guardado como Borrador",
      typeRequired: "Este paso es necesario para todos los tipos de empresa",
      typeChecked: "Esta etapa se recomienda para este tipo de negocio",
      required: "Esta etapa es necesaria",
      mobileToolbarProgressNav: "Haga clic en el círculo para navegar",
      titles: {
        intro: "Asistente para iniciar un nuevo negocio",
        types: "Tipos",
        customize: "Etapas de su Asistente",
        info: "Información",
        supervisors: "Supervisores",
        tables: "Mesas",
        waiters: "Camareros",
        kitchens: "Estaciones de preparación",
        chefs: "Personal",
        categories: "Categorías",
        menus: "Menús",
        extras: "Opciones de artículos",
        items: "Artículos",
        terms: "Términos y Condiciones",
        summary: "Resumen"
      },
      btns: {
        createBusiness: "Crear mi empresa"
      }
    },
    util: {
      seeMore: "Ver todos: {REPLACE}",
      listEmpty: "La lista está vacía :-0"
    },
    intro: {
      title: "Por si quieres aprender algo más... {user}",
      subtitle: "",
      redLine: "¡Disfruta de tu experiencia DreamDiner!",
      title2: "Un breve vídeo explicativo {date}",
      subtitle2: "Un clip explicativo animado muestra el proceso de creación del negocio",
      redLine2: "2 minutos es todo lo que se necesita :)"
    },
    customize: {
      title: "¿Cuál es su tipo de negocio?",
      subtitle: "Seleccione una plantilla de negocio para ayudar al sistema a ajustar sólo las características relevantes necesarias para su tipo.{num} Para personalizar manualmente o explorar todas las características, seleccione Personalizado.",
      clickNext: "Para continuar, haga clic en el botón Siguiente de la esquina superior derecha",
      typeHeader: "Estos son los pasos del asistente recomendados para su tipo de negocio:",
      explan: "Para cambiar el tipo de negocio, simplemente haga clic en Atrás. Índice: Estrella = Sección obligatoria, Casilla de verificación = La sección se puede desmarcar para eliminarla",
      bottom: "No se preocupe: Una vez creado su negocio, cualquier característica omitida puede ser añadida de nuevo a su negocio",
      businessTypes: {
        truck: {
          title: "Camión de comida",
          subtitle: ""
        },
        pub: {
          title: "Bar / Pub",
          subtitle: ""
        },
        fine: {
          title: "Restaurante",
          subtitle: ""
        },
        small: {
          title: "Pequeño restaurante",
          subtitle: ""
        },
        cafe: {
          title: "Cafe",
          subtitle: ""
        },
        fast: {
          title: "Comida rápida",
          subtitle: ""
        },
        buffet: {
          title: "Buffet",
          subtitle: ""
        },
        popup: {
          title: "Pop-up",
          subtitle: ""
        },
        ghost: {
          title: "Cocina Fantasma",
          subtitle: ""
        },
        delivery: {
          title: "TA/Delivery",
          subtitle: ""
        },
        kiosk: {
          title: "Quiosco",
          subtitle: ""
        },
        other: {
          title: "Personalizado",
          subtitle: ""
        }
      },
      skippable: "Opcional",
      points: {
        types: {
          title: "Tipo de empresa",
          subtitle: "Defina su negocio",
          time: "1/2 min"
        },
        customize: {
          title: "Pasos del negocio",
          subtitle: "Personaliza tu propio negocio",
          time: "1/2 min"
        },
        info: {
          title: "Detalles del negocio",
          subtitle: "Nombre de su restaurante, descripción, logotipo, contraseña, etc.",
          time: "2 min"
        },
        supervisors: {
          title: "Supervisores",
          subtitle: "Asigne a los supervisores de su restaurante (¡Administrador sólo es usted!)",
          time: "1 min"
        },
        tables: {
          title: "Mesas",
          subtitle: "Añade tu primera mesa y asientos",
          time: "1 min."
        },
        waiters: {
          title: "Camareros",
          subtitle: "Añade tus camareros y asígnalos a las mesas",
          time: "1 min."
        },
        kitchens: {
          title: "Estaciones de preparación",
          subtitle: "Sus estaciones de preparación: por ejemplo, Parrilla, Ensaladas, Barra, etc.",
          time: "30 seg."
        },
        chefs: {
          title: "Miembros del personal",
          subtitle: "Su equipo: cocineros, camareros, lavaplatos, etc.",
          time: "1 min."
        },
        categories: {
          title: "Categorías",
          subtitle: "Añade tus artículos' categorías",
          time: "30 seg."
        },
        menus: {
          title: "Menús",
          subtitle: "Por ejemplo, menú de desayuno, menú Happy Hour, etc.",
          time: "2 min."
        },
        extras: {
          title: "Opciones / Extras",
          subtitle: "Por ejemplo: Sin sal, Aceite de oliva extra, Poco hecho o Bien hecho, etc.",
          time: "1 min."
        },
        items: {
          title: "Artículos",
          subtitle: "¡Ya puedes crear y enlazar tu primer artículo o plato!",
          time: "3 min."
        },
        terms: {
          title: "Términos y Condiciones",
          subtitle: "Lea y apruebe nuestros términos y condiciones",
          time: "+1 min."
        }
      }
    },
    business: {},
    terms: {
      title0: "Bien, ¿y ahora qué?",
      subtitle0: "En el siguiente paso, confirmará su empresa antes de su creación. ¿Necesita hacer algún cambio? No hay problema; vuelve atrás y cambia; lo único que has hecho es guardar. Una vez creado, escanea el código QR generado para hacer tu primer pedido. ¡Genial! Premiaremos su negocio con 500 créditos GRATIS para hacer 500 pedidos para probar DreamDiner antes de elegir su plan de negocio para continuar.{REPLACE} Siga la guía de incorporación para mejorar aún más su negocio, ¡y ya puede empezar a trabajar EN VIVO!",
      title: "Nuestros Términos y Condiciones",
      description: "Ya casi está, ¡y puede empezar! Un último paso antes de abrir su negocio único en el sistema DreamDiner por favor lea cuidadosamente y acepte los siguientes temas:",
      redLine: "Gracias por leer y confirmar marcando todas las casillas :)",
      termsCheckbox: "Estoy de acuerdo con",
      termsLink: "Términos y Política",
      paymentCheckbox: "Las opciones de pago y configuración del pago se encuentran en Configuración",
      idleCheckbox: "Eliminación del negocio en 90 días si no hay actividad"
    },
    summary: {
      businessCreationTitle: "¿Está su negocio listo para ser creado?",
      businessCreationSubTitle: "Esto es lo que nos ha dicho sobre la creación de su negocio básico. Siéntete libre de volver a cualquier paso que necesites o hazlo después de crear el negocio.",
      businessCreationClickNext: "Para continuar, haga clic en el botón CREAR MI NEGOCIO en la esquina superior derecha",
      businessCreation3only: "Mostrando sólo los tres primeros elementos de cada sección",
      staff: "Equipo",
      supervisors: "Supervisores",
      waiters: "Camareros",
      chefs: "Personal",
      equipment: "Lugar",
      kitchens: "Estaciones de preparación",
      tables: "Mesas",
      products: "Menús & Artículos",
      extras: "Extras",
      menus: "Menús",
      categories: "Categorías",
      items: "Artículos",
      regularOptions: "Opciones regulares",
      groupOptions: "Opciones de grupo",
      extraOptions: "{REPLACE} Opciones",
      addRegularOption: "Añadir opción normal",
      addGroupOption: "Añadir opción de grupo",
      finalize: "Comience su negocio",
      working: "Crear tu Negocio",
      patience: "Por favor espere...",
      saving: {
        info: "Guardando ahora nuestro Negocio...",
        supervisors: "Actualizando Supervisores",
        tables: "Actualizando Tablas",
        waiters: "Actualización de camareros",
        kitchens: "Actualización de puestos de preparación",
        chefs: "Actualización del personal",
        categories: "Actualización de categorías",
        menus: "Actualización de menús",
        extras: "Actualización de opciones de artículos",
        items: "Actualización de elementos"
      }
    },
    steps: {
      info: "Información",
      types: "Tipos",
      customize: "Pasos",
      supervisors: "Supervisores",
      tables: "Mesas",
      waiters: "Camareros",
      kitchens: "Estaciones de preparación",
      chefs: "Personal",
      categories: "Categorías",
      menus: "Menús",
      extras: "Opciones",
      items: "Artículos",
      terms: "Términos",
      summary: "Resumen"
    }
  },
  validation: {
    required: "Esta entrada es necesaria",
    email: "Dirección de correo electrónico no válida",
    minLength: "Debe tener {REPLACE} caracteres o más",
    maxLength: "Debe tener {REPLACE} caracteres o menos",
    specificLengthDigits: "Debe ser {REPLACE} dígitos",
    sameAsEmail: "Emails no coincidentes",
    sameAsPassword: "Las contraseñas no coinciden",
    maxValue: "Debe ser inferior a {num}",
    minValue: "Debe ser mayor que {min}",
    toAfterFrom: "La hora de finalización debe ser posterior a la hora de inicio",
    isUniquePhone: "Este teléfono ya se utiliza aquí",
    isUniqueTitle: "Este título ya se utiliza aquí",
    isUniqueSymbol: "Este símbolo ya se utiliza aquí",
    url: "Debe ser una URL válida",
    mustBeInFuture: "Debe haber un tiempo en el futuro",
    numeric: "Debe ser un número",
    dataNotValid: "Los datos introducidos no son válidos"
  },
  hours: {
    default: "Por defecto",
    sunday: "Domingo",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado"
  },
  warnings: {
    hot: "Muy caliente",
    organic: "Orgánico",
    special: "Especial",
    spicy: "Picante",
    vegan: "Vegano",
    longPreparation: "Preparación larga",
    glutenFree: "Sin gluten",
    crustacean: "Crustáceos",
    fish: "Pescado",
    egg: "Huevo",
    gluten: "Gluten",
    celery: "Apio",
    nuts: "Frutos de cáscara",
    milk: "Leche",
    peanuts: "Cacahuetes",
    sesame: "Sésamo",
    molluscs: "Moluscos",
    lupins: "Altramuces",
    mustard: "Mostaza",
    mushroom: "Champiñón",
    corn: "Maíz",
    soya: "Soja",
    sulphite: "Sulfito"
  },
  imageCropper: {
    cropperTitle: "Cargar una imagen",
    cropperSubTitle: "Si es demasiado grande, intente ajustar el control deslizante y el tamaño de recorte de la imagen",
    imageSize: "Tamaño actual de la imagen:",
    imageQuality: "Calidad de la imagen:",
    maxImageSize: "Tamaño máx. de imagen:",
    calcOnCrop: "[On Crop]",
    replaceImageBtn: "Añadir / Reemplazar",
    cropImageBtn: "Guardar",
    reduceSizeHint: "Reducir el tamaño de la imagen cambiando el valor de calidad de imagen: 0.01 a 1.0."
  },
  explanations: {
    supervisorStep01___Wizard: "Nombre y correo electrónico será el Supervisor's nombre de usuario para iniciar sesión en el panel de control de administración",
    supervisorStep01_Business: "El nombre y la dirección de correo electrónico serán el nombre de usuario del supervisor para acceder al panel de control de administración",
    supervisorStep02___Wizard: "El número de móvil será el nombre de usuario del supervisor para acceder a la aplicación PREP-APP (más información más adelante)",
    supervisorStep02_Business: "El número de móvil será el nombre de usuario del Supervisor para acceder a la aplicación PREP-APP (Ir a: Dashboard > Apps)",
    tableStep01___Wizard: "Cada mesa debe tener un nombre y el número de asientos",
    tableStep01_Business: "Cada mesa debe tener un nombre y el número de asientos",
    tableStep02___Wizard: "Asignar un color para esta mesa",
    tableStep02_Business: "Asignar un color para esta mesa",
    tableStep03___Wizard: "Establecer un código de tabla",
    tableStep03_Business: "Establecer un código de tabla",
    waiterStep01___Wizard: "El nombre y el número de móvil serán el nombre de usuario del camarero para iniciar sesión en la aplicación de camarero",
    waiterStep01_Business: "Nombre y número de móvil serán el nombre de usuario del camarero para iniciar sesión en la aplicación de camarero",
    waiterStep02___Wizard: "Todas las mesas - y los pedidos enviados desde estas mesas - se marcarán con el color del camarero seleccionado para facilitar la entrega.",
    waiterStep02_Business: "Todas las mesas - y los pedidos enviados desde estas mesas - se marcarán con el color del camarero seleccionado para facilitar la entrega.",
    waiterStep03___Wizard: "(Opcional) El cliente podrá ver la foto y el nombre del camarero después de realizar un pedido y en la página de pago para dejar propina.",
    waiterStep03_Business: "(Opcional) El cliente podrá ver la foto y el nombre del camarero después de hacer un pedido y en la página de pago para dejar propina.",
    waiterStep04___Wizard: "Compruebe qué mesas se asignarán a este camarero.",
    waiterStep04_Business: "Comprobar qué mesas se asignarán a este camarero.",
    terminalStep01___Wizard: "Mantenga el nombre dado lo más corto y conciso posible",
    terminalStep01_Business: "Mantenga el nombre dado lo más corto y conciso posible",
    terminalStep02___Wizard: "El color dado coloreará todos los elementos ordenados en el PREP-APP para distinguir fácilmente su estación asignada",
    terminalStep02_Business: "El color dado coloreará todos los elementos ordenados en el PREP-APP para distinguir fácilmente su estación asignada",
    staffStep01___Wizard: "El nombre y el número de teléfono móvil serán el nombre de usuario del miembro del personal para iniciar sesión en la aplicación PREP-APP",
    staffStep01_Business: "El nombre y el número de móvil serán el nombre de usuario del miembro del personal para iniciar sesión en el PREP-APP",
    staffStep02___Wizard: "El color dado coloreará todos los artículos ordenados que este Miembro del Personal hará para los clientes en la PREP-APP",
    staffStep02_Business: "El color dado coloreará todos los artículos ordenados que este miembro del personal hará para los clientes en el PREP-APP",
    staffStep03___Wizard: "Asignar un rol y una estación de preparación",
    staffStep03_Business: "Asignar un rol y un puesto de preparación",
    staffStep04___Wizard: "Una foto es opcional pero útil",
    staffStep04_Business: "Una foto es opcional pero útil",
    categoryStep01___Wizard: "Cada categoría debe incluir un nombre y un color de fondo por defecto",
    categoryStep01_Business: "Cada categoría debe incluir un nombre y un color de fondo por defecto",
    categoryStep02___Wizard: "Puedes subir tu propio icono o imagen o elegir entre el conjunto proporcionado. Los iconos y las imágenes son siempre modificables y actualizables",
    categoryStep02_Business: "Puedes subir tu icono o imagen o elegir entre el conjunto proporcionado. Los iconos y las imágenes son siempre modificables y actualizables",
    menuStep01___Wizard: "Cada Menú debe incluir un nombre, una hora de inicio y una hora de finalización. Cuando configure sus artículos, puede vincularlos a diferentes Menús. Sin embargo, establecer diferentes precios para los artículos en diferentes menús sólo se habilita después de crear el negocio. Si un menú está activo durante sus horas de inicio y fin especificadas, el precio establecido para los artículos de ese menú anulará sus precios regulares.",
    menuStep01_Business: "Cada Menú debe incluir un nombre, una hora de inicio y una hora de finalización. No olvide ir a Ítems y conectar los ítems apropiados a este Menú. Si un menú está activo durante sus horas de inicio y fin especificadas, el precio establecido para los artículos de ese menú tendrá prioridad sobre sus precios regulares. Por defecto, todos los artículos se añaden automáticamente a un nuevo menú hasta que los deseleccione manualmente en la sección Artículos.",
    menuStep02___Wizard: "El color asignado es sólo para la representación visual de los menús en el gráfico. Evite que los menús se solapen.",
    menuStep02_Business: "El color asignado es sólo para la representación visual de los menús en el gráfico. Evitar la superposición de menús.",
    optionsDoThis___Wizard: "Asigne un título a cada opción Marque la casilla si es gratis, o desmarque la casilla para establecer un precio que se añadirá al precio del artículo cuando se pida. Más adelante, podrá vincular sus artículos a esta opción.",
    optionsDoThis_Business: "Escriba un título para cada opción Marque la casilla si es gratuita, o desmarque la casilla para establecer un precio que se añadirá al precio del artículo cuando se pida.{max} Recuerde vincular esta opción a los artículos pertinentes necesarios.",
    groupOptionsStep01___Wizard: "Proporcione un título para la opción de grupo.",
    groupOptionsStep01_Business: "Proporcionar un título para la opción de grupo.",
    groupOptionsStep02___Wizard: "Marque la casilla si es gratis, o desmarque la casilla para establecer un precio Por defecto, la opción superior se selecciona automáticamente en el lado del cliente. Arrastre y suelte para reorganizar el orden de todas las opciones dentro del grupo.",
    groupOptionsStep02_Business: "Marque la casilla si es gratis o desmarque la casilla para fijar un precio Por defecto, la opción superior se selecciona automáticamente en el lado del cliente. Arrastre y suelte para reorganizar el orden de todas las opciones dentro del grupo.",
    itemsStep01___Wizard: "El nombre debe ser breve y atractivo. Prep Code es el código abreviado utilizado en la APP PREP (opcional).",
    itemsStep01_Business: "Mantenga el nombre breve y atractivo. Prep Code es el código abreviado utilizado en el PREP APP (opcional).",
    itemsStep02___Wizard: "Precio: Especifique el precio de lista, y vuelva aquí después de crear el negocio para establecer el precio de venta y los precios del menú si es necesario.",
    itemsStep02_Business: "Recuerde la regla: Un precio de menú es más fuerte que el precio de lista, y el precio de venta los reemplaza a ambos.",
    itemsStep03___Wizard: "La sección Opciones se abre después de la creación del negocio.",
    itemsStep03_Business: "Seleccione qué Opciones y Opciones de Grupo son relevantes para este artículo (se permite la multiselección tanto para las Opciones Regulares como para las Opciones de Grupo)",
    itemsStep04___Wizard: "¡Esto es opcional pero importante! Adjunte iconos de alérgenos (p. ej., sésamo, frutos secos, etc.) y etiquetas (p. ej., vegano, preparación larga, especial del chef, etc.).",
    itemsStep04_Business: "Esto es opcional pero importante Adjunte iconos de alérgenos (p. ej., sésamo, frutos secos, etc.) y etiquetas (p. ej., vegano, preparación prolongada, especial para chefs, etc.).",
    itemsStep05___Wizard: "Descripción del artículo y carga de imágenes",
    itemsStep05_Business: "Descripción del artículo y carga de imágenes",
    itemsStep06___Wizard: "Vincule este artículo al menos a una categoría (obligatorio) y a una estación de preparación (opcional)",
    itemsStep06_Business: "Vincular este artículo a al menos una categoría (obligatorio) y a una estación de preparación (opcional)",
    itemsStep07___Wizard: "La sección Preferencias Especiales se abre tras la creación del negocio.",
    itemsStep07_Business: "Preferencias"
  }






}
}
