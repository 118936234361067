import { getItem } from 'src/store/_helpers'

export function all (state) {
  return state.all
}

// Example: const user = computed(() => store.getters['users/one']("IGnaElkZae"))
export const one = (state) => (id) => {
  return getItem(state.all, id)
}

// Example: const user = computed(() => store.getters['users/findByUsername']("odedzim@mailinator.com"))
export const findByUsername = (state) => (name) => {
  return getItem(state.all, name, 'username')
}
export const findByPhone = (state) => (name) => {
  return getItem(state.all, name, 'phone')
}
