import enUS from './en-US'
import de from './de'
import es from './es'
import zh from './zh-CN'
import he from './he'

//new lang add here (above and below this line) #1/3
export default {
  'en-US': enUS,
  'de': de,
  'es': es,
  'zh': zh,
  'he': he
}

