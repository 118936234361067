import { deleteItem, setItem, parseObjectToStore, getParseObjectAttrs } from 'src/store/_helpers'

export function LOADING (state, status) {
  state.loading = status
}

export function SAVING (state, status) {
  state.saving = status
}

export function DELETING (state, status) {
  state.deleting = status
}

export function SAVE (state, object) {
  object = parseObjectToStore(object)
  for (let i in object) {
    if (object[i] && object[i].__type && object[i].__type === 'Date') {
      object[i] = object[i].iso
    }
  }
  state.all = setItem(state.all, object)
}

export function SAVE_MANY (state, collection) {
  for (const i in collection) {
    state.all = setItem(state.all, (collection[i].attributes && Object.keys(collection[i].attributes).length) ? parseObjectToStore(collection[i], state.includes) : collection[i])
  }
}

export function SAVE_MANY_FOR_PREVIEW(state, collection) {
  for ( const i in collection) { 
    state.all = setItem(state.all, getParseObjectAttrs(collection[i], state.emptyInstance))
  }
}

export function SAVE_COUNT (state, count) {
  state.count = count
}

export function SAVE_SKIP (state, skip) {
  state.skip = skip
}

export function DELETE (state, object) {
  state.all = deleteItem(state.all, object)
}

export function DELETE_ALL (state) {
  state.count = 0
  state.skip = 0
  state.all = []
}
