import { getItem } from 'src/store/_helpers'

export function all (state) {
  return state.all
}


export const one = (state) => (id) => {
  return getItem(state.all, id)
}

export const overview = (state) => (id = false) => {
  if (!id) {
    id = state.current
  }
  if (!state.overviews[id]) {
    return {}
  }
  let result = {
    orders: state.overviews[id].orders.length,
    sales: 0
  }
  for (const i in state.overviews[id].payments) {
    result.sales += parseFloat(payment.price)
  }
  return result
}

export const isBusinessSubscribed = (state) => {
  if(!state.subscription) return false
  const today = new Date()
  return state.subscription.active_till > today
}

export const isSubscriptionActive = (state) => {
  if(!state.subscription) return false
  return state.subscription.active
}

export const businessPlan = (state, getters) => {
  if(!state.current) return false

  if(getters.isBusinessSubscribed) {
      return 'monthlySubscribed'
  } else if(state.liveCredits > 0) {
    return 'credits'
  } else {
    return 'free'
  }
}

export const showStripePlugin = (state) => state.current.country !== 'Israel'

export const showPaymePlugin = (state) => ['United States','Israel'].includes(state.current.country)
