export function SAVING (state, status) {
  state.saving = status
}

export function LOADING (state, status) {
  state.loading = status
}

export function SET_STEP(state, step) {
  state.step = step
}
export function SET_FINISHED(state, status) {
  state.finished = status
}
export function SET_ID(state, status) {
  state.objectId = status
}
export function SET_SHOW_BACK(state, status) {
  state.showBack = status
}