import { LocalStorage } from 'quasar'
import { indexFromProperty, makeLocalId } from 'src/store/_helpers'
import defaults from 'src/store/wizard/data/defaults'

export function RESET (state) {
  for (const index in defaults) {
    state[index] = structuredClone(defaults[index])
    LocalStorage.remove('dd_admin.wizard.' + index)
  }

  state.currentStep = 0
  state.nextStepReady = true
  LocalStorage.remove('dd_admin.wizard.steps')
  LocalStorage.remove('dd_admin.wizard.step')
}

export function NEXT_STEP_READY (state, status) {
  state.nextStepReady = status
}

export function SET_STEP (state, step) {
  state.nextStepReady = (step !== 'summary')
  state.currentStep = step
  state.hasUnsavedChanges = false
  LocalStorage.set('dd_admin.wizard.step', state.currentStep)
  LocalStorage.set('dd_admin.wizard.steps', state.steps)
}

export function SET_CONFIRMED (state) {
  state.confirmed = true
}

export function SET_BUSINESS_TYPE (state, businessType = '') {
  state.businessType = businessType
  LocalStorage.set('dd_admin.wizard.businessType', state.businessType)

  state.steps = businessType.steps
  LocalStorage.set('dd_admin.wizard.steps', state.steps)
}

export function SET_STEPS (state, steps) {
  // state.businessType = ''
  // LocalStorage.set('dd_admin.wizard.businessType', state.businessType)

  state.steps = steps
  LocalStorage.set('dd_admin.wizard.steps', state.steps)
}

export function NEXT_STEP (state) {
  if (state.nextStepReady || state.terms) {
    state.steps[state.currentStep].done = true
    state.nextStepReady = false
    state.currentStep++
    LocalStorage.set('dd_admin.wizard.step', state.currentStep)
    LocalStorage.set('dd_admin.wizard.steps', state.steps)
  }
}

export function PREV_STEP (state) {
  state.nextStepReady = true
  state.currentStep--
  LocalStorage.set('dd_admin.wizard.step', state.currentStep)
  LocalStorage.set('dd_admin.wizard.steps', state.steps)
}

export function UPDATE_BUSINESS (state, business) {
  state.business = business
  LocalStorage.set('dd_admin.wizard.business', state.business)
}

export function ADD_SUPERVISOR (state, supervisor) {
  supervisor.id = makeLocalId()
  supervisor.local = true
  state.supervisors.push(Object.assign({}, supervisor))
  LocalStorage.set('dd_admin.wizard.supervisors', state.supervisors)
}

export function UPDATE_SUPERVISOR (state, { id, supervisor, object }) {
  if (!supervisor || !Object.keys(supervisor).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    supervisor = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.supervisors)
  if (index !== false) {
    state.supervisors[index] = Object.assign({}, state.supervisors[index], supervisor)
    LocalStorage.set('dd_admin.wizard.supervisors', state.supervisors)
  }
}

export function DELETE_SUPERVISOR (state, id) {
  const index = indexFromProperty(id, state.supervisors)
  if (index !== false) {
    state.supervisors.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.supervisors', state.supervisors)
  }
}

export function ADD_TABLE (state, table) {
  table.id = makeLocalId()
  table.local = true
  state.tables.push(Object.assign({}, table))
  LocalStorage.set('dd_admin.wizard.tables', state.tables)
}

export function UPDATE_TABLE (state, { id, table, object }) {
  if (!table || !Object.keys(table).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    table = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.tables)
  if (index !== false) {
    state.tables[index] = Object.assign({}, state.tables[index], table)
    LocalStorage.set('dd_admin.wizard.tables', state.tables)
  }
}

export function DELETE_TABLE (state, id) {
  const index = indexFromProperty(id, state.tables)
  if (index !== false) {
    state.tables.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.tables', state.tables)
  }
}

export function ADD_WAITER (state, waiter) {
  waiter.id = makeLocalId()
  waiter.local = true
  state.waiters.push(Object.assign({}, waiter))
  LocalStorage.set('dd_admin.wizard.waiters', state.waiters)
}

export function UPDATE_WAITER (state, { id, waiter, object }) {
  if (!waiter || !Object.keys(waiter).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    waiter = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.waiters)
  if (index !== false) {
    state.waiters[index] = Object.assign({}, state.waiters[index], waiter)
    LocalStorage.set('dd_admin.wizard.waiters', state.waiters)
  }
}

export function DELETE_WAITER (state, id) {
  const index = indexFromProperty(id, state.waiters)
  if (index !== false) {
    state.waiters.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.waiters', state.waiters)
  }
}

export function ADD_KITCHEN (state, kitchen) {
  kitchen.id = makeLocalId()
  kitchen.local = true
  state.kitchens.push(Object.assign({}, kitchen))
  LocalStorage.set('dd_admin.wizard.kitchens', state.kitchens)
}

export function UPDATE_KITCHEN (state, { id, kitchen, object }) {
  if (!kitchen || !Object.keys(kitchen).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    kitchen = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.kitchens)
  if (index !== false) {
    state.kitchens[index] = Object.assign({}, state.kitchens[index], kitchen)
    LocalStorage.set('dd_admin.wizard.kitchens', state.kitchens)
  }
}

export function DELETE_KITCHEN (state, id) {
  const index = indexFromProperty(id, state.kitchens)
  if (index !== false) {
    state.kitchens.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.kitchens', state.kitchens)
  }
}

export function ADD_CHEF (state, chef) {
  chef.id = makeLocalId()
  chef.local = true
  state.chefs.push(Object.assign({}, chef))
  LocalStorage.set('dd_admin.wizard.chefs', state.chefs)
}

export function UPDATE_CHEF (state, { id, chef, object }) {
  if (!chef || !Object.keys(chef).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    chef = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.chefs)
  if (index !== false) {
    state.chefs[index] = Object.assign({}, state.chefs[index], chef)
    LocalStorage.set('dd_admin.wizard.chefs', state.chefs)
  }
}

export function DELETE_CHEF (state, id) {
  const index = indexFromProperty(id, state.chefs)
  if (index !== false) {
    state.chefs.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.chefs', state.chefs)
  }
}

export function ADD_CATEGORY (state, category) {
  category.id = makeLocalId()
  category.local = true
  state.categories.push(Object.assign({}, category))
  LocalStorage.set('dd_admin.wizard.categories', state.categories)
}

export function UPDATE_CATEGORY (state, { id, category, object }) {
  if (!category || !Object.keys(category).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    category = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.categories)
  if (index !== false) {
    state.categories[index] = Object.assign({}, state.categories[index], category)
    LocalStorage.set('dd_admin.wizard.categories', state.categories)
  }
}

export function DELETE_CATEGORY (state, id) {
  const index = indexFromProperty(id, state.categories)
  if (index !== false) {
    state.categories.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.categories', state.categories)
  }
}

export function ADD_MENU (state, menu) {
  menu.id = makeLocalId()
  menu.local = true
  state.menus.push(Object.assign({}, menu))
  LocalStorage.set('dd_admin.wizard.menus', state.menus)
}

export function UPDATE_MENU (state, { id, menu, object }) {
  if (!menu || !Object.keys(menu).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    menu = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.menus)
  if (index !== false) {
    state.menus[index] = Object.assign({}, state.menus[index], menu)
    LocalStorage.set('dd_admin.wizard.menus', state.menus)
  }
}

export function DELETE_MENU (state, id) {
  const index = indexFromProperty(id, state.menus)
  if (index !== false) {
    state.menus.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.menus', state.menus)
  }
}

export function ADD_ITEM (state, item) {
  item.id = makeLocalId()
  item.local = true
  state.items.push(Object.assign({}, item))
  LocalStorage.set('dd_admin.wizard.items', state.items)
}

export function UPDATE_ITEM (state, { id, item, object }) {
  if (!item || !Object.keys(item).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    item = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.items)
  if (index !== false) {
    state.items[index] = Object.assign({}, state.items[index], item)
    LocalStorage.set('dd_admin.wizard.items', state.items)
  }
}

export function DELETE_ITEM (state, id) {
  const index = indexFromProperty(id, state.items)
  if (index !== false) {
    state.items.splice(index, 1)
    LocalStorage.set('dd_admin.wizard.items', state.items)
  }
}

export function ADD_EXTRA (state, extra) {
  extra.id = makeLocalId()
  extra.local = true
  state.extras.push(Object.assign({}, extra))
  LocalStorage.set('dd_admin.wizard.extras', state.extras)
}

export function UPDATE_EXTRA (state, { id, extra, object }) {
  if (!extra || !Object.keys(extra).length) {
    if (!object || !Object.keys(object).length) {
      return
    }
    extra = Object.assign({}, object)
  }
  const index = indexFromProperty(id, state.extras)
  if (index !== false) {
    state.extras[index] = Object.assign({}, state.extras[index], extra)
    LocalStorage.set('dd_admin.wizard.extras', state.extras)
  }
}

export function DELETE_EXTRA (state, id) {
  const index = indexFromProperty(id, state.extras)
  if (index !== false) {
    state.extras.splice(index, 1)
    for (const i in state.items) {
      if (Object.keys(state.items[i].extras).length) {
        for (const j in state.items[i].extras) {
          if (state.items[i].extras[j].id === id) {
            delete state.items[i].extras[j]
          }
        }
      }
    }
  }
  LocalStorage.set('dd_admin.wizard.items', state.items)
  LocalStorage.set('dd_admin.wizard.extras', state.extras)
}

export function ACCEPT_TERMS (state, status) {
  state.terms = status
  for (const i in state.steps) {
    if (state.steps[i].name === 'terms') {
      state.steps[i].done = true
    }
  }
  LocalStorage.set('dd_admin.wizard.terms', state.terms)
}

export function SAVING (state, status) {
  state.saving = status
}
export function SET_FROM_WIZARD (state, payload) {
  state.fromWizard = payload
}

export function SET_FINISH (state, createdBusinessId) {
  state.createdBusinessId = createdBusinessId
  LocalStorage.set('dd_admin.wizard.createdBusinessId', createdBusinessId)
}

export function CLEAR_FINISH (state) {
  state.createdBusinessId = false
  LocalStorage.remove('dd_admin.wizard.createdBusinessId')
}

export function SET_STEP_SKIPPED (state, objectSlug) {
  for (const j in state.steps) {
    if (state.steps[j].name === objectSlug) {
      state.steps[j].skipped = true
      break
    }
  }
  LocalStorage.set('dd_admin.wizard.steps', state.steps)
}

export function SET_HAS_UNSAVED_CHANGES (state, status) {
  state.hasUnsavedChanges = status
}
