import { Parse } from 'src/boot/parse'
import { deleteItem } from 'src/store/_helpers'
import { LocalStorage } from 'quasar'
import { parseObjectToStore, setItem, parseObjectsToStore, storeObjectToParse } from '../_helpers'


import makeState from 'src/store/_parseObjectTemplate/state'
import * as templateMutations from 'src/store/_parseObjectTemplate/mutations'
import * as templateActions from 'src/store/_parseObjectTemplate/actions'

const getters = {
   // TODO: implement!
  formToParse: (state) => (extras) => {
    const result = []
    for (const i in extras) {
      if (extras[i].groupOptions && extras[i].groupOptions.length) {
        for (const j in extras[i].groupOptions) {
          result.push({
            group: extras[i].title,
            ...extras[i].groupOptions[j]
          })
        }
      } else {
        result.push(extras[i])
      }
      delete result[i].groupOptions
      delete result[i].free
      delete result[i].local
    }

    for (const i in result) {
      result[i].price = parseFloat(result[i].price)
      if (isNaN(result[i].price)) {
        result[i].price = 0
      }
    }

    return result
  },
   // TODO: implement!
  one: (state) => (id) => {
    for (const i in state.all) {
      if (state.all[i].id === id) {
        return state.all[i]
      }
    }
    return {}
  },
   // TODO: implement!
  byTitle: (state) => (title) => {
    for (const i in state.all) {
      if (state.all[i].title === title) {
        return state.all[i]
      }
    }
    return false
  },
  all: (state) => state.all
}

const actions = {
  ...templateActions,
  create ({ commit, dispatch, state }, { object, objectToParse }) {
    commit('SAVING', true)
    return new Promise(async (resolve, reject) => {
      const result = await storeObjectToParse(dispatch, { local: true, ...objectToParse }, state.objectName)
      await result.fetch()
      commit('SAVE_MANY', [result])
      commit('SAVING', false)
      resolve(result)
    })
  },
  saveOne ({ commit, dispatch, state }, { object, objectToParse }) {
    commit('SAVING', true)
    return new Promise(async (resolve, reject) => {
      const result = await storeObjectToParse(dispatch, objectToParse, state.objectName)
      commit('SAVE', object)
      commit('SAVING', false)
      resolve(result)
    })
  },
  destroyGroup: function ({ commit, dispatch }, groupExtra) {

    commit('DELETING', true)
    const promises = []

    const extras = groupExtra.extras_group
    for (const index in extras) {
      promises.push(dispatch('extras/destroy', extras[index], { root: true }))
    }

    return Promise.all(promises).then(async () => {
      await dispatch('destroy', groupExtra)
    }).finally(() => {
      commit('DELETING', false)
    })
  },
  querySearch: function({ state }, { business, property, value, method = 'startsWith' }) {
    const result = []

    state.all.forEach(extra => {
      if(extra.title.toUpperCase().includes(value.toUpperCase())) {
        result.push({ title: extra.title })
      }
    })
    return result
  }
}

const mutations = {
  ...templateMutations,
  SAVE: function  (state, object) {
    state.all = setItem(state.all, object)
  },
  SAVE_MANY: function (state, collection) {
    const firstSave = state.all.length === 0
    if (collection && collection.length) {
      const result = state.all
      for (const i in collection) {
        const extras_group = parseObjectsToStore(collection[i].get('extras_group'))
        extras_group.forEach(extra => {
          delete extra.business
          delete extra.createdAt
          delete extra.updatedAt
        })
        // console.log('extras group',collection[i])

        const item = {
          ...parseObjectToStore(collection[i]),
          extras_group: extras_group
        }
        if(firstSave) {
          result.push(item)
        } else {
          setItem(result, item)
        }
      }

      state.all = result
    }
    
  },
   // TODO: implement!
  DELETE_GROUP: function (state, extra) {
    for (const i in extra.groupOptions) {
      state.all = deleteItem(state.all, extra.groupOptions[i])
    }

    // Delete group from all
    state.all = deleteItem(state.all, extra)

    LocalStorage.set('dd_admin.' + state.objectName.toLowerCase(), state.all)
  },
  DELETE_ALL: function (state) {
    state.count = 0
    state.skip = 0
    state.all = []
  }
}

export default {
  namespaced: true,
  state: makeState('RestaurantItemExtraGroup', {
      id: false,
      title: '',
      extras_group: [],
      free: true
    },
    {
      limit: 10000,
      includes: ['extras_group']
    }
  ),
  getters,
  mutations,
  actions
}
