export default [
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/beer.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/breakfest.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/demo1.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/demo2.jpg',

  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/burger.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/coffee.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/dessert.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/fishsticks.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/orangejuice.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/pizza.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/rice.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/salad.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/sandwich.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/DD_demo_item_images/stirfry.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_image_01.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/demo_image_02.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/demo_image_03.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/demo_image_04.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/demo_image_05.jpg',
  // 'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/demo_image_06.jpg'

  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_BUR1.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_BUR2.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_BCK.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_BVEG.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_PIZ2.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_SAL1.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_SAL2.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_FAL.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_RIC.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_SUP.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_SUS.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_CCK.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_DON.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_COL.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_BER.jpg',
  'https://s3.amazonaws.com/digidine.images/DD_demo_item_images/sample_item_COF.jpg',


]
