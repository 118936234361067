export default function () {
  return {
    objectName: 'RestaurantOrderSummary',
    browsing: false,
    loading: {},
    saving: {},
    all: [],
    sortBy: 'date', // date | num 
    // showType: 'all', // all | eat-in | delivery | take-away
    liveOnly: false,
    unpaidOnly: false,
    unratedOnly: false,
    includes: ['item_orders', 'client', 'general_items', 'table'],
    unpaidCount: 0,
    date: '', // type Date
    skip: 0,
    count: 0,
    currentPage: 1,
    ordersPerPage: 12,
    subscription: null,
    updatesCount: 0,
    newOrdersCount: 0
  }
}
