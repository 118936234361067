import { Parse } from 'src/boot/parse'
import { deleteItem } from 'src/store/_helpers'
import { LocalStorage } from 'quasar'
import { parseObjectToStore } from './../_helpers'


import makeState from 'src/store/_parseObjectTemplate/state'
import * as templateMutations from 'src/store/_parseObjectTemplate/mutations'
import * as templateActions from 'src/store/_parseObjectTemplate/actions'

const getters = {
  formToParse: (state) => (extras) => {
    const result = []
    for (const i in extras) {
      if (extras[i].groupOptions && extras[i].groupOptions.length) {
        for (const j in extras[i].groupOptions) {
          result.push({
            group: extras[i].title,
            ...extras[i].groupOptions[j]
          })
        }
      } else {
        result.push(extras[i])
      }
      delete result[i].groupOptions
      delete result[i].free
      delete result[i].local
    }

    for (const i in result) {
      result[i].price = parseFloat(result[i].price)
      if (isNaN(result[i].price)) {
        result[i].price = 0
      }
    }

    return result
  },
  one: (state) => (id) => {
    for (const i in state.all) {
      if (state.all[i].id === id) {
        return state.all[i]
      }
    }
    return {}
  },
  byTitle: (state) => (title) => {
    for (const i in state.all) {
      if (state.all[i].title === title) {
        return state.all[i]
      }
    }
    return false
  },
  groupOptions: (state) => {
    return state.all.filter(extra => !!extra.group)
  },
  regularOptions: (state) => {
    return state.all.filter(extra => !extra.group)
  },
}

const actions = {
  ...templateActions,
  destroyGroup: function ({ commit, getters, state }, groupExtra) {
    groupExtra = getters.one(groupExtra.id)
    commit('DELETING', true)
    return new Promise((resolve, reject) => {
      new Parse.Query(state.objectName)
        .equalTo('group', groupExtra.title)
        .find({sessionToken: LocalStorage.getItem('auth.token')})
        .then(async (extras) => {
          Parse.Object.destroyAll(extras, 
            { sessionToken: LocalStorage.getItem('auth.token') })
            .then(() => {
              commit('DELETE_GROUP', groupExtra)
              commit('DELETING', false)
              resolve()
            })
        }).catch((e) => {
          commit('DELETING', false)
          reject(e)
        })
    })
  },
  querySearch: function({ state }, { business, property, value, method = 'startsWith' }) {
    const result = []

    state.all.forEach(extra => {
      if(extra.title.toUpperCase().includes(value.toUpperCase())) {
        result.push({ title: extra.title })
      }
    })
    return result
  }
}

const mutations = {
  ...templateMutations,
  // SAVE_MANY: function (state, collection) {
  //   console.log('SAVE_MANY Extras', collection)
  //   if (collection && collection.length) {
  //     const result = []
  //     const newExtrasObjectIds = {}
  //     for (const i in collection) {
  //       // if(collection[i].toJSON) {
  //       collection[i] = parseObjectToStore(collection[i])
  //       // }
  //       newExtrasObjectIds[collection[i].objectId] = true
  //     }
  //     for (const i in state.all) {
  //        // CHECK if this option already saved. Save if not
  //         const saved = collection.some(extra => extra.id === state.all[i].id)
  //         if(!newExtrasObjectIds[state.all[i].id] && !saved) {
  //           collection.push(state.all[i])
  //         }
  //     }

  //     for (const i in collection) {
  //       if (!collection[i].group || !collection[i].group.length) {
  //         // collection[i].groupOptions = []
  //         if (Object.prototype.hasOwnProperty.call(collection[i], 'objectId')) {
  //           collection[i].id = collection[i].objectId
  //           delete collection[i].objectId
  //         }
  //         result.push(collection[i])
  //       } 
  //     }
  //     console.log('SAVE_MANY Extras result', result)
  //     state.all = result

  //     // const result = []
  //     // const groupIndexes = {}
  //     // const newExtrasObjectIds = {}

  //     // for (const i in collection) {
  //     //   // if(collection[i].toJSON) {
  //     //   collection[i] = parseObjectToStore(collection[i])
  //     //   // }
  //     //   newExtrasObjectIds[collection[i].objectId] = true
  //     // }

  //     // for (const i in state.all) {
  //     //   if (state.all[i].groupOptions && state.all[i].groupOptions.length) {
  //     //     for (const gi in state.all[i].groupOptions) {
  //     //       if (!newExtrasObjectIds[state.all[i].groupOptions[gi].objectId]) {
  //     //         let item = state.all[i].groupOptions[gi]

  //     //         // Fix strange bug, when item is id
  //     //         if(typeof item === 'string') {
  //     //           state.all[i].groupOptions[gi] = { id: item, group: state.all[i].title }
  //     //         } else {
  //     //           state.all[i].groupOptions[gi].group = state.all[i].title
  //     //         }
  //     //         collection.push(state.all[i].groupOptions[gi])
  //     //       }
  //     //     }
  //     //   } else {
  //     //     // CHECK if this option already saved. Save if not
  //     //     const saved = collection.some(extra => extra.id === state.all[i].id)
  //     //     if(!newExtrasObjectIds[state.all[i].id] && !saved) {
  //     //       collection.push(state.all[i])
  //     //     }
            
  //     //   }
  //     // }

  //     // for (const i in collection) {
  //     //   if (!collection[i].group || !collection[i].group.length) {
  //     //     collection[i].groupOptions = []
  //     //     if (Object.prototype.hasOwnProperty.call(collection[i], 'objectId')) {
  //     //       collection[i].id = collection[i].objectId
  //     //       delete collection[i].objectId
  //     //     }
  //     //     result.push(collection[i])
  //     //   } else {
  //     //     if (Object.prototype.hasOwnProperty.call(groupIndexes, collection[i].group)) {
  //     //       result[groupIndexes[collection[i].group]].groupOptions.push(collection[i])
  //     //       delete result[groupIndexes[collection[i].group]].groupOptions[result[groupIndexes[collection[i].group]].groupOptions.length-1].group
  //     //     } else {
  //     //       groupIndexes[collection[i].group] = result.length
  //     //       collection[i] = {
  //     //         id: encodeURIComponent(collection[i].group),
  //     //         title: collection[i].group,
  //     //         price: '',
  //     //         groupOptions: [collection[i]]
  //     //       }
  //     //       result.push(collection[i])
  //     //     }
  //     //   }
  //     // }
  //     // state.all = result
  //   }
  // },
  DELETE_GROUP: function (state, extra) {
    for (const i in extra.groupOptions) {
      state.all = deleteItem(state.all, extra.groupOptions[i])
    }

    // Delete group from all
    state.all = deleteItem(state.all, extra)

    LocalStorage.set('dd_admin.' + state.objectName.toLowerCase(), state.all)
  },
  DELETE_ALL: function (state) {
    state.count = 0
    state.skip = 0
    state.all = []
  }
}

export default {
  namespaced: true,
  state: makeState('RestaurantItemExtra', {
    id: false,
    free: true,
    title: '',
    price: '',
    // groupOptions: [],
  },{
    limit: 10000
  }),
  getters,
  mutations,
  actions
}
