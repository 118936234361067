import { LocalStorage } from 'quasar'
import defaults from 'src/store/wizard/data/defaults'

export default function () {
  const wizardObjects = [
    'businessType',
    'businessTypes',
    'steps',
    'business',
    'supervisors',
    'tables',
    'waiters',
    'kitchens',
    'chefs',
    'categories',
    'menus',
    'items',
    'extras',
    'terms',
    'createdBusinessId'
  ]

  const currentStep = LocalStorage.has('dd_admin.wizard.step') ? LocalStorage.getItem('dd_admin.wizard.step') : 0

  const state = {
    defaults,
    currentStep,
    hasUnsavedChanges: false,
    nextStepReady: (currentStep === 0),
    saving: false,
    confirmed: false,
    fromWizard: false
  }

  for (const i in wizardObjects) {
    state[wizardObjects[i]] = LocalStorage.has('dd_admin.wizard.' + wizardObjects[i]) ? LocalStorage.getItem('dd_admin.wizard.' + wizardObjects[i]) : structuredClone(defaults[wizardObjects[i]])
  }

  return state
}
