import { 
  parseObjectsToStore,
  getBusinessPointer,
  getBusinessObject,
  destroyStoreObjectOnParse,
  storeObjectToParse,
  storeObjectsToParse
} from 'src/store/_helpers'
import { Parse } from 'src/boot/parse'
import { LocalStorage } from 'quasar'

// TODO: fix dont get all group options on scroll
export function get ({ commit, state, rootState }, business = false) {
  commit('LOADING', true)
  if (!business) {
    business = rootState.businesses.wizardNew
  }
  return new Promise(async (resolve, reject) => {
    const response = await getBusinessObject(business, state.objectName, state.skip, state.limit, state.includes)

    if(response) {
      // commit('SAVE_MANY', response.results)
      // commit('SAVE_COUNT', response.total)
      // commit('SAVE_SKIP', state.skip + response.results.length)
      // resolve(response.results)

      commit('SAVE_MANY', response)
      commit('SAVE_COUNT', response.length)
      commit('SAVE_SKIP', state.skip + response.length)
 
      resolve(response)
    }
    commit('LOADING', false)
  })
}
export function getAll ({ commit, state, rootState }, business = false) {
  commit('LOADING', true)
  if (!business) {
    business = rootState.businesses.wizardNew
  }
  return new Promise(async (resolve, reject) => {
    const response = await getBusinessObject(business, state.objectName, 0, 10000, state.includes)

    if(response) {

      commit('SAVE_MANY', response)
      commit('SAVE_COUNT', response.length)
      commit('SAVE_SKIP', state.skip + response.length)
 
      resolve(response)
    }
    commit('LOADING', false)
  })
}

export function saveOne ({ commit, dispatch, state }, object) {
  commit('SAVING', true)
  return new Promise(async (resolve, reject) => {
    const result = await storeObjectToParse(dispatch, object, state.objectName)
    commit('SAVE', result)
    commit('SAVING', false)
    resolve(result)
  })
}

export function save ({ commit, dispatch, state }, collection) {
  commit('SAVING', true)
  return new Promise(async (resolve, reject) => {
    const result = await storeObjectsToParse(dispatch, state.objectName, collection)
    commit('SAVE_MANY', result)
    commit('SAVING', false)
    resolve(result)
  })
}

export function create ({ commit, dispatch, state }, instance) {
  commit('SAVING', true)
  return new Promise(async (resolve, reject) => {
    const result = await storeObjectToParse(dispatch, { local: true, ...instance }, state.objectName)
    commit('SAVE_MANY', [result])
    commit('SAVING', false)
    resolve(result)
  })
}

export function destroy ({ commit, dispatch, state }, instance) {
  commit('DELETING', true)
  const id = instance.id
  return new Promise(async (resolve, reject) => {
    destroyStoreObjectOnParse(instance, state.objectName).then(() => {
      commit('DELETE', { id })
      commit('DELETING', false)
      resolve(true)
    }, e => {
      console.log('#### ' + state.objectName + ' destroy error', e)
      resolve(e)
    })
  })
}


export async function querySearch({ commit, state }, { business, property, value, method = 'starts with' }) {
  let result
  const query = new Parse.Query(state.objectName)
    .equalTo('business', getBusinessPointer(business))

  if(method === 'starts with') {
    const upValue =  `${value.charAt(0).toUpperCase()}${value.slice(1)}`
    const lowValue = `${value.charAt(0).toLowerCase()}${value.slice(1)}`

    query.startsWith(property, lowValue)

    const upQuery = new Parse.Query(state.objectName)
      .equalTo('business', getBusinessPointer(business))
      .startsWith(property, upValue)

    result = await Parse.Query.or(query, upQuery).find({sessionToken: LocalStorage.getItem('auth.token')})
  } else if (method === 'full text') {
    query.fullText(property, value)

    result = await query.find({sessionToken: LocalStorage.getItem('auth.token')})
  }
  const parsed = parseObjectsToStore(result)
  commit('SAVE_MANY', parsed)

  return parsed
}
