import { ref } from 'vue'

const isShown = ref(false)
const initialWaiter = ref('')

function useTablePicker() {
  return {
    isShown,
    initialWaiter
  }
}

export default useTablePicker