import { Parse } from 'src/boot/parse'
import {   
  parseObjectsToStore,
  getBusinessPointer,
  getBusinessObject,
  destroyStoreObjectOnParse,
  storeObjectToParse,
  storeObjectsToParse 
} from 'src/store/_helpers'
import { LocalStorage, date } from 'quasar'

export async function browse ({ commit, state, dispatch }, businessId) {
  commit('BROWSING', true);
  commit('SET_UPDATES', 0)
  commit('SET_NEW_ORDERS_COUNT', 0)
  if(state.subscription && state.subscription.subscribed) {
    await state.subscription.unsubscribe()
    commit('SET_SUBSCRIPTION', null)
  }

  const query = new Parse.Query('RestaurantOrderSummary')
    .equalTo('business', getBusinessPointer(businessId))
    .include(state.includes)
    .descending("createdAt")
    .withCount()
    .limit(state.ordersPerPage)
    .skip(state.skip)

    // Don't work. Too long query
  if(state.unpaidOnly) {
    query.equalTo('paid', false)
  }
  
  if(state.unratedOnly) {
    console.log('browes unrated')
    query.equalTo('rated', false)
  }
  if(state.date) {
    const today = new Date(state.date)
    query.greaterThanOrEqualTo('createdAt', today)
    const nextDay = date.addToDate(state.date, { days: 1 })
    query.lessThan('createdAt', nextDay);
  }

  // const data = {
  //   includes: state.includes,
  //   ordersPerPage: state.ordersPerPage,
  //   skip: state.skip,
  //   unpaidOnly: state.unpaidOnly,
  //   unratedOnly: state.unratedOnly,
  //   date: state.date,
  //   businessId,
  //   ordersPerPage: state.ordersPerPage,
  //   isActive: true
  //   // clientId: 'vIQv1Q14WD',
  //   // fieldName: 'item_orders_delivered'
  // }
  // console.log(data)
  try {
    const response = await query.find({ sessionToken: LocalStorage.getItem('auth.token') });
    // Matan tests:
  // TODO: make this cloud func work in pipeline + empty item_orders_delivered for active orders
    // const response = await Parse.Cloud.run('getAllBusinessRestaurantOrdersSummary', data)
    // const response = await Parse.Cloud.run('filterEmptyArrays', { query, fieldName: 'item_orders_delivered' })
    // console.log('getAll Orders', response)
    if(!state.count) {
      commit('SAVE_COUNT', response.count)
    }
    commit('SAVE_MANY', response.results);
    commit('BROWSING', false);
    // console.log(state.all);
    dispatch('startSubscription', { query, businessId })
    return response.results;
  } catch (error) {
    console.log('#### orders get error', error);
    commit('BROWSING', false);
    throw error;
  }
}

export function saveOne ({ commit, dispatch, state }, object) {
  commit('SAVING', true)
  return new Promise(async (resolve, reject) => {
    const result = await storeObjectToParse(dispatch, object, state.objectName)
    // dont count local updates
    commit('SET_UPDATES', state.updatesCount - 1)
    commit('SAVE_MANY', [result])
    commit('SAVING', false)
    resolve(result)
  })
}

export function countUnpaidOrders({commit, dispatch, state}, businessId) {
  commit('BROWSING', true)
  return new Promise(async (resolve, reject) => {
    new Parse.Query('RestaurantOrderSummary')
      .equalTo('business', getBusinessPointer(businessId))
      .notEqualTo("paid", true)
      .count({sessionToken: LocalStorage.getItem('auth.token')})
      .then(orders => {
        commit('SET_UNPAID_COUNT', orders)
        commit('BROWSING', false)
        resolve(orders)
      })
      .catch(error => {
        console.log('#### orders get error', error)
        commit('BROWSING', false)
        reject(error)
      })
    })
}
export function countBusinessOrders({commit, dispatch, state}, businessId) {
  commit('BROWSING', true)
  return new Promise(async (resolve, reject) => {
    new Parse.Query('RestaurantOrderSummary')
      .equalTo('business', getBusinessPointer(businessId))
      .count({sessionToken: LocalStorage.getItem('auth.token')})
      .then(orders => {
        commit('SAVE_COUNT', orders)
        commit('BROWSING', false)
        resolve(orders)
      })
      .catch(error => {
        console.log('#### orders get error', error)
        commit('BROWSING', false)
        reject(error)
      })
    })
}

export async function browsePage ({ commit, dispatch, state }, { businessId, page }) {
  commit('DELETE_ALL')
  commit('SET_CURRENT_PAGE', page)
  commit('SET_SKIP', (page-1)*state.ordersPerPage)
  dispatch('browse', businessId)
}

export async function startSubscription ({ commit, dispatch, state }, { query, businessId } ) {
  const sub = await query.subscribe();
  commit('SET_SUBSCRIPTION', sub)

  sub.on('create', async(object) => {
    commit('SET_NEW_ORDERS_COUNT', state.newOrdersCount + 1)
  });

  sub.on('update', async (object) => {
    commit('SET_UPDATES', state.updatesCount + 1)
  });
}

export async function stopLiveQuery({ state }) {
  if(state.subscription && state.subscription.subscribed) {
    await state.subscription.unsubscribe()
  }
  await Parse.LiveQuery.close();
}

export async function getRaiting({ state }, orderId) {
  const Rating = Parse.Object.extend("Rating");
  const query = new Parse.Query(Rating);
  query.equalTo("restaurant_order_summary", {
    __type: 'Pointer',
    className: 'RestaurantOrderSummary',
    objectId: orderId
  });

  const results = await query.find()
  return results.length > 0 ? results[0].toJSON() : false
}

export async function payOrder({ }, payload) {
  const result = await Parse.Cloud.run('createStripePaymentSession',payload)
  // console.log('createStripePaymentSession', result)
  window.open(result.session.url)

  return result
}
