const commonSteps = {
  start: [
    {
      name: 'intro',
      required: true,
      done: true
    },
    {
      name: 'types',
      required: true,
      done: true
    },
    {
      name: 'customize',
      required: true,
      done: true
    },
    {
      name: 'info',
      required: true,
      done: false
    }
  ],
  end: [
    {
      name: 'extras',
      required: false,
      done: false
    },
    {
      name: 'items',
      required: true,
      done: false
    },
    {
      name: 'terms',
      required: true,
      done: false
    },
    {
      name: 'summary',
      done: true
    }
  ]
}

function organizeSteps(stepsArray) {
  return [
    ...commonSteps.start,
    ...stepsArray,
    ...commonSteps.end
  ]
}

export default [
  {
    slug: 'truck',
    steps: organizeSteps([
      {
        name: 'categories',
        done: false
      }
    ]),
    templates: {
      categories: [ 'coldDrinks', 'sides', 'mains' ],
      kitchens: [ 'main' ],
      menus: [ 'main' ]
    }
  },
  {
    slug: 'pub',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'fine',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'small',
    steps: organizeSteps([
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'cafe',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'fast',
    steps: organizeSteps([
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'buffet',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'popup',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'tables',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
      // {
      //   name: 'terms',
      //   done: false
      // },
      // {
      //   name: 'summary',
      //   done: false
      // }
    ])
  },
  {
    slug: 'ghost',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      }
    ])
  },
  {
    slug: 'delivery',
    steps: organizeSteps([
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  },
  {
    slug: 'kiosk',
    steps: organizeSteps([
      {
        name: 'categories',
        done: false
      }
    ])
  },
  {
    slug: 'other',
    steps: organizeSteps([
      {
        name: 'supervisors',
        done: false
      },
      {
        name: 'tables',
        done: false
      },
      {
        name: 'waiters',
        done: false
      },
      {
        name: 'kitchens',
        done: false
      },
      {
        name: 'chefs',
        done: false
      },
      {
        name: 'categories',
        done: false
      },
      {
        name: 'menus',
        done: false
      }
    ])
  }
]
