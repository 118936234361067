export default {
  path: '/business/:businessId',
  redirect: '/',
  components: {
    default: () => import('layouts/business/Main.vue'),
    toolbar: () => import('components/business/Toolbar.vue'),
    drawer: () => import('components/business/Drawer.vue')
  },
  children: [
    {
      path: '/business/:businessId',
      name: 'business.dashboard',
      meta: { middleware: ['auth'] },
      component: () => import('pages/business/Dashboard.vue'),
    },
    {
      path: '/business/:businessId/credits',
      component: () => import('pages/business/Credits.vue'),
      name: 'business.credits',
      meta: {
        breadcrumbs: ['plans', 'credits'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/payments',
      component: () => import('pages/business/Payments.vue'),
      name: 'business.payments',
      meta: {
        breadcrumbs: ['payments'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/subscription',
      component: () => import('pages/business/Subscription.vue'),
      name: 'business.subscription',
      meta: {
        breadcrumbs: ['plans', 'subscription'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/credits/purchases',
      component: () => import('pages/business/credits/Purchases.vue'),
      name: 'business.purchases',
      meta: {
        breadcrumbs: ['credits', 'purchases'],
        middleware: ['auth']
      }
    },
    // {
    //   path: '/business/:businessId/credits/subscription',
    //   component: () => import('pages/business/Subscription.vue'),
    //   name: 'business.subscription',
    //   meta: {
    //     breadcrumbs: ['credits', 'subscription'],
    //     middleware: ['auth']
    //   }
    // },
    {
      path: '/business/:businessId/info',
      component: () => import('pages/business/Info.vue'),
      name: 'business.info',
      meta: {
        breadcrumbs: ['info'],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/apps',
      component: () => import('pages/business/Apps.vue'),
      name: 'business.apps',
      meta: {
        breadcrumbs: ['apps'],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/apps/:qr',
      component: () => imפort('pages/business/Apps.vue'),
      name: 'business.apps.qr',
      meta: {
        breadcrumbs: ['qr'],
        middleware: ['auth']
      },
      props: true
    },
    {
      path: '/business/:businessId/reports',
      component: () => import('pages/business/Reports.vue'),
      name: 'business.reports',
      meta: {
        breadcrumbs: ['reports'],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/tips',
      component: () => import('pages/business/Tips.vue'),
      name: 'business.tips',
      meta: {
        breadcrumbs: ['tips'],
        middleware: ['auth']
      }
    },

    // {
    //   path: '/business/:businessId/onboarding',
    //   component: () => import('pages/business/Onboarding.vue'),
    //   name: 'business.onboarding',
    //   meta: {
    //     breadcrumbs: [
    //       'onboarding'
    //     ],
    //     middleware: ['auth']
    //   }
    // },
    {
      path: '/business/:businessId/orders',
      component: () => import('layouts/business/Orders.vue'),
      meta: {
        middleware: ['auth'],
        breadcrumbs: ['orders'],
      },
      children: [
        {
          path: '/business/:businessId/orders',
          component: () => import('pages/business/orders/Overview.vue'),
          name: 'business.orders',
          meta: { middleware: ['auth'] },
        },
      ]
    },
    {
      path: '/business/:businessId/overview',
      component: () => import('pages/business/Overview.vue'),
      name: 'business.overview',
      meta: {
        breadcrumbs: ['overview'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/planner',
      component: () => import('pages/business/Planner.vue'),
      name: 'business.planner',
      meta: {
        breadcrumbs: ['planner'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/qr',
      component: () => import('pages/business/Qr.vue'),
      name: 'business.qr',
      meta: {
        breadcrumbs: ['qr'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/plans',
      component: () => import('pages/business/Plans.vue'),
      name: 'business.plans',
      meta: {
        breadcrumbs: ['plans'],
        middleware: ['auth']
      },
    },
    {
      path: '/business/:businessId/sales',
      component: () => import('pages/business/Sales.vue'),
      name: 'business.sales',
      meta: {
        breadcrumbs: ['sales'],
        middleware: ['auth']
      },
    },

    {
      path: '/business/:businessId/overview/supervisors',
      component: () => import('pages/business/objects/Supervisors.vue'),
      name: 'business.supervisors',
      meta: {
        breadcrumbs: [
          'overview',
          'supervisors'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/supervisors/:modelId',
      component: () => import('pages/business/objects/Supervisors.vue'),
      name: 'business.supervisors.show',
      meta: {
        breadcrumbs: [
          'overview',
          'supervisors',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/tables',
      component: () => import('pages/business/objects/Tables.vue'),
      name: 'business.tables',
      meta: {
        breadcrumbs: [
          'overview',
          'tables'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/tables/:modelId',
      component: () => import('pages/business/objects/Tables.vue'),
      name: 'business.tables.show',
      meta: {
        breadcrumbs: [
          'overview',
          'tables',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/waiters',
      component: () => import('pages/business/objects/Waiters.vue'),
      name: 'business.waiters',
      meta: {
        breadcrumbs: [
          'overview',
          'waiters'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/waiters/:modelId',
      component: () => import('pages/business/objects/Waiters.vue'),
      name: 'business.waiters.show',
      meta: {
        breadcrumbs: [
          'overview',
          'waiters',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/kitchens',
      component: () => import('pages/business/objects/Kitchens.vue'),
      name: 'business.kitchens',
      meta: {
        breadcrumbs: [
          'overview',
          'kitchens'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/kitchens/:modelId',
      component: () => import('pages/business/objects/Kitchens.vue'),
      name: 'business.kitchens.show',
      meta: {
        breadcrumbs: [
          'overview',
          'kitchens',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/chefs',
      component: () => import('pages/business/objects/Chefs.vue'),
      name: 'business.chefs',
      meta: {
        breadcrumbs: [
          'overview',
          'chefs'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/chefs/:modelId',
      component: () => import('pages/business/objects/Chefs.vue'),
      name: 'business.chefs.show',
      meta: {
        breadcrumbs: [
          'overview',
          'chefs',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/categories',
      component: () => import('pages/business/objects/Categories.vue'),
      name: 'business.categories',
      meta: {
        breadcrumbs: [
          'overview',
          'categories'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/categories/:modelId',
      component: () => import('pages/business/objects/Categories.vue'),
      name: 'business.categories.show',
      meta: {
        breadcrumbs: [
          'overview',
          'categories',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/menus',
      component: () => import('pages/business/objects/Menus.vue'),
      name: 'business.menus',
      meta: {
        breadcrumbs: [
          'overview',
          'menus'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/menus/:modelId',
      component: () => import('pages/business/objects/Menus.vue'),
      name: 'business.menus.show',
      meta: {
        breadcrumbs: [
          'overview',
          'menus',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/items',
      component: () => import('pages/business/objects/Items.vue'),
      name: 'business.items',
      meta: {
        breadcrumbs: [
          'overview',
          'items'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/items/:modelId',
      component: () => import('pages/business/objects/Items.vue'),
      name: 'business.items.show',
      meta: {
        breadcrumbs: [
          'overview',
          'items',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/extras/:modelId',
      component: () => import('pages/business/objects/Extras.vue'),
      name: 'business.extras.show',
      meta: {
        breadcrumbs: [
          'overview',
          'extras',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/extras',
      component: () => import('pages/business/objects/Extras.vue'),
      name: 'business.extras',
      meta: {
        breadcrumbs: [
          'overview',
          'extras'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/groups/:modelId',
      component: () => import('pages/business/objects/ExtrasGroup.vue'),
      name: 'business.groups.show',
      meta: {
        breadcrumbs: [
          'overview',
          'groups',
          'edit'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/groups',
      component: () => import('pages/business/objects/ExtrasGroup.vue'),
      name: 'business.groups',
      meta: {
        breadcrumbs: [
          'overview',
          'groups'
        ],
        middleware: ['auth']
      }
    },
    {
      path: '/business/:businessId/overview/hours',
      component: () => import('pages/business/objects/Hours.vue'),
      name: 'business.hours',
      meta: {
        breadcrumbs: [
          'overview',
          'hours'
        ],
        middleware: ['auth']
      }
    }
  ]
}
