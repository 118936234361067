import { Quasar, LocalStorage } from 'quasar'

export default async () => {
  const langIso = LocalStorage.has('dreamdiner.locale') ? LocalStorage.getItem('dreamdiner.locale') : Quasar.lang.getLocale()

  try {
    await import(
      /* webpackInclude: /(he|en-US)\.js$/ */
      'quasar/lang/' + langIso
    ).then(lang => {
      Quasar.lang.set(lang.default)
    })
  } catch (err) {
    Quasar.lang.set(langIso)
  }
}
