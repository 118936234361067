export function SET_RESTAURANT_ORDERS (state, orders) {
  state.restaurantOrders = orders
}

export function SET_SUMMARIES (state, orders) {
  state.summaries = orders
}

export function SET_PAYMENTS (state, payments) {
  state.payments = payments
}

export function SET_RATINGS (state, ratings) {
  state.ratings = ratings
}
