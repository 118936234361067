import { store } from 'quasar/wrappers'
import { createStore } from 'vuex'

import dreamdiner from './dreamdiner'
import layout from './layout'
import auth from './auth'
import extras from './extras'
import groups from './groups'
import wizard from './wizard'
import businesses from './businesses'
import reports from './reports'
import orders from './orders'
import users from './users'
import onboarding from './onboarding'

import { emptyInstances } from './data'
import makeState from 'src/store/_parseObjectTemplate/state'
import * as getters from 'src/store/_parseObjectTemplate/getters'
import * as mutations from 'src/store/_parseObjectTemplate/mutations'
import * as actions from 'src/store/_parseObjectTemplate/actions'


/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const parseObjectNames = {
    categories: 'Category',
    chefs: 'Kitchen',
    hours: 'OpeningHours',
    items: 'RestaurantItem',
    kitchens: 'KitchenTerminal',
    menus: 'RestaurantMenu',
    supervisors: 'SubAdmin',
    tables: 'Table',
    waiters: 'Waiter'
  }

  const extraData = {
    tables: {
      typeColors: {
        '0': 'orange',
        '1': 'ppBlue',
        '2': 'violet',
        '3': 'yellow'
      },
      limit: 100000
    },
    categories: {
      limit: 100000
    },
    kitchens: {
      limit: 100000
    },
    menus: {
      limit: 100000
    },
    items: {
      includes: ['extras_group','menus']
    },
    waiters: {
      includes: ['tables']
    }
  }

  const parseObjects = {}

  for (const i in parseObjectNames) {
    parseObjects[i] = {
      namespaced: true,
      state: () => makeState(parseObjectNames[i], emptyInstances[i], (extraData[i] ? extraData[i] : {})),
      getters,
      mutations,
      actions
    }
  }

  const Store = createStore({
    modules: {
      dreamdiner,
      wizard,
      layout,
      auth,
      businesses,
      orders,
      users,
      ...parseObjects,
      extras,
      groups,
      reports,
      onboarding
    },

    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
    strict: process.env.DEBUGGING
  })

  return Store
})
