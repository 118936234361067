import { setItem, parseObjectsToStore } from 'src/store/_helpers'

export function SAVING (state, status) {
  state.saving = status
}

export function LOADING (state, { businessId, status }) {
  state.loading[businessId] = status
}

export function SAVE_ALL (state, users) {
  state.all = users
}
export function SAVE (state, user) {
  state.all.push(user)
}