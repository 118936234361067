import { LocalStorage } from 'quasar'
import { parseObjectToStore } from './../_helpers'

export function AUTH_SET_USER (state, user) {
	state.checked = true
  state.logged = true
  state.token = user.get('sessionToken')
  const image = user.get('image')
  const photo = image && image.url()
  const parsedUser = parseObjectToStore(user)
  parsedUser.image = photo
  state.user = parsedUser
  LocalStorage.set('auth.token', user.get('sessionToken'))
}

export function AUTH_FLUSH_DATA (state) {
	state.checked = true
  state.logged = false
  state.user = {}
  state.token = ''
  LocalStorage.remove('auth.token')
}

export function AUTH_LOADING (state, status) {
  state.loading = status
}

export function AUTH_VERIFICATION_CODE_SET (state, verificationCode) {
  state.verificationCode = verificationCode
}

export function SET_NEW_USER (state, newUser = {}) {
	state.newUser = newUser
}

export function AUTH_UPDATE_USER (state, user) {
  const image = user.get('image')
  const phone = user.get('phone')
  const name = user.get('name')
  const photo = image && image.url()
  state.user.image = photo
  state.user.phone = phone
  state.user.name = name
}