import { i18n } from 'src/boot/i18n'
import { date } from 'quasar'
import categoryIcon from 'src/assets/img/icons/objects/category.svg'
import settingsIcon from 'src/assets/img/icons/objects/settings.svg'
import activityHoursIcon from 'src/assets/img/icons/objects/activity.svg'
import chefIcon from 'src/assets/img/icons/objects/chef.svg'
import extraIcon from 'src/assets/img/icons/objects/extra.svg'
import groupOptionIcon from 'src/assets/img/icons/objects/groupOption.svg'
import itemIcon from 'src/assets/img/icons/objects/item.svg'
import kitchenIcon from 'src/assets/img/icons/objects/kitchen.svg'
import menuIcon from 'src/assets/img/icons/objects/menu.svg'
import optionIcon from 'src/assets/img/icons/objects/option.svg'
import supervisorIcon from 'src/assets/img/icons/objects/supervisor.svg'
import tableIcon from 'src/assets/img/icons/objects/table.svg'
import waiterIcon from 'src/assets/img/icons/objects/waiter.svg'
import logoSquare from 'src/assets/img/logo/square.svg'

export const displayDate = (timestamp) => {
  return date.formatDate(timestamp, 'DD/MM/YYYY HH:mm:ss')
}

export const extractDate = (rawDate, format = 'DD/MM/YYYY HH:mm:ss') => {
  // TODO: fix Bug - not getting months!
  // return date.extractDate(`${rawDate}`, format)
  const splittedDate = rawDate.split(' ')
  const _date = splittedDate[0].split('/')
  const _time = splittedDate[1].split(':')
  const result = date.buildDate({ year: _date[2], day: _date[0], month: _date[1], hours: _time[0], seconds: _time[1] })
  // console.log('result', result)
  return result
}

// Buggy! Try not to use!
export const formatCurrency = (price = false) => {
  return i18n.global.messages[i18n.global.locale].currency.prefix +
    ((price !== false) ? parseFloat(price).toFixed(2) : '...') +
    i18n.global.messages[i18n.global.locale].currency.suffix
}

export const formatCurrency2 = (currency, price = false) => {
  if (price === false) {
    price = '...'
  }
  return (currency?.prefix && currency?.prefix.length ? currency.prefix : '') + price + (currency?.suffix && currency?.suffix.length ? currency.suffix : '')
}

export const formatHour = (timestamp = '') => {
  if (timestamp.iso && timestamp.iso.length) {
    timestamp = timestamp.iso
  }

  if (
    (timestamp.length === 4 && timestamp.indexOf(':') === 1) ||
    (timestamp.length === 5 && timestamp.indexOf(':') === 2)
  ) {
    return timestamp
  }
  if(typeof timestamp === 'object') {
      return getUTCHoursFromDate(timestamp)
  }

  if(!timestamp) return ''
  timestamp = timestamp.toString().split('T')[1].split('.')[0].split(':')
  return timestamp[0] + ':' + timestamp[1]
}

export const hourToTimestamp = (hour) => {
  hour = hour.split(':')

  return {
    __type: 'Date',
    iso: '1970-01-01T' + hour[0] + ':' + hour[1] + ':00.000Z'
  }
}

export function getUTCHoursFromDate(date) {
  date = new Date(date)
  const hours =  date.getUTCHours()
  const minutes =  date.getUTCMinutes()
  return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes
}

export default {
  getUTCHoursFromDate,
  displayDate,
  extractDate,
  formatCurrency,
  formatCurrency2,
  formatHour,
  hourToTimestamp,
  settingsIcon,
  activityHoursIcon,
  categoryIcon,
  chefIcon,
  extraIcon,
  groupOptionIcon,
  itemIcon,
  kitchenIcon,
  menuIcon,
  optionIcon,
  supervisorIcon,
  tableIcon,
  waiterIcon,
  logoSquare
}
