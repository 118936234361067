import { makeLocalId } from 'src/store/_helpers'
import businessTypes from 'src/store/wizard/data/business_types'

let steps = []
let defaultBusinessType = {}
for (const i in businessTypes) {
  if (businessTypes[i].slug === 'other') {
    defaultBusinessType = businessTypes[i]
    steps = businessTypes[i].steps
  }
}

export default {
    businessTypes,
    steps,
    businessType: defaultBusinessType,
    createdBusinessId: false,
    business: {
      id: makeLocalId(),
      local: true,
      title: '',
      email: '',
      description: '',
      country: '',
      currency: {},
      hours: [],
      time_zone: '',
      image: '',
      adminPassword: '',
    },
    supervisors: [],
    tables: [],
    waiters: [],
    kitchens: [],
    chefs: [],
    categories: [],
    menus: [],
    items: [],
    extras: [],
    terms: false
  }
