const notFound = { error: 'not found' }
const defaultColor = 'primary'

export const nameFromColor = (state) => function (hex) {
  for (const i in state.colors) {
    if (state.colors[i].color === hex) {
      return state.colors[i].name
    }
  }
  return defaultColor
}

export const colorFromName = (state) => function (color) {
  let fallback = ''
  for (const i in state.colors) {
    if (i === color) {
      return state.colors[i].color
    }
    if (state.colors[i].name === defaultColor) {
      fallback = state.colors[i].color
    }
  }
  return fallback
}

export const currencyFromId = (state) => function (id) {
  for (const i in state.currencies) {
    if (state.currencies[i].id === id) {
      return state.currencies[i]
    }
  }
  return notFound
}

export const kitchenRole = (state) => function (id) {
  for (const i in state.kitchenRoles) {
    if (state.kitchenRoles[i].id === id) {
      return state.kitchenRoles[i]
    }
  }
  return notFound
}

export function labels (state) {
  const labels = []

  for (const i in state.warnings) {
    if (state.warnings[i].type === 1) {
      labels.push(state.warnings[i])
    }
  }

  return labels
}

export function allergens (state) {
  const allergens = []

  for (const i in state.warnings) {
    if (state.warnings[i].type === 0) {
      allergens.push(state.warnings[i])
    }
  }

  return allergens
}

export function countryNames (state) {
  const countries = []

  for (const i in state.countriesJson) {
    countries.push(state.countriesJson[i].name)
  }

  return countries
}

export const countryByCode = (state) => function (code) {
  const countries = []

  for (const i in state.countriesJson) {
    if (state.countriesJson[i].code === code) {
      return state.countriesJson[i]
    }
  }

  return notFound
}

export const getCodeByCountry = (state) => (abb) => {
  for (const i in state.countriesJson) {
    if (state.countriesJson[i].abb === abb) {
      return state.countriesJson[i].code
    }
  }

  return null
}
export const getAbbByCountry = (state) => (country) => {
  for (const i in state.countriesJson) {
    if (state.countriesJson[i].name === country) {
      return state.countriesJson[i].abb
    }
  }

  return null
}

// TODO: convert it to no-reactive func
export const getVideoGuideByKey = (state) => (key) => {
  return state.videoGuides[key]
}
