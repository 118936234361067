import { Parse } from 'src/boot/parse'
import { paginateQuery } from 'parse-paginate-query'
import { LocalStorage } from 'quasar'

export function getBusinessPointer (business) {
  if (typeof business === 'string' || business instanceof String) {
    return {
      __type: 'Pointer',
      className: 'Business',
      objectId: business
    }
  }

  if (business && (business.id || business.objectId)) {
    return {
      __type: 'Pointer',
      className: 'Business',
      objectId: business.id ? business.id : business.objectId
    }
  }

  return business
}

export const removeLocalIds = (collection) => {
  const result = []
  for (const i in collection) {
    result[i] = structuredClone(collection[i])
    if (result[i].local) {
      delete result[i].id
    }
    delete result[i].local
    if (result[i].default) {
      delete result[i].default
    }
  }
  return result
}

export const getBusinessObject = (business, objectName, skip, limit, includes = []) => {

  return new Promise(async (resolve, reject) => {
    try {
      business = getBusinessPointer(business)
      const Business = Parse.Object.extend('Business');
      const emptyBusiness = Business.createWithoutData(business.objectId);
  
      const query = new Parse.Query(objectName)
        .equalTo('business', emptyBusiness)
        .limit(limit)
  
      if (skip > 0) {
        query.skip(skip)
      }

      // Include all attrs of object pointers that you need.
      // Add to state.includes at the module store
      query.include(includes)
  
      const response = await query.find({sessionToken: LocalStorage.getItem('auth.token')})

      resolve(response)
  
      // paginateQuery(query)
      //   .then(response => {
      //     resolve(response)
      //   })
      //   .catch(error => {
      //     console.warn('[dd getBusinessObject]: ' + objectName.toLowerCase() + ' get error', error)
      //     reject(error)
      //   })
    } catch (error) {
      console.log('response error: ' + error)
    }
  })
}

// TODO: Replace everywhere with getParseObjectAttrs that ~x10 faster
// The problem this func is not work properly with images
export const parseObjectToStore = (object, includes = false) => {
  if (Object.prototype.hasOwnProperty.call(object, 'objectId')) {
    object.id = object.objectId
    delete object.objectId
  }

  // Parse children objects from includes
  const additinalFields = {}
  if(includes) {
    for(const obj of includes) {
      if(object.attributes[obj]) {

        if(object.attributes[obj].length) {
          additinalFields[obj] = object.attributes[obj].map(obj => parseObjectToStore(obj))
        } else {
          additinalFields[obj] = object.attributes[obj].attributes ? parseObjectToStore(object.attributes[obj]) : object.attributes[obj]
        }
      } else {
        additinalFields[obj] = false
      }
    }
  }

  return {
    id: object.id,
    local: false,
    ...structuredClone(object.attributes),
    ...additinalFields
  }
}

export const parseObjectsToStore = (objects) => {
  const result = []
  for (const i in objects) {
    result.push(getParseObjectAttrs(objects[i]))
  }
  return result
}

export const storeObjectsToParse = async (dispatch, objectName, passedCollection, imageCol = 'image', phoneCol = 'phone') => {
  const promises = []
  const collection = structuredClone(passedCollection)
  for (const i in collection) {
    collection[i].business = getBusinessPointer(collection[i].business)
    if (Object.prototype.hasOwnProperty.call(collection[i], 'price')) {
      collection[i].price = parseFloat(collection[i].price)
    }
    if (collection[i][imageCol] && collection[i][imageCol].length && collection[i][imageCol].indexOf('data:image') === 0) {
      promises.push(new Promise (async () => {
        collection[i][imageCol] = await dispatch('dreamdiner/saveImage', { base64: collection[i][imageCol] }, { root: true })
        collection[i][imageCol] = collection[i][imageCol]._url
      }))
    }
    if (collection[i][imageCol] && !Object.keys(collection[i][imageCol]).length) {
      delete collection[i][imageCol]
    }

    if (collection[i][phoneCol] && collection[i][phoneCol].length) {
      collection[i][phoneCol] = collection[i][phoneCol].split('-').join()
      if (collection[i][phoneCol].indexOf('0') === 0) {
        collection[i][phoneCol] = collection[i][phoneCol].slice(1)
      }
    }
  }

  return new Promise((resolve, reject) => {
    Promise.all(promises).then(async () => {
      for (const i in collection) {
        if (collection[i].local) {
          delete collection[i].id
        }
        delete collection[i].local
        collection[i] = new Parse.Object(objectName, collection[i])
      }
      resolve(await Parse.Object.saveAll(collection, { sessionToken: LocalStorage.getItem('auth.token')}))
    })
  })
}

export const storeObjectToParse = async (dispatch, object, objectName, imageCol = 'image') => {
  if (object[imageCol] && object[imageCol].length && object[imageCol].indexOf('data:image') === 0) {
    object[imageCol] = await dispatch('dreamdiner/saveImage', { base64: object[imageCol] }, { root: true })
    object[imageCol] = object[imageCol]._url
  }

  if (object[imageCol] && !Object.keys(object[imageCol]).length) {
    delete object[imageCol]
  }

  if (Object.prototype.hasOwnProperty.call(object, 'price') && object.price.length) {
    object.price = parseFloat(object.price)
  }

  object.business = getBusinessPointer(object.business)

  return new Parse.Object(objectName, object).save(null, { sessionToken: LocalStorage.getItem('auth.token')})
}

export const makeLocalId = () => {
  return (Math.random() + 1).toString(36).substring(5)
}

export const indexFromProperty = (propValue, collection, propName = 'id') => {
  for (const i in collection) {
    if (collection[i][propName] === propValue) {
      return i
    }
  }
  return false
}

export const getItem = (collection, propValue, propName = 'id') => {
  const index = indexFromProperty(propValue, collection, propName)

  if (index !== false) {
    return collection[index]
  }

  return false
}

export const setItem = (collection, item, key = 'id', sortByDate = false) => {
  if (!collection) {
    collection = []
  }

  const index = indexFromProperty(item[key], collection, key)
  if (index !== false) {
    collection[index] = item
  } else {
    collection.push(item)
  }

  if (!sortByDate) {
    return collection
  }

  return collection.sort(function (a, b) {
    return new Date(b.createdAt) - new Date(a.createdAt)
  })
}

export function deleteItem (collection, item, key = 'id') {
  const index = indexFromProperty(item[key], collection, key)
  if (index !== false) {
    if (collection.constructor === Array) {
      collection.splice(index, 1)
    } else {
      delete collection[index]
    }
  }
  return collection
}

export const sortByColumn = (array, index = false) => {
  if (!array.length) {
    return array
  }

  if (!index) {
    if (array[0].name) {
      index = 'name'
    } else if (array[0].title) {
      index = 'title'
    } else {
      return array
    }
  }

  return array.sort((a, b) => {
    if (a[index].toLowerCase() < b[index].toLowerCase()) {
      return -1
    }
    if (a[index].toLowerCase() > b[index].toLowerCase()) {
      return 1
    }
    return 0
  })
}

export function destroyStoreObjectOnParse (instance, objectName) {
  return new Promise((resolve, reject) => {
    new Parse.Object(objectName, instance).destroy({sessionToken: LocalStorage.getItem('auth.token')})
      .then(() => {
        resolve()
      }).catch((e) => {
        reject(e)
      })
  })
}

export function getPointersFromIds(arrayOfIds, className) {

  const pointers = arrayOfIds.map(id =>{
    return {
      __type: 'Pointer',
      className: className,
      objectId: id
    }})

  return pointers
}

export function getPointersFromObjects(objects, className) {
  const ids = Object.values(objects).map(item => item.id)
  return getPointersFromIds(ids, className)
}

// Just an idea for now. Gettings full data from array of pointers.
async function getObjectsFromPointers(arr, className = arr[0].className) {
  const result = []
  const promises = []
  console.log('class', className)
  const instance = Parse.Object.extend(className)
  const query = new Parse.Query(instance)

  for(let pointer of arr) {
    const prom = query.get(pointer.id, 
      {sessionToken: LocalStorage.getItem('auth.token')}).then(obj => {
      result.push(obj)
    })
    promises.push(prom)
  }

  await Promise.all(promises)
  console.log('result', result)
  return result
}

export function getParseObjectAttrs(ParseObject, attrs = null) {
  const newObject = {}

  // get all attributes if attrs are not provided
  if(!attrs) {
    attrs = ParseObject.attributes
  }

  for(const attr in attrs) {
    if(attr === 'image' && ParseObject.className === 'Business') {
      const file = ParseObject.get(attr)
      newObject[attr] = file.url()
      continue
    }
    newObject[attr] = ParseObject.get(attr)
  }

  newObject.id = ParseObject.id ? ParseObject.id : ParseObject.objectId
  newObject.objectId = newObject.id
  newObject.local = false

  return newObject
}


export function measureProcessTime( process, processName = null) {
  if(!processName) {
    processName = process.name || 'some action'
  }
  
  const t0 = performance.now()

  process()

  const t1 = performance.now()
  console.log(`Call to ${processName} took ${t1 - t0} milliseconds.`);

  return t1 - t0
}
const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';

export function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export default {
  getBusinessPointer,
  makeLocalId,
  indexFromProperty,
  parseObjectToStore,
  sortByColumn,
  setItem,
  getItem,
  deleteItem,
  destroyStoreObjectOnParse,
  getParseObjectAttrs,
  measureProcessTime,
  generateString
}
