export default {
  path: '/admin',
  name: 'admin',
  redirect: { name: 'admin.overview' },
  // meta: {
  //   middleware: ['auth']
  // },
  components: {
    default: () => import('layouts/Main.vue'),
    // toolbar: () => import('components/business/Toolbar.vue'),
    // drawer: () => import('components/business/Drawer.vue')
  },
  children: [
    {
      path: '/admin/intro',
      component: () => import('pages/admin/Intro.vue'),
      name: 'admin.intro',
      meta: {
        middleware: ['auth']
      },
    },
  ]
}
