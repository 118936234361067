import { LocalStorage } from 'quasar'

export default {
  token: '',
  user: {},
  checked: false,
  logged: false,
  loading: false,
  verificationCode: false,
  newUser: {}
}
