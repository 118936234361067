export default {
  general: {
    yes: "是的",
    no: "是",
    yesNo: "是或否",
    add: "添加",
    addCredits: "添加学分",
    color: "指定颜色",
    colorBg: "背景颜色",
    assigned_icon: "指定图标",
    assigned_items: "指定项目",
    back: "返回",
    confirm: "确认",
    continue: "继续",
    cancel: "取消",
    clear: "清除",
    close: "关闭",
    day: "日",
    delete: "删除",
    duplicate: "重复",
    ends: "结束",
    finish: "结束",
    from: "从",
    loading: "加载中...",
    mobile: "手机号码",
    name: "姓名",
    next: "下一个",
    none: "无",
    price: "价格",
    verify: "验证",
    optional: "可选",
    save: "保存",
    send: "发送",
    restart: "重新启动",
    resetAll: "全部重置",
    start: "启动",
    select: "选择",
    selectDate: "选择日期",
    starts: "开始",
    title: "标题",
    to: "至",
    update: "更新",
    learnMore: "了解更多",
    assigned_itemsCount: "项目计数：{count}",
    no_results: "无结果",
    restore: "恢复",
    daily: "日常使用",
    system: "系统",
    noItemsAssigned: "未找到指定项目",
    chooseSearchMethod: "选择搜索方法",
    chooseSearchMethodFull: "包含单词...",
    chooseSearchMethodStart: "以",
    searchItem: "搜索项目",
    saveInfo: "保存更改",
    resetInfo: "重置更改",
    overviewBtnSettings: "业务信息及设置",
    overviewBtnActivity: "业务活动时间",
    accordionExpansionLearnMoreTitle: "了解更多有关此部分的信息",
    active: "活动",
    step01: "步骤 1",
    step02: "第 2 步",
    step03: "步骤 3",
    step04: "第四步",
    step05: "第五步",
    step06: "第六步",
    step07: "第七步",
    option_01: "选择 1",
    option_02: "选择 2",
    option_03: "方案 3",
    option_04: "方案 4",
    doThis: "这样做"
  },
  app: {
    headsup: "抬起头来",
    errorMessage: "出问题了...",
    errorSaveMessage: "保存错误：输入数据无效...",
    never: "[尚无数据]",
    empty: "无结果",
    autocomplete: "选择或开始键入",
    phonePlaceholder: "只有数字",
    phoneHint: "只输入数字 (0-9)，不输入连字符 (-)，不输入空格，不输入点 (.), 不输入 / ( )，最多 20 个字符。最多 20 个字符。",
    verified: "验证",
    fail: "哎呀。出错了",
    registeredSupervisorMobileExists: "已存在使用此手机号码注册的监督员",
    registeredWaiterMobileExists: "已存在使用此手机号码注册的服务员",
    registeredStaffMobileExists: "已用此手机号码注册的工作人员",
    itemSpecialPriceAlert: "确定特价过期日期或删除日期。然后再试一次。",
    itemInvalidParams: "某些数据无效或丢失。请再次检查",
    days: {
      everyday: "每天",
      sunday: "周日",
      monday: "星期一",
      tuesday: "星期二",
      wednesday: "星期三",
      thursday: "星期四",
      friday: "星期五",
      saturday: "星期六"
    },
    localeSelect: "选择语言",
    timezoneSelect: "选择时区",
    countrySelect: "选择国家",
    version: "版本：{version}",
    deleteConfirm: "您确定要删除此 {object} 吗？",
    unsavedChanges: "关闭此表单？任何更改都将无法保存。"
  },
  auth: {
    emailVerification: {
      title: "请验证您的电子邮件",
      subtitle: "请在下面输入您的电子邮件，我们将向您发送一封带有验证链接的电子邮件。点击电子邮件中的链接继续。",
      resendTitle: "您需要我们重新发送验证链接吗？",
      resendCta: "重新发送链接",
      resent: "验证电子邮件已成功发送",
      success: "您已完成电子邮件验证过程。"
    },
    popup: {},
    login: {
      title: "欢迎来到梦想餐厅",
      titleWithName: "欢迎来到梦想餐厅 {name}！",
      subtitle: "登录开始体验",
      confirm: "登录",
      noUser: "请先注册以继续",
      unverified: "请验证您的电子邮件地址以继续。\n发送至{email}.如有需要，请检查垃圾邮件文件夹。",
      fail: "用户名/密码无效",
      success: "登录成功",
      email: "输入您的电子邮件地址",
      password: "输入密码",
      forgot: "忘记密码",
      register: "我要注册",
      resendVerification: "重新发送验证电子邮件",
      changeUser: "不是 {user}?"
    },
    forgot: {
      title: "忘记密码了？",
      subtitle: "输入您的电子邮件地址，然后查看收件箱（或垃圾邮件文件夹！），获取新的密码重置链接",
      login: "记住我的密码了！返回登录",
      register: "返回注册",
      confirm: "发送链接",
      success: "重置链接已成功发送",
      profileResetPassword: "重置密码",
      profileResetPasswordSent: "重置链接已发送至您的电子邮件",
      errors: {
        205: "未找到用户。"
      }
    },
    register: {
      title: "欢迎来到梦想餐厅",
      subtitle: "注册开始体验",
      email: "输入您的电子邮件地址",
      emailConfirm: "再次输入电子邮件地址",
      phone: "输入手机号码",
      password: "输入密码",
      passwordConfirm: "再次输入密码",
      name: "你叫什么名字？",
      existingUser: "已经有使用您的电子邮件或电话号码的用户注册了",
      success: "欢迎来到 DreamDiner！请检查收件箱，验证您的电子邮件地址，以便继续。",
      resend: "重新发送验证码 ({counter})",
      fail: "哎呀。出错了",
      confirm: "注册",
      login: "我已经注册",
      otpTitle: "输入您的代码",
      otpSubtitle: "我们向 {email} 发送了验证码",
      incorrectOTP: "对不起验证码错误。请重试或重新发送"
    }
  },
  autocompleteInput: {
    defaultPlaceholder: "开始输入..."
  },
  tooltips: {
    infoAdminCode: "这不是登录密码！建议每天或每周更改此密码",
    tableMasterCode: "如果管理员没有给出特定的密码，则使用默认的表格密码",
    infoBusinessWebsite: "主要供客户使用，以便轻松导航到您的网站。精确复制浏览器中显示的 URL 链接",
    registrationPhone: "登录移动应用程序所需",
    waitersAssignTables: "启用多表选择",
    itemKitchens: "此项目将链接到所选的准备台",
    itemCategories: "此项目将始终显示在所选类别下。允许多个类别。例如，FishBurger（鱼肉汉堡）可以放在 Burgers（汉堡）和 Seafood（海鲜）下。",
    itemLabels: "此商品的一般分类。允许多个选择",
    itemAllergens: "允许多选",
    itemSymbol: "PREP 应用程序使用的 3 个字母代码",
    categoryName: "最好是单字标题",
    menuPrice: "将此项目链接到菜单，并为每个菜单设置特殊价格。如果未设置，则使用默认价格",
    settingsCog: "您的所有业务信息和设置",
    activityHours: "您的企业每日和每周活动时间",
    businessStatusLive: "如果隐藏，则客户只能通过二维码或直接 URL 链接到达企业",
    addTemplateBtn: "点击右侧打开的表格中的添加按钮",
    colorWaiter: "从该服务员处订购的所有商品都将带有该服务员的颜色'#___#",
    colorTerminal: "与此站相关的所有项目将使用此站的颜色'#___#",
    colorStaff: "为该工作人员指定颜色",
    colorMenu: "为该菜单指定颜色，单击添加，观察左侧图表",
    addBtnHint: "单击此处添加到您的列表",
    itemCategoryAlert: "必须链接到一个或多个类别",
    settingsBlock: "功能及设置",
    categories: {
      cat_100: "啤酒",
      cat_101: "啤酒",
      cat_102: "啤酒龙头",
      cat_103: "冷饮",
      cat_104: "饮料",
      cat_105: "茶",
      cat_106: "咖啡",
      cat_107: "葡萄酒",
      cat_108: "香槟酒",
      cat_109: "酒精饮料",
      cat_110: "羊角面包",
      cat_111: "面包",
      cat_112: "椒盐脆饼",
      cat_113: "谷物",
      cat_114: "鸡蛋",
      cat_115: "早餐",
      cat_116: "沙拉",
      cat_117: "番茄",
      cat_118: "汤",
      cat_119: "去",
      cat_120: "披萨",
      cat_121: "比萨",
      cat_122: "炸薯条",
      cat_123: "三明治",
      cat_124: "寿司",
      cat_125: "寿司",
      cat_126: "包装",
      cat_127: "面条",
      cat_128: "米饭",
      cat_129: "儿童",
      cat_130: "汉堡",
      cat_131: "烧烤",
      cat_132: "鱼",
      cat_133: "鱼",
      cat_134: "鸡肉",
      cat_135: "烤肉串",
      cat_136: "烧烤",
      cat_137: "热狗",
      cat_138: "海鲜",
      cat_139: "外卖",
      cat_140: "水果",
      cat_141: "蛋糕",
      cat_142: "蛋糕",
      cat_143: "冰淇淋",
      cat_144: "雪糕",
      cat_145: "圣代",
      cat_146: "松饼",
      cat_147: "糖果",
      cat_148: "甜点",
      cat_149: "华夫饼",
      cat_150: "晚餐",
      cat_151: "餐点",
      cat_152: "刀叉",
      cat_153: "披风",
      cat_154: "特制厨师",
      cat_155: "餐厅",
      cat_156: "午餐",
      cat_157: "托盘",
      cat_158: "食堂",
      cat_159: "餐具"
    }
  },
  business: {
    adminProfile: {
      profileTitle: "用户资料",
      profileSubTitle: "在此设置您的个人信息",
      name: "姓名",
      nameHint: "在此输入您的姓名",
      mobile: "手机",
      mobileExplanation: "您的手机号码将用于登录管理应用程序",
      userPhoto: "照片",
      userPhotoExplanation: "拍一张好的:)",
      changePassword: "更改密码",
      changePassword2: "单击 \"重置 \"按钮更改密码，然后检查收到的电子邮件。如果没有收到，可能还需要检查一下垃圾邮件文件夹",
    },
    business: {
      title: "企业名称",
      titlePlaceholder: "您的企业名称",
      titleDescriptionPlaceholder: "另一个梦想餐厅企业",
      titleWebsitePlaceholder: "https://www.your-business.com",
      titleEn: "英文企业名称",
      titleEnPlaceholder: "您的英文企业名称",
      titleEnExplanation: "收到的短信将以该发件人姓名",
      statusDemo: "演示业务",
      statusLive: "状态：LIVE",
      statusHidden: "状态：隐藏",
      switchLive: "设置为 LIVE",
      switchHidden: "实时",
      email: "商业电子邮件",
      emailSubtitle: "您的业务联系电子邮件对系统通信至关重要，可让您及时了解重要通知和更改、新功能、精彩的教程视频以及更多信息。",
      emailPlaceholder: "您的业务电子邮件",
      emailVerifyText: "好的。您的商业电子邮件与您的用户电子邮件相同",
      emailVerifyBtn: "现在验证",
      useMyEmail: "使用我的登录电子邮件",
      adminPassword: "管理员密码",
      adminPasswordSubtitle: "此密码是一种安全措施，只有拥有此密码的授权用户才能执行各种任务，如退款、删除订单等。可以每天或每周更改一次。",
      enterNewPassword: "输入新密码",
      pin: "客户主验证码",
      pinSubtitle: "使用此代码可访问因任何原因无法进行手机验证的客户。请不要经常使用此验证码，并经常更改。默认值为 999",
      pinPlaceholder: "仅数字",
      website: "您的企业网站",
      websitePlaceholder: "https://...",
      timezone: "时区",
      timezoneSubtitle: "可能需要根据夏令时和冬令时进行调整",
      country: "国家",
      currency: "货币",
      tagline: "企业标语",
      taglineExplanation: "简明扼要的描述，将出现在客户应用程序中您的企业名称下",
      hours: "营业时间",
      currentTime: "当前营业时间：",
      adminPasswordPlaceholder: "至少 6 位数字，只限数字",
      adminPasswordRepeatPlaceholder: "重复密码",
      businessLogo: "商业标识",
      businessLogoSubtitle: "您的徽标将显示在整个客户应用程序和许多其他地方。如果留空 - 系统将显示 DreamDiner 徽标。在没有照片或上传图片的所有项目中，商业徽标也将是默认图片。",
      logoSelectDemoBtn: "选择演示徽标",
      logoSelectSampleBtn: "选择临时图像",
      logoSelectOr: "或者",
      logoSelectTitle: "从给定的演示徽标中选择",
      logoSelectSubtitle: "稍后，你将用自己的徽标替换它",
      openinghoursClosed: "关闭",
      openinghoursAllDay: "24 小时",
      addTimeSegment: "添加时间段",
      addTimeSegmentExplan: "每天必须至少有一个时间段。请不要重叠，并保持时间段，以便第一个时间段是一天中最早的时间段。\n要添加早期时间段，请删除每天的时间段并编辑第一个时间段，将其设置为最早的时间段。",
      updateMessageSnackBar: "更改更新成功",
      businessColorTitle: "业务主题颜色",
      businessColorSubtitle: "与您的企业氛围和徽标最匹配的首选颜色主题",
      businessLocationTitle: "业务地点",
      businessLocationSubtitle: "需要，以便附近的客户能在客户应用程序中轻松找到您的企业",
      businessLatitude: "纬度：",
      businessLongitude: "经度",
      getLocationFromDevice: "为我设置位置",
      getLocationAccessDenied: "检查浏览器设置并启用定位访问",
      noTrackerEnableTitle: "客户跟踪进度（通常适用于非餐饮企业）",
      noTrackerEnableDesc: "客户应用程序具有订单准备过程跟踪选项组件（已订购、进行中、准备就绪、已交付）。如果您的企业不处理准备过程，或者您不希望客户在执行订单后看到此状态选项卡，您可以决定隐藏此组件。",
      noTrackerSwitchEnabled: "客户将跟踪准备过程（餐厅模式）",
      noTrackerSwitchDisabled: "客户应用程序将不显示准备状态选项卡",
      businessEatInTitle: "堂食选项",
      EatInSwitchEnabled: "在客户端应用程序中可以看到 \"请进 \"按钮",
      EatInSwitchDisabled: "吃进 \"按钮现在隐藏",
      businessAllowEatInSubtitle: "例如，如果您的企业只经营外卖和送餐业务，您可以决定在客户应用程序中隐藏此按钮",
      EatInMinimumTitle: "堂食订单最低金额：",
      EatInMinimum: "设置价格值",
      businessEatInMinimumSubtitle: "设置接受堂食订单的最低价格值。值必须是 >6",
      businessTakeAwayTitle: "外卖选项",
      TakeAwaySwitchEnabled: "外卖按钮在客户端应用程序中可见",
      TakeAwaySwitchDisabled: "带走按钮现在隐藏",
      businessAllowTakeAwaySubtitle: "如果启用（绿色）- 则客户应用程序中的 \"Take-Away \"按钮将根据定义的开始和结束时间启用",
      takeAwayMinimumTitle: "外卖订单最小值：",
      takeAwayMinimum: "设置价格值",
      businessDeliveryTitle: "送货选项",
      DeliverySwitchEnabled: "在客户端应用程序中显示交付按钮",
      DeliverySwitchDisabled: "交付现在隐藏",
      businessAllowDeliverySubtitle: "如果启用（绿色）- 则根据定义的开始和结束时间启用客户端应用程序中的交付按钮",
      deliveryMinimumTitle: "配送订单最小值：",
      deliveryMinimum: "设置价格值",
      tablePlannerTitle: "餐桌规划设置",
      tablePlannerDesc: "餐桌规划器的一些基本设置",
      tablePlannerEnableWeb: "启用网络表格规划器",
      tablePlannerLinkBtn: "在新标签页中打开 \"餐桌规划器",
      tablePlannerEnableWebDesc: "网络版餐桌规划师预订可让您的客户直接从桌面或手机上进行预订，您将立即在餐桌规划师应用程序主板上看到这些预订。",
      EnableWebSwitchEnabled: "客户现在可以在网络版餐桌规划师中进行自助预订",
      EnableWebSwitchDisabled: "在网络版餐桌规划师应用程序中，您的业务将被隐藏，无法被搜索到",
      tablePlannerFirstDay: "设置一周的第一天",
      tablePlannerFirstDaySun: "星期日",
      tablePlannerFirstDayMon: "星期一",
      tablePlannerFirstDayDesc: "确定主板'的第一天",
      tablePlannerIntervals: "主板间隔",
      tablePlannerIntervalsDesc: "设置主板的列间隔",
      tablePlannerMainboardDefaultView: "主板默认视图",
      tablePlannerMainboardDefaultViewDesc: "主板加载时，默认显示哪个视图",
      tablePlannerReservationDefaultTime: "预约默认时间",
      tablePlannerReservationDefaultTimeDesc: "默认确定预约结束时间",
      paymentsPaymeSettingsTitle: "付款设置",
      paymentsPaymeSettingsTitleDesc: "接受付款所需的设置",
      paymentsPaymeSettingsIdPlaceholder: "在此输入 MPLXXXXX-XXXXXXX-XXXXXXXXX",
      paymentsPaymeSettingsIdTooltip: "该 ID 在您签收付款后生成",
      paymentsAcceptApplePayTitle: "接受 Apple Pay",
      paymentsSellerIdTitle: "卖家 ID",
      paymentsSellerIdTitleDesc: "您的 PayMe 卖家 ID",
      paymentsSellerIdDebug: "卖家 ID 调试",
      paymentsSellerIdDebugSample: "复制并将其置于 DEV 模式下：MPL16444-14303SH9-AJLRRH1F-YU1W0IAH",
      paymentsAcceptBitTitle: "接受比特付款",
      paymentsAcceptBitTitleDesc: "让您的客户使用比特应用程序付款。\n 首先，在 Payme 面板（Payme 侧边栏> 管理> 应用程序市场）上启用比特应用程序；然后，启用此开关。\n 注意：每笔交易将增加 0.3 新谢克尔",
      paymentsBitSwitchEnabled: "为您的客户启用比特支付",
      paymentsBitSwitchDisabled: "禁用比特支付",
      paymentsAcceptIcountTitle: "为客户启用 iCount 发票",
      paymentsAcceptIcountTitleDesc: "让您的客户下载他们购买的发票。\n 首先，在您的 Payme 面板（Payme 侧边栏> 管理> 应用程序市场）上启用 iCount 应用程序；然后，启用此开关。\n 注意：每笔交易将增加 0.35 新谢克尔",
      paymentsIcountSwitchEnabled: "为您的客户启用 iCount 发票",
      paymentsIcountSwitchDisabled: "禁用 iCount 发票"
    },
    plans: {
      plansTitle: "可用的业务计划",
      plansSubTitle: "选择最适合您业务的计划",
      plansItalTitle: "计划一经设置即自动相应更改，如所述",
      plansBtnToSetPayments: "在此设置您的支付网关",
      learnMoreBtnSite: "https://dreamdiner.io/start-he/#plans",
      plansStep1Chip: "步骤 1",
      plansStep2Chip: "步骤 2",
      plansStep1Title: "学分计划（或继续免费）",
      plansStep2Title: "设置付款",
      howWorksTitle: "如何使用？",
      howWorksInfo: "了解什么最适合您的企业",
      howWorksLine01: "任何企业都可以上传其菜单项目并创建数字菜单，供客户使用生成的 QR 代码访问。此外，企业还可以通过管理仪表板共享链接。",
      howWorksLine02: "要接收和管理订单，企业需要有一个积极的信用账户。信用额度可作为即用即付套餐或无限量包月套餐购买。",
      howWorksLine03: "要接受付款并让顾客为其订单付款（包括需要预付款的外卖和送餐订单），企业需要通过 DreamDiner 平台注册并创建一个 Stripe 账户。在这种情况下，所有销售资金都直接存入企业账户，并扣除 Stripe 的常规费用和 DreamDiner 平台的 1.2% 佣金。",
      planFreeTitle: "菜单",
      planCreditTitle: "积分",
      planPaymentsTitle: "无限",
      planGetPaidTitle: "获取报酬",
      planFreeInfo: "免费计划",
      planCreditInfo: "积分余额：{number}",
      planPaymentsInfo: "续订时间：{date}",
      planPaymentsNotSubscr: "未订阅",
      planGetPaidInfo: "促进并确保您的销售！",
      planFreeLine01: "当其他计划都未激活时，默认计划就会生效",
      planCreditLine01: "现收现付计划，1 个信用额度 = 1 个订单。信用包是一次性付款，您的配额不会过期。您可以购买一定数量的订单信用点数，然后随时使用（即用即付）。",
      planPaymentsLine01: "包月套餐建议每月订单超过 500 单的企业使用。订购每月收取固定数量的订单信用点数。该计划让您的企业高枕无忧。您不必担心不断充值点数，也不必担心在工作期间点数用完时系统被锁定。每月订单不受限制，您之前的信用额度余额（如果有的话）保持冻结，不受影响",
      planGetPaidLine01: "加入 \"获取付款 \"计划后，客户可在系统内直接付款，无需企业处理付款事宜，并确保订单（尤其是外卖和送货订单）得到安全支付。",
      whenFreePlan: "贷方余额达到 0（零）且未激活订阅时自动切换",
      whenCreditPlan: "当贷方余额为正 (>1) 且订购未激活时自动设置",
      whenPaymentsPlan: "该计划在订购后自动激活，余额不受影响",
      planGetPaidPlan: "该计划对于使用 \"信用 \"或 \"无限 \"计划的企业来说是一个强大的附加功能。它支持客户付款，确保订单无缝、安全",
      explanFreePlan: "当没有设置其他计划，也没有可用的企业积分时，该备用计划将自动激活。此外，您还可以随时免费获得可与客户共享的多达 200 个项目的数字菜单。",
      explanCreditPlan: "简单的 \"现收现付 \"计划意味着每一笔订单都等于一个积分。每当积分余额达到设定的最低限额时，就可以手动充值。",
      explanGetPaidPlan: "这种功能齐全的计划一经订阅就会自动设置，并通过设置一个独特的按钮链接到您首选的支付提供商，直接接收所有客户订单的付款到您的账户，包括外卖和送货销售选项。",
      planFreeClient: "客户可以查看菜单，但不能下订单",
      planCreditClient: "只要您的商业信用余额大于 1，客户就可以下订单",
      planPaymentsClient: "企业每月可接受不限数量的订单",
      planGetPaidClient: "客户可以直接付款完成订单",
      benefitMenu: "全数字菜单",
      benefitOrders: "管理订单",
      benefitSetPaid: "将订单设为已付款",
      benefitPayments: "启用客户直接付款",
      benefitUnlimited: "无限制订单",
      price100: "100 个信用点 = 10 美元 (0.1)",
      price500: "500 个信用点 = 44 美元 (0.088)",
      price1000: "1000 个信用点 = 150 美元 (0.15)",
      priceSubs: "订阅费 = 44 美元/月",
      priceGetPaid: "费用 = Stripe 通常收取 2.9% + 每笔成功刷卡费用 30¢ + 每笔订单 1.2% 的 DreamDiner 费用",
      creditTerms: "积分自购买之日起一年内有效",
      subscriptionTerms: "随时取消，订阅在下次续订日期前保持有效",
      getPaidTerms: "随时停用链接的 Stripe 账户即可取消订阅",
      btnGetMoreCredits: "获取更多积分",
      btnUnlimited: "无限量",
      btnGetPaid: "注册 GetPaid",
      siteLinkPayme: "https://dreamdiner.io/he-payment/",
      asterisk01: "企业使用当前的支付系统接受付款，并在订单卡或 PREP-APP （DreamDiner 准备应用程序）中手动将订单标记为 \"已付\"。",
      asterisk02: "取决于企业是否已注册 GetPaid"
    },
    credits: {
      buyCreditsTitle: "购买点数 - 即买即付",
      buyCreditsText1: "1 积分 = 1 订单",
      buyCreditsTextBtn: "显示所有商业采购",
      currentBalance: "当前赊购余额：{balance}",
      lastCreditPurchase: "最近一次赊购：{date}",
      underCreditsBtn: "为您的账户添加 {credits} 积分",
      subscribeTitle: "订阅 - 无限使用。",
      subscribeText: "按月订购，随时取消。",
      lastRenewal: "续订时间：：{date}",
      subscrEnds: "订阅结束时间：",
      backToPlansPageBtn: "返回计划页面",
      underSubscribeBtn: "购买订阅",
      beforeMinimum: "当您的余额低于最低限额时设置提醒",
      minimumLabel: "每当余额低于最低限额时提醒我：",
      purchasePageTitle: "购买的商业积分列表",
      purchasePageFreeCredits: "免费信用点",
      purchasePageTotalPurchased: "购买总数",
      purchasePageTotalOrders: "订单总数",
      purchasePageCreditBalance: "贷方余额"
    },
    fixPlanPage: {
      title: "订阅 FIX 计划",
      titleIsSubscr: "订阅设置",
      subscrStatus: "订阅状态：",
      subscrStatusCanceled: "已取消",
      nextPeymant: "下次付款日期",
      activeUntil: "订阅有效期至",
      subscrInvalid: "订阅无效。请在客户门户重新订阅",
      btnCustomerPortal: "客户门户",
      CustomerPortalExplan: "在此设置您的所有个人数据、付款方式、取消订阅、续订等信息"
    },
    getPaidPage: {
      title: "注册接收付款",
      subtitle01: "请在此选择您喜欢的支付提供商网关，并在 DreamDiner 平台下开设一个连接账户。这将使客户能够为其订单付款，资金将存入您的连接账户。",
      subtitle02: "稍后，您可以访问销售页面，查看您的付款仪表板和交易情况。",
      ifGermanyTitle: "根据您的业务所在地--德国，选择您的支付提供商",
      ifIsraelTitle: "根据您的业务地点 - 以色列，选择您的支付提供商",
      btnStripSetupAccount: "建立 Stripe 账户",
      btnStripCreateAccount: "创建 Stripe 账户",
      btnStripCreateConnected: "连接为： bus@bus.com",
      btnSoon: "即将上市",
      learnMoreStripeLink: "了解有关 Stripe Connect 的更多信息",
      learnMoreStripeHref: "https://stripe.com/en-de/connect",
      learnMorePayMeLink: "了解如何设置 PayMe 帐户",
      learnMorePayMeHref: "https://dreamdiner.io/he-payment/",
      stripe: "Stripe",
      stripeEnabled: "已启用 Stripe 付款！",
      btnGoToSalesPage: "访问您的 Stripe 面板",
      paypal: "贝宝",
      payme: "PayMe",
      sepa: "SEPA",
      amazon: "亚马逊",
      bitcoin: "比特币",
      countries01: "可用国家全球",
      countries02: "可用国家美国、以色列",
      countries03: "可提供的国家欧洲",
      countries04: "可提供的国家美国、欧洲"
    },
    qrPage: {
      title: "您的业务订单",
      stripeTitle: "使用您的 Stripe 账户获得付款"
    },
    salesPage: {
      title: "管理你的销售交易",
      subtitle01: "快速查看和控制你的 Stripe 支付和付款仪表板",
      subtitle02: "点击选项卡加载当前仪表盘。加载可能需要一些时间",
      btnStripeLoadDashboards: "加载 Stripe 仪表板",
      subtitle03: "点选表格中的任意一行，查看更多信息和操作（退款、交易详情等。）",
      subtitle04: "请设置一个已连接的 Stripe 账户以激活按钮。",
      getPaidLink: "将您的业务设置为 GetPaid",
      paymentsTab: "付款",
      paymentsTabExplan: "显示具有导出、退款和争议功能的付款列表",
      payoutTab: "付款",
      payoutTabExplan: "显示付款信息并允许执行付款"
    },
    supervisors: {
      title: "监督员",
      subtitle: "主管可以访问管理仪表板，但有某些限制和约束",
      description: "主管可以在系统中执行各种任务，如查看订单、检查总销售额、接收付款、给予折扣等。主管可以访问仪表板，但只能访问系统管理员有权访问的某些功能。",
      redLine: "",
      redLine_wizard: "本部分可选，但建议至少添加一名主管。您也可以自行设置。",
      mySupervisors: "您的主管",
      addSupervisors: "添加主管",
      supervisorName: "主管姓名",
      supervisorEmail: "主管的电子邮件",
      moreSubtitle: "以后可在设置中更改监督员的图像、日志等信息"
    },
    tables: {
      title: "表格",
      subtitle: "高效发送客户订单，订单会根据表格名称和颜色进行标注。",
      description: "餐桌在 DreamDiner 系统中扮演着重要角色，它是客户订单和服务员的重要标识符。如果没有可用的餐桌，系统会在幕后自动创建一个餐桌。每张餐桌都有唯一的名称和颜色代码，便于识别餐桌、客户和相应的服务员。此外，餐桌还可用于 \"餐桌规划师 \"预订应用程序，这是一款智能预订和座位优化程序。建议餐桌名称不要超过四个字符。",
      redLine: "此部分为可选项，但建议使用。企业不需要有餐桌。",
      redLine_wizard: "本节为可选项，但建议使用。企业不需要有表格。",
      myTables: "您的表格：",
      addTables: "添加表格",
      name: "表格名称",
      namePlaceholder: "最多 4 个字符4 个字符",
      seats: "桌位",
      colorNote: "系统将根据分配给该桌的服务员的颜色实时调整该桌的颜色",
      tablePin: "餐桌 6 位数字代码",
      rbTitle: "您企业中的餐桌状态",
      rbExplan: "从以下选项中选择一个，指定客户端应用程序有关业务表格的行为。",
      rbOption01Title: "表格和编码：（推荐）企业有表格，并将使用表格编码选项。",
      rbOption01TitleClient: "Dreamliner 鼓励顾客在准备台直接下单，无需服务员接单。建议顾客在外卖点餐时输入 6 位数字代码，以确认他们是否在餐桌旁，因为这些点餐通常是在付款前准备好的。在这种情况下，顾客在提交订单前会选择一张餐桌并输入 6 位数的餐桌代码。此程序不适用于外卖和外送订单。餐桌代码可以是每张餐桌独有的，也可以是店内所有餐桌的通用（主）代码。建议定期更改此代码。",
      rbOption02Title: "无代码：我们有表格，但不需要编码系统",
      rbOption02TitleClient: "在这种情况下，客户只需选择一个表格，即可提交订单",
      rbOption03Title: "无表格：我们根本没有表格",
      rbOption03TitleClient: "在这种情况下，客户将跳过表格选择和编码页面",
      rbOption04Title: "表格 QR 码（未来功能）：为表格名称和编码使用 QR 代码",
      rbOption04TitleClient: "在这种情况下，客户端只需扫描表格名称和代码的二维码即可",
      alertTableStatusUpdated: "业务表状态更新成功",
      alertTableStatusError: "业务表状态遇到错误。请重试",
      tablePinExplanation: "请在有此代码的表上贴上贴纸或其他指示符。如果没有指定特定的表代码，系统将自动使用表的主代码。建议定期更新这些代码。",
      tableMasterCodeTitle: "表主代码",
      tableMasterCodeSubtitle: "如果表格没有定义特定代码，这将是备用表格代码",
      tableMasterCodeHint: "例如 456789（6 位数字）",
      tableMasterCodeSuccessMessage: "主代码已更改并成功保存",
      tableMasterCodeErrorMessage: "保存或更改主密码出错",
      tableMasterCodePaletteLabel: "使用表主代码",
      tablePicker: {
        title: "服务员选表器",
        subTitle: "选择一名服务员，然后点击任何餐桌以分配",
        waiters: "您的服务员",
        tables: "您的桌子",
        alertNotAssigned: "有未分配的桌子"
      }
    },
    waiters: {
      waiter: "服务员",
      title: "服务员",
      subtitle: "服务员被分配到您的桌位，以便更好地控制订单的送达。",
      description: "服务员对餐厅的服务和工作效率起着至关重要的作用。然而，DreamDiner 可以大大减少服务员的数量以及餐厅对他们的依赖。\n原因如下：客户应用程序中的客户订单会直接发送到准备台，之后再进行独立支付。服务员应用程序主要是一种高效的工具，帮助他们将宣布为 \"准备就绪 \"的物品送到急切等待的饥饿顾客手中。\n服务员应用程序还可以访问菜单，接受和制作客户订单。\n服务员可以被分配到您的餐桌上，从而为每份订单和每张餐桌赋予服务员的颜色，以便更好地控制和高效地送达订单。\n服务员将使用您在此处批准的手机号码进入服务员应用程序。",
      redLine: "",
      redLine_wizard: "本部分可选，但建议至少添加一名服务员。您也可以自行设置。",
      myWaiters: "您的服务员名单：",
      addWaiters: "添加服务员",
      waiterName: "服务员姓名",
      waiterPhoto: "服务员照片",
      paletteTablesTitle: "桌子",
      tables: "指定桌位",
      tablePickerBtn: "将桌子分配给服务员",
      assignAll: "分配给所有桌子",
      notAssignToTable: "不分配给任何桌子",
      openPickerBtn: "打开表格选择器",
      moreSubtitle: "",
      moreSubtitle_wizard: "注意：服务员的图像、日志以及更多信息可稍后在仪表板 > 服务员中控制"
    },
    kitchens: {
      title: "准备（预备）台",
      subtitle: "将项目划分为不同的准备工作站可以减轻负荷，并只显示与每个工作站相关的项目。",
      description: "准备台是准备某类食物的指定区域。例如烧烤、室内吧台、沙拉准备等。\n在每个准备台放置一个准备应用程序，并设置相应的选择器。现在，您订购的菜品会显示在它们应该准备的地方：订购的鸡尾酒只会显示在设置为酒吧的应用程序准备站，而家庭汉堡则显示在烧烤准备站，等等。这种选择性路由大大提高了准备工作的准确性和便利性。\n准备工作站不受限制。\n主准备台是系统默认设置，默认情况下保留所有点菜台项目。",
      redLine: "",
      redLine_wizard: "主预置台是系统默认设置，将显示所有订购的项目，无论其分配到哪个台。",
      myKitchens: "您的准备站",
      addKitchens: "添加预处理站",
      kitchenName: "准备台名称",
      kitchenPlaceholder: "最多 3 个字符3 个字符"
    },
    staff: {
      title: "工作人员",
      subtitle: "工作人员可登录 PREP-APP 管理其指定工作站的项目准备工作。",
      description: "例如，员工可以是厨师、副厨师、调酒师、烧烤师等。工作人员还有权进入 PREP 应用程序--所有准备工作都在这里进行和交付。\n如果愿意，可将每位员工分配到一个准备站：调酒师分配到吧台站，副主厨分配到沙拉准备站，等等。如果未指定，则将始终显示在默认的准备工位 - 主准备工位下。\n工作人员将使用您在此处批准的手机号码输入 PREP App。",
      redLine: "",
      redLine_wizard: "本部分为可选项，但建议至少添加一名工作人员。您也可以自行设置。",
      myStaff: "您的工作人员：",
      addStaff: "添加工作人员",
      staffName: "工作人员姓名",
      staffPhoto: "工作人员照片",
      staffMobile: "员工手机号码",
      kitchen: "默认准备站",
      kitchenRoles: "角色",
      staffTerminalHint: "如果为空，您可能需要返回并添加一个准备站",
      color: "指定颜色",
      moreSubtitle_wizard: "推荐：员工图像、日志等可稍后在设置中控制",
      staffSelectTerminal: "分配到准备台",
      staffSelectMainKitchen: "主准备"
    },
    categories: {
      title: "类别",
      subtitle: "类别用于对相关项目进行分组，然后显示在菜单登陆页面上",
      description: "类别将相关项目分组，然后显示在菜单中，如汉堡、开胃菜、鸡尾酒和热饮。您的项目和菜肴可以链接到一个或多个类别：例如，家庭汉堡可以链接到汉堡类别、牛肉类别和我们的特色菜类别！\n类别不限，但尽量简洁。\n您可以从提供的图标集中选择一个图标，也可以上传自己的图标或图片，让您的菜单拥有完美的外观和感觉。",
      myCategories: "您的类别",
      showIcons: "显示类别图标",
      addCategories: "添加类别",
      categoryName: "类别名称",
      checkbox: "从所有菜单中隐藏",
      option_01: "上传图片或图标",
      option_02: "从给定的图标集中选择一个图标（背景颜色与步骤 1 中设置的相同）",
      cancelItemCategory: "只有当一个项目至少有另一个类别时，才能从该类别中取消链接",
      hasItems: "有些项目已经链接到这个类别",
      hideFromMenu: "从菜单中隐藏此类别",
      hideFromMenuHidden: "类别已隐藏",
      hideFromMenuVisible: "类别现在可见",
      iconExplan: "选择一个可以暂时使用并在以后更改的图标"
    },
    options: {
      title: "选项",
      subtitle: "选项是客户在个性化订购物品时可以使用的所有定制功能。",
      description: "项目附加功能是您允许客户在订购项目时要求的所有个性化调整。\n例如无洋葱、加番茄酱、打包带走等。\n每个额外项目可以是免费的，也可以在项目总价上加上特定的价格标签。此外，可根据需要将项目链接到任意多个额外项目。",
      redLine: "从给定模板中选择或添加您自己的模板。",
      redLine_wizard: "从给定模板中选择或添加您自己的模板。",
      myExtras: "您的项目选项",
      addExtra: "添加项目选项",
      groupOptionsCount: "{count}选项",
      freeNoCharge: "不收费",
      groupToggle: "这是团体选项",
      groupToggleDescription: "当选项分组时，用户只能从给定的选项组中选择一个选项",
      optionTitle: "选项标题",
      optionNum: "选项 #{num}",
      addGroupOption: "添加选项"
    },
    groupOptions: {
      title: "分组选项",
      subtitle: "组选项是一组只能选择一个选项的选项。",
      description: "组选项是一组客户只能选择一个的选项。例如，肉类制作的程度可以是生肉、中等肉或熟肉。另一个组选项的例子是单个配菜的选择：客户只能选择意大利面、米饭或薯条中的一种。\n组选项中的每个选项可以是免费的，也可以在项目总价上加上特定的价格标签。",
      redLine: "从给定模板中选择或添加您自己的模板。",
      redLine_wizard: "从给定模板中选择或添加您自己的模板。",
      myGroupOptions: "您的小组选项",
      addExtra: "添加组选项",
      groupOptionsCount: "{count}选项",
      freeNoCharge: "不收费",
      groupOptionsDrawerTitle: "组选项标题",
      groupOptionsDrawerExplan: "注：默认情况下，第一个选项将设置为在客户应用程序中选择的选项",
      groupOptionsDrawerNum: "选项 #{num}(本组中）",
      addGroupOption: "向该组添加一个选项",
      groupBadge: "组选项"
    },
    menus: {
      title: "菜单",
      subtitle: "菜单用于在营业时间调整不同项目的价格。",
      description: "菜单是动态的，与时间相关，可控制客户端应用程序中显示的项目的外观。例如，08:00-12:00 为早餐菜单，17:00-19:00 为欢乐时光菜单等。\n每个菜单都将显示与该菜单相关的项目。一个特定项目可以在多个菜单中显示。每个菜单中的项目都显示在其指定的类别中。例如，您的主打鸡尾酒可以显示在欢乐时光菜单和深夜菜单中，但不包括在早餐菜单中。\n在每个菜单中，每个项目都可以有不同的价格标签。家庭鸡尾酒可以在欢乐时光菜单上显示为 6 美元，但在深夜菜单上显示为 9.5 美元。另一种可能是设置一个全局折扣，应用于该菜单上的所有项目'价格。\n菜单时间轴可以更直观地显示一天中不同时段的所有活动菜单。\n避免菜单重叠。\n当没有其他菜单可用时，主菜单是默认菜单，显示所有可见项目。\n在非工作时间，相关菜单会显示在客户应用程序中。\n当没有定义其他菜单时，主菜单是系统默认的 24 小时菜单。当业务在工作时间外被定义为关闭时，您在客户应用程序中的业务菜单将默认显示包含所有项目的主菜单。",
      redLine: "",
      redLine_wizard: "本节为可选项，但建议使用。企业无需保留菜单。",
      myMenus: "您的菜单",
      addMenu: "添加菜单",
      hideMenu: "隐藏菜单",
      menuName: "菜单名称",
      startTime: "开始时间",
      endTime: "结束时间",
      hasCategories: "此菜单与类别相关联",
      itemsCount: "{count} 项目"
    },
    items: {
      title: "项目",
      subtitle: "项目是指企业菜单上的所有产品，包括菜肴和饮料。确保它们引人入胜，质量上乘！",
      description: "最后，您可以开始经营第一个项目了！现在，我们已经有了准备台、菜单和类别（欢迎回去添加更多！）--我们可以将它们全部连接起来，创建您完美的第一个项目。接下来，上传该菜品的精美图片：美味的食物从美味的外观开始。在这里，您还可以从给定列表中指定过敏原和标签（允许多选），并设置选项和附加功能。",
      myItems: "我的项目",
      addItem: "添加项目",
      hide: "从所有菜单中隐藏",
      symbol: "准备代码",
      itemDescription: "描述（最多 {length} 个字符）",
      image: "项目/菜肴图片",
      categories: "链接到您的分类：",
      menus: "链接到您的菜单：",
      labels: "添加标签：可选",
      allergens: "添加过敏原：（可选）",
      kitchens: "准备台",
      kitchenTerminalHint: "选择准备台",
      kitchenTerminalMainKitchen: "主要准备",
      price: "挂牌价：",
      menuPrice: "菜单价格",
      discountPlaceholder: "无特价",
      regularOptions: "常规选项",
      groupOptions: "团体选项",
      optionsAlertTooltip: "该选项可能有错误",
      addOption: "创建一个新选项",
      optionsCount: "选项：{num}",
      groupOptionsCount: "组： {num}",
      extras: "选项",
      duplicateMessage: "项目已成功复制！",
      linkToMenu: "为每个自定义菜单设置不同的价格（主菜单是系统默认菜单）：",
      linkToMenuWizard: "(创建业务后链接到菜单）",
      linkToMenuBusiness: "(创建项目后链接到菜单)",
      pricesUpdate: "价格更新",
      pricesReset: "价格重置",
      takeawayAllowTitle: "启用此商品的外卖/送货？",
      takeawayAllowIsAlsoForTA: "此商品也可外卖和送货",
      takeawayAllownotForTA: "此商品只供堂食",
      badgeNotForTA: "非外卖/外送项目",
      badgeForAll: "堂食/外卖/外送",
      addCategory: "创建新类别",
      archiveItemTitle: "归档此项目（从所有菜单中隐藏）",
      archiveItemSwitchisVisible: "项目可见",
      archiveItemSwitchisArchived: "项目设置为存档",
      wizardImagesPopup: {
        title: "选择图片样本",
        subtitle: "您可以在以后的项目设置中更改它"
      },
      specialPrice: {
        price: "售价：",
        date: "有效期至",
        hint: "点击此处设置结束日期和时间",
        dateTooltip: "设置此特价的终止日期"
      }
    },
    dashboard: {
      staffCardTitle: "工作人员",
      chefs: "登录的工作人员：",
      waiters: "登录的服务员",
      emptyStaff: "目前没有登录",
      onboardingCardTitle: "入职",
      onboardingCardText: "轻松入门",
      qrBlockTitle: "客户 QR&网址",
      qrBlockSubTitle: "扫描我客户订单选项和可共享链接",
      settingsBlockTitle: "概述",
      settingsBlockSubTitle: "所有业务信息和设置",
      settingsBlockDynamicTitle: "企业 ID：",
      settingsBlockButtonTitle: "更新设置",
      salesBlockTitle: "销售交易",
      salesBlockSubTitle: "您的 Stripe 支付仪表板易于访问和管理",
      tipsBlockTitle: "服务员提示",
      tipsBlockSubTitle: "服务员小费摘要",
      reportsBlockTitle: "销售图表",
      reportsBlockSubTitle: "所有订单和项目分析一目了然",
      reportsBlockDynamicTitle: "今天的销售额",
      reportsBlockButtonTitle: "查看报告",
      appsBlockTitle: "应用程序",
      appsBlockSubTitle: "通知员工如何下载应用程序以及如何登录",
      appsBlockDynamicTitle: "白标：",
      appsBlockButtonTitle: "探索应用程序",
      staffBlockTitle: "工作人员",
      staffBlockSubTitle: "您的所有主管、员工和服务员",
      staffBlockDynamicTitle: "当前登录：",
      staffBlockButtonTitle: "（工作）",
      tableplannerBlockTitle: "餐桌规划员预订",
      tableplannerBlockSubTitle: "组织并优化您的餐厅餐桌预订",
      tableplannerBlockDynamicTitle: "立即预订：",
      tableplannerBlockButtonTitle: "（工作）",
      ordersBlockTitle: "订单管理",
      ordersBlockSubTitle: "实时跟踪所有订单",
      ordersBlockDynamicTitle: "准备中：",
      ordersBlockButtonTitle: "（工作）",
      businessTierBlockTitle: "升级计划",
      businessTierBlockSubTitle: "继续使用免费计划或升级以获得付款",
      businessTierBlockDynamicTitle: "当前计划：",
      businessTierBlockButtonTitle: "（工作）",
      OnboardingBtn: "入职",
      OnboardingBlockTitle: "上机步进",
      OnboardingBlockSubTitle: "帮助您了解系统组件的分步指南",
      OnboardingBlockDynamicTitle: "当前步骤：12",
      OnboardingBlockButtonTitle: "开始",
      qrCodeClientBlockTitle: "订单二维码",
      qrCodePrintInputTitle: "为打印添加标题（可选）",
      qrCodeCopiedAlert: "已复制 URL 链接",
      availableSoon: "即将上市！",
      credits: "当前计划：积分",
      free: "当前计划：免费",
      getpaid: "当前计划无限",
      getPaidBlockTitle: "付费",
      getPaidBlockSubTitle: "让您的企业通过 Stripe 或 PayPal 接收付款",
      batchSettings: "设置",
      batchUtility: "实用工具"
    },
    overview: {
      title: "概览页"
    },
    apps: {
      title: "下载应用程序",
      subTitle: "在此找到所有链接和登录凭证，以便高效工作",
      back: "返回 {name}",
      appStore: "应用程序商店",
      getItOn: "获取",
      googlePlay: "谷歌游戏",
      download: "下载",
      headerApp: "应用程序",
      headerUsage: "使用方法",
      headerLinks: "下载链接",
      headerCreds: "授权用户和登录凭证",
      qrLinkSubtitle: "在浏览器中打开",
      qrLinkExplan: "使用此二维码或点击下面的按钮，您的企业就可以接受订单了。与您的客户分享此二维码或 URL",
      kitchen: "准备应用程序",
      kitchenUsage: "放置在每个准备站，以控制新订单并推广准备工作",
      kitchenAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.kitchen",
      kitchenQrTitle: "厨师头衔",
      kitchenPrintTitle: "PREP App @Google Play",
      waiters: "服务员应用程序",
      waitersUsage: "让您的服务员能够接受客户订单，并将准备好的物品送到客户手中",
      waitersAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.waiter",
      waiterQRTitle: "服务员标题",
      waitersPrintTitle: "Waiters App @Google Play",
      supervisors: "管理员应用程序",
      supervisorsUsage: "您或任何主管控制当前服务的有效工具：订单、钱箱、员工等",
      supervisorsAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.admin",
      supervisorsWeb: "https://admin.dreamdiner.io",
      supervisorsQrTitle: "主管职称",
      supervisorsPrintTitle: "管理应用程序 @ 谷歌播放",
      clients: "客户应用程序",
      clientsUsage: "在这里，您的客户可以找到您：查看您的菜单或下订单",
      clientsAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
      clientsAppStore: "https://apps.apple.com/us/app/gypsy-ace-2023/id6449999003",
      clientsWeb: "https://client.dreamdiner.io",
      clientsQrTitle: "客户名称",
      clientsPrintTitle: "客户应用程序",
      label: "白标应用程序",
      labelUsage: "您在 Google Play 和 Apple App Store 上的私人客户应用程序，只包含您的餐厅项目",
      labelAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
      labelAppStore: "",
      labelWeb: "https://dev.client.dreamdiner.io/business/4Cu0vVmfFH",
      labelQrTitle: "标签标题",
      labelPrintTitle: "标签标题",
      tablePlanner: "表格规划器",
      tablePlannerUsage: "一款跨平台的多设备应用程序，它将为您的餐厅预订提供强有力的控制",
      tablePlannerAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.planner",
      tablePlannerAppStore: "https://apps.apple.com/us/app/restaurant-seating-planner/id1463277281?ls=1",
      tablePlannerQrTitle: "表格规划者标题",
      tablePlannerPrintTitle: "tablePlanner title",
      userName: "名称",
      userRole: "角色",
      userLogin: "登录",
      userSms: "短信"
    },
    reports: {
      title: "报告页面",
      startDate: "开始日期",
      endDate: "结束日期",
      orders: "订单",
      sales: "销售额",
      refunds: "退款",
      ratings: "评分",
      selectY: "选择 Y",
      selectX: "选择 X",
      displayType: "显示类型",
      totalSales: "销售总额",
      totalOrders: "订单总数",
      totalRefunds: "退款总额",
      totalRatings: "总评分",
      totalTips: "小费总数",
      tips: "小费",
      bar: "酒吧",
      line: "线路",
      area: "区域",
      average: "平均",
      showAverage: "显示平均值",
      print: "打印",
      export: "出口",
      byDays: "按天",
      byProducts: "按项目",
      byWaiters: "按服务员"
    },
    orders: {
      switchOnlyLive: "仅进行中",
      switchOnlyUnpaid: "仅未付款",
      labelPaid: "已付",
      labelUnPaid: "未付",
      labelInProgress: "进行中",
      labelDone: "已完成",
      sortBy: "排序方式",
      perPage: "每页",
      byNum: "订单号",
      byDate: "订购日期",
      allTime: "来自所有时间",
      downloadPrepApp: "请管理员和列名工作人员下载 PREP-APP，以获得更多选项和功能",
      downloadPrepAppLink: "https://dreamdiner.io/dpa/",
      clientRatingItem: "项目被客户评为：",
      clientRatingWaiter: "服务员被客户评为",
      clientTip: "服务员的小费：",
      clientNoRating: "发现没有评分",
      tooltipRefreshOrders: "刷新页面",
      tooltipRefreshOrdersUpdates: "更新",
      tooltipRefreshOrdersNew: "新的",
      tooltipFullScreen: "全屏模式",
      tooltipSwitchInProgress: "切换为只显示进行中订单",
      tooltipSwitchUnPaid: "切换到只显示未付款订单",
      tooltipSwitchItems: "显示订单",
      tooltipOrderType: "订单类型",
      tooltipItemNumber: "此订单中的物品数量",
      tooltipItemNumberExtra: "请注意某些商品有特殊选项",
      tooltipBtnInProgress: "点击将订单设为完成",
      tooltipBtnDone: "点击将订单设为进行中",
      tooltipBtnPaidStatusPaid: "改为 UNPAID",
      tooltipBtnPaidStatusUnPaid: "设为已付款",
      tooltipBtnOrderRated: "客户已对订单评分",
      tooltipBtnOrderRequest: "客户要求",
      list: {
        num: "编号",
        client: "客户",
        tel: "电话",
        type: "类型",
        table: "表格",
        sum: "总和",
        items: "项目",
        remark: "备注",
        payment: "付款",
        id: "身份证",
        time: "时间",
        status: "准备工作"
      },
      tabs: {
        progress: "进度",
        completed: "准备",
        delivered: "已交付"
      }
    },
    onboarding: {
      backToOnboardingBtn: "继续入职",
      onboarding_cards: {
        obQR: {
          title: "下第一笔订单！",
          description: "扫描生成的代码或点击按钮开始。这是您发送给客户的 QR，以便他们下订单！",
          next_cta: "下一个",
          prev_cta: "返回"
        },
        obOrder: {
          title: "这是您的订单",
          description: "订单提交后，请在仪表板上查看。尝试将其设置为已付款或已完成。",
          label: "带我去",
          next_cta: "下一个",
          prev_cta: "返回"
        },
        obReports: {
          title: "探索报告",
          description: "所有活动的实时数据一目了然！即使是您刚刚下达的订单...",
          label: "向我展示报告",
          next_cta: "下一页",
          prev_cta: "返回"
        },
        obKitchen: {
          title: "梦想餐厅准备应用程序",
          subtitle: "适用于安卓设备",
          description: "(可选）下载准备和订单控制 (POC) Android 应用程序。邀请您指定的员工登录！",
          next_cta: "下一页",
          prev_cta: "返回",
          label: "应用程序链接和工作人员"
        },
        obSettings: {
          title: "设置和位置",
          description: "查看所有业务设置并更新位置",
          next_cta: "下一步",
          prev_cta: "返回",
          label: "带我去"
        },
        obImages: {
          title: "替换演示图像",
          description: "(可选）如果你使用了演示图片，也许现在可以用你的真实图片替换它们？",
          next_cta: "下一页",
          prev_cta: "返回",
          label: "带我去看我要替换的图片"
        },
        obOptions: {
          title: "链接项目选项",
          description: "(可选）还记得你的物品选项和附加功能吗？现在你可以将它们链接到任何物品",
          next_cta: "下一个",
          prev_cta: "返回",
          label: "带我去我的项目更新选项"
        },
        obMenu: {
          title: "菜单项目价格",
          description: "(可选）根据你的菜单设置不同的项目价格（如果你没有打开任何菜单，则跳过）",
          next_cta: "下一步",
          prev_cta: "返回",
          label: "带我去我的项目链接菜单"
        },
        obTables: {
          title: "设置表格代码",
          description: "(可选）为每个表设置一个唯一的 6 位数代码，或更改默认主代码",
          next_cta: "下一步",
          prev_cta: "返回",
          label: "带我去换密码"
        },
        obWaiters: {
          title: "分配服务员",
          description: "(可选）如果有服务员，请确保将他们分配到各自的桌子上（单击 \"将桌子分配给服务员 \"按钮）",
          next_cta: "下一步",
          prev_cta: "返回",
          label: "带我去见我的服务员"
        },
        obPlans: {
          title: "升级计划",
          description: "保持免费、购买积分或获得付款？由您选择！",
          next_cta: "下一页",
          prev_cta: "返回",
          label: "完成",
          learnMore: "在此了解更多信息：",
          business_models_link: "向我展示商业计划"
        },
        obCategories: {
          title: "4.更新类别图标",
          description: "按照您的要求设置类别、图标或图像！",
          next_cta: "下一页",
          prev_cta: "返回",
          label: "带我去"
        },
        obLive: {
          title: "Set business to LIVE",
          description: "准备好直播了吗？将卡上的开关设置为 \"设置为直播",
          next_cta: "下一步",
          prev_cta: "返回",
          label: "带我去"
        },
        noshowDialog: {
          title: "退出 \"随身听\"？",
          explan: "强烈建议至少完成一次入职向导，以正确设置所有链接和图像，实现完美功能",
          label: "在此标记不再显示登录向导"
        }
      }
    },
    invite: "通知 {name}",
    inviteSuccess: "下载应用程序并登录的短信邀请已成功发送 :)",
    sections: {
      dashboard: "仪表盘",
      info: "设置",
      hours: "活动时间",
      supervisors: "监督员",
      tables: "表格",
      waiters: "服务员",
      kitchens: "准备台",
      chefs: "工作人员",
      categories: "类别",
      menus: "菜单",
      items: "项目",
      extras: "项目选项",
      groupOptions: "组选项",
      infoExplan: "所有业务信息及设置",
      hoursExplan: "何时营业？关闭？",
      supervisorsExplan: "管理您的主管",
      tablesExplan: "添加和编辑您的表格",
      waitersExplan: "编辑服务员并分配到桌",
      kitchensExplan: "您的准备台",
      chefsExplan: "管理您的员工",
      categoriesExplan: "添加和编辑你的分类",
      menusExplan: "添加和编辑你的菜单",
      itemsExplan: "添加和编辑你的项目",
      extrasExplan: "添加和编辑你的项目选项",
      groupOptionsExplan: "添加和编辑你的组选项"
    },
    toolbar: {
      creditsBannerText: "警告：您只剩下 {credits} 个信用点数！您已定义的最低额度为： {minimum}",
      creditsBannerCta: "在此充值业务积分",
      credits: "信用余额：{credits}",
      miminum: "不足时发出警报：{miminum}",
      helpTitle: "支持",
      helpTooltip: "支持聊天",
      supportPopupTitle: "扫描二维码",
      supportPopupOr: "或直接点击联系我们：",
      supportPopupBtn: "开始支持聊天",
      supportPopupSupportRemark: "我们的支持中心团队的工作时间为周日至周五上午 8:00 至下午 4:00（UTC+2）。我们在这里回答有关设置业务的问题，以及之后的任何问题！",
      supportHelpScreenshot: "请提供并附上最能描述您的问题的截图或短片，以帮助我们解决您的问题",
      breadcrumbs: {
        home: "我的企业",
        info: "业务信息",
        dashboard: "仪表板",
        qr: "QR 码和订单链接",
        overview: "概览",
        apps: "应用程序",
        reports: "销售图表",
        tips: "提示摘要",
        orders: "管理订单",
        onboarding: "入职",
        supervisors: "主管",
        tables: "表格",
        waiters: "服务员",
        kitchens: "准备台",
        chefs: "工作人员",
        categories: "类别",
        menus: "菜单",
        items: "项目",
        extras: "项目选项",
        edit: "编辑对象",
        hours: "时间",
        groups: "组选项",
        purchases: "购买",
        planner: "餐桌规划器",
        plans: "升级计划",
        sales: "销售交易",
        credits: "获得更多积分",
        subscription: "FIX 计划订阅",
        payments: "注册以获得报酬"
      }
    },
    fromWizard: {
      title: "您的新业务 {title} 已准备就绪！\n500 点数已添加到您的信用银行（右上角）",
      text: "欢迎邮件将发送至{email}(检查垃圾邮件！），其中包含一些重要细节。\n\n下一阶段是友好的入职教程，帮助您完美设置业务。请尽情享受！",
      btn: "开始入职"
    },

  imagePicker: {
    default: "在此处拖放文件或\r\n浏览上传",
    wizard: "为该项目上传图片"
  },
  kitchenRoles: {
    chef: "厨师",
    sousChef: "副厨师",
    grillChef: "烧烤厨师",
    bartender: "酒吧服务员",
    kitchenAssistant: "厨房助理",
    other: "其他"
  },
  landing: {
    welcome: "您好，{user}。您的业务控制面板",
    lastUpdated: "最近更新： {date}",
    beta: "测试版",
    wizardTitle: "创建新业务",
    wizardDescription: "友好的向导将全程指导您",
    demoBusinessDescription: "试用所有功能的地方",
    emptyBusinessTitle: "在这里您将看到您的新业务。",
    emptyBusinessSubTitle: "创建业务通常包括两个阶段。首先是初始阶段，在这一阶段您可以使用向导设置基本功能，例如下第一份订单样本所需的一些关键项目和相关功能。然后是终极阶段，在这一阶段，您需要花时间设置完整的业务，包括所有必要的项目和功能，以完美地适应您的工作流程。"
  },
  models: {
    category: {
      singular: "类别",
      plural: "类别"
    },
    chef: {
      singular: "厨师",
      plural: "厨师"
    },
    extra: {
      singular: "额外的",
      plural: "额外的"
    },
    item: {
      singular: "项目",
      plural: "项目"
    },
    kitchen: {
      singular: "厨房",
      plural: "厨房"
    },
    menu: {
      singular: "菜单",
      plural: "菜单"
    },
    supervisor: {
      singular: "主管",
      plural: "监督员"
    },
    table: {
      singular: "表",
      plural: "表"
    },
    waiter: {
      singular: "服务员",
      plural: "服务员"
    }
  },
  multiSelect: {
    placeholder: "选择一个或多个",
    empty: "列表目前为空",
    skipped: "跳过（但添加了默认值）",
    onlyOption: "目前，这是唯一可用的选项"
  },
  demoBusiness: {
    demoMode: "仅供演示。已禁用保存",
    showdemo: "显示演示业务",
    hidedemo: "隐藏演示版业务"
  },
  nav: {
    login: "登录",
    logout: "退出登录",
    user: {
      guest: "欢迎您，访客",
      btn: "您的资料",
      editProfile: "编辑个人资料"
    },
    business: {
      demoBusinessTitle: "演示业务",
      enterBusiness: "输入业务",
      demoBusinessBtn: "现场演示"
    }
  },
  wizard: {
    dialog: {
      title: "您回到了已保存的业务创建草稿版本。",
      subtitle: "您想继续刚才的操作吗？",
      confirm_title: "警告！",
      confirm_subtitle: "重新启动向导将还原您迄今为止所做的一切，并删除所有更改。你确定要从头开始吗？",
      confirm_cta: "当然，重置一切",
      confirm_back: "不，返回"
    },
    suggestions: {
      option_01: "从给定模板立即添加",
      option_02: "从头开始添加自己的模板",
      toggle: "显示建议",
      add: "从模板添加",
      addTemplateBtn: "添加此模板",
      skipNextBtn: "暂时跳过"
    },
    toolbar: {
      saved: "保存为草稿",
      typeRequired: "所有业务类型都需要此步骤",
      typeChecked: "建议此业务类型采用此步骤",
      required: "本阶段为必经阶段",
      mobileToolbarProgressNav: "点击圆圈导航",
      titles: {
        intro: "创办新企业向导",
        types: "类型",
        customize: "您的向导阶段：",
        info: "信息",
        supervisors: "监督员",
        tables: "表格",
        waiters: "服务员",
        kitchens: "准备台",
        chefs: "工作人员",
        categories: "类别",
        menus: "菜单",
        extras: "项目选项",
        items: "项目",
        terms: "条款及条件",
        summary: "摘要"
      },
      btns: {
        createBusiness: "创建我的企业"
      }
    },
    util: {
      seeMore: "查看全部： {num}",
      listEmpty: "列表当前为空 :-0"
    },
    intro: {
      title: "如果你还想了解更多...",
      subtitle: "",
      redLine: "享受您的梦幻餐厅体验",
      title2: "解说视频短片",
      subtitle2: "动画解说短片展示业务创建过程",
      redLine2: "只需 2 分钟 :)"
    },
    customize: {
      title: "您的业务类型是什么？",
      subtitle: "选择业务模板，帮助系统只调整您的类型所需的相关功能。\n要手动定制或探索所有功能，请选择 \"自定义\"。",
      clickNext: "要继续，请单击右上角的 \"下一步 \"按钮",
      typeHeader: "以下是针对您的业务类型推荐的向导步骤：",
      explan: "要更改业务类型，只需单击 \"返回\"。索引：星号 = 必填部分，复选框 = 可取消选中以删除的部分",
      bottom: "不用担心：一旦创建了您的企业，任何跳过的功能都可以重新添加到您的企业中",
      businessTypes: {
        truck: {
          title: "餐车",
          subtitle: ""
        },
        pub: {
          title: "酒吧",
          subtitle: ""
        },
        fine: {
          title: "餐厅",
          subtitle: ""
        },
        small: {
          title: "小餐馆",
          subtitle: ""
        },
        cafe: {
          title: "咖啡馆",
          subtitle: ""
        },
        fast: {
          title: "快餐",
          subtitle: ""
        },
        buffet: {
          title: "自助餐",
          subtitle: ""
        },
        popup: {
          title: "弹出式",
          subtitle: ""
        },
        ghost: {
          title: "幽灵厨房",
          subtitle: ""
        },
        delivery: {
          title: "TA/Delivery",
          subtitle: ""
        },
        kiosk: {
          title: "信息亭",
          subtitle: ""
        },
        other: {
          title: "自定义",
          subtitle: ""
        }
      },
      skippable: "可选",
      points: {
        types: {
          title: "业务类型",
          subtitle: "定义您的业务",
          time: "1/2 min"
        },
        customize: {
          title: "业务步骤",
          subtitle: "定制自己的业务",
          time: "1/2 分钟"
        },
        info: {
          title: "业务内容",
          subtitle: "您的餐馆名称、描述、标识、密码等。",
          time: "2 分钟"
        },
        supervisors: {
          title: "监督员",
          subtitle: "指派您的餐厅主管（管理员只能由您担任！）。",
          time: "1 分钟"
        },
        tables: {
          title: "餐桌",
          subtitle: "添加第一张桌子和座位",
          time: "1 分钟"
        },
        waiters: {
          title: "服务员",
          subtitle: "添加服务员并分配到各桌",
          time: "1 分钟"
        },
        kitchens: {
          title: "准备台",
          subtitle: "您的准备工位：如烧烤、沙拉、吧台等。",
          time: "30 秒"
        },
        chefs: {
          title: "工作人员",
          subtitle: "您的团队：厨师、调酒师、洗碗工等。",
          time: "1 分钟"
        },
        categories: {
          title: "类别",
          subtitle: "添加您的项目' 类别",
          time: "30秒"
        },
        menus: {
          title: "菜单",
          subtitle: "如早餐菜单、欢乐时光菜单等。",
          time: "2 分钟"
        },
        extras: {
          title: "选项/附加",
          subtitle: "例如：无盐、特级橄榄油、罕见或全熟等。",
          time: "1 分钟"
        },
        items: {
          title: "项目",
          subtitle: "现在，您可以创建并链接第一个项目或菜肴！",
          time: "3分钟"
        },
        terms: {
          title: "条款及条件",
          subtitle: "阅读并批准我们的条款和条件",
          time: "+1 min."
        }
      }
    },
    business: {},
    terms: {
      title0: "好了，下一步是什么？",
      subtitle0: "下一步，您将在创建之前确认您的业务。需要做任何更改吗？没问题，回去修改；您所做的只是保存。创建完成后，扫描生成的二维码，即可下第一笔订单。酷！\n我们将为您的企业提供 500 个免费信用点数，让您在选择业务计划继续经营之前，先做 500 个订单来测试 DreamDiner。\n请遵循入职指南，使您的业务更上一层楼，然后您就可以开始正式运营了！",
      title: "我们的条款和条件",
      description: "我们就快成功了，您可以开始了！在我们在 DreamDiner 系统中开启您的独特业务之前，还有最后一步，请仔细阅读并同意以下内容：",
      redLine: "感谢您阅读并确认勾选所有方框:)",
      termsCheckbox: "我同意",
      termsLink: "条款和政策",
      paymentCheckbox: "获取付款和付款设置选项见设置",
      idleCheckbox: "如果没有任何活动，业务将在 90 天内删除"
    },
    summary: {
      businessCreationTitle: "您的企业准备好了吗？",
      businessCreationSubTitle: "以下是您告诉我们的有关创建基本业务的信息。请随时返回您需要的任何步骤，或在企业创建后再进行。",
      businessCreationClickNext: "要继续，请单击右上角的 \"创建我的企业 \"按钮",
      businessCreation3only: "只显示每个部分的前三个要素",
      staff: "团队",
      supervisors: "监督员",
      waiters: "服务员",
      chefs: "工作人员",
      equipment: "地点",
      kitchens: "准备站",
      tables: "桌子",
      products: "菜单和物品",
      extras: "额外",
      menus: "菜单",
      categories: "类别",
      items: "项目",
      regularOptions: "常规选项",
      groupOptions: "团体选项",
      extraOptions: "{num}选项",
      addRegularOption: "添加常规选项",
      addGroupOption: "添加组选项",
      finalize: "开始您的业务",
      working: "创建您的企业",
      patience: "请稍候...",
      saving: {
        info: "现在保存我们的业务...",
        supervisors: "更新主管人员",
        tables: "更新表格",
        waiters: "更新服务员",
        kitchens: "更新准备台",
        chefs: "更新工作人员",
        categories: "更新类别",
        menus: "更新菜单",
        extras: "更新项目选项",
        items: "更新项目"
      }
    },
    steps: {
      info: "信息",
      types: "类型",
      customize: "步骤",
      supervisors: "监督员",
      tables: "表格",
      waiters: "服务员",
      kitchens: "准备台",
      chefs: "工作人员",
      categories: "类别",
      menus: "菜单",
      extras: "选项",
      items: "项目",
      terms: "条款",
      summary: "摘要"
    }
  },
  validation: {
    required: "必须输入",
    email: "无效电子邮件地址",
    minLength: "必须为 {min} 字符或更长",
    maxLength: "必须是 {max} 个字符或更短",
    specificLengthDigits: "必须是 {number} 位数",
    sameAsEmail: "电子邮件不匹配",
    sameAsPassword: "密码不匹配",
    maxValue: "必须小于 {max}",
    minValue: "必须大于 {min}",
    toAfterFrom: "结束时间必须晚于开始时间",
    isUniquePhone: "此电话已在此处使用",
    isUniqueTitle: "此标题已在此处使用",
    isUniqueSymbol: "此符号已在此处使用",
    url: "必须是有效的 URL",
    mustBeInFuture: "未来必须有时间",
    numeric: "必须是数字",
    dataNotValid: "输入的数据无效"
  },
  hours: {
    default: "默认值",
    sunday: "星期日",
    monday: "星期一",
    tuesday: "星期二",
    wednesday: "星期三",
    thursday: "星期四",
    friday: "星期五",
    saturday: "星期六"
  },
  warnings: {
    hot: "非常热",
    organic: "有机",
    special: "特别",
    spicy: "辣味",
    vegan: "素食",
    longPreparation: "长时间准备",
    glutenFree: "无麸质",
    crustacean: "甲壳类",
    fish: "鱼类",
    egg: "蛋类",
    gluten: "面筋",
    celery: "芹菜",
    nuts: "坚果",
    milk: "牛奶",
    peanuts: "花生",
    sesame: "芝麻",
    molluscs: "软体动物",
    lupins: "羽扇豆",
    mustard: "芥菜",
    mushroom: "蘑菇",
    corn: "玉米",
    soya: "大豆",
    sulphite: "亚硫酸盐"
  },
  imageCropper: {
    cropperTitle: "上传图片",
    cropperSubTitle: "如果太大，请尝试调整滑块和图像裁剪尺寸",
    imageSize: "当前图像大小：",
    imageQuality: "图像质量：",
    maxImageSize: "最大图像尺寸：",
    calcOnCrop: "[裁剪]",
    replaceImageBtn: "添加/替换",
    cropImageBtn: "保存",
    reduceSizeHint: "通过更改图像质量值减小图像尺寸：0.01 至 1.0。"
  },
  explanations: {
    supervisorStep01___Wizard: "姓名和电子邮件将作为主管'登录管理仪表板的用户名",
    supervisorStep01_Business: "姓名和电子邮件将是主管'登录管理控制面板的用户名",
    supervisorStep02___Wizard: "手机号码将作为主管登录 PREP-APP 的用户名（稍后提供更多信息）",
    supervisorStep02_Business: "手机号码将是主管登录 PREP-APP 的用户名（转到：仪表板 > 应用程序）",
    tableStep01___Wizard: "每张桌子必须有名称和座位数",
    tableStep01_Business: "每张桌子必须有名称和座位数",
    tableStep02___Wizard: "为这张桌子指定一种颜色",
    tableStep02_Business: "为这张桌子指定颜色",
    tableStep03___Wizard: "设置表格代码",
    tableStep03_Business: "设置表格代码",
    waiterStep01___Wizard: "姓名和手机号码将作为服务员登录服务员应用程序的用户名",
    waiterStep01_Business: "姓名和手机号码将作为服务员登录服务员应用程序的用户名",
    waiterStep02___Wizard: "所有桌位以及从这些桌位发出的订单都将标上所选服务员的颜色，以便于送达。",
    waiterStep02_Business: "所有桌位以及从这些桌位发送的订单都将标注此选定服务员的颜色，以方便送达。",
    waiterStep03___Wizard: "(可选）客户可以在下单后和付款页面上查看服务员的照片和姓名，以便留下小费。",
    waiterStep03_Business: "(可选）客户可以在下单后查看服务员的照片和姓名，也可以在付款页面上查看服务员的照片和姓名，以便留下小费。",
    waiterStep04___Wizard: "检查哪些餐桌将分配给该服务员。",
    waiterStep04_Business: "查看哪些餐桌将分配给该服务员。",
    terminalStep01___Wizard: "给定的名字尽量简短扼要",
    terminalStep01_Business: "所给名字尽量简短扼要",
    terminalStep02___Wizard: "给定的颜色将为 PREP-APP 中的所有订购项目着色，以便于区分其指定的工作站",
    terminalStep02_Business: "给定的颜色将给 PREP-APP 中所有已排序的项目着色，以便于区分其所分配的工作站",
    staffStep01___Wizard: "姓名和手机号码将作为工作人员登录 PREP-APP 的用户名",
    staffStep01_Business: "姓名和手机号码将作为工作人员登录 PREP-APP 的用户名。",
    staffStep02___Wizard: "给定的颜色将为该工作人员在 PREP-APP 中为客户制作的所有订购物品着色",
    staffStep02_Business: "给定的颜色将使该工作人员在 PREP-APP 中为客户制作的所有订购项目着色",
    staffStep03___Wizard: "指定角色和准备站",
    staffStep03_Business: "指定角色和准备站",
    staffStep04___Wizard: "照片可有可无，但很有用",
    staffStep04_Business: "照片可有可无，但很有用",
    categoryStep01___Wizard: "每个类别必须包括名称和默认背景颜色",
    categoryStep01_Business: "每个类别必须包含名称和默认背景色",
    categoryStep02___Wizard: "您可以上传自己的图标或图像，也可以从提供的图标或图像中选择。图标和图像可随时更改和更新",
    categoryStep02_Business: "您可以上传图标或图像，也可以从提供的图标或图像中选择。图标和图像可随时更改和更新",
    menuStep01___Wizard: "每个菜单必须包括名称、开始和结束时间。设置项目时，可以将它们链接到不同的菜单。不过，只有在创建业务后才能为不同菜单上的项目设置不同的价格。如果菜单在其指定的开始和结束时间内处于活动状态，则该菜单上的项目所设置的价格将覆盖其常规价格。",
    menuStep01_Business: "每个菜单必须包括名称、开始和结束时间。不要忘记进入 \"项目\"，将相应的项目连接到该菜单。如果菜单在其指定的开始和结束时间内处于活动状态，则该菜单上项目的价格将优先于其正常价格。默认情况下，所有项目都会自动添加到新菜单，直到您在 \"项目 \"部分手动取消选择。",
    menuStep02___Wizard: "指定的颜色只是为了在图表上直观地显示菜单。避免菜单重叠。",
    menuStep02_Business: "指定的颜色仅用于图表上菜单的视觉呈现。避免菜单重叠。",
    optionsDoThis___Wizard: "为每个选项提供一个标题。\n如果是免费的，请选中该复选框；如果是设置价格的，请取消选中该复选框。稍后，您就可以将您的商品链接到该选项。",
    optionsDoThis_Business: "为每个选项提供一个标题。\n如果是免费的，请选中该复选框，如果是设置价格的，请取消选中该复选框。\n记住要将此选项与所需的相关项目链接起来。",
    groupOptionsStep01___Wizard: "为组选项提供一个标题。",
    groupOptionsStep01_Business: "为组选项提供标题。",
    groupOptionsStep02___Wizard: "如果是免费的，请选中该复选框，如果是定价的，请取消选中该复选框。\n默认情况下，客户会自动选择最上面的选项。拖放可重新排列组内所有选项的顺序。",
    groupOptionsStep02_Business: "如果是免费的，则选中该复选框，如果是设置价格的，则取消选中该复选框。\n默认情况下，客户'会自动选择最上面的选项。拖放可重新排列组内所有选项的顺序。",
    itemsStep01___Wizard: "名称要简短动听。Prep Code 是 PREP APP 中使用的缩写代码（可选）。",
    itemsStep01_Business: "名称要简短动听。Prep Code 是 PREP APP 中使用的缩写代码（可选）。",
    itemsStep02___Wizard: "定价：指定列出的价格，并在创建业务后返回此处设置销售价格和菜单价格（如有必要）。",
    itemsStep02_Business: "记住一条规则：菜单价格强于列出价格，销售价格高于两者。",
    itemsStep03___Wizard: "创建业务后，选项部分将打开。",
    itemsStep03_Business: "选择与此项目相关的选项和组选项（常规选项和组选项允许多选）",
    itemsStep04___Wizard: "这是可选项，但很重要！请附上过敏原图标（如芝麻、坚果等）和标签（如素食、长时间准备、厨师特制等）。",
    itemsStep04_Business: "这是可选项，但很重要！请附上过敏原图标（如芝麻、坚果等）和标签（如素食、长时间准备、厨师特制等）。",
    itemsStep05___Wizard: "项目描述和图片上传",
    itemsStep05_Business: "项目描述和图片上传",
    itemsStep06___Wizard: "将此项目链接到至少一个类别（必填）和一个准备站（选填）",
    itemsStep06_Business: "将此项目链接到至少一个类别（必填）和一个预备站（选填）",
    itemsStep07___Wizard: "创建业务后，将打开 \"特殊偏好 \"部分。",
    itemsStep07_Business: "首选项"
  }
}
}
