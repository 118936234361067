const objectName = 'Business'

export default function () {
  return {
    objectName: objectName,
    all: [],
    current: false,
    wizardNew: false,
    saving: false,
    browsing: false,
    loading: {},
    overviews: {},
    overviewLoading: {},
    supervisorsLoading: {},
    loggedSupervisors: {},
    chefsLoading: {},
    loggedChefs: {},
    waitersLoading: {},
    loggedWaiters: {},
    liveCredits: 0,
    purchases: [],
    subscription: null,
    loadingSubscription: true
  }
}
