import * as templateGetters from 'src/store/_parseObjectTemplate/getters'
import * as templateMutations from 'src/store/_parseObjectTemplate/mutations'

import state from './state'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'

export default {
  namespaced: true,
  state,
  getters: { ...templateGetters, ...getters },
  mutations: { ...templateMutations, ...mutations },
  actions
}
