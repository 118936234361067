import wizardRoutes from 'src/router/routes/wizard'
import businessRoutes from 'src/router/routes/business'
import admin from 'src/router/routes/admin'

const routes = [
  {
    path: '/',
    component: () => import('layouts/Main.vue'),
    children: [
      {
        path: '',
        name: 'businesses.index',
        component: () => import('pages/Index.vue')
      },
      {
        path: 'terms',
        name: 'terms',
        component: () => import('pages/Index.vue')
      },
      businessRoutes,
      wizardRoutes,
      admin
    ]
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    redirect: '/'
  }
]

export default routes
