export function GET_PAYMENTS (state) {
  return state.payments
}

export function GET_RESTAURANT_ORDERS (state) {
  return state.restaurantOrders
}

export function GET_RATINGS (state) {
  return state.ratings
}
