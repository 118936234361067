import { Parse } from 'src/boot/parse'
import { getParseObjectAttrs } from 'src/store/_helpers'
import { LocalStorage } from 'quasar'

const defaultUser = {
  username: '',
  id: '',
  country: '',
  business: '',
  email: '',
  phone: '',
  createdAt: '',
  emailVerified2: null,
  is_admin: null,
  language: null,
  registered_using: '',
  updatedAt: '',
  name: ''
}

export async function getUsersByBusinessId({ commit, state}, businessId ) {
  commit('LOADING', true)

  const Business = Parse.Object.extend('Business')

  const business = new Business()
  business.id = businessId
  const query = new Parse.Query(Parse.User)

  query.equalTo('business', business)

  const users = await query.find({sessionToken: LocalStorage.getItem('auth.token')})

  const parsedUsers = []
  if(users.length > 0) {
    users.forEach(user => {
      parsedUsers.push(getParseObjectAttrs(user, defaultUser ))
    })

    commit('SAVE_ALL', parsedUsers)
  }
  commit('LOADING', false)

  return parsedUsers
}

export async function getUserByUsername({commit, state}, username) {
  commit('LOADING', true)
  const query = new Parse.Query(Parse.User)
  query.equalTo('username', username)
  const user = await query.find({sessionToken: LocalStorage.getItem('auth.token')})
  commit('LOADING', false)

  return user[0]
}

export async function create({ commit, state, dispatch }, payload ) {
  commit('SAVING', true)
  const user = new Parse.User(payload)
  await user.signUp()
  const userId = user.id

  await dispatch('auth/refresh', null, { root: true })
  
  commit('SAVING', false)

  return userId
}
