import { Parse } from 'src/boot/parse'
import { getParseObjectAttrs, getBusinessPointer, storeObjectToParse, getBusinessObject } from 'src/store/_helpers'
import { LocalStorage } from 'quasar'

export function create({ commit, dispatch, state }, businessId ) {
  commit('SAVING', true)
  const object = {
    business: businessId,
    step: 0,
    finished: false
  }
  return new Promise(async (resolve, reject) => {
    const result = await storeObjectToParse(dispatch, object , state.objectName)
    commit('SET_STEP', result.get('step'))
    commit('SET_FINISHED', result.get('finished'))
    commit('SET_ID', result.id || result.objectId)
    commit('SAVING', false)
    resolve(result)
  })
}

export function get({ commit, dispatch, state }, businessId) {
  commit('LOADING', true)
  return new Promise(async (resolve, reject) => {
    const response = await getBusinessObject(businessId, state.objectName, 0, 1)

    if(response[0]) {

      commit('SET_STEP', response[0].get('step'))
      commit('SET_FINISHED', response[0].get('finished'))
      commit('SET_ID', response[0].id || response[0].objectId)
 
      commit('LOADING', false)
      resolve(response[0])
    } else {
      resolve(dispatch('create', businessId))
    }
    commit('LOADING', false)
  })
}

export async function updateStep({ commit, dispatch, state }, currentStep) {
  commit('SAVING', true)
  const onboarding = {
    step: currentStep,
    id: state.objectId
  }
  const result = await storeObjectToParse(dispatch, onboarding, state.objectName)

  if(result) {
    commit('SET_STEP', result.get('step'))
  } 
  commit('SAVING', false)
  return result
}

export async function finish({ commit, dispatch, state }, status) {
  commit('SAVING', true)
  const onboarding = {
    finished: status,
    id: state.objectId
  }
  const result = await storeObjectToParse(dispatch, onboarding, state.objectName)

  if(result) {
    commit('SET_FINISHED', result.get('finished'))
  } 
  commit('SAVING', false)
  return result
}
