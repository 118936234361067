import ItemImages from 'src/store/dreamdiner/data/demoitemimages'

export default {








	general: {
    yes: "Ja",
    no: "Nein",
    yesNo: "Ja oder Nein",
    add: "Fügen Sie  hinzu",
    addCredits: "Kredite hinzufügen",
    color: "Zugewiesene Farbe",
    colorBg: "Hintergrundfarbe",
    assigned_icon: "Zugewiesenes Symbol",
    assigned_items: "Zugewiesene Elemente",
    back: "Zurück",
    confirm: "Bestätigen Sie",
    continue: "Weiter",
    cancel: "Abbrechen",
    clear: "Löschen",
    close: "Schließen",
    day: "Tag",
    delete: "Löschen",
    duplicate: "Duplizieren",
    ends: "Endet:",
    finish: "Fertigstellung",
    from: "Von",
    loading: "Laden...",
    mobile: "Handy-Nummer",
    name: "Name",
    next: "Nächste",
    none: "Keine",
    price: "Preis",
    verify: "Überprüfen Sie",
    optional: "Optional",
    save: "Speichern",
    send: "Senden",
    restart: "Neustart",
    resetAll: "Alles zurücksetzen",
    start: "Start",
    select: "Wählen Sie",
    selectDate: "Datum wählen",
    starts: "Beginnt:",
    title: "Titel",
    to: "An",
    update: "Aktualisierung",
    learnMore: "Mehr erfahren",
    assigned_itemsCount: "Anzahl der Artikel: {count}",
    no_results: "Keine Ergebnisse",
    restore: "Wiederherstellen",
    noItemsAssigned: "Keine zugewiesenen Elemente gefunden",
    chooseSearchMethod: "Suchmethode wählen",
    chooseSearchMethodFull: "Enthält das Wort...",
    chooseSearchMethodStart: "Beginnt mit",
    searchItem: "Suchbegriff",
    saveInfo: "Speichern Sie Ihre Änderungen",
    resetInfo: "Änderungen zurücksetzen",
    overviewBtnSettings: "Business Info & Einstellungen",
    overviewBtnActivity: "Geschäftsaktivität Stunden",
    accordionExpansionLearnMoreTitle: "Lesen Sie mehr über diesen Abschnitt",
    active: "Aktiv",
    step01: "Schritt 1",
    step02: "Schritt 2",
    step03: "Schritt 3",
    step04: "Schritt 4",
    step05: "Schritt 5",
    step06: "Schritt 6",
    step07: "Schritt 7",
    option_01: "Option 1",
    option_02: "Option 2",
    doThis: "Tun Sie dies"
  },
  app: {
    headsup: "Aufgepasst!",
    errorMessage: "Etwas ist schief gelaufen...",
    errorSaveMessage: "Fehler beim Speichern: Eingabedaten sind nicht gültig...",
    never: "[Noch keine Daten]",
    empty: "Keine Ergebnisse",
    autocomplete: "Wählen Sie oder beginnen Sie zu tippen",
    phonePlaceholder: "Nur Ziffern",
    phoneHint: "Nur Zahlen (0-9), keine Bindestriche (-), keine Leerzeichen, keine Punkte (.), keine / ( ), max. 20 Zeichen.",
    verified: "Geprüft",
    fail: "Ups. Etwas ist schief gelaufen",
    registeredSupervisorMobileExists: "Ein registrierter Supervisor mit dieser Handynummer existiert bereits",
    registeredWaiterMobileExists: "Ein registrierter Kellner mit dieser Handynummer ist bereits vorhanden",
    registeredStaffMobileExists: "Ein registriertes Personalmitglied mit dieser Handynummer existiert bereits",
    itemSpecialPriceAlert: "Ändern Sie das Ablaufdatum des Sonderpreises oder löschen Sie das Datum. Dann versuchen Sie es erneut.",
    itemInvalidParams: "Einige Daten sind ungültig oder fehlen. Bitte nochmals prüfen",
    days: {
      everyday: "Jeden Tag",
      sunday: "Sonntag",
      monday: "Montag",
      tuesday: "Dienstag",
      wednesday: "Mittwoch",
      thursday: "Donnerstag",
      friday: "Freitag",
      saturday: "Samstag"
    },
    localeSelect: "Sprache auswählen",
    timezoneSelect: "Zeitzone wählen",
    countrySelect: "Land auswählen",
    version: "Version: {version}",
    deleteConfirm: "Sind Sie sicher, dass Sie diesen {object} löschen wollen?",
    unsavedChanges: "Dieses Formular schließen? Alle vorgenommenen Änderungen werden nicht gespeichert."
  },
  auth: {
    emailVerification: {
      title: "Bitte verifizieren Sie Ihre E-Mail",
      subtitle: "Geben Sie Ihre E-Mail-Adresse unten ein, und wir senden Ihnen eine E-Mail mit einem Bestätigungslink. Klicken Sie auf den Link in der E-Mail, um fortzufahren.",
      resendTitle: "Sollen wir Ihnen den Verifizierungslink erneut zusenden?",
      resendCta: "Link erneut senden",
      resent: "Verifizierungs-E-Mail erfolgreich gesendet",
      success: "Sie haben den E-Mail-Verifizierungsprozess abgeschlossen."
    },
    popup: {},
    login: {
      title: "Willkommen bei DreamDiner",
      titleWithName: "Willkommen bei DreamDiner {name}!",
      subtitle: "Logge dich ein, um dein Erlebnis zu beginnen",
      confirm: "Melde dich an",
      noUser: "Bitte melden Sie sich zuerst an, um fortzufahren",
      unverified: "Bitte verifizieren Sie Ihre E-Mail-Adresse, um fortzufahren.\nGesendet an: {email}. Prüfen Sie ggf. den Spam-Ordner.",
      fail: "Ungültiger Benutzername/Passwort",
      success: "Erfolgreich eingeloggt",
      email: "Geben Sie Ihre E-Mail Adresse ein",
      password: "Geben Sie Ihr Passwort ein",
      forgot: "Ich habe mein Passwort vergessen",
      register: "Ich möchte mich anmelden",
      resendVerification: "Bestätigungs-E-Mail erneut senden",
      changeUser: "Nicht {user}?"
    },
    forgot: {
      title: "Haben Sie Ihr Passwort vergessen?",
      subtitle: "Geben Sie Ihre E-Mail-Adresse ein und überprüfen Sie dann Ihren Posteingang (oder SPAM-Ordner!), um einen neuen Link zum Zurücksetzen des Passworts zu erhalten",
      login: "Ich habe mein Passwort vergessen! Zurück zur Anmeldung",
      register: "Zurück zur Anmeldung",
      confirm: "Link senden",
      success: "Link zurücksetzen erfolgreich gesendet",
      profileResetPassword: "Passwort zurücksetzen",
      profileResetPasswordSent: "Reset-Link an Ihre E-Mail gesendet",
      errors: {
        205: "Kein Benutzer gefunden."
      }
    },
    register: {
      title: "Willkommen bei DreamDiner",
      subtitle: "Melde dich an, um dein Erlebnis zu beginnen",
      email: "Gib deine E-Mail-Adresse ein",
      emailConfirm: "Geben Sie Ihre E-Mail-Adresse erneut ein",
      phone: "Geben Sie eine Handynummer ein",
      password: "Geben Sie Ihr Passwort ein",
      passwordConfirm: "Geben Sie Ihr Passwort erneut ein",
      name: "Wie lautet Ihr Name?",
      existingUser: "Ein Benutzer mit Ihrer E-Mail oder Telefonnummer hat sich bereits angemeldet",
      success: "Willkommen bei DreamDiner! Überprüfe deinen Posteingang, um deine E-Mail-Adresse zu verifizieren und fortzufahren.",
      resend: "Verifizierungscode erneut senden ({counter})",
      fail: "Ups. Etwas ist schief gelaufen",
      confirm: "Anmelden",
      login: "Ich habe mich bereits angemeldet",
      otpTitle: "Geben Sie Ihren Code ein",
      otpSubtitle: "Wir haben einen Verifizierungscode an {email} gesendet",
      incorrectOTP: "Entschuldigung. Falscher Code. Bitte versuchen Sie es erneut oder senden Sie erneut"
    }
  },
  autocompleteInput: {
    defaultPlaceholder: "Fang an zu tippen..."
  },
  tooltips: {
    infoAdminCode: "Dies ist kein Login-Passwort! Es wird empfohlen, diesen Code täglich oder wöchentlich zu ändern",
    tableMasterCode: "Standard-Tabellencode, wenn der Administrator keinen spezifischen Code angegeben hat",
    infoBusinessWebsite: "Hauptsächlich für Kunden, damit sie leicht zu Ihrer Website navigieren können. Kopieren Sie den URL-Link genau wie in Ihrem Browser angezeigt",
    registrationPhone: "Erforderlich für die Anmeldung in der mobilen App",
    waitersAssignTables: "Auswahl mehrerer Tabellen möglich",
    itemKitchens: "Dieses Element wird mit der ausgewählten Prep Station verknüpft",
    itemCategories: "Dieser Artikel wird immer unter den ausgewählten Kategorien angezeigt. Mehrere Kategorien sind erlaubt. Z.B. FischBurger kann unter \"Burger\" und unter \"Meeresfrüchte\" stehen;",
    itemLabels: "Eine allgemeine Klassifizierung dieses Artikels. Mehrfachauswahlen erlaubt",
    itemAllergens: "Mehrfachauswahlen erlaubt",
    itemSymbol: "Ein 3-Buchstaben-Code für die Verwendung der PREP-App",
    categoryName: "Vorzugsweise ein Ein-Wort-Titel",
    menuPrice: "Verknüpfen Sie diesen Artikel mit Menüs und legen Sie für jedes einen speziellen Preis fest. Wenn nicht festgelegt, wird der Standardpreis angegeben",
    settingsCog: "Alle Ihre Geschäftsinformationen und Einstellungen",
    activityHours: "Ihr Geschäft Tägliche und wöchentliche Aktivitätsstunden",
    businessStatusLive: "Wenn versteckt, dann können Kunden das Geschäft nur über einen QR-Code oder einen direkten URL-Link erreichen",
    addTemplateBtn: "Klicken Sie auf die Schaltfläche \"Hinzufügen\" in dem Formular, das sich rechts öffnet",
    colorWaiter: "Alle Artikel, die bei diesem Kellner bestellt werden, haben die Farbe dieses Kellners",
    colorTerminal: "Alle Artikel, die sich auf diese Station beziehen, werden die Farbe dieser Station haben",
    colorStaff: "Weisen Sie diesem Mitarbeiter eine Farbe zu",
    colorMenu: "Weisen Sie diesem Menü eine Farbe zu, klicken Sie auf ADD und beobachten Sie die Grafik auf der linken Seite",
    addBtnHint: "Klicken Sie hier, um Ihre Liste zu ergänzen",
    itemCategoryAlert: "Muss mit einer oder mehreren Kategorien verknüpft sein",
    categories: {
      cat_100: "Bier",
      cat_101: "Bier",
      cat_102: "Bierzapfanlage",
      cat_103: "Kaltes Getränk",
      cat_104: "Getränk",
      cat_105: "Tee",
      cat_106: "Kaffee",
      cat_107: "Wein",
      cat_108: "Champagner",
      cat_109: "Alkohol",
      cat_110: "Croissants",
      cat_111: "Brot",
      cat_112: "Brezeln",
      cat_113: "Müsli",
      cat_114: "Eier",
      cat_115: "Frühstück",
      cat_116: "Salat",
      cat_117: "Tomate",
      cat_118: "Suppe",
      cat_119: "ToGo",
      cat_120: "Pizza",
      cat_121: "Pizza",
      cat_122: "Pommes frites",
      cat_123: "Sandwich",
      cat_124: "Sushi",
      cat_125: "Sushi",
      cat_126: "Wrap",
      cat_127: "Nudeln",
      cat_128: "Reis",
      cat_129: "Kinder",
      cat_130: "Burger",
      cat_131: "Grill",
      cat_132: "Fisch",
      cat_133: "Fisch",
      cat_134: "Huhn",
      cat_135: "Kebab",
      cat_136: "Barbecue",
      cat_137: "Hotdog",
      cat_138: "Meeresfrüchte",
      cat_139: "Zum Mitnehmen",
      cat_140: "Obst",
      cat_141: "Torte",
      cat_142: "Torte",
      cat_143: "Speiseeis",
      cat_144: "Speiseeis",
      cat_145: "Eisbecher",
      cat_146: "Muffin",
      cat_147: "Bonbon",
      cat_148: "Nachspeise",
      cat_149: "Waffeln",
      cat_150: "Abendessen",
      cat_151: "Mahlzeit",
      cat_152: "Messer und Gabel",
      cat_153: "Cloche",
      cat_154: "Chefkoch Spezial",
      cat_155: "Restaurant",
      cat_156: "Mittagessen",
      cat_157: "Tablett",
      cat_158: "Cafeteria",
      cat_159: "Besteck"
    }
  },
  business: {
    adminProfile: {
      profileTitle: "Benutzerprofil",
      profileSubTitle: "Stellen Sie hier Ihre persönlichen Informationen ein",
      name: "Name",
      nameHint: "Geben Sie hier Ihren Namen ein",
      mobile: "Handy",
      mobileExplanation: "Ihre Handynummer wird für die Anmeldung bei der Admin App verwendet",
      userPhoto: "Foto",
      userPhotoExplanation: "Mach ein gutes Foto :)",
      changePassword: "Passwort ändern",
      changePassword2: "Klicken Sie auf die Schaltfläche Zurücksetzen, um Ihr Passwort zu ändern, und überprüfen Sie dann Ihre eingehenden E-Mails. Wenn Sie keine erhalten, müssen Sie möglicherweise auch den SPAM-Ordner überprüfen"
    },
    business: {
      title: "Geschäftlicher Name",
      titlePlaceholder: "Ihr Firmenname",
      titleDescriptionPlaceholder: "Ein anderes DreamDiner-Geschäft",
      titleWebsitePlaceholder: "https://www.your-business.com",
      titleEn: "Englischer Geschäftsname",
      titleEnPlaceholder: "Ihr englischer Firmenname",
      titleEnExplanation: "Empfangene SMS werden unter diesem Absendernamen stehen",
      statusDemo: "DEMO Business",
      statusLive: "Status: LIVE",
      statusHidden: "Status: Versteckt",
      switchLive: "Eingestellt auf LIVE",
      switchHidden: "LIVE",
      email: "Geschäftliche E-Mail",
      emailSubtitle: "Ihre Geschäftskontakt-E-Mail ist für die Systemkommunikation unerlässlich und hält Sie mit wichtigen Hinweisen und Änderungen, neuen Funktionen, spannenden Anleitungsvideos und vielem mehr auf dem Laufenden.",
      emailPlaceholder: "Ihre geschäftliche E-Mail",
      emailVerifyText: "OK. Ihre geschäftliche E-Mail ist dieselbe wie Ihre Benutzer-E-Mail",
      emailVerifyBtn: "Überprüfen Sie jetzt",
      useMyEmail: "Meine Login-E-Mail verwenden",
      adminPassword: "Admin-Passcode",
      adminPasswordSubtitle: "Dieser Passcode wird als Sicherheitsmaßnahme verwendet, damit nur autorisierte Benutzer mit dem Passwort verschiedene Aufgaben ausführen können, wie z. B. Rückerstattungen ausstellen, Bestellungen löschen usw. Es kann täglich oder wöchentlich geändert werden.",
      enterNewPassword: "Neues Passwort eingeben",
      pin: "Client Master Verification Code",
      pinSubtitle: "Verwenden Sie diesen Code, um einem Kunden Zugang zu gewähren, der aus irgendeinem Grund nicht mobil verifiziert werden kann. Verwenden Sie diesen Code nicht regelmäßig und ändern Sie ihn häufig. Der Standardwert ist 999",
      pinPlaceholder: "Nur Ziffern",
      website: "Website Ihres Unternehmens",
      websitePlaceholder: "https://...",
      timezone: "Zeitzone",
      timezoneSubtitle: "Es könnte eine Anpassung an die Sommer- und Winterzeit erforderlich sein",
      country: "Land",
      currency: "Währung",
      tagline: "Tagline des Unternehmens",
      taglineExplanation: "Eine prägnante Beschreibung, die in der Kunden-App unter Ihrem Firmennamen erscheint",
      hours: "Geschäftszeiten",
      currentTime: "Aktuelle Geschäftszeit:",
      adminPasswordPlaceholder: "mindestens 6 Ziffern, nur Ziffern",
      adminPasswordRepeatPlaceholder: "Wiederholung des Passcodes",
      businessLogo: "Geschäftslogo",
      businessLogoSubtitle: "Ihr Logo wird in der Client App und an vielen anderen Stellen angezeigt. Wenn Sie es leer lassen, zeigt das System das DreamDiner-Logo an. Das Geschäftslogo wird auch das Standardbild in allen Artikeln sein, die kein Foto oder ein hochgeladenes Bild haben",
      logoSelectDemoBtn: "Demo-Logo auswählen",
      logoSelectSampleBtn: "Wählen Sie ein temporäres Bild",
      logoSelectOr: "Oder:",
      logoSelectTitle: "Wählen Sie aus einem vorgegebenen Demo-Logo",
      logoSelectSubtitle: "Später werden Sie es durch Ihr Logo ersetzen",
      openinghoursClosed: "Geschlossen",
      openinghoursAllDay: "24 Stunden.",
      addTimeSegment: "Zeitsegment hinzufügen",
      addTimeSegmentExplan: "Jeder Tag muss mindestens ein Zeitsegment haben. Bitte überschneiden Sie sich nicht und halten Sie die Zeitsegmente so, dass das erste das früheste des Tages ist.\nUm ein frühes Segment hinzuzufügen, löschen Sie die Tagessegmente und bearbeiten Sie das erste so, dass es als frühestes Segment festgelegt wird.",
      updateMessageSnackBar: "Änderungen erfolgreich aktualisiert",
      businessColorTitle: "Business Theme Farbe",
      businessColorSubtitle: "Ihr bevorzugtes Farbthema, das am besten zu Ihrem Unternehmen und Ihrem Logo passt",
      businessLocationTitle: "Standort des Unternehmens",
      businessLocationSubtitle: "Erforderlich, damit Kunden in der Nähe Ihr Geschäft in der Kunden-App leicht finden können",
      businessLatitude: "Breitengrad:",
      businessLongitude: "Längengrad:",
      getLocationFromDevice: "Standort für mich festlegen",
      getLocationAccessDenied: "Überprüfen Sie Ihre Browsereinstellungen und aktivieren Sie den Standortzugriff",
      noTrackerEnableTitle: "Fortschrittsverfolgung des Kunden (in der Regel für Nicht-Restaurantbetriebe)",
      noTrackerEnableDesc: "Die Kunden-App verfügt über eine Tracking-Optionskomponente für den Auftragsvorbereitungsprozess (Bestellt, in Arbeit, Fertig, Geliefert). Wenn Ihr Unternehmen keinen Vorbereitungsprozess abwickelt oder Sie nicht möchten, dass Ihre Kunden nach der Ausführung ihrer Bestellung diesen Status-Tab zu sehen bekommen, können Sie diese Komponente ausblenden",
      noTrackerSwitchEnabled: "Die Kunden werden den Vorbereitungsprozess verfolgen (Restaurant-Modus)",
      noTrackerSwitchDisabled: "Die Kunden-App zeigt die Registerkarte für den Zubereitungsstatus nicht an",
      businessEatInTitle: "Eat-In Optionen",
      EatInSwitchEnabled: "Schaltfläche \"Eat-In\" ist in der Client App sichtbar",
      EatInSwitchDisabled: "Eat-In ist jetzt ausgeblendet",
      businessAllowEatInSubtitle: "Wenn Sie zum Beispiel ein Geschäft haben, das nur Take-Away und Delivery anbietet, können Sie diese Schaltfläche in der Client App ausblenden",
      EatInMinimumTitle: "Mindestbestellmenge für Eat-In:",
      EatInMinimum: "Legen Sie einen Preiswert fest",
      businessEatInMinimumSubtitle: "Legen Sie den Mindestpreis für die Annahme von Eat-In-Bestellungen fest. Der Wert muss >6",
      businessTakeAwayTitle: "Take-Away-Optionen",
      TakeAwaySwitchEnabled: "Die Schaltfläche Take-Away ist in der Client App sichtbar",
      TakeAwaySwitchDisabled: "Take-Away ist jetzt versteckt",
      businessAllowTakeAwaySubtitle: "Wenn aktiviert (grün) - dann ist die Schaltfläche \"Take-Away\" in der Client-App entsprechend der definierten Start- und Endzeit aktiviert",
      takeAwayMinimumTitle: "Minimum für die Mitnahmebestellung:",
      takeAwayMinimum: "Setzen Sie einen Preiswert",
      businessDeliveryTitle: "Lieferoptionen",
      DeliverySwitchEnabled: "Schaltfläche Lieferung ist in der Client App sichtbar",
      DeliverySwitchDisabled: "Zustellung ist jetzt versteckt",
      businessAllowDeliverySubtitle: "Wenn aktiviert (grün) - dann wird die Schaltfläche \"Zustellung\" in der Kunden-App entsprechend der definierten Start- und Endzeit aktiviert",
      deliveryMinimumTitle: "Lieferauftrag Minimum:",
      deliveryMinimum: "Setzen Sie einen Preiswert",
      tablePlannerTitle: "Tischplanungseinstellungen",
      tablePlannerDesc: "Einige grundlegende Einstellungen für Ihren Table Planner",
      tablePlannerEnableWeb: "Web Planner einschalten",
      tablePlannerLinkBtn: "Table Planner in neuer Registerkarte öffnen",
      tablePlannerEnableWebDesc: "Mit dem Web Planner können Ihre Kunden direkt von ihrem Desktop oder Handy aus eine Reservierung vornehmen, und Sie sehen diese Reservierungen sofort auf dem Table Planner App Mainboard",
      EnableWebSwitchEnabled: "Kunden können jetzt selbst Reservierungen in Web Planner vornehmen",
      EnableWebSwitchDisabled: "Ihr Unternehmen wird in der Web Planner App nicht mehr gesucht",
      tablePlannerFirstDay: "Bevorzugten ersten Tag der Woche einstellen",
      tablePlannerFirstDaySun: "Sonntag",
      tablePlannerFirstDayMon: "Montag",
      tablePlannerFirstDayDesc: "Bestimmt mainboard's ersten Tag",
      tablePlannerIntervals: "Mainboard-Intervalle",
      tablePlannerIntervalsDesc: "Setzt Mainboard's Spaltenintervalle",
      tablePlannerMainboardDefaultView: "Hauptplatine Standardansicht",
      tablePlannerMainboardDefaultViewDesc: "Wenn das Mainboard geladen wird, welche Ansicht standardmäßig angezeigt werden soll",
      tablePlannerReservationDefaultTime: "Reservierung Standardzeit",
      tablePlannerReservationDefaultTimeDesc: "Bestimmt standardmäßig die Endzeit der Reservierung",
      paymentsPaymeSettingsTitle: "PayMe Einstellungen",
      paymentsPaymeSettingsTitleDesc: "Einstellungen, um Zahlungen zu akzeptieren",
      paymentsPaymeSettingsIdPlaceholder: "ID hier eingeben",
      paymentsPaymeSettingsIdTooltip: "Diese ID wird generiert, nachdem Sie für Zahlungen unterschrieben haben",
      paymentsAcceptApplePayTitle: "Apple Pay akzeptieren",
      paymentsSellerIdTitle: "Verkäufer-ID",
      paymentsSellerIdTitleDesc: "Ihre PayMe Verkäufer-ID",
      paymentsSellerIdDebug: "Verkäufer-ID Debug",
      paymentsAcceptBitTitle: "Bit-Zahlungen akzeptieren",
      paymentsAcceptBitTitleDesc: "Ermöglichen Sie Ihren Kunden, mit ihrer Bit App zu bezahlen.\n Aktivieren Sie zunächst die Bit App in Ihrem Payme Dashboard (Payme Sidebar > Admin > Apps Marketplace); Aktivieren Sie dann diesen Schalter.\n Hinweis: 0,3 NIS werden zu jeder Transaktion hinzugefügt",
      paymentsBitSwitchEnabled: "Bit-Zahlungen für Ihre Kunden aktiviert",
      paymentsBitSwitchDisabled: "Bit-Zahlungen sind deaktiviert",
      paymentsAcceptIcountTitle: "Aktivieren Sie iCount Invoices für Ihre Kunden",
      paymentsAcceptIcountTitleDesc: "Ermöglichen Sie Ihren Kunden das Herunterladen ihrer Einkaufsrechnungen.\n Aktivieren Sie zunächst die iCount App in Ihrem Payme Dashboard (Payme Sidebar > Admin > Apps Marketplace); Aktivieren Sie dann diesen Schalter.\n Hinweis: 0,35 NIS werden zu jeder Transaktion hinzugefügt",
      paymentsIcountSwitchEnabled: "iCount-Rechnungen sind für Ihre Kunden aktiviert",
      paymentsIcountSwitchDisabled: "iCount-Rechnungen sind deaktiviert"
    },
    plans: {
      plansTitle: "Verfügbare Business-Pläne",
      plansSubTitle: "Wählen Sie das Beste für Ihr Unternehmen",
      plansItalTitle: "Die Tarife ändern sich automatisch, sobald sie wie beschrieben eingestellt sind",
      plansBtnToSetPayments: "Stellen Sie hier Ihr Zahlungsgateway ein",
      learnMoreBtnSite: "https://dreamdiner.io/start-he/#plans",
      plansStep1Chip: "Schritt 1",
      plansStep2Chip: "Schritt 2",
      plansStep1Title: "Kredite einstellen oder auf FREE bleiben",
      plansStep2Title: "Zahlungen einstellen",
      howWorksTitle: "Wie funktioniert es?",
      howWorksInfo: "Verstehen, was das Beste für Ihr Unternehmen ist",
      howWorksLine01: "Jedes Unternehmen kann seine Menüpunkte hochladen und ein digitales Menü erstellen, auf das die Kunden über einen generierten QR-Code zugreifen können. Zusätzlich können Unternehmen Links über das Admin-Dashboard teilen.",
      howWorksLine02: "Um Bestellungen zu erhalten und zu verwalten, müssen Unternehmen ein positives Guthabenkonto haben. Das Guthaben kann als Pay-as-you-go-Paket oder als unbegrenztes Monatsabonnement erworben werden.",
      howWorksLine03: "Um Zahlungen zu akzeptieren und Kunden die Möglichkeit zu geben, ihre Bestellungen zu bezahlen (einschließlich Bestellungen zum Mitnehmen und Liefern, bei denen eine Vorauszahlung erforderlich ist), müssen sich die Unternehmen anmelden und ein Stripe-Konto über die DreamDiner-Plattform einrichten. In diesem Szenario werden alle Verkaufsgelder direkt auf das Geschäftskonto eingezahlt, wobei die regulären Stripe-Gebühren und eine Provision von 1,2 % für die DreamDiner-Plattform abgezogen werden.",
      planFreeTitle: "Menü",
      planCreditTitle: "Gutschriften",
      planPaymentsTitle: "Unbegrenzt",
      planGetPaidTitle: "Bezahlt werden",
      planFreeInfo: "FREE Plan",
      planCreditInfo: "Guthaben: {number}",
      planPaymentsInfo: "Verlängert um: {date}",
      planPaymentsNotSubscr: "Nicht abonniert",
      planGetPaidInfo: "Steigern und sichern Sie Ihren Umsatz!",
      planFreeLine01: "Ein Standardplan ist vorhanden, wenn kein anderer Plan aktiv ist",
      planCreditLine01: "Ein Pay-as-you-go-Plan, bei dem 1 Credit = 1 Bestellung ist. Credit-Pakete sind einmalige Zahlungen, und Ihr Kontingent verfällt nicht. Sie können eine bestimmte Anzahl von Bestellguthaben kaufen und diese nach Belieben verwenden (\"pay as you go\").",
      planPaymentsLine01: "Ein monatliches Abonnement, das für Unternehmen mit mehr als 500 monatlichen Bestellungen empfohlen wird. Bei einem Abonnement zahlen Sie jeden Monat für einen festen Betrag an Bestellguthaben. Dieser Plan bietet Ihrem Unternehmen ein sicheres Gefühl. Sie müssen sich keine Gedanken mehr über das ständige Aufladen von Guthaben machen oder sich mit einem gesperrten System auseinandersetzen, wenn Ihrem Unternehmen mitten in der Arbeit das Guthaben ausgeht. Die monatlichen Bestellungen sind unbegrenzt, und Ihr bisheriges Guthaben (sofern vorhanden) bleibt eingefroren und unberührt",
      planGetPaidLine01: "Durch die Teilnahme am Get-Paid-Programm können Kunden direkt über das System bezahlen, so dass das Unternehmen keine Zahlungen mehr abwickeln muss und Bestellungen, insbesondere für Mitnahme- und Lieferdienste, sicher bezahlt werden",
      whenFreePlan: "Automatisch umgeschaltet, wenn das Guthaben 0 (Null) erreicht und kein Abonnement aktiv ist",
      whenCreditPlan: "Wird automatisch eingestellt, wenn das Guthaben positiv ist (>1) und das Abonnement nicht aktiv ist",
      whenPaymentsPlan: "Dieser Plan wird automatisch bei Abschluss eines Abonnements aktiviert, und das Guthaben bleibt davon unberührt",
      planGetPaidPlan: "Dieser Tarif ist eine leistungsstarke Ergänzung für Unternehmen, die entweder den Tarif Credits oder Unlimited nutzen. Er ermöglicht Kundenzahlungen und gewährleistet eine nahtlose und sichere Bestellung",
      explanFreePlan: "Dieser Ausweichplan wird automatisch aktiviert, wenn kein anderer Plan eingestellt ist und kein Geschäftsguthaben verfügbar ist. Eine kostenlose digitale Speisekarte mit bis zu 200 Artikeln, die Sie mit Ihren Kunden teilen können, ist immer verfügbar",
      explanCreditPlan: "Ein einfacher Pay-as-you-go-Plan bedeutet, dass jede Bestellung einem Guthaben entspricht. Jedes Mal, wenn Ihr Guthaben den festgelegten Mindestbetrag erreicht, laden Sie es manuell auf",
      explanGetPaidPlan: "Dieser voll ausgestattete Plan wird automatisch eingerichtet, sobald Sie ein Abonnement abgeschlossen haben, indem Sie einen eindeutigen Button-Link zu Ihrem bevorzugten Zahlungsanbieter einrichten, um Zahlungen von allen Kundenbestellungen direkt auf Ihr Konto zu erhalten, einschließlich der Verkaufsoptionen zum Mitnehmen und Liefern",
      planFreeClient: "Kunden können das Menü einsehen, aber keine Bestellungen aufgeben",
      planCreditClient: "Kunden können Bestellungen aufgeben, solange Ihr Geschäftsguthaben größer als 1 ist",
      planPaymentsClient: "Unternehmen können eine unbegrenzte Anzahl von Bestellungen pro Monat annehmen",
      planGetPaidClient: "Kunden können ihre Bestellungen abschließen, indem sie direkt bezahlen",
      benefitMenu: "Vollständig digitales Menü",
      benefitOrders: "Verwalten von Bestellungen",
      benefitSetPaid: "Bestellungen als PAID einstellen",
      benefitPayments: "Kunden-Direktzahlungen aktivieren",
      benefitUnlimited: "Unbegrenzte Aufträge",
      price100: "100 Credits = $10 (0.1)",
      price500: "500 Credits = $44 (0.088)",
      price1000: "1000 Credits = $150 (0.15)",
      priceSubs: "Abonnement = $44/Monat",
      priceGetPaid: "Gebühren = Stripe berechnet normalerweise 2,9% + 30¢ für jede erfolgreiche Kartenbelastung + 1,2% DreamDiner-Gebühr pro Bestellung",
      creditTerms: "Credits sind ab Kaufdatum 1 Jahr lang gültig",
      subscriptionTerms: "Jederzeit kündbar, das Abonnement bleibt bis zum nächsten Verlängerungsdatum aktiv",
      getPaidTerms: "Jederzeit kündbar durch Deaktivierung Ihres verknüpften Stripe-Kontos",
      btnGetMoreCredits: "Mehr Credits erhalten",
      btnUnlimited: "Unbegrenzt gehen",
      btnGetPaid: "Sich für GetPaid anmelden",
      siteLinkPayme: "https://dreamdiner.io/he-payment/",
      asterisk01: "Das Unternehmen akzeptiert Zahlungen mit seinem aktuellen Zahlungssystem und markiert Bestellungen manuell als PAID in der Bestellkarte oder in der PREP-APP (DreamDiner Preparation App)",
      asterisk02: "Abhängig davon, ob sich das Unternehmen bei GetPaid angemeldet hat"
    },
    credits: {
      buyCreditsTitle: "Guthaben kaufen - Pay as you go",
      buyCreditsText1: "1 Kredit = 1 Bestellung",
      buyCreditsTextBtn: "Alle geschäftlichen Einkäufe anzeigen",
      currentBalance: "Aktuelles Guthaben: {balance}",
      lastCreditPurchase: "Letzter Kreditkauf: {date}",
      underCreditsBtn: "fügt {credits} Credits zu Ihrem Konto hinzu",
      subscribeTitle: "Abonniert sein - Unbegrenzte Nutzung.",
      subscribeText: "Monatliches Abonnement, jederzeit kündbar.",
      lastRenewal: "Abonnement-Verlängerung um: {date}",
      subscrEnds: "Das Abonnement endet am:",
      backToPlansPageBtn: "Zurück zu den Plänen Seite",
      underSubscribeBtn: "Kauft ein Abonnement",
      beforeMinimum: "Setzen Sie einen Alarm, wenn Ihr Guthaben unter ein Minimum fällt",
      minimumLabel: "Alarmieren Sie mich, wenn das Guthaben unter den Mindestbetrag fällt:",
      purchasePageTitle: "Liste der gekauften Business Credits",
      purchasePageFreeCredits: "Kostenlose Kredite",
      purchasePageTotalPurchased: "GESAMT Käufe",
      purchasePageTotalOrders: "GESAMT-Bestellungen",
      purchasePageCreditBalance: "Gutschriften Saldo"
    },
    fixPlanPage: {
      title: "Abonnieren Sie den \"FIX\" Plan",
      titleIsSubscr: "Einstellungen für das Abonnement",
      subscrStatus: "Status des Abonnements:",
      subscrStatusCanceled: "Abgebrochen",
      nextPeymant: "Nächstes Zahlungsdatum:",
      activeUntil: "Abonnement aktiv bis:",
      subscrInvalid: "Das Abonnement ist ungültig. Bitte melden Sie sich im Kundenportal erneut an",
      btnCustomerPortal: "Kundenportal",
      CustomerPortalExplan: "Hier können Sie Ihre persönlichen Daten, die Zahlungsmethode, die Kündigung des Abonnements, die Verlängerung und vieles mehr einstellen"
    },
    getPaidPage: {
      title: "Für den Empfang von Zahlungen anmelden",
      subtitle01: "Bitte wählen Sie hier Ihr bevorzugtes Zahlungsanbieter-Gateway aus und eröffnen Sie ein verbundenes Konto auf der DreamDiner-Plattform. Dies ermöglicht es Kunden, für ihre Bestellungen zu bezahlen, und die Gelder werden auf Ihr verbundenes Konto eingezahlt",
      subtitle02: "Später können Sie die Verkaufsseite besuchen, um Ihr Zahlungs-Dashboard und Ihre Transaktionen einzusehen",
      btnStripSetupAccount: "Einrichten eines Stripe-Kontos",
      btnStripCreateAccount: "Ein Stripe-Konto erstellen",
      btnStripCreateConnected: "Verbunden als: bus@bus.com",
      btnSoon: "Bald verfügbar",
      learnMoreStripeLink: "Erfahren Sie mehr über Stripe Connect",
      learnMoreStripeHref: "https://stripe.com/en-de/connect",
      learnMorePayMeLink: "Erfahren Sie, wie Sie ein PayMe-Konto einrichten",
      learnMorePayMeHref: "https://dreamdiner.io/he-payment/",
      stripe: "Stripe",
      stripeEnabled: "Stripe-Zahlungen sind aktiviert!",
      btnGoToSalesPage: "Besuchen Sie Ihre Stripe Dashboards",
      paypal: "PayPal",
      payme: "PayMe",
      sepa: "SEPA",
      amazon: "Amazon",
      bitcoin: "Bitcoin",
      countries01: "Verfügbare Länder: Weltweit",
      countries02: "Verfügbare Länder: USA, Israel",
      countries03: "Verfügbare Länder: Europa",
      countries04: "Verfügbare Länder: USA, Europa"
    },
    qrPage: {
      title: "Ihre Geschäftsbestellungen",
      stripeTitle: "Bezahlen Sie mit Ihrem Stripe-Konto"
    },
    salesPage: {
      title: "Verwalten Sie Ihre Verkaufstransaktionen",
      subtitle01: "Zeigen Sie schnell Ihre Stripe-Zahlungen und -Auszahlungen in Dashboards an und kontrollieren Sie sie",
      subtitle02: "Öffnen Sie eine Registerkarte, um Ihre aktuellen Dashboards zu laden. Das Laden kann einige Zeit in Anspruch nehmen",
      btnStripeLoadDashboards: "Stripe Dashboards laden",
      subtitle03: "Tippen Sie auf eine beliebige Zeile in dieser Tabelle, um weitere Informationen und Aktionen anzuzeigen (Erstattungen, Transaktionsdetails usw.)",
      subtitle04: "Bitte richten Sie ein verbundenes Stripe-Konto ein, um die Schaltfläche zu aktivieren.",
      getPaidLink: "Setzen Sie Ihr Unternehmen auf GetPaid",
      paymentsTab: "Zahlungen",
      paymentsTabExplan: "Zeigt eine Liste von Zahlungen mit Export-, Erstattungs- und Streitbeilegungsfunktionen an",
      payoutTab: "Auszahlungen",
      payoutTabExplan: "Zeigt Auszahlungsinformationen und ermöglicht die Durchführung von Auszahlungen"
    },
    supervisors: {
      title: "Aufsichtspersonen",
      subtitle: "Aufsichtspersonen können auf das Admin-Dashboard zugreifen, allerdings mit bestimmten Einschränkungen",
      description: "Aufsichtspersonen können verschiedene Aufgaben innerhalb des Systems ausführen, wie z. B. die Übersicht über Bestellungen, die Überprüfung des Gesamtumsatzes, die Entgegennahme von Zahlungen, die Gewährung von Rabatten und vieles mehr. Aufsichtspersonen können auf das Dashboard zugreifen, wobei der Zugriff auf einige Funktionen eingeschränkt ist, die nur dem Systemadministrator zur Verfügung stehen.",
      redLine: "",
      redLine_wizard: "Dieser Abschnitt ist optional, aber es wird empfohlen, dass mindestens ein Supervisor hinzugefügt wird. Sie können sich auch selbst festlegen.",
      mySupervisors: "Ihre Aufsichtspersonen:",
      addSupervisors: "Aufsichtsperson hinzufügen",
      supervisorName: "Betreuer's Name",
      supervisorEmail: "Aufsichtsperson's Email",
      moreSubtitle: "Supervisors' image, logs, and more can be later changed in Settings"
    },
    tables: {
      title: "Tabellen",
      subtitle: "Liefern Sie effizient Ihre Kundenaufträge, die entsprechend dem Tischnamen und der Farbe, von der sie gesendet wurden, gekennzeichnet sind.",
      description: "Tische spielen eine wichtige Rolle im DreamDiner-System, denn sie dienen als wichtige Identifikatoren für Kundenbestellungen und die Kellner, die sie bedienen. Das System erstellt automatisch einen Tisch im Hintergrund, wenn keine Tische verfügbar sind. Jeder Tisch ist eindeutig benannt und farblich gekennzeichnet, damit sowohl der Tisch, der Kunde als auch der entsprechende Kellner leicht zu identifizieren sind. Außerdem werden Tische in der Table Planner Booking App verwendet, einem intelligenten Reservierungs- und Sitzplatzoptimierer. Es wird empfohlen, die Namen der Tische auf maximal vier Zeichen zu beschränken.\nNach dem Anlegen eines Unternehmens muss jedem Tisch ein 6-stelliger Code zugewiesen werden, um die Anwesenheit des Kunden zu gewährleisten. Dieser Code kann für jede Tabelle eindeutig sein oder ein gemeinsamer (Master-)Code für alle Tabellen innerhalb des Unternehmens. Es ist ratsam, diesen Code regelmäßig zu ändern.",
      redLine: "Dieser Abschnitt ist optional, wird aber empfohlen. Ein Unternehmen muss keine Tabellen haben.",
      redLine_wizard: "Dieser Abschnitt ist fakultativ, wird aber empfohlen. Ein Unternehmen braucht keine Tabellen zu haben.",
      myTables: "Ihre Tabellen:",
      addTables: "Tabelle hinzufügen",
      name: "Tabelle's Name",
      namePlaceholder: "Max. 4 Zeichen",
      seats: "Tischplätze",
      colorNote: "In Echtzeit passt das System die Tischfarbe entsprechend der Farbe des zugewiesenen Kellners an",
      tablePin: "Tisch 6-stelliger Code",
      tablePinExplanation: "Es ist wichtig, einen Tischcode zu haben, um zu bestätigen, dass der Kunde physisch am Tisch anwesend ist, wenn er eine Bestellung aufgibt. Sie können einen Aufkleber oder einen anderen Indikator mit diesem Code auf dem Tisch anbringen, und der Kunde muss ihn in der App eingeben, bevor er eine Bestellung zum Essen aufgibt. Wenn kein spezieller Tischcode zugewiesen ist, verwendet das System automatisch den Mastercode des Tisches. Es wird empfohlen, diese Codes regelmäßig zu aktualisieren.",
      tableMasterCodeTitle: "Tische Master Code",
      tableMasterCodeSubtitle: "Dies ist der Fallback-Tabellencode für den Fall, dass eine Tabelle keinen spezifischen Code definiert hat",
      tableMasterCodeHint: "z.B. 456789 (6 Ziffern)",
      tableMasterCodeSuccessMessage: "Master Code geändert und erfolgreich gespeichert",
      tableMasterCodeErrorMessage: "Fehler beim Speichern oder Ändern des Master-Codes",
      tableMasterCodePaletteLabel: "Verwendet Tabelle Master Code",
      tablePicker: {
        title: "Waiter's Table Picker",
        subTitle: "Wählen Sie einen Kellner und tippen Sie dann auf einen beliebigen Tisch, um ihn zuzuweisen",
        waiters: "Deine Kellner",
        tables: "Ihre Tische",
        alertNotAssigned: "Es gibt noch nicht zugewiesene Tische!"
      }
    },
    waiters: {
      waiter: "Kellner",
      title: "Kellner",
      subtitle: "Die Kellner werden Ihren Tischen zugewiesen, um die Lieferung der Bestellungen besser kontrollieren zu können.",
      description: "Kellner spielen eine entscheidende Rolle für den Service und die Effizienz der Arbeit im Restaurant's. DreamDiner kann jedoch ihre Anzahl und die Abhängigkeit des Restaurants von ihnen erheblich reduzieren.\nHier ist der Grund dafür: Kundenbestellungen aus den Kunden-Apps werden direkt an die Vorbereitungsstationen gesendet, mit anschließender unabhängiger Bezahlung. Die Kellner-App ist vor allem ein effizientes Werkzeug, das ihnen hilft, die als \"fertig\" deklarierten Speisen an die hungrigen Kunden zu liefern, die sehnsüchtig warten.\nDie Kellner-App ermöglicht auch den Zugriff auf das Menü, um Kundenbestellungen entgegenzunehmen und auszuführen.\nKellner können Ihren Tischen zugewiesen werden, so dass jeder Bestellung und jedem Tisch die Farbe des Kellners zugeordnet werden kann, um eine bessere Kontrolle und eine effiziente Auslieferung der Bestellung zu gewährleisten.\nDer Kellner meldet sich hier mit der von Ihnen genehmigten Handynummer in der Kellner-App an.",
      redLine: "",
      redLine_wizard: "Dieser Abschnitt ist optional, aber es wird empfohlen, dass mindestens ein Kellner hinzugefügt wird. Sie können auch sich selbst einstellen.",
      myWaiters: "Ihre Kellnerliste:",
      addWaiters: "Kellner hinzufügen",
      waiterName: "Kellner's Name",
      waiterPhoto: "Kellner's Foto",
      paletteTablesTitle: "Tische:",
      tables: "Zugewiesene Tische",
      tablePickerBtn: "Tische an Kellner zuweisen",
      assignAll: "Allen Tischen zuweisen",
      notAssignToTable: "Keinem Tisch zugewiesen",
      openPickerBtn: "Table Picker öffnen",
      moreSubtitle: "",
      moreSubtitle_wizard: "Hinweis: Waiters' image, logs, and more can be later controlled in Dashboard > Waiters"
    },
    kitchens: {
      title: "Prep (\"Vorbereitung\") Stations",
      subtitle: "Die Aufteilung der Artikel in Zubereitungsstationen erleichtert die Arbeit und zeigt nur die Artikel an, die mit der jeweiligen Station verbunden sind.",
      description: "Eine Zubereitungsstation ist ein bestimmter Bereich, in dem eine bestimmte Art von Speisen zubereitet wird. Zum Beispiel: \"Grill\", \"Indoor Bar\", \"Salatzubereitung\", usw.\nPlatzieren Sie eine PREP App in jeder Zubereitungsstation und stellen Sie den Selektor entsprechend ein. Ihre bestellten Artikel werden nun dort angezeigt, wo sie zubereitet werden sollen: ein bestellter Cocktail wird nur in der App Prep Station angezeigt, die auf \"Bar\" eingestellt ist - und der Home Burger in der \"Grill\" Prep Station, usw. Dieses selektive Routing verbessert die Genauigkeit und den Komfort Ihrer Vorbereitungen erheblich.\nDie Anzahl der Vorbereitungsstationen ist unbegrenzt.\nDie Hauptvorbereitung ist eine Systemvorgabe und enthält standardmäßig alle bestellten Stationsartikel.",
      redLine: "",
      redLine_wizard: "Die Hauptvorbereitung ist eine Systemvorgabe und zeigt alle bestellten Artikel unabhängig von den zugewiesenen Stationen an.",
      myKitchens: "Ihre Vorbereitungsstationen",
      addKitchens: "Hinzufügen einer Vorbereitungsstation",
      kitchenName: "Vorbereitungsstation's Name",
      kitchenPlaceholder: "Max. 3 Zeichen"
    },
    staff: {
      title: "Mitglieder des Personals",
      subtitle: "Mitarbeiter können sich in die PREP-APP einloggen, um die Artikelzubereitung an den ihnen zugewiesenen Stationen zu verwalten.",
      description: "Ein Mitarbeiter kann zum Beispiel ein \"Chef\", ein \"Sous Chef\", ein \"Barkeeper\", ein \"Grillmeister\", usw. sein. Ein Mitarbeiter ist auch berechtigt, die PREP-App zu betreten, in der alle Vorbereitungen durchgeführt und geliefert werden.\nWenn Sie möchten, können Sie jedes Mitglied einer Vorbereitungsstation zuweisen: einen Barkeeper der Station \"Bar\", den Sous-Chef der Station \"Salatzubereitung\", usw. Wenn sie nicht zugewiesen ist, wird sie immer unter der Standard-Vorbereitungsstation - \"Hauptvorbereitung\" - angezeigt.\nDer Mitarbeiter gibt die PREP App mit der von Ihnen genehmigten Handynummer hier ein.",
      redLine: "",
      redLine_wizard: "Dieser Abschnitt ist optional, aber es wird empfohlen, mindestens ein Personalmitglied hinzuzufügen. Sie können sich auch selbst eintragen.",
      myStaff: "Ihre Mitarbeiter:",
      addStaff: "Personalmitglied hinzufügen",
      staffName: "Name des Personals",
      staffPhoto: "Personal's Foto",
      staffMobile: "Mobilnummer des Personals",
      kitchen: "Standard-Vorbereitungsstation",
      kitchenRoles: "Rolle",
      staffTerminalHint: "Falls leer, müssen Sie möglicherweise zurückgehen und eine Vorbereitungsstation hinzufügen",
      color: "Zugewiesene Farbe",
      moreSubtitle_wizard: "Empfohlen: Personalbild, Protokolle und mehr können später in den Einstellungen kontrolliert werden",
      staffSelectTerminal: "Zuweisung zu einer Vorbereitungsstation",
      staffSelectMainKitchen: "Hauptvorbereitung"
    },
    categories: {
      title: "Kategorien",
      subtitle: "Kategorien werden verwendet, um relevante Einträge zu gruppieren, die dann auf der Menü-Landeseite angezeigt werden",
      description: "Kategorien gruppieren relevante Artikel und zeigen sie dann in den Menüs an, z. B. Burger, Vorspeisen, Cocktails und Heißgetränke. Ihre Artikel und Gerichte können mit einer oder mehreren Kategorien verknüpft werden: Zum Beispiel kann ein Home Burger mit der Kategorie \"Burger\" oder mit der Kategorie \"Rindfleisch\" und mit der Kategorie \"Unsere Specials\" verknüpft werden!\nKategorien sind unbegrenzt, aber versuchen Sie, sich kurz zu fassen.\nSie können ein Icon aus einem vorgegebenen Satz auswählen oder Ihr eigenes Icon oder Bild hochladen, um Ihrer Speisekarte das perfekte Aussehen zu geben.",
      myCategories: "Ihre Kategorien",
      showIcons: "Kategorie-Symbole anzeigen",
      addCategories: "Kategorie hinzufügen",
      categoryName: "Kategorie Name",
      checkbox: "Aus allen Menüs ausblenden",
      option_01: "Ein Bild oder ein Symbol hochladen",
      option_02: "Wählen Sie ein Icon aus einem vorgegebenen Set (Hintergrundfarbe wie in Schritt 1 festgelegt)",
      cancelItemCategory: "Entlinke einen Artikel aus dieser Kategorie nur, wenn ihm mindestens eine weitere Kategorie zugewiesen ist",
      hasItems: "Einige Artikel sind bereits mit dieser Kategorie verknüpft",
      hideFromMenu: "Diese Kategorie aus den Menüs ausblenden",
      hideFromMenuHidden: "Kategorie ist jetzt ausgeblendet",
      hideFromMenuVisible: "Kategorie ist jetzt sichtbar",
      iconExplan: "Wählen Sie ein Symbol, das vorübergehend verwendet und später geändert werden kann"
    },
    options: {
      title: "Optionen",
      subtitle: "Optionen sind alle Anpassungsfunktionen, die Sie Ihrem Kunden bei der Personalisierung seiner bestellten Artikel zur Verfügung stellen.",
      description: "Artikel-Extras sind alle persönlichen Anpassungen, die Sie Ihren Kunden bei der Bestellung eines Artikels ermöglichen.\nZum Beispiel: \"Keine Zwiebeln\", \"Ketchup hinzufügen\", \"Wrap to go\", usw.\nJedes \"Extra\" kann kostenlos sein oder mit einem bestimmten Preisschild versehen werden, das dem Gesamtpreis des Artikels hinzugefügt wird. Außerdem können Artikel mit so vielen \"Extras\" wie nötig verknüpft werden.",
      redLine: "Wählen Sie aus der vorgegebenen Vorlage oder fügen Sie Ihre eigene hinzu.",
      redLine_wizard: "Wählen Sie aus der vorgegebenen Vorlage oder fügen Sie Ihre eigene hinzu.",
      myExtras: "Ihre Artikeloptionen",
      addExtra: "Artikeloption hinzufügen",
      groupOptionsCount: "{count} Optionen",
      freeNoCharge: "Keine Gebühr",
      groupToggle: "Dies ist eine Gruppenoption",
      groupToggleDescription: "Wenn Optionen gruppiert sind, kann der Benutzer nur eine Option aus der gegebenen Gruppe auswählen",
      optionTitle: "Option Titel",
      optionNum: "Option #{num}",
      addGroupOption: "Option hinzufügen"
    },
    groupOptions: {
      title: "Optionen gruppieren",
      subtitle: "Gruppenoptionen sind eine Reihe von Optionen, bei denen nur eine Auswahl getroffen werden kann.",
      description: "Eine \"Gruppenoption\" ist eine Reihe von Optionen, von denen der Kunde nur eine auswählen kann. Zum Beispiel ist ein Grad in der Fleischzubereitung entweder roh, medium oder durchgebraten. Ein weiteres Beispiel für eine \"Gruppenoption\" ist die Auswahl einer einzelnen Beilage: der Kunde kann nur eine auswählen - Spaghetti, Reis oder Pommes frites.\nJede Option innerhalb der Gruppenoption kann kostenlos sein oder mit einem speziellen Preisschild versehen werden, das dem Gesamtpreis des Artikels hinzugefügt wird.",
      redLine: "Wählen Sie aus der vorgegebenen Vorlage oder fügen Sie Ihre eigene hinzu.",
      redLine_wizard: "Wählen Sie aus der vorgegebenen Vorlage oder fügen Sie Ihre eigene hinzu.",
      myGroupOptions: "Ihre Gruppenoptionen",
      addExtra: "Eine Gruppenoption hinzufügen",
      groupOptionsCount: "{count} Optionen",
      freeNoCharge: "Keine Gebühr",
      groupOptionsDrawerTitle: "Gruppe Option Titel",
      groupOptionsDrawerExplan: "Hinweis: Standardmäßig wird die erste Option so eingestellt, wie sie in der Client-App ausgewählt wurde",
      groupOptionsDrawerNum: "Option #{num} (in dieser Gruppe)",
      addGroupOption: "Hinzufügen einer Option zu dieser Gruppe",
      groupBadge: "Gruppe Option"
    },
    menus: {
      title: "Menüs",
      subtitle: "Menüs werden verwendet, um verschiedene Artikelpreise während der Geschäftszeiten anzupassen.",
      description: "Menüs sind dynamisch und zeitbezogen und steuern das Erscheinungsbild der in der Kunden-App angezeigten Artikel. Zum Beispiel: Frühstücksmenü zwischen 08:00-12:00 Uhr, Happy Hour Menü zwischen 17:00-19:00 Uhr, usw.\nIn jedem Menü werden die mit diesem Menü verknüpften Artikel angezeigt. Ein bestimmter Artikel kann in mehreren Menüs angezeigt werden. Die Artikel werden in jedem Menü innerhalb ihrer zugewiesenen Kategorie angezeigt. So kann z. B. Ihr Home-Cocktail im Happy-Hour-Menü und im Late-Night-Menü angezeigt werden, während er im Frühstücksmenü nicht erscheint.\nJeder Artikel kann in jedem Menü, in dem er präsentiert wird, ein anderes Preisschild tragen. Der Home Cocktail kann im Happy-Hour-Menü 6 $ kosten, im Late-Night-Menü aber 9,5 $. Eine weitere Möglichkeit besteht darin, einen globalen Rabatt festzulegen, der auf alle Artikel und Preise in diesem Menü angewendet wird.\nDie Menü-Zeitleiste bietet Ihnen eine bessere visuelle Darstellung aller aktiven Menüs zu verschiedenen Tageszeiten.\nVermeiden Sie sich überschneidende Menüs.\nDas Hauptmenü ist das Standardmenü, wenn kein anderes Menü verfügbar ist, und zeigt alle sichtbaren Elemente an.\nDas entsprechende Menü wird in der Kunden-App angezeigt, wenn das Geschäft außerhalb der Geschäftszeiten stattfindet.\nDas Hauptmenü ist das Standardmenü des Systems für 24 Stunden, wenn kein anderes Menü definiert ist. Wenn das Geschäft außerhalb der Geschäftszeiten als \"Geschlossen\" definiert ist, wird in Ihrem Geschäftsmenü in der Kunden-App standardmäßig das Hauptmenü mit allen Elementen angezeigt.",
      redLine: "",
      redLine_wizard: "Dieser Abschnitt ist optional, wird aber empfohlen. Ein Unternehmen braucht keine Menüs zu führen.",
      myMenus: "Ihre Speisekarten",
      addMenu: "Menü hinzufügen",
      hideMenu: "Menü ausblenden",
      menuName: "Menü Name",
      startTime: "Startzeit",
      endTime: "Endzeit",
      hasCategories: "Dieses Menü ist mit Kategorien verknüpft",
      itemsCount: "{count} Artikel"
    },
    items: {
      title: "Artikel",
      subtitle: "Artikel sind alle Produkte, die auf der Speisekarte Ihres Unternehmens stehen, einschließlich Speisen und Getränke. Stellen Sie sicher, dass sie ansprechend und von höchster Qualität sind!",
      description: "Endlich sind Sie bereit, Ihren ersten Artikel zu eröffnen! Jetzt, wo wir unsere Vorbereitungsstationen, unsere Menüs und Kategorien haben (Sie können gerne zurückgehen und weitere hinzufügen!), können wir sie alle miteinander verbinden, um Ihren perfekten ersten Artikel zu erstellen. Als Nächstes laden Sie ein schönes und spektakuläres Bild dieses Artikels hoch: Leckeres Essen beginnt mit einem köstlichen Aussehen. Hier können Sie auch Allergene und Etiketten aus einer vorgegebenen Liste zuweisen (Mehrfachauswahl möglich) und die Optionen und Extras festlegen.",
      myItems: "Meine Artikel",
      addItem: "Artikel hinzufügen",
      hide: "Aus allen Menüs ausblenden",
      symbol: "Code vorbereiten",
      itemDescription: "Beschreibung (bis zu {length} Zeichen)",
      image: "Artikel-/Gerichtsbild",
      categories: "Link zu Ihren Kategorien:",
      menus: "Link zu Ihren Menüs:",
      labels: "Etiketten hinzufügen: (Optional)",
      allergens: "Allergene hinzufügen: (Optional)",
      kitchens: "Vorbereitungsstationen",
      kitchenTerminalHint: "Vorbereitungsstation auswählen",
      kitchenTerminalMainKitchen: "Hauptvorbereitung",
      price: "Gelisteter Preis:",
      menuPrice: "Menü Preis:",
      discountPlaceholder: "Kein Sonderpreis",
      regularOptions: "Reguläre Optionen",
      groupOptions: "Gruppen-Optionen",
      optionsAlertTooltip: "Diese Option kann einen Fehler enthalten",
      addOption: "Erstellen Sie eine neue Option",
      optionsCount: "Optionen: {num}",
      groupOptionsCount: "Gruppen: {num}",
      extras: "Optionen",
      duplicateMessage: "Der Artikel wurde erfolgreich dupliziert!",
      linkToMenu: "Legen Sie für jedes benutzerdefinierte Menü einen anderen Preis fest (das Hauptmenü ist ein Standard-Systemmenü):",
      linkToMenuWizard: "(Link zu Menüs nach der Geschäftserstellung)",
      linkToMenuBusiness: "(Link zu den Menüs nach der Artikelerstellung)",
      pricesUpdate: "Preise aktualisieren",
      pricesReset: "Preise zurücksetzen",
      takeawayAllowTitle: "Mitnahme / Lieferung für diesen Artikel aktivieren?",
      takeawayAllowIsAlsoForTA: "Dieser Artikel ist auch für TA und Lieferung",
      takeawayAllownotForTA: "Dieser Artikel ist nur für den Verzehr bestimmt",
      badgeNotForTA: "Artikel nicht für Take-Away / Lieferung",
      badgeForAll: "Verzehr / Mitnahme / Lieferung",
      addCategory: "Eine neue Kategorie erstellen",
      archiveItemTitle: "Diesen Artikel archivieren (aus allen Menüs ausblenden)",
      archiveItemSwitchisVisible: "Artikel ist sichtbar",
      archiveItemSwitchisArchived: "Artikel ist als \"Archiviert\" eingestellt;",
      wizardImagesPopup: {
        title: "Wählen Sie ein Beispielbild",
        subtitle: "Sie können es später in den Artikeleinstellungen jederzeit ändern"
      },
      specialPrice: {
        price: "Verkaufspreis:",
        date: "Gültig bis:",
        hint: "Tippen Sie hier, um das Enddatum und die Uhrzeit festzulegen",
        dateTooltip: "Legen Sie ein Verfallsdatum für diesen Sonderpreis fest"
      }
    },
    dashboard: {
      staffCardTitle: "Personal",
      chefs: "Eingeloggtes Personalmitglied:",
      waiters: "Eingeloggte Kellner:",
      emptyStaff: "Derzeit sind keine angemeldet",
      onboardingCardTitle: "Onboarding",
      onboardingCardText: "Starten Sie auf einfache Weise",
      qrBlockTitle: "Aufträge erhalten",
      qrBlockSubTitle: "Scanne mich! Kundenbestelloptionen und weiterleitbare Links",
      settingsBlockTitle: "Übersicht",
      settingsBlockSubTitle: "Alle Geschäftsinformationen und Einstellungen",
      settingsBlockDynamicTitle: "Geschäfts-ID:",
      settingsBlockButtonTitle: "Einstellungen aktualisieren",
      salesBlockTitle: "Verkaufstransaktionen",
      salesBlockSubTitle: "Ihr Stripe-Zahlungs-Dashboard ist einfach zu bedienen und zu verwalten",
      reportsBlockTitle: "Verkaufstabellen",
      reportsBlockSubTitle: "Alle Bestellungen und Artikelanalysen auf einen Blick",
      reportsBlockDynamicTitle: "Heute's Umsatz:",
      reportsBlockButtonTitle: "Berichte ansehen",
      appsBlockTitle: "Apps",
      appsBlockSubTitle: "Informieren Sie Ihre Mitarbeiter darüber, wie sie ihre Apps herunterladen und sich anmelden können",
      appsBlockDynamicTitle: "Weißes Etikett:",
      appsBlockButtonTitle: "Apps erforschen",
      staffBlockTitle: "Personal",
      staffBlockSubTitle: "Alle Ihre Supervisoren, Mitarbeiter und Kellner",
      staffBlockDynamicTitle: "Derzeit eingeloggt:",
      staffBlockButtonTitle: "(ARBEIT)",
      tableplannerBlockTitle: "Tisch-Planer",
      tableplannerBlockSubTitle: "Organisieren und optimieren Sie Ihre Tischreservierungen im Restaurant",
      tableplannerBlockDynamicTitle: "Reservierungen heute:",
      tableplannerBlockButtonTitle: "(WORK)",
      ordersBlockTitle: "Verwalten von Aufträgen",
      ordersBlockSubTitle: "Überblick über Ihre PREP App für die heutigen Bestellungen",
      ordersBlockDynamicTitle: "In Vorbereitung:",
      ordersBlockButtonTitle: "(ARBEIT)",
      businessTierBlockTitle: "Upgrade-Pläne",
      businessTierBlockSubTitle: "Bleiben Sie bei einem FREE-Tarif oder führen Sie ein Upgrade durch, um Zahlungen zu erhalten",
      businessTierBlockDynamicTitle: "Aktueller Plan:",
      businessTierBlockButtonTitle: "(ARBEIT)",
      OnboardingBtn: "Einschulung",
      OnboardingBlockTitle: "Onboarding Stepper",
      OnboardingBlockSubTitle: "Eine Schritt-für-Schritt-Anleitung, die Ihnen hilft, die Systemkomponenten zu verstehen",
      OnboardingBlockDynamicTitle: "Aktuelle Schritte: 12",
      OnboardingBlockButtonTitle: "START",
      qrCodeClientBlockTitle: "QR-Code bestellen",
      qrCodePrintInputTitle: "Fügen Sie dem Druck einen Titel hinzu (optional)",
      qrCodeCopiedAlert: "Der URL-Link wurde kopiert",
      availableSoon: "Bald verfügbar!",
      credits: "Aktueller Plan: Credits",
      free: "Aktueller Plan: Kostenlos",
      "get-paid": "Aktueller Plan: Unbegrenzt",
      getPaidBlockTitle: "Bezahlt werden",
      getPaidBlockSubTitle: "Melden Sie Ihr Unternehmen an, um Zahlungen über Stripe oder PayPal zu erhalten",
      batchSettings: "Einstellungen",
      batchUtility: "Dienstprogramm"
    },
    overview: {
      title: "Übersichtsseite"
    },
    apps: {
      title: "Laden Sie Ihre Apps herunter",
      subTitle: "Hier finden Sie alle Links und Anmeldedaten, um effizient zu arbeiten",
      back: "Zurück zu {name}",
      appStore: "App Store",
      getItOn: "GET IT ON",
      googlePlay: "Google Play",
      download: "Herunterladen auf der",
      headerApp: "App",
      headerUsage: "Verwendung",
      headerLinks: "Download-Links",
      headerCreds: "Autorisierte Benutzer und Anmeldedaten",
      qrLinkSubtitle: "Im Browser öffnen",
      qrLinkExplan: "Ihr Unternehmen ist bereit, mit diesem QR-Code oder durch Klicken auf die Schaltfläche unten Bestellungen anzunehmen. Teilen Sie diesen QR-Code oder diese URL mit Ihren Kunden",
      kitchen: "PREP-App",
      kitchenUsage: "Platziert in jeder Prep Station, um neue Bestellungen zu kontrollieren und Vorbereitungen zu fördern",
      kitchenAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.kitchen",
      kitchenQrTitle: "titel des Küchenchefs",
      kitchenPrintTitle: "PREP App @Google Play",
      waiters: "Kellner App",
      waitersUsage: "Ermöglichen Sie Ihren Kellnern, Kundenbestellungen entgegenzunehmen und die fertigen Artikel an die Kunden zu liefern",
      waitersAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.waiter",
      waiterQRTitle: "kellner-Titel",
      waitersPrintTitle: "Kellner App @Google Play",
      supervisors: "Admin App",
      supervisorsUsage: "Ein effizientes Tool für Sie oder jeden Supervisor, um den aktuellen Service zu kontrollieren: Bestellungen, Kasse, Personal und mehr",
      supervisorsAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.admin",
      supervisorsWeb: "https://admin.dreamdiner.io",
      supervisorsQrTitle: "titel der Aufsichtsbehörde",
      supervisorsPrintTitle: "Admin App @Google Play",
      clients: "Client App",
      clientsUsage: "Hier können Ihre Kunden Sie finden: Ihr Menü einsehen oder Bestellungen aufgeben",
      clientsAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
      clientsAppStore: "https://apps.apple.com/us/app/gypsy-ace-2023/id6449999003",
      clientsWeb: "https://client.dreamdiner.io",
      clientsQrTitle: "kunden-Titel",
      clientsPrintTitle: "Kunden Apps",
      label: "Whitelabel-App",
      labelUsage: "Ihre private Kunden-App, die in Google Play und Apple App Store nur mit Ihren Restaurantartikeln präsentiert wird",
      labelAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.client.prod",
      labelAppStore: "",
      labelWeb: "https://dev.client.dreamdiner.io/business/4Cu0vVmfFH",
      labelQrTitle: "etikettentitel",
      labelPrintTitle: "etikettentitel",
      tablePlanner: "Tabelle Planer",
      tablePlannerUsage: "Eine plattformübergreifende Multi-Device-App, die Ihre Restaurantreservierungen zuverlässig steuert",
      tablePlannerAppGooglePlay: "https://play.google.com/store/apps/details?id=com.dreamdiner.planner",
      tablePlannerAppStore: "https://apps.apple.com/us/app/restaurant-seating-planner/id1463277281?ls=1",
      tablePlannerQrTitle: "tabelle Plannertitel",
      tablePlannerPrintTitle: "tablePlanner Titel",
      userName: "Name",
      userRole: "Rolle",
      userLogin: "Anmeldung",
      userSms: "SMS"
    },
    reports: {
      title: "Berichte Seite",
      startDate: "Start Datum",
      endDate: "Enddatum",
      orders: "Bestellungen",
      sales: "Verkäufe",
      refunds: "Erstattungen",
      ratings: "Bewertungen",
      selectY: "Wählen Sie Y",
      selectX: "Wähle X",
      displayType: "Typ anzeigen",
      totalSales: "Gesamtumsatz",
      totalOrders: "Aufträge gesamt",
      totalRefunds: "Erstattungen insgesamt",
      totalRatings: "Total Bewertungen",
      bar: "Bar",
      line: "Zeile",
      area: "Bereich",
      average: "Durchschnitt",
      showAverage: "Zeige Durchschnitt",
      print: "Drucken",
      export: "Exportieren",
      byDays: "Nach Tagen",
      byProducts: "Nach Artikeln",
      byWaiters: "Nach Kellnern"
    },
    orders: {
      switchOnlyLive: "\"In-Progress\" nur",
      switchOnlyUnpaid: "\"UnPaid\" only",
      labelPaid: "PAID",
      labelUnPaid: "UNPAID",
      labelInProgress: "In Bearbeitung",
      labelDone: "Erledigt",
      sortBy: "Sortieren nach:",
      perPage: "Pro Seite:",
      byNum: "Bestellnummer",
      byDate: "Datum der Bestellung",
      allTime: "Von allen Zeiten",
      downloadPrepApp: "Administratoren und gelistete Mitarbeiter sind eingeladen, die PREP-APP herunterzuladen, die eine breite Palette an zusätzlichen Optionen und Funktionen bietet",
      downloadPrepAppLink: "https://dreamdiner.io/dpa/",
      clientRatingItem: "Artikel vom Kunden bewertet als:",
      clientRatingWaiter: "Der Kellner wurde vom Kunden wie folgt bewertet:",
      clientTip: "Kellner's Trinkgeld:",
      clientNoRating: "Keine Bewertung gefunden",
      tooltipRefreshOrders: "Seite aktualisieren",
      tooltipRefreshOrdersUpdates: "Aktualisiert",
      tooltipRefreshOrdersNew: "Neu",
      tooltipFullScreen: "Vollbildmodus",
      tooltipSwitchInProgress: "Umschalten, um nur Aufträge in Bearbeitung anzuzeigen",
      tooltipSwitchUnPaid: "Nur unbezahlte Aufträge anzeigen",
      tooltipSwitchItems: "Bestellung anzeigen",
      tooltipOrderType: "Art der Bestellung",
      tooltipItemNumber: "Anzahl der Artikel in dieser Bestellung",
      tooltipItemNumberExtra: "Achtung! Einige Artikel haben besondere Optionen",
      tooltipBtnInProgress: "Klicken Sie, um eine Bestellung als DONE zu setzen",
      tooltipBtnDone: "Klicken Sie hier, um einen Auftrag als in Bearbeitung zurückzustellen",
      tooltipBtnPaidStatusPaid: "Zu UNPAID ändern",
      tooltipBtnPaidStatusUnPaid: "Als PAID einstellen",
      tooltipBtnOrderRated: "Auftrag wurde vom Kunden bewertet",
      tooltipBtnOrderRequest: "Anfrage des Kunden",
      list: {
        num: "Nummer.",
        client: "Kunde",
        tel: "Tel.",
        type: "Typ",
        table: "Tabelle",
        sum: "Summe",
        items: "Posten",
        remark: "Hinweis",
        payment: "Zahlung",
        id: "ID",
        time: "Zeit",
        status: "Vorbereitung"
      },
      tabs: {
        progress: "Fortschritt",
        completed: "Bereit",
        delivered: "Ausgeliefert"
      }
    },
    onboarding: {
      backToOnboardingBtn: "Weiter mit dem Onboarding",
      onboarding_cards: {
        obQR: {
          title: "Machen Sie die erste Bestellung!",
          description: "Scannen Sie den generierten Code oder klicken Sie auf die Schaltfläche, um zu starten. Dies ist der QR-Code, den Sie Ihren Kunden übermitteln, damit diese ihre Bestellung aufgeben können!",
          next_cta: "Weiter",
          prev_cta: "Zurück"
        },
        obOrder: {
          title: "Hier'ist Ihre Bestellung!",
          description: "Nachdem die Bestellung abgeschickt wurde, sehen Sie sie hier in Ihrem Dashboard. Versuchen Sie, sie als \"PAID\" oder \"DONE\" einzustellen.",
          label: "Bring mich hin",
          next_cta: "Weiter",
          prev_cta: "Zurück"
        },
        obReports: {
          title: "Berichte erforschen",
          description: "Echtzeitdaten zu allen Aktivitäten auf einen Blick! Sogar die Bestellung, die Sie gerade getätigt haben...",
          label: "Zeige mir Berichte",
          next_cta: "Weiter",
          prev_cta: "Zurück"
        },
        obKitchen: {
          title: "DreamDiner Zubereitungs-App",
          subtitle: "Verfügbar für Android-Geräte",
          description: "(Optional) Laden Sie die Android-App für die Zubereitungs- und Bestellungssteuerung (POC) herunter. Ihre zugewiesenen Mitarbeiter sind eingeladen, sich einzuloggen!",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "App-Link und Mitarbeiter"
        },
        obSettings: {
          title: "Einstellungen & Standort",
          description: "Überprüfen Sie alle Ihre Geschäftseinstellungen und aktualisieren Sie Ihren Standort",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Take me there"
        },
        obImages: {
          title: "Ersetze Demo-Bilder",
          description: "(Optional) Wenn Sie die Demobilder verwendet haben, können Sie sie jetzt durch Ihre echten Bilder ersetzen?",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Bring mich zu meinen zu ersetzenden Bildern"
        },
        obOptions: {
          title: "Link Artikeloptionen",
          description: "(Optional) Erinnern Sie sich an Ihre Artikeloptionen und Extras? Jetzt können Sie sie mit jedem Artikel verknüpfen",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Bring mich zu meinen zu aktualisierenden Optionen"
        },
        obMenu: {
          title: "Menüpunkt Preise",
          description: "(Optional) Legen Sie verschiedene Artikelpreise für Ihre Menüs fest (Überspringen, wenn Sie keine Menüs geöffnet haben)",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Bringen Sie mich zu meinen Artikeln, um Menüs zu verlinken"
        },
        obTables: {
          title: "Tabelle Codes einstellen",
          description: "(Optional) Legen Sie einen eindeutigen 6-stelligen Code für jede Tabelle fest, oder ändern Sie den Standard-Master-Code",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Nimm mich, um die Codes zu ändern"
        },
        obWaiters: {
          title: "Wartende zuweisen",
          description: "(Optional) Wenn Sie Kellner haben, stellen Sie sicher, dass sie ihren jeweiligen Tischen zugewiesen sind (Klicken Sie auf die Schaltfläche \"Tische den Kellnern zuweisen\")",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Bring mich zu meinen Kellnern"
        },
        obPlans: {
          title: "Upgrade-Pläne",
          description: "KOSTENLOS bleiben, KREDITEN kaufen oder ZAHLUNGEN erhalten? Sie haben die Wahl!",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Ende",
          learnMore: "Erfahren Sie hier mehr:",
          business_models_link: "Business-Pläne anzeigen"
        },
        obCategories: {
          title: "4. Kategorie-Symbole aktualisieren",
          description: "Legen Sie Ihre Kategorien, Icons oder Bilder genau so fest, wie Sie sie haben wollen!",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Take me there"
        },
        obLive: {
          title: "Setzen Sie das Geschäft auf LIVE",
          description: "Bereit zum LIVE-Schalten? Stellen Sie den Schalter auf der Karte auf \"Als LIVE einstellen\"",
          next_cta: "Weiter",
          prev_cta: "Zurück",
          label: "Take me there"
        },
        noshowDialog: {
          title: "Beenden Sie das OnBoarding?",
          explan: "Es wird dringend empfohlen, den Onboarding-Assistenten mindestens einmal zu durchlaufen, um alle Links und Bilder korrekt einzurichten, damit sie perfekt funktionieren",
          label: "Markieren Sie hier, um das OnBoarding nicht mehr anzuzeigen"
        }
      }
    },
    invite: "Benachrichtigen {name}",
    inviteSuccess: "Eine SMS-Einladung zum Download der App und zum Login wurde erfolgreich versendet :)",
    sections: {
      dashboard: "Dashboard",
      info: "Einstellungen",
      hours: "Aktivität-Stunden",
      supervisors: "Aufsichtspersonen",
      tables: "Tische",
      waiters: "Kellner",
      kitchens: "Vorbereitungsstationen",
      chefs: "Personal",
      categories: "Kategorien",
      menus: "Menüs",
      items: "Artikel",
      extras: "Artikel-Optionen",
      groupOptions: "Gruppen-Optionen",
      infoExplan: "Alle Geschäftsinformationen & Einstellungen",
      hoursExplan: "Wann sind Sie geöffnet? Geschlossen?",
      supervisorsExplan: "Verwalten Sie Ihre Vorgesetzten",
      tablesExplan: "Hinzufügen und Bearbeiten Ihrer Tische",
      waitersExplan: "Kellner bearbeiten und Tischen zuweisen",
      kitchensExplan: "Ihre Vorbereitungsstationen",
      chefsExplan: "Verwalten Sie Ihr Personal",
      categoriesExplan: "Hinzufügen und Bearbeiten Ihrer Kategorien",
      menusExplan: "Menüs hinzufügen und bearbeiten",
      itemsExplan: "Hinzufügen und Bearbeiten von Artikeln",
      extrasExplan: "Hinzufügen und Bearbeiten von Artikeloptionen",
      groupOptionsExplan: "Gruppenoptionen hinzufügen und bearbeiten"
    },
    toolbar: {
      creditsBannerText: "ALERT: Sie haben nur noch {credits} Credits übrig! Ihr definiertes Minimum ist: {minimum}",
      creditsBannerCta: "Geschäftsguthaben hier aufladen",
      credits: "Guthaben: {credits}",
      miminum: "Warnung, wenn unter: {miminum}",
      helpTitle: "Unterstützung",
      helpTooltip: "Unterstützungs-Chat",
      supportPopupTitle: "Scannen Sie den QR-Code",
      supportPopupOr: "Oder klicken Sie einfach, um uns zu erreichen:",
      supportPopupBtn: "Starten Sie einen Support-Chat",
      supportPopupSupportRemark: "Unser Support Center Team ist von 8:00 bis 16:00 Uhr, von Sonntag bis Freitag (UTC+2), erreichbar. Wir sind für Sie da, um Fragen zur Einrichtung Ihres Unternehmens zu beantworten und stehen Ihnen danach für alle Fragen und Probleme zur Verfügung!",
      supportHelpScreenshot: "Bitte helfen Sie uns, indem Sie einen Screenshot oder einen kurzen Clip beifügen, der Ihr Problem am besten beschreibt",
      breadcrumbs: {
        home: "Meine Geschäfte",
        info: "Geschäftliche Informationen",
        dashboard: "Dashboard",
        qr: "QR-Code und Bestellungslinks",
        overview: "Übersicht",
        apps: "Apps",
        reports: "Verkaufscharts",
        orders: "Aufträge verwalten",
        onboarding: "Onboarding",
        supervisors: "Vorgesetzte",
        tables: "Tabellen",
        waiters: "Kellner",
        kitchens: "Vorbereitungsstationen",
        chefs: "Personal",
        categories: "Kategorien",
        menus: "Menüs",
        items: "Artikel",
        extras: "Artikel-Optionen",
        edit: "Objekt bearbeiten",
        hours: "Stunden",
        groups: "Gruppenoptionen",
        purchases: "Einkäufe",
        planner: "Planer",
        plans: "Upgrade-Pläne",
        sales: "Verkaufstransaktionen",
        credits: "Mehr Credits erhalten",
        subscription: "FIX-Plan-Abonnement",
        payments: "Anmelden, um bezahlt zu werden",
      },
    },
    fromWizard: {
      title: "Ihr neues Unternehmen \"{title}\" ist bereit!\n500 Credits wurden Ihrem Guthaben hinzugefügt (obere rechte Ecke)",
      text: "Erwarten Sie eine Willkommens-E-Mail an: {email} (Spam prüfen!) mit einigen wichtigen Details.\n\nDie nächste Stufe ist ein freundliches Onboarding-Tutorial, das Ihnen hilft, Ihr Unternehmen perfekt einzurichten. Viel Spaß!",
      btn: "Starten Sie das Onboarding",
    },
  },
  currency: {
    name: "Dollar",
    abv: "USD",
    prefix: "$",
    suffix: "",
    make: "${num}"
  },
  currencies: {
    usd: {
      name: "U.S. Dollar (USD)",
      symbol: "$"
    },
    eur: {
      name: "Euro",
      symbol: "€"
    },
    ils: {
      name: "Israelischer Schekel",
      symbol: "₪"
    }
  },
  imagePicker: {
    default: "Ziehen Sie eine Datei hierher oder\r\nzum Hochladen durchsuchen",
    wizard: "Ein Bild für diesen Artikel hochladen"
  },
  kitchenRoles: {
    chef: "Chefkoch",
    sousChef: "Souschef",
    grillChef: "Grillchef",
    bartender: "Bar Tender",
    kitchenAssistant: "Küchenhilfe",
    other: "Sonstiges"
  },
  landing: {
    welcome: "Hallo, {user}. Ihr Business Dashboard",
    lastUpdated: "Letzte Aktualisierung: {date}",
    beta: "BETA",
    wizardTitle: "Ein neues Geschäft erstellen",
    wizardDescription: "Ein freundlicher Assistent führt Sie durch den gesamten Prozess",
    demoBusinessDescription: "Der Ort, um alle Funktionen auszuprobieren",
    emptyBusinessTitle: "Hier werden Sie Ihr neues Unternehmen sehen.",
    emptyBusinessSubTitle: "Die Gründung eines Unternehmens umfasst in der Regel zwei Phasen. Zunächst gibt es die \"Anfangsphase\", in der Sie einen Assistenten verwenden, um die Grundlagen einzurichten, wie z. B. einige Schlüsselartikel und relevante Funktionen, die für eine erste Musterbestellung benötigt werden. Dann gibt es die \"Ultimative\" Phase, in der Sie sich die Zeit nehmen, das komplette Geschäft mit allen notwendigen Artikeln und Funktionen einzurichten, die perfekt auf Ihren Arbeitsablauf zugeschnitten sind.",
  },
  models: {
    category: {
      singular: "kategorie",
      plural: "kategorien"
    },
    chef: {
      singular: "chefkoch",
      plural: "köche"
    },
    extra: {
      singular: "extra",
      plural: "extras"
    },
    item: {
      singular: "artikel",
      plural: "artikel"
    },
    kitchen: {
      singular: "küche",
      plural: "küchen"
    },
    menu: {
      singular: "speisekarte",
      plural: "menüs"
    },
    supervisor: {
      singular: "supervisor",
      plural: "vorgesetzte"
    },
    table: {
      singular: "tabelle",
      plural: "tische"
    },
    waiter: {
      singular: "kellner",
      plural: "kellner"
    }
  },
  multiSelect: {
    placeholder: "Wählen Sie einen oder mehrere",
    empty: "Die Liste ist derzeit leer",
    skipped: "Übersprungen (aber Standardwert hinzugefügt)",
    onlyOption: "Derzeit ist dies die einzige verfügbare Option hier"
  },
  demoBusiness: {
    demoMode: "Nur für Demozwecke. Speichern ist deaktiviert",
    showdemo: "DEMO Business anzeigen",
    hidedemo: "DEMO Business ausblenden"
  },
  nav: {
    login: "Einloggen",
    logout: "Abmelden",
    user: {
      guest: "Willkommen, Gast!",
      btn: "Dein Profil",
      editProfile: "Profil bearbeiten"
    },
    business: {
      demoBusinessTitle: "Demo Business",
      enterBusiness: "Geschäft eingeben",
      demoBusinessBtn: "Live-Demo"
    }
  },
  wizard: {
    dialog: {
      title: "Sie kehren zur gespeicherten Version Ihres Entwurfs zur Unternehmensgründung zurück.",
      subtitle: "Möchten Sie dort weitermachen, wo Sie aufgehört haben?",
      confirm_title: "Warnung!",
      confirm_subtitle: "Wenn Sie den Assistenten neu starten, wird alles, was Sie bisher getan haben, rückgängig gemacht und alle Ihre Änderungen werden gelöscht. Sind Sie sicher, dass Sie von vorne beginnen möchten?",
      confirm_cta: "Sicher, alles zurücksetzen",
      confirm_back: "Nein, zurückgehen"
    },
    suggestions: {
      option_01: "Sofortiges Hinzufügen aus einer gegebenen Vorlage",
      option_02: "Eigene Vorlage von Grund auf hinzufügen",
      toggle: "Vorschläge anzeigen",
      add: "Aus Vorlage hinzufügen",
      addTemplateBtn: "Diese Vorlage hinzufügen",
      skipNextBtn: "Vorerst überspringen"
    },
    toolbar: {
      saved: "Gesichert als Entwurf",
      typeRequired: "Dieser Schritt ist für alle Geschäftsarten erforderlich",
      typeChecked: "Dieser Schritt wird für diese Geschäftsart empfohlen",
      required: "Dieser Schritt ist erforderlich",
      mobileToolbarProgressNav: "Klicken Sie auf den Kreis, um zu navigieren",
      titles: {
        intro: "Assistent für die Gründung eines neuen Unternehmens",
        types: "Typen",
        customize: "Ihre Wizard-Stufen:",
        info: "Info",
        supervisors: "Vorgesetzte",
        tables: "Tische",
        waiters: "Kellner",
        kitchens: "Vorbereitungsstationen",
        chefs: "Personal",
        categories: "Kategorien",
        menus: "Menüs",
        extras: "Artikel-Optionen",
        items: "Artikel",
        terms: "Begriffe & Bedingungen",
        summary: "Zusammenfassung"
      },
      btns: {
        createBusiness: "Mein Geschäft erstellen!"
      }
    },
    util: {
      seeMore: "Alle anzeigen: {num}",
      listEmpty: "Liste ist derzeit leer :-0"
    },
    intro: {
      title: "Falls Sie mehr erfahren wollen...",
      subtitle: "",
      redLine: "Genießen Sie Ihr DreamDiner-Erlebnis!",
      title2: "Ein kurzes Erklärungsvideo",
      subtitle2: "Ein animierter Erklärungsclip zeigt den Prozess der Geschäftsgründung",
      redLine2: "2 Minuten sind alles, was man braucht :)"
    },
    customize: {
      title: "Was ist Ihr Geschäftstyp?",
      subtitle: "Wählen Sie eine Unternehmensvorlage aus, damit das System nur die für Ihren Unternehmenstyp erforderlichen Funktionen anpasst.\nWählen Sie \"Benutzerdefiniert\", um die Einstellungen manuell vorzunehmen oder alle Funktionen zu erkunden.",
      clickNext: "Um fortzufahren, klicken Sie auf die Schaltfläche \"Weiter\" in der oberen rechten Ecke",
      typeHeader: "Hier sind die Schritte des Assistenten, die für Ihren Unternehmenstyp empfohlen werden:",
      explan: "Um die Geschäftsart zu ändern, klicken Sie einfach auf \"Zurück\". Index: Stern = Erforderlicher Abschnitt, Kontrollkästchen = Abschnitt kann abgehakt werden, um zu löschen",
      bottom: "Machen Sie sich keine Sorgen: Sobald Ihr Unternehmen erstellt ist, kann jede übersprungene Funktion wieder zu Ihrem Unternehmen hinzugefügt werden",
      businessTypes: {
        truck: {
          title: "Food Truck",
          subtitle: ""
        },
        pub: {
          title: "Bar / Kneipe",
          subtitle: ""
        },
        fine: {
          title: "Restaurant",
          subtitle: ""
        },
        small: {
          title: "Kleines Diner Diner",
          subtitle: ""
        },
        cafe: {
          title: "Cafe",
          subtitle: ""
        },
        fast: {
          title: "Fast Food",
          subtitle: ""
        },
        buffet: {
          title: "Buffet",
          subtitle: ""
        },
        popup: {
          title: "Pop-up",
          subtitle: ""
        },
        ghost: {
          title: "Geisterküche",
          subtitle: ""
        },
        delivery: {
          title: "TA/Lieferung",
          subtitle: ""
        },
        kiosk: {
          title: "Kiosk",
          subtitle: ""
        },
        other: {
          title: "Benutzerdefiniert",
          subtitle: ""
        }
      },
      skippable: "Optional",
      points: {
        types: {
          title: "Geschäftstyp",
          subtitle: "Definieren Sie Ihr Unternehmen",
          time: "1/2 Minute"
        },
        customize: {
          title: "Geschäftliche Schritte",
          subtitle: "Gestalten Sie Ihr eigenes Geschäft",
          time: "1/2 Minute"
        },
        info: {
          title: "Geschäftliche Details",
          subtitle: "Name Ihres Restaurants, Beschreibung, Logo, Passwort, etc.",
          time: "2 Minuten"
        },
        supervisors: {
          title: "Vorgesetzte",
          subtitle: "Weisen Sie Ihre Restaurantaufseher zu (Admin sind nur Sie!)",
          time: "1 Minute"
        },
        tables: {
          title: "Tische",
          subtitle: "Fügen Sie Ihren ersten Tisch und Sitzplätze hinzu",
          time: "1 Minute."
        },
        waiters: {
          title: "Kellner",
          subtitle: "Fügen Sie Ihre Kellner hinzu und weisen Sie sie den Tischen zu",
          time: "1 Minute."
        },
        kitchens: {
          title: "Vorbereitungs-Stationen",
          subtitle: "Ihre Zubereitungsstationen: z. B. Grill, Salate, Bar usw.",
          time: "30 Sek."
        },
        chefs: {
          title: "Mitglieder des Personals",
          subtitle: "Ihr Team: Köche, Barkeeper, Tellerwäscher, etc.",
          time: "1 Minute."
        },
        categories: {
          title: "Kategorien",
          subtitle: "Fügen Sie Ihre Artikel' Kategorien hinzu",
          time: "30 Sekunden."
        },
        menus: {
          title: "Menüs",
          subtitle: "Z.B. Frühstücksmenü, Happy Hour Menü, etc.",
          time: "2 Minuten."
        },
        extras: {
          title: "Optionen/Extras",
          subtitle: "Z.B. Kein Salz, Extra Olivenöl, Rare oder Well-Done, etc.",
          time: "1 Minute."
        },
        items: {
          title: "Artikel",
          subtitle: "Jetzt kannst du deinen ersten Artikel oder dein erstes Gericht erstellen und verlinken!",
          time: "3 Minuten."
        },
        terms: {
          title: "Bedingungen & Konditionen",
          subtitle: "Lesen und akzeptieren Sie unsere Allgemeinen Geschäftsbedingungen",
          time: "+1 min."
        }
      }
    },
    business: {},
    terms: {
      title0: "OK, wie geht es weiter?",
      subtitle0: "Im nächsten Schritt werden Sie Ihr Unternehmen vor der Erstellung bestätigen. Sie müssen noch Änderungen vornehmen? Kein Problem, gehen Sie zurück und ändern Sie, Sie haben nur gespeichert. Nach der Erstellung scannen Sie den generierten QR-Code, um Ihre erste Bestellung aufzugeben. Toll!\nWir belohnen Ihr Unternehmen mit 500 KOSTENLOSEN Credits, mit denen Sie 500 Bestellungen aufgeben können, um DreamDiner zu testen, bevor Sie sich für einen weiteren Geschäftsplan entscheiden.\nFolgen Sie dem Einführungsleitfaden, um Ihr Geschäft noch besser zu machen, und Sie können LIVE gehen!",
      title: "Unsere Bedingungen & Bedingungen",
      description: "Wir haben es fast geschafft, und Sie sind startklar! Ein letzter Schritt, bevor wir Ihr einzigartiges Geschäft im DreamDiner-System eröffnen, lesen Sie bitte sorgfältig die folgenden Punkte und stimmen Sie ihnen zu:",
      redLine: "Vielen Dank fürs Lesen und Bestätigen durch Ankreuzen aller Kästchen :)",
      termsCheckbox: "Ich stimme den folgenden Punkten zu",
      termsLink: "Bedingungen und Richtlinien",
      paymentCheckbox: "Die Optionen für die Bezahlung und die Einrichtung der Zahlung sind in den Einstellungen aufgeführt",
      idleCheckbox: "Geschäftslöschung innerhalb von 90 Tagen, wenn keine Aktivität erfolgt"
    },
    summary: {
      businessCreationTitle: "Ist Ihr Unternehmen bereit für die Erstellung?",
      businessCreationSubTitle: "Hier sehen Sie, was Sie uns über die Gründung Ihres Unternehmens erzählt haben. Sie können zu jedem Schritt, den Sie benötigen, zurückkehren oder diesen nach der Gründung des Unternehmens ausführen.",
      businessCreationClickNext: "Um fortzufahren, klicken Sie auf die Schaltfläche \"CREATE MY BUSINESS\" in der oberen rechten Ecke",
      businessCreation3only: "In jedem Abschnitt werden nur die ersten drei Elemente angezeigt",
      staff: "Team",
      supervisors: "Vorgesetzte",
      waiters: "Kellner",
      chefs: "Personal",
      equipment: "Ort",
      kitchens: "Vorbereitungsstationen",
      tables: "Tische",
      products: "Menüs & Artikel",
      extras: "Extras",
      menus: "Menüs",
      categories: "Kategorien",
      items: "Artikel",
      regularOptions: "Reguläre Optionen",
      groupOptions: "Gruppen-Optionen",
      extraOptions: "{num} Optionen",
      addRegularOption: "Regelmäßige Option hinzufügen",
      addGroupOption: "Option \"Gruppe hinzufügen\"",
      finalize: "Beginnen Sie Ihr Geschäft",
      working: "Ihr Unternehmen gründen",
      patience: "Bitte warten Sie...",
      saving: {
        info: "Jetzt unser Geschäft speichern...",
        supervisors: "Aktualisierung der Supervisoren",
        tables: "Tabellen aktualisieren",
        waiters: "Kellner aktualisieren",
        kitchens: "Aktualisierung der Vorbereitungsstationen",
        chefs: "Aktualisierung des Personals",
        categories: "Aktualisieren von Kategorien",
        menus: "Menüs aktualisieren",
        extras: "Aktualisieren von Artikeloptionen",
        items: "Aktualisieren von Artikeln"
      }
    },
    steps: {
      info: "Infos",
      types: "Typen",
      customize: "Schritte",
      supervisors: "Vorgesetzte",
      tables: "Tabellen",
      waiters: "Kellner",
      kitchens: "Vorbereitungsstationen",
      chefs: "Personal",
      categories: "Kategorien",
      menus: "Menüs",
      extras: "Optionen",
      items: "Artikel",
      terms: "Begriffe",
      summary: "Zusammenfassung"
    }
  },
  validation: {
    required: "Diese Eingabe ist erforderlich",
    email: "Ungültige E-Mail Adresse",
    minLength: "Muss {min} Zeichen oder länger sein",
    maxLength: "Müssen {max} Zeichen oder kürzer sein",
    specificLengthDigits: "Müssen {number} Ziffern sein",
    sameAsEmail: "Emails stimmen nicht überein",
    sameAsPassword: "Passwörter stimmen nicht überein",
    maxValue: "Muss kleiner sein als {max}",
    minValue: "Muss mehr als {min} sein",
    toAfterFrom: "Die Endzeit muss später sein als die Startzeit",
    isUniquePhone: "Dieses Telefon wird hier bereits verwendet",
    isUniqueTitle: "Dieser Titel wird bereits hier verwendet",
    isUniqueSymbol: "Dieses Symbol wird bereits hier verwendet",
    url: "Muss eine gültige URL sein",
    mustBeInFuture: "Es muss eine Zeit in der Zukunft sein",
    numeric: "Muss eine Zahl sein",
    dataNotValid: "Eingegebene Daten nicht gültig"
  },
  hours: {
    default: "Standard",
    sunday: "Sonntag",
    monday: "Montag",
    tuesday: "Dienstag",
    wednesday: "Mittwoch",
    thursday: "Donnerstag",
    friday: "Freitag",
    saturday: "Samstag"
  },
  warnings: {
    hot: "Sehr heiß",
    organic: "Bio",
    special: "Spezial",
    spicy: "Scharf",
    vegan: "Vegan",
    longPreparation: "Lange Zubereitung",
    glutenFree: "Glutenfrei",
    crustacean: "Krustentier",
    fish: "Fisch",
    egg: "Ei",
    gluten: "Gluten",
    celery: "Sellerie",
    nuts: "Nüsse",
    milk: "Milch",
    peanuts: "Erdnüsse",
    sesame: "Sesam",
    molluscs: "Mollusken",
    lupins: "Lupinen",
    mustard: "Senf",
    mushroom: "Pilz",
    corn: "Mais",
    soya: "Soja",
    sulphite: "Sulfit"
  },
  imageCropper: {
    cropperTitle: "Ein Bild hochladen",
    cropperSubTitle: "Wenn es zu groß ist, versuchen Sie, den Schieberegler und die Größe des Bildausschnitts anzupassen",
    imageSize: "Aktuelle Bildgröße:",
    imageQuality: "Bildqualität:",
    maxImageSize: "Max. Bildgröße:",
    calcOnCrop: "[Beim Zuschneiden]",
    replaceImageBtn: "Hinzufügen / Ersetzen",
    cropImageBtn: "Speichern",
    reduceSizeHint: "Verringern Sie die Bildgröße, indem Sie den Wert für die Bildqualität ändern: 0,01 bis 1,0."
  },
  explanations: {
    supervisorStep01___Wizard: "Name und E-Mail sind der Benutzername des Supervisors für die Anmeldung im Admin-Dashboard",
    supervisorStep01_Business: "Name und E-Mail werden der Benutzername des Supervisors sein, um sich in das Admin-Dashboard einzuloggen",
    supervisorStep02___Wizard: "Die Handynummer wird der Benutzername des Supervisors sein, um sich in die PREP-APP einzuloggen (mehr dazu später)",
    supervisorStep02_Business: "Die Handynummer wird der Benutzername des Supervisors sein, um sich in die PREP-APP einzuloggen (Gehe zu: Dashboard > Apps)",
    tableStep01___Wizard: "Jeder Tisch muss einen Namen und die Anzahl der Plätze haben",
    tableStep01_Business: "Jeder Tisch muss einen Namen und die Anzahl der Sitzplätze haben",
    tableStep02___Wizard: "Weisen Sie eine Farbe für diesen Tisch zu",
    tableStep02_Business: "Weisen Sie eine Farbe für diesen Tisch zu",
    tableStep03___Wizard: "Setzen eines Tabellencodes",
    tableStep03_Business: "Setzen eines Tabellencodes",
    waiterStep01___Wizard: "Name und Handynummer sind der Benutzername des Kellners, mit dem er sich in die Kellner-App einloggen kann",
    waiterStep01_Business: "Name und Handynummer sind der Benutzername des Kellners, um sich in die Kellner-App einzuloggen",
    waiterStep02___Wizard: "Alle Tische - und Bestellungen, die von diesen Tischen aus gesendet werden - werden mit der Farbe des ausgewählten Kellners markiert, um die Lieferung zu erleichtern.",
    waiterStep02_Business: "Alle Tische - und Bestellungen, die von diesen Tischen aus gesendet werden - werden mit der Farbe des ausgewählten Kellners markiert, um die Zustellung zu erleichtern.",
    waiterStep03___Wizard: "(Optional) Der Kunde kann das Foto und den Namen des Kellners sehen, nachdem er eine Bestellung aufgegeben hat und auf der Bezahlseite, um Trinkgeld zu geben.",
    waiterStep03_Business: "(Optional) Der Kunde kann das Foto und den Namen des Kellners sehen, nachdem er eine Bestellung aufgegeben hat und auf der Bezahlseite, um ein Trinkgeld zu hinterlassen.",
    waiterStep04___Wizard: "Prüfen Sie, welche Tische diesem Kellner zugewiesen werden.",
    waiterStep04_Business: "Prüfen Sie, welche Tische diesem Kellner zugewiesen werden sollen.",
    terminalStep01___Wizard: "Halten Sie den angegebenen Namen so kurz und prägnant wie möglich",
    terminalStep01_Business: "Halten Sie den angegebenen Namen so kurz und prägnant wie möglich",
    terminalStep02___Wizard: "Die angegebene Farbe wird alle bestellten Artikel in der PREP-APP einfärben, um ihre zugewiesene Station leicht zu erkennen",
    terminalStep02_Business: "Die angegebene Farbe wird alle bestellten Artikel in der PREP-APP einfärben, um ihre zugewiesene Station leicht zu erkennen",
    staffStep01___Wizard: "Name und Handynummer sind der Benutzername des Mitarbeiters, mit dem er sich in die PREP-APP einloggt",
    staffStep01_Business: "Name und Handynummer sind der Benutzername des Mitarbeiters, mit dem er sich in die PREP-APP einloggt",
    staffStep02___Wizard: "Die angegebene Farbe färbt alle bestellten Artikel, die das Personalmitglied für Kunden in der PREP-APP anfertigt",
    staffStep02_Business: "Die angegebene Farbe färbt alle bestellten Artikel, die dieser Mitarbeiter für Kunden in der PREP-APP herstellt",
    staffStep03___Wizard: "Zuweisung einer Rolle und einer Zubereitungsstation",
    staffStep03_Business: "Zuweisung einer Rolle und einer Zubereitungsstation",
    staffStep04___Wizard: "Ein Foto ist optional, aber nützlich",
    staffStep04_Business: "Ein Foto ist optional, aber nützlich",
    categoryStep01___Wizard: "Jede Kategorie muss einen Namen und eine Standard-Hintergrundfarbe enthalten",
    categoryStep01_Business: "Jede Kategorie muss einen Namen und eine Standard-Hintergrundfarbe enthalten",
    categoryStep02___Wizard: "Sie können Ihr eigenes Symbol oder Bild hochladen oder aus dem mitgelieferten Satz auswählen. Icons und Bilder sind immer änderbar und aktualisierbar",
    categoryStep02_Business: "Sie können Ihr eigenes Icon oder Bild hochladen oder aus dem mitgelieferten Set auswählen. Icons und Bilder sind immer änderbar und aktualisierbar",
    menuStep01___Wizard: "Jedes Menü muss einen Namen, eine Start- und eine Endzeit enthalten. Wenn Sie Ihre Artikel einstellen, können Sie sie mit verschiedenen Menüs verknüpfen. Das Festlegen unterschiedlicher Preise für Artikel in verschiedenen Menüs ist jedoch erst nach der Erstellung des Geschäfts möglich. Wenn ein Menü während der angegebenen Start- und Endzeit aktiv ist, hat der für die Artikel dieses Menüs festgelegte Preis Vorrang vor den regulären Preisen.",
    menuStep01_Business: "Jedes Menü muss einen Namen, eine Start- und eine Endzeit enthalten. Vergessen Sie nicht, zu Artikel zu gehen und die entsprechenden Artikel mit diesem Menü zu verbinden. Wenn ein Menü während der angegebenen Start- und Endzeit aktiv ist, hat der für die Artikel dieses Menüs festgelegte Preis Vorrang vor den regulären Preisen. Standardmäßig werden alle Artikel automatisch zu einem neuen Menü hinzugefügt, bis Sie sie im Abschnitt Artikel manuell abwählen.",
    menuStep02___Wizard: "Die zugewiesene Farbe dient nur der visuellen Darstellung der Menüs im Diagramm. Vermeiden Sie die Überlappung von Menüs.",
    menuStep02_Business: "Die zugewiesene Farbe dient nur der visuellen Darstellung der Menüs auf dem Diagramm. Überschneidungen der Menüs sind zu vermeiden.",
    optionsDoThis___Wizard: "Geben Sie für jede Option einen Titel an.\nKreuzen Sie das Kästchen an, wenn es kostenlos ist, oder deaktivieren Sie es, um einen Preis festzulegen, der bei der Bestellung zum Preis des Artikels hinzugefügt wird. Später können Sie Ihre Artikel mit dieser Option verknüpfen.",
    optionsDoThis_Business: "Geben Sie einen Titel für jede Option an.\nAktivieren Sie das Kästchen, wenn die Option kostenlos ist, oder deaktivieren Sie es, um einen Preis festzulegen, der bei der Bestellung zum Preis des Artikels hinzukommt.\nDenken Sie daran, diese Option mit den entsprechenden benötigten Artikeln zu verknüpfen.",
    groupOptionsStep01___Wizard: "Geben Sie einen Titel für die Gruppenoption an.",
    groupOptionsStep01_Business: "Geben Sie einen Titel für die Gruppenoption an.",
    groupOptionsStep02___Wizard: "Markieren Sie das Kästchen, wenn es kostenlos ist, oder deaktivieren Sie es, um einen Preis festzulegen.\nStandardmäßig wird die oberste Option auf der Kundenseite automatisch ausgewählt. Durch Ziehen und Ablegen können Sie die Reihenfolge aller Optionen innerhalb der Gruppe ändern.",
    groupOptionsStep02_Business: "Markieren Sie das Kästchen, wenn es kostenlos ist, oder deaktivieren Sie es, um einen Preis festzulegen.\nStandardmäßig wird die oberste Option auf der Kundenseite automatisch ausgewählt. Durch Ziehen und Ablegen können Sie die Reihenfolge aller Optionen innerhalb der Gruppe ändern.",
    itemsStep01___Wizard: "Halten Sie den Namen kurz und ansprechend. Prep Code ist der abgekürzte Code, der in der PREP APP verwendet wird (optional).",
    itemsStep01_Business: "Halten Sie den Namen kurz und ansprechend. Prep Code ist der abgekürzte Code, der in der PREP APP verwendet wird (optional).",
    itemsStep02___Wizard: "Preisgestaltung: Geben Sie den Listenpreis an und kehren Sie nach der Erstellung des Geschäfts hierher zurück, um den Verkaufspreis und die Menüpreise festzulegen, falls erforderlich.",
    itemsStep02_Business: "Denken Sie an die Regel: Ein Menüpreis ist stärker als der Listenpreis, und der Verkaufspreis hat Vorrang vor beiden.",
    itemsStep03___Wizard: "Der Bereich Optionen öffnet sich nach der Erstellung des Geschäfts.",
    itemsStep03_Business: "Wählen Sie aus, welche Optionen und Gruppenoptionen für diesen Artikel relevant sind (Mehrfachauswahl sowohl für reguläre als auch für Gruppenoptionen erlaubt)",
    itemsStep04___Wizard: "Dies ist optional, aber wichtig! Bitte fügen Sie Allergensymbole (z. B. Sesam, Nüsse usw.) und Kennzeichnungen (z. B. Vegan, Lange Zubereitung, Chefkoch Spezial usw.) hinzu.",
    itemsStep04_Business: "Dies ist optional, aber wichtig! Bitte fügen Sie Allergen-Symbole (z. B. Sesam, Nüsse usw.) und Kennzeichnungen (z. B. Vegan, Lange Zubereitung, Chefkoch-Spezial usw.) bei.",
    itemsStep05___Wizard: "Artikelbeschreibung und Bild-Upload",
    itemsStep05_Business: "Artikelbeschreibung und Bild-Upload",
    itemsStep06___Wizard: "Verknüpfen Sie diesen Artikel mit mindestens einer Kategorie (erforderlich) und mit einer Vorbereitungsstation (optional)",
    itemsStep06_Business: "Verknüpfen Sie diesen Artikel mit mindestens einer Kategorie (erforderlich) und mit einer Vorbereitungsstation (optional)",
    itemsStep07___Wizard: "Nach dem Anlegen des Geschäfts öffnet sich der Abschnitt Besondere Einstellungen.",
    itemsStep07_Business: "Präferenzen"
  }
}
