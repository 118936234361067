export default {
  supervisor: '769471323',
  table: '769471437',
  waiter: '769471566',
  kitchen: '769471731',
  chef: '769471872',
  menu: '769471998',
  category: '769472227',
  extra: '769472323',
  group: '769472323',
  item: '769472463'

}